import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  OnlineStatus,
  useGetConversationsQuery,
  useGetPtoCoverageConfigsQuery,
  useGetUsersQuery,
} from "@store";
import {isFamilyMember, isPatient, isPatientOrFamilyMember} from "@utils";
import {Banner, Text} from "ferns-ui";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import {DateTime} from "luxon";
import React from "react";

interface OfflineBannerProps {
  staffId?: string;
}

const WARNING_TEXT = (text: string): string =>
  `${text} is not online. They will not see messages until they are back online. If you need ` +
  "immediate clinical care, please dial 988, 911, or go to your local emergency room.";

const OUT_OF_OFFICE_TEXT = (userOutOfOffice: string, ptoCoverageUser?: string): string =>
  `${userOutOfOffice} is currently out of the office and will not receive your messages. ${
    ptoCoverageUser
      ? `${ptoCoverageUser} will be reaching out until ${userOutOfOffice} returns.`
      : "Please message the other members of your care team."
  }`;

export const OfflineBanner = ({staffId}: OfflineBannerProps): React.ReactElement | null => {
  const profile = useReadProfile();
  const {data: conversationData} = useGetConversationsQuery(
    isPatientOrFamilyMember(profile?.type) ? {} : skipToken
  );
  const conversationUserIds = uniq(
    flatten(conversationData?.data?.map((c) => c.users?.map((u) => u.userId?._id)))
  ).filter((u) => u) as string[];
  const otherUserIds = conversationUserIds.filter((u) => u !== profile?._id);
  const {data: otherUsersData} = useGetUsersQuery({_id: {$in: otherUserIds}});
  const {data: ptoCoverages} = useGetPtoCoverageConfigsQuery({
    userIdOnPto: {$in: otherUserIds},
  });

  // Array of care team online statuses
  const getCareTeamOnlineStatus = (): OnlineStatus[] | undefined => {
    return otherUsersData?.data?.map((staff) => staff?.online);
  };

  // Boolean representing that ALL care team staff are offline for patients
  const careTeamOfflineForPatients = getCareTeamOnlineStatus()?.every(
    (status) => status?.forPatients !== true
  );
  // Boolean representing that ALL care team staff are offline for family members
  const careTeamOfflineForFamilyMembers = getCareTeamOnlineStatus()?.every(
    (status) => status?.forFamilyMembers !== true
  );

  if (staffId) {
    // Check if an individual staff is offline or not
    const staff = otherUsersData?.data?.find((s) => s._id === staffId);
    const staffOffline =
      (isPatient(profile?.type) && !staff?.online?.forPatients) ||
      (isFamilyMember(profile?.type) && !staff?.online?.forFamilyMembers);
    const staffOutOfOffice = staff?.outOfOffice ?? false;
    if (staffOutOfOffice) {
      // Check to see if staff is on PTO
      const ptoCoverage = ptoCoverages?.data?.find(
        (p) =>
          p.userIdOnPto === staffId &&
          DateTime.fromISO(p.startDateTime) <= DateTime.now() &&
          DateTime.fromISO(p.endDateTime) >= DateTime.now()
      );
      // Get the name of the user that is covering the staff on PTO
      const coveringStaffUserName = ptoCoverage?.caseloadCoverages?.find(
        (c) => c.coveredUserId._id === profile?._id
      )?.coveringStaffId.name;
      // out of office message takes precedence since they wouldn't be online anyway
      return (
        <Banner
          hasIcon
          id="offlineWarning"
          status="warning"
          text={OUT_OF_OFFICE_TEXT(
            staff?.name ?? "Your Flourish team",
            coveringStaffUserName || undefined
          )}
        />
      );
    }
    if (staffOffline) {
      return (
        <Banner
          hasIcon
          id="offlineWarning"
          status="warning"
          text={WARNING_TEXT(staff?.name ?? "Your Flourish team")}
        />
      );
    } else {
      return null;
    }
  } else {
    // Check if all staff are offline or not. If they are all online,
    // display a message about response time.
    const allOffline =
      (isPatient(profile?.type) && !careTeamOfflineForPatients) ||
      (isFamilyMember(profile?.type) && !careTeamOfflineForFamilyMembers);
    if (allOffline) {
      return <Text>Online staff typically respond in 15 minutes or less.</Text>;
    } else {
      return (
        <Banner
          hasIcon
          id="offlineWarning"
          status="warning"
          text={WARNING_TEXT("Your Flourish team")}
        />
      );
    }
  }
};
