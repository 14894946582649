import {Day, DayTimeRange, SectionDivider, UserList} from "@components";
import {timezoneOptions} from "@constants";
import {useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  GetUsersArgs,
  ScheduleItem,
  useDeleteScheduleItemsByIdMutation,
  useGetScheduleItemsQuery,
  useGetUsersQuery,
  usePatchUsersByIdMutation,
  User,
} from "@store";
import {StaffStackParamList, StaffStackScreenProps} from "@types";
import {Box, Button, Heading, IconButton, Page, Text, useToast} from "ferns-ui";
import {DateTime} from "luxon";
import React, {Fragment, useCallback, useEffect, useState} from "react";

function printDayTimeRange(scheduleItem: ScheduleItem): string {
  const start = DateTime.fromISO(scheduleItem.startDatetime, {zone: scheduleItem.timezone});
  const end = DateTime.fromISO(scheduleItem.endDatetime, {zone: scheduleItem.timezone});

  // Check if both start and end are set to 00:00:00, indicating an all-day event.
  if (scheduleItem.allDay) {
    if (start.year === end.year && start.month === end.month && start.day === end.day) {
      return start.toFormat("MM/dd/yyyy");
    } else {
      return `${start.toFormat("MM/dd/yyyy")} - ${end.toFormat("MM/dd/yyyy")}`;
    }
  } else {
    if (scheduleItem.startDatetime === scheduleItem.endDatetime) {
      return start.toFormat("MM/dd/yyyy");
    } else {
      return `${start.toFormat("MM/dd/yyyy h:mm a")} - ${end.toFormat("h:mm a")}`;
    }
  }
}

interface TeamMemberAvailabilityProps {
  user: User;
  appointmentType: "Intake" | "Care";
}

const TeamMemberAvailability: React.FC<TeamMemberAvailabilityProps> = ({user, appointmentType}) => {
  const [updateUser] = usePatchUsersByIdMutation();
  const [schedule, setSchedule] = useState(
    (user.availabilitySchedule ?? []).filter((s) => s.appointmentType === appointmentType)
  );

  // Use effect to update schedule when user.availabilitySchedule changes.
  useEffect(() => {
    setSchedule(
      (user.availabilitySchedule ?? []).filter((s) => s.appointmentType === appointmentType)
    );
  }, [user.availabilitySchedule, appointmentType]);

  const toast = useToast();

  const timezoneText = `times in ${timezoneOptions.find((tz) => tz.value === user.timezone)?.label}`;

  const createUserSchedule = useCallback((): void => {
    {
      const toUpdateSchedule = [...schedule];
      // Pick the first unscheduled day as a default.
      const day = ([
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ].find((d) => !schedule.find((s) => s.day === d && s.appointmentType === appointmentType)) ??
        "Monday") as Day;
      const newSchedule = {
        day,
        startHour: 9,
        startMin: 0,
        endHour: 17,
        endMin: 0,
        appointmentType,
      };
      toUpdateSchedule.push(newSchedule);
      setSchedule(toUpdateSchedule);
      updateUser({
        id: user._id,
        body: {
          availabilitySchedule: [
            ...user.availabilitySchedule.filter((as) => as.appointmentType !== appointmentType),
            ...toUpdateSchedule,
          ],
        },
      }).catch(toast.catch);
    }
  }, [appointmentType, schedule, toast.catch, updateUser, user._id, user.availabilitySchedule]);

  return (
    <Box gap={2}>
      <Heading>
        {appointmentType === "Intake" ? "Intake" : "Care"} Availability ({timezoneText})
      </Heading>
      {schedule.map((s, i) => (
        <DayTimeRange
          key={s.day + String(s.startMin) + String(s.startHour)}
          dayTimeRange={s}
          showDelete
          onChange={(newSchedule): void => {
            const toUpdateSchedule = [...schedule];
            toUpdateSchedule[i] = {...newSchedule, appointmentType};
            setSchedule(toUpdateSchedule);
            updateUser({
              id: user._id,
              body: {
                availabilitySchedule: [
                  ...user.availabilitySchedule.filter(
                    (as) => as.appointmentType !== appointmentType
                  ),
                  ...toUpdateSchedule,
                ],
              },
            }).catch(toast.catch);
            toast.show(`${user.name}'s availability schedule updated.`);
          }}
          onDelete={(): void => {
            const toUpdateSchedule = [...schedule];
            toUpdateSchedule.splice(i, 1);
            setSchedule(toUpdateSchedule);
            updateUser({
              id: user._id,
              body: {
                availabilitySchedule: [
                  ...user.availabilitySchedule.filter(
                    (as) => as.appointmentType !== appointmentType
                  ),
                  ...toUpdateSchedule,
                ],
              },
            }).catch(toast.catch);
            toast.show(`${user.name}'s availability schedule updated.`);
          }}
        />
      ))}
      <Box width={200}>
        <Button
          iconName="plus"
          text="Add Schedule"
          variant="secondary"
          onClick={createUserSchedule}
        />
      </Box>
    </Box>
  );
};

interface TeamMemberProps {
  user: User;
}

const TeamMember = ({user}: TeamMemberProps): React.ReactElement => {
  const navigation = useNavigation<NativeStackNavigationProp<StaffStackParamList, "Vacation">>();

  const [deleteScheduleItem] = useDeleteScheduleItemsByIdMutation();
  const toast = useToast();

  const {data: vacationData} = useGetScheduleItemsQuery({
    "staff.userId": user._id,
    itemType: "Vacation",
  });

  return (
    <Box alignItems="center" color="base" maxWidth={400} paddingX={4} paddingY={2}>
      <Box gap={4}>
        <Heading size="lg">{user.name}</Heading>
        <TeamMemberAvailability appointmentType="Intake" user={user} />
        <TeamMemberAvailability appointmentType="Care" user={user} />
        <Box marginTop={2}>
          <Heading>Vacation</Heading>
        </Box>
        {vacationData?.data?.map((p: ScheduleItem) => (
          <Box
            key={p._id}
            alignItems="center"
            border="default"
            direction="row"
            marginBottom={2}
            paddingX={4}
            paddingY={2}
            rounding="lg"
            width="100%"
          >
            <Box flex="grow" height="100%" justifyContent="center">
              <Box marginBottom={1}>
                <Text>{p.title}</Text>
              </Box>
              <Box marginBottom={1}>
                <Text size="sm">{printDayTimeRange(p)}</Text>
              </Box>
              {Boolean(p.staffNotes !== "") && (
                <Box marginBottom={1}>
                  <Text size="sm">{p.staffNotes}</Text>
                </Box>
              )}
            </Box>
            <Box alignItems="center" height="100%" justifyContent="center" marginLeft={2}>
              <IconButton
                accessibilityLabel="edit vacation"
                iconName="pencil"
                onClick={() => {
                  navigation.navigate("Vacation", {
                    staffId: user._id,
                    scheduleItemId: p._id,
                  });
                }}
              />
            </Box>
            <Box alignItems="center" height="100%" justifyContent="center" marginLeft={2}>
              <IconButton
                accessibilityLabel="delete vacation"
                iconName="trash"
                variant="destructive"
                withConfirmation
                onClick={async () => {
                  await deleteScheduleItem(p._id).catch(toast.catch);
                }}
              />
            </Box>
          </Box>
        ))}
        <Box paddingY={2} width={200}>
          <Button
            iconName="plane"
            text="Add Vacation"
            onClick={(): void => {
              navigation.navigate("Vacation", {
                staffId: user._id,
                scheduleItemId: "",
              });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

interface MyTeamProps extends StaffStackScreenProps<"Team"> {}

export const MyTeamScreen = ({route}: MyTeamProps): React.ReactElement | null => {
  const profile = useReadProfile();
  const navigation = useNavigation<NativeStackNavigationProp<StaffStackParamList, "Team">>();

  const {allStaff, userIds} = route.params ?? {};
  // Set navigation title.
  useEffect(() => {
    if (allStaff) {
      navigation.setOptions({title: "All Staff"});
    } else {
      navigation.setOptions({title: "My Team"});
    }
  }, [allStaff, navigation]);

  let query: GetUsersArgs;
  if (allStaff) {
    query = {};
  } else if (userIds) {
    query = {_id: {$in: userIds}};
  } else {
    query = {supervisor: profile?._id};
  }

  const {data: supervisedUserData} = useGetUsersQuery(profile?._id ? query : skipToken);

  const [users, setUsers] = useState<User[]>([]);

  // Default to supervised users, but can add more users.
  useEffect(() => {
    if (users.length === 0 && supervisedUserData?.data?.length) {
      setUsers(supervisedUserData?.data!);
    }
  }, [supervisedUserData?.data, users.length]);

  return (
    <Page navigation={navigation}>
      <Box direction="column" mdDirection="row" width="100%">
        <Box marginBottom={4} marginRight={4}>
          <UserList
            buttonText="Select Users"
            staff
            title="Staff"
            userIds={users?.map((u) => u._id)}
            userPickerTitle="Staff"
            onChangeUsers={setUsers}
          />
        </Box>

        <Box direction="column" flex="grow" gap={4}>
          {users.map((u) => (
            <Fragment key={u._id}>
              <TeamMember user={u} />
              <SectionDivider />
            </Fragment>
          ))}
          {users.length === 0 && (
            <Box width="100%">
              <Box paddingY={4}>
                <Text align="center" bold>
                  You are not supervising any staff.
                </Text>
              </Box>
              <Box>
                <Text align="center">
                  To add supervised staff, add them to your panel, click User Info, and edit
                  &ldquo;Supervisor&rdquo;.
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Page>
  );
};
