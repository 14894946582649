import {NotificationManager, UserList} from "@components";
import {APPOINTMENT_CONFIG, SCHEDULE_ITEM_DEFAULT_USER_NOTE} from "@constants";
import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  AppointmentSlot,
  generateScheduleItemTitle,
  printAddress,
  ScheduleItemType,
  useGetScheduleItemsByIdQuery,
  useGetUsersQuery,
  usePatchScheduleItemsByIdMutation,
  usePostRecurringScheduleItemsMutation,
  usePostScheduleItemsMutation,
  User,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {hasFeatureFlag, isStaff} from "@utils";
import {
  Box,
  Button,
  Field,
  Heading,
  Page,
  printDate,
  printDateRange,
  printOnlyDate,
  TapToEdit,
  Text,
  useToast,
} from "ferns-ui";
import {DateTime} from "luxon";
import React, {useCallback, useEffect, useMemo, useState} from "react";

const generateScheduleItemLocation = ({
  type,
  users,
  staff,
}: {
  type: ScheduleItemType;
  users: User[];
  staff: User[];
}): {location: string; helperText: string} => {
  const appointmentConfig = APPOINTMENT_CONFIG[type];
  if (!appointmentConfig) {
    console.warn(`No appointment config found for type: ${type}`);
    return {location: "", helperText: ""};
  }

  let location = "";
  let helperText = "";

  if (appointmentConfig.video) {
    // If this is a clinical intake, we always want to use the guide's link.
    if (type === "Clinical Intake" || type === "Guide Clinical Intake") {
      const guide = staff?.find((s) => s.staffRoles.PatientGuide && Boolean(s.videoChatLink));
      if (guide?.videoChatLink) {
        helperText = `Automatically set to ${guide.name}'s video chat link.`;
        location = guide.videoChatLink;
      }
    } else {
      // In all other cases, grab the first staff with a video chat link.
      const videoStaff = staff?.find((s) => Boolean(s.videoChatLink));
      if (videoStaff?.videoChatLink) {
        helperText = `Automatically set to ${videoStaff.name}'s video chat link.`;
        location = videoStaff.videoChatLink;
      }
    }
  } else if (type === "In Home Onboarding Visit" || type === "In Home Guide Visit") {
    // Grab the first user with an address.
    const selectedAddressUser = users.find((m) => m.address?.address1);
    // For in person, automatically use the address of the first user.
    if (selectedAddressUser) {
      helperText = `Autofilled address of ${selectedAddressUser.name}`;
      location = printAddress(selectedAddressUser.address);
    }
  }

  return {location, helperText};
};

interface CreateIntakeScreenProps extends StaffStackScreenProps<"CreateIntake"> {}

export const CreateIntakeScreen = ({
  navigation,
  route,
}: CreateIntakeScreenProps): React.ReactElement | null => {
  const authUser = useReadProfile();
  const {
    slot: stringfiedSlot,
    userIds,
    staffIds,
    cadence,
    scheduleItemId,
    navToScreenOnSave,
  } = route.params;

  // When refreshing, these come through as strings rather than booleans. In normal cases,
  // they are passed as booleans.
  const urgent = (route.params.urgent as any) === "true" || route.params.urgent === true;
  const tentative = (route.params.tentative as any) === "true" || route.params.tentative === true;

  const slot: AppointmentSlot = JSON.parse(stringfiedSlot);
  const toast = useToast();
  const [createScheduleItem] = usePostScheduleItemsMutation();
  const [updateScheduleItem] = usePatchScheduleItemsByIdMutation();
  const [createRecurringScheduleItem] = usePostRecurringScheduleItemsMutation();
  const [users, setUsers] = useState<User[]>([]);
  const [staff, setStaff] = useState<User[]>([]);
  const [staffNotes, setStaffNotes] = useState("");
  const [userNotes, setUserNotes] = useState(SCHEDULE_ITEM_DEFAULT_USER_NOTE);
  const [notifications, setNotifications] = useState(APPOINTMENT_CONFIG[slot.type]!.reminders);
  const [location, setLocation] = useState(
    slot ? generateScheduleItemLocation({type: slot.type, users, staff}).location : ""
  );
  const [isZoomMeeting, setIsZoomMeeting] = useState(false);

  const hasValidZoomStaff = useMemo(() => {
    return staff.some((s) => s.zoomUserId);
  }, [staff]);

  // For recurring schedule items, default the end date to 1 year from the start date.
  const [endDate, setEndDate] = useState<string | undefined>(
    DateTime.fromISO(slot.startDatetime).plus({years: 1}).toISO()!
  );
  const [frequency, setFrequency] = useState<"Weekly" | "Every Other Week" | undefined>(
    cadence && ["Weekly", "Every Other Week"].includes(cadence)
      ? (cadence as "Weekly" | "Every Other Week")
      : undefined
  );

  const isRecurring = Boolean(frequency);

  const {data: scheduleItem} = useGetScheduleItemsByIdQuery(scheduleItemId ?? skipToken);

  const {data: userAndStaffData} = useGetUsersQuery(
    Boolean(userIds?.length || staffIds?.length)
      ? {_id: {$in: [...userIds, ...staffIds]}}
      : skipToken
  );
  const title = generateScheduleItemTitle(slot.type, users, staff, "NoRepeat");

  // Initial fetch of users and staff
  useEffect(() => {
    if (!userAndStaffData?.data?.length) {
      return;
    }
    const fetchedUsers = userAndStaffData.data.filter((u) => !isStaff(u.type));
    const fetchedStaff = userAndStaffData?.data.filter((u) => isStaff(u.type));
    setUsers(fetchedUsers);
    setStaff(fetchedStaff);
    setLocation(
      generateScheduleItemLocation({type: slot.type, users: fetchedUsers, staff: fetchedStaff})
        .location
    );
  }, [slot.type, userAndStaffData]);

  const confirm = useCallback(async (): Promise<void> => {
    if (!scheduleItemId) {
      toast.error("No schedule item id found, cannot confirm tentative appointment");
      return;
    }
    await updateScheduleItem({id: scheduleItemId, body: {tentative: false}})
      .unwrap()
      .catch(toast.catch);
  }, [scheduleItemId, updateScheduleItem, toast]);

  const onSave = useCallback(async (): Promise<void> => {
    if (slot.type === "Guide Clinical Intake") {
      if (!slot.associatedSlots?.[0]) {
        toast.error("Error scheduling Therapy Clinical Intake, no associated slot found");
        return;
      }
    }

    // Only send user ids and role, instead of the entire user objects.
    // That was causing request too large errors.
    const userIdsMapped = users.map((u) => ({userId: u._id}));
    let staffIdsMapped;
    if (slot.type === "Guide Clinical Intake") {
      // Remove the therapist from the initial staff list for Guide Clinical Intake, they'll
      // be scheduled separately.
      staffIdsMapped = staff
        .filter((s) => !Boolean(s.staffRoles.Therapist))
        .map((u) => ({userId: u._id}));
    } else {
      staffIdsMapped = staff.map((u) => ({userId: u._id}));
    }

    const body = {
      title,
      type: slot.type,
      startDatetime: slot.startDatetime,
      endDatetime: slot.endDatetime,
      location,
      isZoomMeeting,
      staff: staffIdsMapped as any,
      users: userIdsMapped as any,
      staffNotes,
      userNotes,
      notifications: notifications as any,
    };

    try {
      if (isRecurring) {
        const dayOfWeek = DateTime.fromISO(slot.startDatetime).weekdayLong!;
        const durationMinutes =
          DateTime.fromISO(slot.endDatetime)
            .diff(DateTime.fromISO(slot.startDatetime), "minutes")
            .toMillis() / 60000;

        // Default end date to 1 year from start date.

        await createRecurringScheduleItem({
          ...body,
          frequency: "Weekly",
          durationMinutes,
          interval: frequency === "Weekly" ? 1 : 2,
          daysOfWeek: [dayOfWeek],
          endDatetime: endDate,
          tentative,
        }).unwrap();
      } else {
        await createScheduleItem({
          ...body,
          urgent,
          // carePodId is used to set the care pod on the user automatically during intake.
          ...(APPOINTMENT_CONFIG[slot.type]?.intake ? {carePodId: slot.carePodId} : ({} as any)),
        }).unwrap();
      }
    } catch (error: any) {
      toast.catch(error);
      return;
    }

    if (slot.type === "Guide Clinical Intake") {
      // Also schedule the associated slot.
      const associatedSlot = slot.associatedSlots?.[0];
      const therapistStaff = staff.filter((s) => Boolean(s.staffRoles.Therapist));
      const therapistsMapped = therapistStaff.map((u) => ({userId: u._id}));
      const therapyTitle = generateScheduleItemTitle(
        associatedSlot.type,
        users,
        therapistStaff,
        "NoRepeat"
      );

      let res;
      try {
        res = await createScheduleItem({
          title: therapyTitle,
          type: associatedSlot.type,
          startDatetime: associatedSlot.startDatetime,
          endDatetime: associatedSlot.endDatetime,
          location,
          isZoomMeeting,
          staff: therapistsMapped as any,
          users: userIdsMapped as any,
          staffNotes,
          userNotes,
          notifications: notifications as any,
          urgent,
          tentative,
        }).unwrap();
      } catch (error: any) {
        toast.catch(error);
        return;
      }
      if (res) {
        toast.show(`Clinical Intake scheduled.`);
      }
    } else {
      if (tentative) {
        toast.show(`${slot.type} Tentative therapy slot scheduled.`);
      } else {
        toast.show(`${slot.type} scheduled.`);
      }
    }
    if (navToScreenOnSave) {
      if (navToScreenOnSave === "Workflows") {
        navigation.navigate("Staff", {
          screen: "Workflows",
        });
        return;
      } else if (navToScreenOnSave === "UserExplorer") {
        navigation.navigate("UserExplorer", {});
      }
    }
  }, [
    slot.type,
    slot.startDatetime,
    slot.endDatetime,
    slot.associatedSlots,
    slot.carePodId,
    users,
    title,
    location,
    staffNotes,
    userNotes,
    notifications,
    navToScreenOnSave,
    toast,
    staff,
    isRecurring,
    createRecurringScheduleItem,
    frequency,
    endDate,
    tentative,
    createScheduleItem,
    urgent,
    navigation,
    isZoomMeeting,
  ]);

  if (!slot) {
    return null;
  }

  if (!APPOINTMENT_CONFIG[slot.type]) {
    console.warn(`No config found for appointment type: ${slot.type}`);
    return null;
  }

  let time;
  if (slot.type === "Guide Clinical Intake") {
    if (!slot.associatedSlots?.[0]) {
      console.warn("No associated slot found for Guide Clinical Intake");
      return null;
    }
    time = printDateRange(slot.startDatetime, slot.associatedSlots[0]!.endDatetime, {
      timezone: slot.timezone,
    });
  } else if (frequency) {
    time =
      `${DateTime.fromISO(slot.startDatetime).toFormat("h:mm a")} ` +
      `to ${DateTime.fromISO(slot.endDatetime).toFormat("h:mm a")} ` +
      `${DateTime.fromISO(slot.startDatetime).toFormat("ZZZZ")} ` +
      `on ${DateTime.fromISO(slot.startDatetime).toFormat("EEEE")} ${printDate(slot.startDatetime)} `;
  } else {
    time = printDateRange(slot.startDatetime, slot.endDatetime, {timezone: slot.timezone});
  }

  const disabledSave = users.length === 0 || staff.length === 0;
  return (
    <Page maxWidth={800} navigation={navigation} scroll>
      <Box paddingY={4} width="100%">
        <Heading>
          {tentative ? "(Tentative) " : ""}
          {title}
        </Heading>
      </Box>
      <Box marginBottom={2}>
        <Text bold>Time: {time}</Text>
      </Box>
      {Boolean(isRecurring) && (
        <Box maxWidth={400}>
          <TapToEdit
            options={[
              {label: "Weekly", value: "Weekly"},
              {label: "Every Other Week", value: "Every Other Week"},
            ]}
            requireValue
            setValue={setFrequency}
            title="Frequency:"
            type="select"
            value={frequency}
            onSave={setFrequency}
          />
          <TapToEdit
            setValue={setEndDate}
            title="Recurring End Date:"
            transform={printOnlyDate}
            type="date"
            value={endDate}
            onSave={setEndDate}
          />
        </Box>
      )}
      {Boolean(tentative) && (
        <Box marginBottom={2}>
          <Text>
            Note: this is a tentative appointment, it will not be added to the calendar until
            confirmed.
          </Text>
        </Box>
      )}
      {Boolean(slot.type === "Guide Clinical Intake") && (
        <Box marginBottom={2}>
          <Text>Note: two events will be created, one for guides and one for the therapist.</Text>
        </Box>
      )}

      <Box maxWidth={400} paddingY={2}>
        <UserList
          familyMember
          patient
          pickerCarePodId={slot.carePodId}
          title="Users"
          userIds={users.map((u) => u._id)}
          onChangeUsers={setUsers}
        />
      </Box>

      {/* TODO: check if the staff are free if they change. */}
      <Box maxWidth={400} paddingY={2}>
        <UserList staff title="Staff" userIds={staff.map((u) => u._id)} onChangeUsers={setStaff} />
      </Box>

      <Box gap={2} maxWidth={400}>
        <Box>
          <Text bold>Notifications</Text>
        </Box>
        <NotificationManager
          appointmentType={slot.type}
          attendeeIds={users.map((u) => u._id)}
          notifications={notifications}
          setNotifications={setNotifications}
        />
        {Boolean(authUser && hasFeatureFlag(authUser, "zoom")) && (
          <Box paddingY={2}>
            <Field
              disabled={!hasValidZoomStaff}
              title="Zoom Meeting"
              type="boolean"
              value={isZoomMeeting}
              onChange={setIsZoomMeeting}
            />
            <Box marginTop={2}>
              <Text size="sm">
                Zoom meeting will automatically be created upon creation of the schedule item. This
                will also auto populate the location of the meeting.
              </Text>
            </Box>
            {Boolean(!hasValidZoomStaff) && (
              <Box marginTop={2}>
                <Text color="error" size="sm">
                  No valid Zoom staff found, please make sure at least one staff member has a Zoom
                  account attached to their profile.
                </Text>
              </Box>
            )}
          </Box>
        )}
        <TapToEdit
          disabled={isZoomMeeting}
          helperText={
            slot.type === "In Home Onboarding Visit"
              ? "Address where the onboarding will take place"
              : "Video Link for the meeting."
          }
          setValue={setLocation}
          title="Location"
          type="text"
          value={isZoomMeeting ? "Zoom Meeting" : location}
          onSave={setLocation}
        />
        <TapToEdit
          helperText="Internal notes about the meeting. These will not show up in the user's app."
          setValue={setStaffNotes}
          title="Staff-Facing Notes"
          type="textarea"
          value={staffNotes}
          onSave={setStaffNotes}
        />
        <TapToEdit
          helperText="These will show up in the user's app"
          setValue={setUserNotes}
          title="User-Facing Notes"
          type="textarea"
          value={userNotes}
          onSave={setUserNotes}
        />
        <Box direction="row" gap={2} paddingY={4}>
          <Button
            disabled={disabledSave}
            text={
              scheduleItem
                ? "Edit Appointment"
                : tentative
                  ? "Schedule Tentative Appointment"
                  : "Schedule Appointment"
            }
            variant={scheduleItem ? "secondary" : "primary"}
            onClick={onSave}
          />
          {Boolean(tentative && scheduleItemId) && (
            <Button text="Confirm Tentative Appointment" onClick={confirm} />
          )}
        </Box>
      </Box>
    </Page>
  );
};
