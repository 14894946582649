import {useGetUsersByIdQuery, usePostZoomSyncUserMutation} from "@store";
import {Box, Button, Text, useToast} from "ferns-ui";
import React, {FC, useCallback} from "react";

export interface ZoomUserIdRowProps {
  userId: string;
}

export const ZoomUserIdRow: FC<ZoomUserIdRowProps> = ({userId}) => {
  const toast = useToast();
  const {data: user} = useGetUsersByIdQuery(userId);
  const [syncUser] = usePostZoomSyncUserMutation();

  const onSync = useCallback(async () => {
    await syncUser({userId})
      .unwrap()
      .catch((error: any) => {
        toast.catch(error, "Error syncing");
      });
  }, [syncUser, userId, toast]);

  return (
    <Box borderBottom="default" direction="row" justifyContent="between" paddingY={2} width="100%">
      <Text bold>Zoom User ID: {user?.zoomUserId ?? "Not set"}</Text>

      <Button text="Sync" variant="muted" onClick={onSync} />
    </Box>
  );
};
