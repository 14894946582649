import {useFormInstanceContext} from "@components";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {
  FormInstance,
  usePatchFormInstanceAnswerMutation,
  usePatchFormInstancesByIdMutation,
  useSentryAndToast,
} from "@store";
import {useCallback} from "react";

import {useRetryPatchFormInstance} from "./useRetryPatchFormInstance";

export type FormInstancePatchReturnType = Promise<
  | {
      data?: FormInstance;
      error?: Error | FetchBaseQueryError;
    }
  | undefined
>;

type PatchAnswerParams = {
  answer: FormInstance["answers"][number];
  remoteAnswer?: FormInstance["answers"][number]["answers"] | undefined;
  customErrorMessage?: string;
};

type UseFormInstancePatchReturnType = {
  patchAnswer: (params: PatchAnswerParams) => FormInstancePatchReturnType;
  updateFormInstance: (
    body: Partial<FormInstance>,
    customErrorMessage?: string
  ) => FormInstancePatchReturnType;
  isLoading: boolean;
};

// TODO: limit logs for useFormInstancePatch to when AUTH_DEBUG is true
const log = (s: string): void => console.debug(`[useFormInstancePatch]: ${s}`);

export const versionErrorTitleIncluded = (e: any, formInstanceId: string): boolean => {
  return e?.data?.title?.includes(`No matching document found for id "${formInstanceId}" version`);
};

/**
 * Manages patching operations for a single `FormInstance`, ensuring sequential execution,
 * duplicate prevention, while preventing concurrent updates that could cause
 * `VersionError`, and by centralizing patch functions.
 *
 * How It Works:
 * - Tracks Pending Updates (`pendingUpdates`): Skips requests already in
 *      progress.
 * - Uses a Mutex (`formInstanceMutex`): Ensures only one request runs at a time.
 * - Handles API Calls with `unwrap()`: Auto SentryAndToast and returns errors instead of throwing.
 * - Cleans Up After Execution: Removes completed or failed updates from `pendingUpdates` to
 *   allow new updates.
 */
export const useFormInstancePatch = (
  formInstanceId: string,
  readOnly: boolean
): UseFormInstancePatchReturnType => {
  const sentryAndToast = useSentryAndToast();
  const [patchFormInstanceAnswer, {isLoading: isPatchingAnswer}] =
    usePatchFormInstanceAnswerMutation();
  const [patchFormInstance, {isLoading: isPatchingForm}] = usePatchFormInstancesByIdMutation();
  const {formInstanceMutex, pendingUpdates} = useFormInstanceContext();
  const isLoading = isPatchingAnswer || isPatchingForm || formInstanceMutex.isLocked();
  const {retryPatchAnswerOnVersionError} = useRetryPatchFormInstance(formInstanceId);

  // helper function to consistently remove request key from pending updates and log the action
  const removeRequestKeyAndLog = (requestKey: string, reqStatus: "succeeded" | "failed"): void => {
    log(`Patch ${reqStatus}: Removing request from pending updates ${requestKey}`);
    pendingUpdates.current.delete(requestKey);
  };

  // Method specific to updating answers
  const patchAnswer = useCallback(
    async ({
      answer,
      remoteAnswer,
      customErrorMessage,
    }: PatchAnswerParams): FormInstancePatchReturnType => {
      if (readOnly || !answer) {
        log(`Skipping patchAnswer because ${readOnly ? "it's readOnly" : "answer is null"}`);
        return;
      }

      const requestKey = `answer-${answer._id}-${JSON.stringify(answer.answers)}${Boolean(answer?.followUpResponse) ? `-[FR]-${answer.followUpResponse}` : ``}`;

      if (pendingUpdates.current.has(requestKey)) {
        log(`Skipping duplicate patch for ${requestKey}`);
        return;
      }

      pendingUpdates.current.add(requestKey);
      log(`Adding request to pending updates ${requestKey}`);

      try {
        log(`Current Queue Before Execution: ${Array.from(pendingUpdates.current).join(", ")}`);
        return await formInstanceMutex.runExclusive(async () => {
          log(`Patching answer ${requestKey}`);
          try {
            const data = await patchFormInstanceAnswer({
              formInstanceId,
              answerId: answer._id!,
              answer,
            }).unwrap();
            removeRequestKeyAndLog(requestKey, "succeeded");
            return {data};
          } catch (error) {
            const isVersionError = versionErrorTitleIncluded(error, formInstanceId);
            if (isVersionError && remoteAnswer) {
              log(`Retrying patchAnswer due to version error...`);
              return await retryPatchAnswerOnVersionError(
                answer,
                remoteAnswer,
                error as FetchBaseQueryError,
                requestKey
              );
            } else {
              removeRequestKeyAndLog(requestKey, "failed");
              const errMsg = customErrorMessage || "Error saving answer. Please try again.";
              sentryAndToast(errMsg, error as Error);
              return {error: error as Error};
            }
          }
        });
      } catch (error) {
        removeRequestKeyAndLog(requestKey, "failed");
        sentryAndToast(
          `${customErrorMessage || "Error saving update. Please try again."}`,
          error as Error
        );
        // Don't throw the error here to avoid stopping queued updates.
        // Return the error so the UI can handle it.
        return {error: error as Error};
      }
    },
    // exclude `formInstanceMutex` from dependencies to prevent re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readOnly, patchFormInstanceAnswer, formInstanceId, sentryAndToast]
  );

  // Method for updating form instance fields other than answers (status, attendance)
  const updateFormInstance = useCallback(
    async (
      body: Partial<FormInstance>,
      customErrorMessage?: string
    ): FormInstancePatchReturnType => {
      if (readOnly || !body) {
        log(`Skipping updateFormInstance because ${readOnly ? "it's readOnly" : "body is null"}`);
        return;
      }

      const requestKey = `form-${JSON.stringify(body)}`;

      if (pendingUpdates.current.has(requestKey)) {
        log(`Skipping duplicate patch for ${requestKey}`);
        return;
      }

      pendingUpdates.current.add(requestKey);
      log(`Adding request to pending updates ${requestKey}`);

      try {
        log(`Current Queue Before Execution: ${Array.from(pendingUpdates.current).join(", ")}`);
        return await formInstanceMutex.runExclusive(async () => {
          log(`Patching form instance ${requestKey}`);
          try {
            const data = await patchFormInstance({id: formInstanceId, body}).unwrap();
            removeRequestKeyAndLog(requestKey, "succeeded");
            return {data};
          } catch (error) {
            removeRequestKeyAndLog(requestKey, "failed");
            sentryAndToast(
              `${customErrorMessage || "Error saving update. Please try again."}`,
              error as Error
            );
            return {error: error as Error};
          }
        });
      } catch (error) {
        removeRequestKeyAndLog(requestKey, "failed");
        sentryAndToast("Error saving update. Please try again.", error as Error);
        // Don't throw the error here to avoid stopping queued updates.
        // Return the error so the UI can handle it.
        return {error: error as Error};
      }
    },
    // exclude `formInstanceMutex` from dependencies to prevent re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readOnly, patchFormInstance, formInstanceId, sentryAndToast]
  );

  return {patchAnswer, updateFormInstance, isLoading};
};
