import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useCollapseAllRightBar, useGetTodoQuery, useSetCollapseAllRightBar} from "@store";
import {IsMobileDevice} from "@utils";
import {
  BooleanField,
  Box,
  ScrollView,
  SegmentedControl,
  SegmentedControlBadgeConfig,
  SelectField,
} from "ferns-ui";
import React, {useState} from "react";

import {ActivityLogView} from "./ActivityLogView";
import {WorkflowChatView} from "./chat";
import {ClinicalView} from "./ClinicalView";
import {FitbitView} from "./FitbitView";
import {FormsColumn} from "./FormsColumn";
import {ScheduleColumn} from "./ScheduleColumn";
import {ToDoList} from "./ToDoList";
import {UserInfoView} from "./UserInfoView";

// Shows a segmented control with the tabs on desktop and a SelectList on mobile.
const ColumnSelector: React.FC<{
  tabs: StaffColumnTab[];
  maxTabs?: number;
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
  userId?: string;
}> = ({tabs, maxTabs, selectedTab, setSelectedTab, userId}) => {
  const profile = useReadProfile();
  const {data: assignedToDos} = useGetTodoQuery(
    userId && profile
      ? {referencedUser: userId, assignedUser: profile._id, isCompleted: false}
      : skipToken
  );
  if (IsMobileDevice) {
    return (
      <SelectField
        options={tabs.map((tab, i) => ({label: tab, value: String(i)}))}
        value={String(selectedTab)}
        onChange={(value: string) => setSelectedTab(Number(value))}
      />
    );
  }
  return (
    <SegmentedControl
      badges={
        tabs.map((t) =>
          t === "To Do's" && assignedToDos?.data?.length ? {count: assignedToDos.data.length} : null
        ) as SegmentedControlBadgeConfig[]
      }
      items={tabs}
      maxItems={maxTabs}
      selectedIndex={selectedTab}
      onChange={setSelectedTab}
    />
  );
};

export type StaffColumnTab =
  | "Chat"
  | "Forms"
  | "To Do's"
  | "Clinical"
  | "User"
  | "Fitbit"
  | "Activity"
  | "Schedule";

interface StaffColumnProps {
  tabs: StaffColumnTab[];
  maxTabs?: number;
  onRemoveUser: () => void;
  setShowHelpModal: (show: boolean) => void;
  showHelpModal: boolean;
  userId?: string;
  isCenterColumn?: boolean;
}

const COLUMN_MAP = {
  Chat: WorkflowChatView,
  Forms: FormsColumn,
  "To Do's": ToDoList,
  Clinical: ClinicalView,
  User: UserInfoView,
  Fitbit: FitbitView,
  Activity: ActivityLogView,
  Schedule: ScheduleColumn,
};

export const StaffColumn: React.FC<StaffColumnProps> = ({
  tabs,
  maxTabs,
  onRemoveUser,
  showHelpModal,
  setShowHelpModal,
  userId,
  isCenterColumn,
}) => {
  const [selectedTab, setSelectedTab] = useState<StaffColumnTab>(tabs[0]);
  const collapsed = useCollapseAllRightBar();
  const setCollapsed = useSetCollapseAllRightBar();

  if (!userId) {
    return null;
  }

  if (tabs.length === 1) {
    const ColumnComponent = COLUMN_MAP[tabs[0]];

    return (
      <ColumnComponent
        isCenterColumn={isCenterColumn}
        setShowHelpModal={setShowHelpModal}
        showHelpModal={showHelpModal}
        userId={userId}
        onRemoveUser={onRemoveUser}
      />
    );
  }

  const ColumnComponent = COLUMN_MAP[selectedTab];

  const shouldRenderCollapsedBooleanField =
    !IsMobileDevice && ["Clinical", "User"].includes(selectedTab);

  const shouldWrapInScrollView = selectedTab !== "Chat";

  return (
    <Box color="base" height="100%" marginLeft={2} marginRight={2} padding={2}>
      <ColumnSelector
        maxTabs={maxTabs}
        selectedTab={tabs.indexOf(selectedTab)}
        setSelectedTab={(t: number) => setSelectedTab(tabs[t])}
        tabs={tabs}
        userId={userId}
      />
      {Boolean(shouldRenderCollapsedBooleanField) && (
        <Box alignItems="center" direction="row" marginLeft={4} paddingY={4}>
          <BooleanField
            title="Collapse / Uncollapse All Sections"
            value={collapsed}
            onChange={setCollapsed}
          />
        </Box>
      )}
      {shouldWrapInScrollView ? (
        <ScrollView
          contentContainerStyle={{
            alignItems: "stretch",
            marginTop: 10,
            paddingHorizontal: 16,
          }}
        >
          <ColumnComponent
            isCenterColumn={isCenterColumn}
            setShowHelpModal={setShowHelpModal}
            showHelpModal={showHelpModal}
            userId={userId}
            onRemoveUser={onRemoveUser}
          />
        </ScrollView>
      ) : (
        <ColumnComponent
          isCenterColumn={isCenterColumn}
          setShowHelpModal={setShowHelpModal}
          showHelpModal={showHelpModal}
          userId={userId}
          onRemoveUser={onRemoveUser}
        />
      )}
    </Box>
  );
};
