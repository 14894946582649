/* Columns used in various views of Alerts Explorer */

import {AlertsExplorerColumn, CheckedCell, DateCell, NameCell, TextCell} from "@components";
import {ExplorerAlerts, separateOnUpperCase} from "@store";
import React from "react";

// Cell widths
export const DATE = 150;
export const CHECKBOX = 100;
export const LINK_BUTTON = 140;

export const resolvedColumn: AlertsExplorerColumn = {
  title: "Resolved",
  width: CHECKBOX,
  sort: "resolved",
  Component: (alertInstance: ExplorerAlerts): React.ReactElement => (
    <CheckedCell cellData={{value: alertInstance.resolved}} />
  ),
};

export const staffNameColumn: AlertsExplorerColumn = {
  title: "Assigned Staff",
  width: 200,
  Component: (alertInstance: ExplorerAlerts): React.ReactElement => (
    <TextCell
      cellData={{value: alertInstance.assignedToUsers?.map((as) => as.name)?.join(", ") ?? ""}}
    />
  ),
};

// Need to sort role
export const StaffTypeColumn: AlertsExplorerColumn = {
  title: "Staff Role(s)",
  width: 200,
  Component: (alertInstance: ExplorerAlerts): React.ReactElement => {
    const readableRoles = alertInstance.recipientStaffRole.map((role) => separateOnUpperCase(role));
    return <TextCell cellData={{value: readableRoles.join(", ") ?? ""}} />;
  },
};

export const userNameColumn: AlertsExplorerColumn = {
  title: "Patient/Caregiver Name",
  sort: "referencedUser.name",
  width: 205,
  Component: (alertInstance: ExplorerAlerts): React.ReactElement => (
    <NameCell text={alertInstance.referencedUser.name} userId={alertInstance.referencedUser._id} />
  ),
};

export const userTypeColumn: AlertsExplorerColumn = {
  title: "Patient/Caregiver",
  width: 205,
  Component: (alertInstance: ExplorerAlerts): React.ReactElement => (
    <TextCell
      cellData={{value: alertInstance.referencedUser.type === "Patient" ? "Patient" : "Caregiver"}}
    />
  ),
};

export const alertTypeColumn: AlertsExplorerColumn = {
  title: "Alert Type",
  sort: "parentAlert.title",
  width: 205,
  Component: (alertInstance: ExplorerAlerts): React.ReactElement => (
    <TextCell cellData={{value: alertInstance.parentAlert.title}} />
  ),
};

export const alertCreatedDateColumn: AlertsExplorerColumn = {
  title: "Alert Created Date",
  width: DATE,
  sort: "created",
  Component: (alertInstance: ExplorerAlerts): React.ReactElement => (
    <DateCell field="created" model={alertInstance} />
  ),
};

export const dueDateColumn: AlertsExplorerColumn = {
  title: "Due Date",
  width: DATE,
  sort: "dueDate",
  Component: (alertInstance: ExplorerAlerts): React.ReactElement => (
    <DateCell field="dueDate" model={{...alertInstance, ...alertInstance?.todoItem}} />
  ),
};
