import {MarkdownView} from "@components";
import {baseUrl, getAuthToken} from "@ferns-rtk";
import {useReadProfile} from "@hooks";
import {useLocalFormInstance} from "@store";
import {hasFeatureFlag} from "@utils";
import {Box, Button, IconButton, Text, useToast} from "ferns-ui";
import React, {useCallback, useState} from "react";

interface GPTMessage {
  isPrompt?: boolean; // true if submitted prompt by user, false if from GPT
  text: string;
  role: "assistant" | "user";
}

interface FormAISummarizerProps {
  formInstanceId: string;
}

export const FormAISummarizer: React.FC<FormAISummarizerProps> = ({formInstanceId}) => {
  const profile = useReadProfile();
  const formInstance = useLocalFormInstance(formInstanceId);
  const toast = useToast();
  const [messages, setMessages] = useState<GPTMessage[]>([]);

  const gptFetch = useCallback(
    async (endpoint: string, body: any, callback: (response: string) => void): Promise<void> => {
      const token = await getAuthToken();

      // Have to use fetch because axios doesn't support streaming responses
      let response;
      try {
        response = await fetch(`${baseUrl}/${endpoint}`, {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        });
      } catch (error: any) {
        toast.error("GPT request failed.", error);
        return;
      }
      if (!response.ok || !response.body) {
        toast.error(`GPT returned an invalid response: ${response.statusText}`);
        return;
      }

      // Here we start prepping for the streaming response
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      const loopRunner = true;

      while (loopRunner) {
        // Here we start reading the stream, until its done.
        const {value, done} = await reader.read();
        if (done) {
          break;
        }
        const decodedChunk = decoder.decode(value, {stream: true});
        callback(decodedChunk);
      }
    },
    [toast]
  );

  const handleNotesSummarySubmit = useCallback(async (): Promise<void> => {
    setMessages([{text: "", role: "assistant"}]);
    await gptFetch(
      "gpt/guideProgressNoteSummarization",
      {noteId: formInstanceId},
      (decodedChunk) => {
        setMessages((m) => {
          const lastMessage = m[m.length - 1];
          if (!lastMessage) {
            return [...m];
          }
          lastMessage.text = (lastMessage.text ?? "") + decodedChunk;
          return [...m];
        });
      }
    );
  }, [gptFetch, formInstanceId]);

  if (
    !formInstance ||
    !profile ||
    !hasFeatureFlag(profile, "enableGuideProgressNoteAISummarization")
  ) {
    return null;
  }

  return (
    <Box gap={4} marginBottom={4}>
      <Button
        text="Generate AI Summary (Beta)"
        variant="muted"
        onClick={handleNotesSummarySubmit}
      />
      <Box color="base" gap={4} paddingX={2} paddingY={4}>
        <Text>AI Summary:</Text>
        {messages.map((message, index) => {
          return (
            <Box key={index} marginRight={6}>
              <MarkdownView>{message.text}</MarkdownView>
              <Box direction="row" gap={2} justifyContent="end" marginTop={2} width="100%">
                <IconButton
                  accessibilityHint="Copy the summary"
                  accessibilityLabel="Copy"
                  iconName="copy"
                  tooltipText="Copy summary"
                  variant="secondary"
                  onClick={(): void => {
                    void navigator.clipboard.writeText(message.text);
                    toast.show("Copied to clipboard");
                  }}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
