import {ClinicalInfoFieldTemplates} from "@constants";
import {useAnalytics} from "@hooks";
import {useGetUsersByIdQuery, usePatchUsersByIdMutation} from "@store";
import {isStaff} from "@utils";
import {Box, Button, Text, TextArea} from "ferns-ui";
import React, {useEffect, useState} from "react";

interface UserTextFieldProps {
  userId: string;
  field: string;
}
const getTemplate = (field: keyof typeof ClinicalInfoFieldTemplates): string => {
  if (ClinicalInfoFieldTemplates[field]) {
    return ClinicalInfoFieldTemplates[field]?.join("\n\n") ?? "";
  } else {
    return "";
  }
};

export const UserTextField = ({userId, field}: UserTextFieldProps): React.ReactElement | null => {
  const [updateUser, {isLoading}] = usePatchUsersByIdMutation();
  const {data: user} = useGetUsersByIdQuery(userId);
  const existingText: string = (user as any)[field] || "";
  const [editText, setEditText] = useState(false);
  const [text, setText] = useState(existingText);
  const logEvent = useAnalytics();

  // When the user changes, refresh the current text with the user's values.
  // && avoid resetting text while editing.
  useEffect(() => {
    if (!editText) {
      setText(existingText);
    }
  }, [field, user, editText, existingText]);

  if (!user || isStaff(user?.type)) {
    return null;
  }

  const userUpdateFields = [
    "oneLiner",
    "weeklyUpdates",
    "engagementDetails",
    "carePlan",
    "safetyPlan",
  ];

  const fieldLogEventName = {
    oneLiner: "UpdateOneLiner",
    weeklyUpdates: "UpdateWeeklyUpdates",
    engagementDetails: "UpdateEngagementDetails",
    carePlan: "UpdateCarePlan",
    safetyPlan: "UpdateSafetyPlan",
  };

  if (editText) {
    return (
      <Box>
        <TextArea
          blurOnSubmit={false}
          grow
          value={text}
          onChange={(result): void => setText(result)}
        />
        <Box direction="row" paddingY={2}>
          <Box marginRight={4}>
            <Button
              disabled={isLoading}
              loading={isLoading}
              text="Save"
              onClick={async (): Promise<void> => {
                await updateUser({id: user._id, body: {[field]: text}});
                // This should be an ok solution for now. If we start to have more of these,
                // we should create a more generic solution
                if (userUpdateFields.includes(field)) {
                  await logEvent({
                    // keyof typeof fieldLogEventName gets the type of the object
                    // and then the keys of that object. Might be good to use
                    // where we are explicitly defining the keys of careTeam
                    name: fieldLogEventName[field as keyof typeof fieldLogEventName],
                    appliedUserId: user._id,
                    collectionModel: "users",
                    isActivityLogEvent: true,
                    docId: user._id,
                    payload: {prevValue: existingText, newValue: text},
                  });
                } else {
                  await logEvent({name: "EditUserTextField", field, appliedUserId: user._id});
                }
                setEditText(false);
              }}
            />
          </Box>
          <Button
            confirmationText="Are you sure you want to cancel? Your work will not be saved."
            text="Cancel"
            variant="muted"
            withConfirmation={text !== existingText}
            onClick={(): void => {
              setEditText(false);
              setText(existingText || ""); // Reset to existingText or empty string
            }}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        <Box paddingY={4}>
          <Text>{text}</Text>
        </Box>
        <Box direction="row" paddingY={2} width={180}>
          <Button
            iconName="pencil"
            text="Edit"
            onClick={(): void => {
              setEditText(true);
              // Only set to template if existingText is empty
              setText(existingText || getTemplate(field));
            }}
          />
        </Box>
      </Box>
    );
  }
};
