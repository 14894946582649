import {IsWeb} from "@utils";
import React, {useImperativeHandle, useRef, useState} from "react";
import {
  ActivityIndicator,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Pressable,
  ScrollView,
  Text,
  View,
} from "react-native";

import Color from "./Color";
import {TEST_ID} from "./Constant";
import {LoadEarlier} from "./LoadEarlier";
import {Message} from "./Message";
import {IMessage, LoadEarlierProps, MessageContainerProps, MessageProps} from "./Models";
import {TypingIndicator} from "./TypingIndicator";

export interface MessageContainerHandles {
  scrollTo: (options: {animated?: boolean; offset: number}) => void;
  scrollToBottom: (animated: boolean) => void;
}

export const MessageContainer = React.forwardRef<MessageContainerHandles, MessageContainerProps>(
  (props, ref): React.ReactElement | null => {
    const {
      activityIndicatorColor,
      activityIndicatorSize,
      activityIndicatorStyle,
      bottomContainerStyle,
      containerStyle,
      containerToNextStyle,
      containerToPreviousStyle,
      dateFormat,
      infiniteScroll = false,
      inverted = true,
      isLoadingEarlier = false,
      isTyping = false,
      label,
      loadEarlier = false,
      messages = [],
      onLoadEarlier = (): void => {},
      onMessageLayout,
      onPress,
      onQuickReply = (): void => {},
      onSelectMessageIds,
      optionTitles,
      quickReplyStyle,
      quickReplyTextStyle,
      renderAvatar,
      renderBubble,
      renderChatEmpty,
      renderDay,
      renderFooter,
      renderLoadEarlier,
      renderMessage,
      renderMessageAudio,
      renderMessageImage,
      renderMessageText,
      renderMessageVideo,
      renderQuickReplies,
      renderQuickReplySend,
      renderSystemMessage,
      renderTicks,
      renderTime,
      renderUsername,
      renderUsernameOnMessage,
      scrollToBottomComponent,
      scrollToBottomOffset = 200,
      scrollToBottomStyle,
      selectedMessageIds,
      shouldUpdateMessage,
      showMessageSelector,
      showScrollToBottom = false,
      showUserAvatar,
      textProps,
      textStyle,
      tickStyle,
      timeFormat,
      touchableProps,
      user,
      usernameStyle,
      wrapperStyle,
    } = props;

    const [isNearBottom, setIsNearBottom] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);

    const scrollViewRef = useRef<ScrollView>(null);
    const scrollPosition = useRef(0);
    const prevContentSizeHeight = useRef<number | undefined>(undefined);
    const prevDistanceFromBottom = useRef<number | undefined>(undefined);

    const doScrollTo = (options: {animated?: boolean; offset: number}): void => {
      scrollViewRef?.current?.scrollTo({
        x: 0,
        y: options.offset,
        animated: options.animated,
      });
    };

    const doScrollToBottom = (animated: boolean = true): void => {
      if (!scrollViewRef?.current) {
        console.warn("GiftedChat: scrollToBottom was called but component is not mounted");
        return;
      }
      scrollViewRef.current!.scrollToEnd({animated});
    };

    useImperativeHandle(ref, () => ({
      scrollToBottom: doScrollToBottom,
      scrollTo: doScrollTo,
    }));

    const renderTypingIndicator = (): React.ReactElement | null => {
      if (IsWeb) {
        return null;
      }
      return <TypingIndicator isTyping={isTyping || false} />;
    };

    const doRenderFooter = (): React.ReactElement | null => {
      if (renderFooter) {
        return renderFooter(props);
      }

      return renderTypingIndicator();
    };

    const doRenderLoadEarlier = (): React.ReactElement | null => {
      if (!loadEarlier) {
        return null;
      }
      const loadEarlierProps: LoadEarlierProps = {
        isLoadingEarlier,
        onLoadEarlier,
        label,
        containerStyle,
        wrapperStyle,
        textStyle,
        activityIndicatorColor,
        activityIndicatorSize,
        activityIndicatorStyle,
      };
      if (renderLoadEarlier) {
        return renderLoadEarlier(loadEarlierProps);
      }
      return <LoadEarlier {...loadEarlierProps} />;
    };

    const handleOnScroll = async (
      event: NativeSyntheticEvent<NativeScrollEvent>
    ): Promise<void> => {
      const {
        nativeEvent: {
          contentOffset: {y: contentOffsetY},
          contentSize: {height: contentSizeHeight},
          layoutMeasurement: {height: layoutMeasurementHeight},
        },
      } = event;
      scrollPosition.current = contentOffsetY + layoutMeasurementHeight;

      prevDistanceFromBottom.current = contentSizeHeight - contentOffsetY;
      prevContentSizeHeight.current = contentSizeHeight;

      await checkEndReached(contentOffsetY);
      if (!inverted) {
        if (scrollPosition.current > contentSizeHeight - scrollToBottomOffset!) {
          setIsNearBottom(true);
          setHasScrolled(true);
        } else {
          setIsNearBottom(false);
          setHasScrolled(true);
        }
      } else {
        if (scrollPosition.current < contentSizeHeight - scrollToBottomOffset!) {
          setIsNearBottom(true);
          setHasScrolled(true);
        } else {
          setIsNearBottom(false);
          setHasScrolled(true);
        }
      }
    };

    const onSelectMessage = async (message: IMessage, selected: boolean): Promise<void> => {
      if (!showMessageSelector) {
        return;
      }
      let newMessages: (string | number)[] = [];
      if (!selected) {
        newMessages = selectedMessageIds?.filter((id) => id !== message._id) ?? [];
      } else {
        newMessages = [...(selectedMessageIds ?? []), message._id];
      }
      await onSelectMessageIds?.(newMessages);
    };

    const renderRow = (item: any, index: number): React.ReactElement | null => {
      if (!item._id && item._id !== 0) {
        console.warn("GiftedChat: `_id` is missing for message", JSON.stringify(item));
      }
      if (!item.user) {
        if (!item.system) {
          console.warn("GiftedChat: `user` is missing for message", JSON.stringify(item));
        }
        item.user = {_id: 0};
      }
      if (messages && user) {
        const prevMsg = (inverted ? messages[index - 1] : messages[index + 1]) || {};
        const nextMsg = (inverted ? messages[index + 1] : messages[index - 1]) || {};

        const messageProps: MessageProps<IMessage> = {
          bottomContainerStyle,
          containerToNextStyle,
          containerToPreviousStyle,
          currentMessage: item,
          dateFormat,
          inverted,
          isSelected: (selectedMessageIds ?? []).includes(item._id),
          key: item._id,
          nextMessage: nextMsg,
          onMessageLayout,
          onPress,
          onQuickReply,
          timeFormat,
          optionTitles,
          onSelectMessage: (selected: boolean) => onSelectMessage(item, selected),
          position: item.user._id === user._id ? "right" : "left",
          previousMessage: prevMsg,
          quickReplyStyle,
          quickReplyTextStyle,
          renderAvatar,
          renderBubble,
          renderDay,
          renderMessageAudio,
          renderMessageImage,
          renderMessageText,
          renderMessageVideo,
          renderQuickReplies,
          renderQuickReplySend,
          renderSystemMessage,
          renderTicks,
          renderTime,
          renderUsername,
          renderUsernameOnMessage,
          shouldUpdateMessage,
          showMessageSelector,
          selectedMessageIds,
          showUserAvatar,
          textProps,
          tickStyle,
          touchableProps,
          user,
          usernameStyle,
        };

        if (renderMessage) {
          return renderMessage(messageProps);
        }

        const {key, ...rest} = messageProps;
        return <Message key={key} {...rest} />;
      }
      return null;
    };

    const doRenderScrollBottomComponent = (): React.ReactElement | null => {
      if (scrollToBottomComponent) {
        return scrollToBottomComponent();
      }

      return <Text>V</Text>;
    };

    const renderScrollToBottomWrapper = (): React.ReactElement | null => {
      return (
        <Pressable
          hitSlop={{top: 20, left: 20, right: 20, bottom: 20}}
          onPress={(): void => doScrollToBottom()}
        >
          <View
            style={[
              {
                opacity: 0.8,
                position: "absolute",
                right: 10,
                bottom: 30,
                zIndex: 999,
                height: 40,
                width: 40,
                borderRadius: 20,
                backgroundColor: Color.white,
                alignItems: "center",
                justifyContent: "center",
                shadowColor: Color.black,
                shadowOpacity: 0.5,
                shadowOffset: {width: 0, height: 0},
                shadowRadius: 1,
              },
              scrollToBottomStyle,
            ]}
          >
            {doRenderScrollBottomComponent()}
          </View>
        </Pressable>
      );
    };

    const checkEndReached = async (distanceFromEnd: number): Promise<void> => {
      if (
        infiniteScroll &&
        (hasScrolled || distanceFromEnd > 0) &&
        distanceFromEnd <= 100 &&
        loadEarlier &&
        onLoadEarlier &&
        !isLoadingEarlier
      ) {
        await onLoadEarlier();
      }
    };

    const onContentSizeChange = (contentWidth: number, contentHeight: number): void => {
      if (prevContentSizeHeight.current && inverted) {
        // Maintain the current scroll position, whether we're adding messages above or a new
        // message below.
        scrollViewRef?.current?.scrollTo({
          y:
            contentHeight -
            prevContentSizeHeight.current! +
            (prevContentSizeHeight.current! - prevDistanceFromBottom.current!),
          animated: false,
        });
      }
    };

    return (
      <View
        style={[{flexGrow: 1, overflow: "visible"}, containerStyle]}
        testID={TEST_ID.MESSAGE_CONTAINER}
      >
        {Boolean(isLoadingEarlier) && (
          <View
            style={{
              position: "absolute",
              width: "100%",
              top: 30,
              height: 50,
              alignItems: "center",
              justifyContent: "center",
              zIndex: 999,
            }}
          >
            <ActivityIndicator color="#0000ff" size="large" />
          </View>
        )}
        {renderChatEmpty && !messages?.length ? (
          inverted ? (
            renderChatEmpty()
          ) : (
            <View
              style={{
                flex: 1,
                transform: [{scaleY: -1}],
              }}
            >
              {renderChatEmpty()}
            </View>
          )
        ) : (
          <ScrollView
            ref={scrollViewRef}
            contentContainerStyle={inverted ? undefined : {flexGrow: 1}}
            scrollEventThrottle={16}
            style={{flex: 1}}
            onContentSizeChange={onContentSizeChange}
            onScroll={handleOnScroll}
          >
            {doRenderLoadEarlier()}
            {(messages ?? []).map((msg, index) => (
              <React.Fragment key={msg._id}>{renderRow(msg, index)}</React.Fragment>
            ))}
            {doRenderFooter()}
          </ScrollView>
        )}
        {isNearBottom && showScrollToBottom ? renderScrollToBottomWrapper() : null}
      </View>
    );
  }
);

MessageContainer.displayName = "MessageContainer";
