import {DownloadFileButton, ToolButton} from "@components";
import {baseUrl} from "@ferns-rtk";
import * as Sentry from "@sentry/react-native";
import {
  usePostScriptsBackfillPatientIdsMutation,
  usePostScriptsBackfillUserSettingsMutation,
  usePostScriptsCleanUpConversationStatusesMutation,
  usePostScriptsCleanupNotificationTasksMutation,
  usePostScriptsCreateConvosMutation,
  usePostScriptsDeleteNotificationsForDeletedScheduledItemsMutation,
  usePostScriptsDeleteOrphanWorkflowMappingsMutation,
  usePostScriptsFindOrphanedGoogleCalendarEventsMutation,
  usePostScriptsFtpMutation,
  usePostScriptsLoadAlertsDataMutation,
  usePostScriptsLoadCompanyHolidaysMutation,
  usePostScriptsLoadMedicalDiagnosisDataMutation,
  usePostScriptsLoadTestDataMutation,
  usePostScriptsSaveAllUsersMutation,
  usePostScriptsSyncAllScheduleItemsGoogleCalendarMutation,
  usePostScriptsSyncAssessmentsMutation,
  usePostScriptsSyncIntakeDatesMutation,
  usePostScriptsSyncPatientPharmaciesWithDoseSpotMutation,
  usePostScriptsSyncZoomUsersMutation,
  usePostScriptsUpdateConversationStatusIdsMutation,
  usePostScriptsUpdatePatientPreferredPharmaciesFromDoseSpotMutation,
  usePostScriptsUpdateScheduleItemTimezoneMutation,
  versionInfo,
} from "@store";
import {Box, Button, Heading, Tooltip} from "ferns-ui";
import React, {ReactElement} from "react";

import {useReadProfile} from "../../hooks";
import {hasFeatureFlag} from "../../utils";
import {CreateGoogleCalendar} from "./CreateGoogleCalendar";
import {MigrationButton} from "./MigrationButton";

export const SuperUserTools = ({}: {}): ReactElement => {
  const user = useReadProfile();
  return (
    <Box marginBottom={8}>
      <Box paddingY={2}>
        <Heading>Super User Tools</Heading>
      </Box>
      <Box direction="row" gap={2} paddingY={2} width="100%" wrap>
        <ToolButton
          iconName="link"
          screen="ReferralSourceAdmin"
          text="Referral Sources"
          variant="secondary"
        />
        <ToolButton
          iconName="link"
          screen="ReferralMethodAdmin"
          text="Referral Methods"
          variant="secondary"
        />
        <ToolButton
          iconName="file-medical"
          screen="InsurancePlanAdmin"
          text="Insurance Plans"
          variant="secondary"
        />
        <ToolButton
          iconName="user-doctor"
          screen="ExternalClinicianAdmin"
          text="External Clinician"
          variant="secondary"
        />
        <ToolButton iconName="link" screen="CarePodAdmin" text="Care Pods" variant="secondary" />
        <ToolButton
          iconName="id-badge"
          screen="UserStatusAdmin"
          text="User Status"
          variant="secondary"
        />
        <ToolButton iconName="copy" screen="FormAdmin" text="Forms Admin" variant="secondary" />
        <ToolButton
          iconName="clipboard"
          screen="HealthItemAdminScreen"
          text="Health Event Admin"
          variant="secondary"
        />
        <ToolButton
          iconName="house-medical"
          screen="CompanyOrganizationAdmin"
          text="Flourish Organizations"
          variant="secondary"
        />
        <ToolButton
          iconName="triangle-exclamation"
          screen="AlertsAdmin"
          text="Alerts Admin"
          variant="secondary"
        />
        <ToolButton
          iconName="keyboard"
          screen="DotPhraseAdmin"
          text="Dot Phrases"
          variant="secondary"
        />
        <ToolButton
          iconName="clock"
          screen="GuidingHours"
          text="Guiding Hours"
          variant="secondary"
        />
        {Boolean(user) && Boolean(hasFeatureFlag(user!, "enableCrisisConsultantSetUp")) && (
          <ToolButton
            iconName="clock"
            screen="OnCallScheduleScreen"
            text="On Call Schedule"
            variant="secondary"
          />
        )}
        <Box width={250}>
          <DownloadFileButton
            filename="message-exploration.csv"
            text="Messages CSV"
            url={`${baseUrl}/messageCSV`}
            variant="secondary"
          />
        </Box>
      </Box>
      <Box paddingY={2}>
        <Heading>Migrations</Heading>
      </Box>
      {Boolean(versionInfo().environment !== "production") && (
        <MigrationButton
          iconName="laptop"
          mutation={usePostScriptsLoadTestDataMutation}
          name="Load Test Data"
        />
      )}

      <Box gap={2} width="100%">
        <MigrationButton
          iconName="comments"
          mutation={usePostScriptsCreateConvosMutation}
          name="Create Conversations"
        />
        <MigrationButton
          iconName="calendar"
          mutation={usePostScriptsSyncAllScheduleItemsGoogleCalendarMutation}
          name="Google Cal ScheduleItems"
        />
        <MigrationButton
          iconName="comments"
          mutation={usePostScriptsDeleteOrphanWorkflowMappingsMutation}
          name="Delete Orphan Workflow Mappings"
        />
        <MigrationButton
          iconName="sim-card"
          mutation={usePostScriptsBackfillUserSettingsMutation}
          name="Backfill User Settings"
        />
        <MigrationButton
          iconName="file-medical"
          mutation={usePostScriptsLoadMedicalDiagnosisDataMutation}
          name="Load Medical Diagnosis Data"
        />
        <MigrationButton
          iconName="file-medical"
          mutation={usePostScriptsLoadAlertsDataMutation}
          name="Load Admin Alerts"
        />
        <MigrationButton
          iconName="chart-area"
          mutation={usePostScriptsSyncAssessmentsMutation}
          name="Sync Assessments"
        />
        <MigrationButton
          iconName="broom"
          mutation={usePostScriptsCleanupNotificationTasksMutation}
          name="Cleanup Notification Tasks"
        />
        <MigrationButton
          iconName="calendar"
          mutation={usePostScriptsSyncAllScheduleItemsGoogleCalendarMutation}
          name="Sync Google Calendar"
        />
        <MigrationButton
          iconName="trash"
          mutation={usePostScriptsDeleteNotificationsForDeletedScheduledItemsMutation}
          name="Scheduled Item Notifications"
        />
        <MigrationButton
          iconName="sim-card"
          mutation={usePostScriptsBackfillPatientIdsMutation}
          name="Backfill PatientIds"
        />
        <MigrationButton
          iconName="calendar-week"
          mutation={usePostScriptsSyncIntakeDatesMutation}
          name="Sync Intake Dates"
        />
        <CreateGoogleCalendar />
        <MigrationButton
          iconName="plane"
          mutation={usePostScriptsLoadCompanyHolidaysMutation}
          name="Load Company Holidays"
        />
        <MigrationButton
          iconName="file"
          mutation={usePostScriptsFtpMutation}
          name="Aetna FTP to Drive"
        />
        <MigrationButton
          iconName="user"
          mutation={usePostScriptsSaveAllUsersMutation}
          name="Save All Users"
        />
        <Box>
          <Tooltip text="This will generate a test error for Sentry">
            <Box marginRight={5} paddingY={2} width={350}>
              <Button
                fullWidth
                iconName="bug"
                text="Test Sentry"
                onClick={() => void Sentry.captureException(new Error("Super User Error Test"))}
              />
            </Box>
          </Tooltip>
        </Box>
        <MigrationButton
          iconName="comments"
          mutation={usePostScriptsUpdateConversationStatusIdsMutation}
          name="Update Conversation Status Ids"
        />
        <MigrationButton
          iconName="trash"
          mutation={usePostScriptsCleanUpConversationStatusesMutation}
          name="Cleanup Conversation Statuses"
        />
        <MigrationButton
          iconName="calendar"
          mutation={usePostScriptsUpdateScheduleItemTimezoneMutation}
          name="Updated Schedule Item Timezones"
        />
        <MigrationButton
          iconName="user"
          mutation={usePostScriptsUpdatePatientPreferredPharmaciesFromDoseSpotMutation}
          name="Update Patient Preferred Pharmacies from DoseSpot"
        />
        <MigrationButton
          iconName="user"
          mutation={usePostScriptsSyncPatientPharmaciesWithDoseSpotMutation}
          name="Sync Patient Pharmacies with DoseSpot"
        />
        <MigrationButton
          iconName="user"
          mutation={usePostScriptsSyncZoomUsersMutation}
          name="Sync Zoom Users"
        />
        <MigrationButton
          iconName="calendar"
          mutation={usePostScriptsFindOrphanedGoogleCalendarEventsMutation}
          name="Remove Orphaned Google Calendar Events"
        />
      </Box>
    </Box>
  );
};
