import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {captureException, IsAndroid, IsWeb} from "@utils";
import Constants from "expo-constants";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import {useToast} from "ferns-ui";
import uniqBy from "lodash/uniqBy";
import {useEffect} from "react";

import {useReadProfile} from "../hooks/useReadProfile";
import * as serviceWorkerRegistration from "../src/serviceWorkerRegistration";
import {usePatchUsersByIdMutation} from "./openApiSdk";
import {getCurrentExpoToken} from "./utils";

export interface Notification {
  id: string;
  background?: boolean;
}

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: [] as Notification[],
  },
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications.push(action.payload);
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter((n) => n.id === action.payload);
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
  },
});

export const {addNotification, removeNotification, clearNotifications} = notificationsSlice.actions;

export const notificationsReducer = notificationsSlice.reducer;

async function registerForPushNotificationsAsync(): Promise<string | undefined> {
  // setNotificationChannelAsync must be called before getExpoPushTokenAsync per expo docs
  if (IsAndroid) {
    console.debug("Setting up Android notification channel");
    try {
      await Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });

      console.debug("Android notification channel set");
    } catch (error) {
      console.error("Error setting up notifications", error);
      captureException(error);
    }
  }

  let token;
  // Expo doesn't support web push notifications or push notifications on the simulator
  if (Device.isDevice && !IsWeb) {
    const {status: existingStatus} = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const {status} = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      console.debug("Push notification permissions not granted.");
      return;
    }
    let tokenRes;
    try {
      tokenRes = await getCurrentExpoToken();
    } catch (error) {
      console.error(error);
      captureException(error);
    }
    token = tokenRes?.data;
  } else {
    console.debug("Must use physical device for Push Notifications");
  }

  return token;
}

export const useSetupWebNotifications = function (): void {
  const [updateProfile] = usePatchUsersByIdMutation();
  const toast = useToast();
  const user = useReadProfile();

  // On mount, register the service worker and attempt to register for web push.
  useEffect(() => {
    if (!user?._id) {
      return;
    }

    // Feature flag for web push notifications.
    if (!user?.featureFlags?.enableWebPush?.enabled) {
      console.debug("Missing feature flag for web push notifications, not registering.");
      return;
    }

    serviceWorkerRegistration
      .register({
        onRegistered: async (registration) => {
          if (!user?._id) {
            console.warn("No user id to register for web push notifications");
            return;
          }

          if (!IsWeb) {
            console.debug("Not setting up web notifications because not on web");
            return;
          }
          if (!registration?.pushManager) {
            console.warn("No registration or push manager for web notifications");
            toast.error("Push notifications are not supported in your browser");
            return;
          }
          const subscription = await registration.pushManager.getSubscription();
          if (subscription !== null) {
            console.debug("Push subscription already exists", subscription);
            // return;
          }
          return registration.pushManager
            .subscribe({
              // This is the public key for the push notification service.
              applicationServerKey: Constants.expoConfig?.extra?.VAPID_PUBLIC_KEY,
              // This is a contract with the browser that we will show a notification when a push
              // comes in.
              userVisibleOnly: true,
            })
            .then(async (pushSubscription) => {
              const {endpoint, keys} = pushSubscription.toJSON();
              if (!endpoint || !keys?.auth || !keys?.p256dh) {
                console.error("Error registering for web push: missing endpoint or keys");
                toast.error("Failed to register for push notifications");
                return;
              }

              try {
                const token = {
                  endpoint,
                  auth: keys?.auth,
                  p256dh: keys?.p256dh,
                };

                await updateProfile({
                  id: user._id,
                  body: {webPushTokens: uniqBy([...(user.webPushTokens ?? []), token], "endpoint")},
                });

                console.debug("Registered for web push");
              } catch (error) {
                console.error("Error registering for web push", error);
                captureException(error);
                return;
              }
            });
        },
      })
      .then(() => {
        console.debug("Registered service worker");
      })
      .catch((error) => {
        console.error("Error registering for web push", error);
        // Don't capture expected error when service worker isn't available
        if (!error.message?.includes("Subscription failed - no active Service Worker")) {
          captureException(error);
        }
        toast.error("Failed to register for push notifications");
      });
  }, [
    updateProfile,
    user?._id,
    user?.featureFlags?.enableWebPush?.enabled,
    user?.webPushTokens,
    toast,
  ]);
};

export const useSetupNotifications = function (): void {
  const [updateProfile] = usePatchUsersByIdMutation();
  const toast = useToast();
  const user = useReadProfile();

  // On mount, register the service worker and attempt to register for web push.
  useEffect(() => {
    // Expo doesn't support web push notifications or push notifications on the simulator
    if (!Device.isDevice || IsWeb) return;

    // if the user doesn't want notifications, don't setup notifications
    if (!user || !user.pushNotifications) return;

    registerForPushNotificationsAsync()
      .then(async (token) => {
        if (!token) {
          console.debug("No token for notifications");
          return;
        }
        console.debug("Notifications token:", token);
        const tokens = [...(user.expoTokens ?? []), token].filter((t) => t) as string[];
        if (token) {
          await updateProfile({
            id: user._id,
            body: {expoTokens: [...new Set(tokens)]},
          });

          // In rare situations a push token may be changed by the push notification service while
          // the app is running. When a token is rolled,
          // the old one becomes invalid and sending notifications to it will fail.
          Notifications.addPushTokenListener(async (devicePushToken) => {
            if (!devicePushToken?.data) {
              console.warn("No device push token data on listener update");
              return;
            }
            console.debug("Push token updated", devicePushToken);
            await updateProfile({
              id: user?._id!,
              body: {expoTokens: [...new Set([...(user.expoTokens ?? []), devicePushToken.data])]},
            })
              .unwrap()
              .catch(toast.catch);
          });

          // This listener is fired whenever a notification is received while the app is
          // foregrounded
          Notifications.addNotificationReceivedListener((notification) => {
            console.debug("Notification received", notification);
            // dispatch(addNotification({id: getNewId()}));
          });

          // This listener is fired whenever a user taps on or interacts with a notification (works
          // when app is foregrounded, backgrounded,
          // or killed)
          Notifications.addNotificationResponseReceivedListener((response) => {
            console.debug("Notification responded", response);
          });
        }
      })
      .catch((error) => {
        console.error("Error registering for push notifications", error);
        captureException(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, user?.pushNotifications]);
};
