import {SHOULD_VALIDATE_CONFLICT_STATUSES} from "@constants";

import {
  AttendanceStatusValues,
  FormInstance,
  FormInstanceWithForm,
  FormQuestion,
  MissingAttendanceStatuses,
  SingleCheckboxOptions,
  User,
} from "./modelTypes";

// // IMPORTANT! This file is copied and pasted to the backend
// If you update this file, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts

// This constant is also used in the backend if updating, UPDATE BOTH
export const OPEN_NOTE_TYPES_ENGAGEMENT = [
  "Guide Phone Call",
  "Guide Video Session",
  "Guide In Person Visit",
];

// If you change FormValidator, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
export const FormValidator = {
  // If you change this, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
  validateAttendanceDetails: (formInstance: FormInstance): string | undefined => {
    if (
      formInstance.attendanceStatus === "Attended" &&
      (!formInstance.attended || !formInstance.attended.length)
    ) {
      return `Status is marked as attended but no attended users are provided. Please provide at least one attended user.`;
    }
    return undefined;
  },
  // If you change this, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
  validateServiceDateAndAttendance: (formInstance: FormInstance): string | undefined => {
    if (
      !formInstance.serviceDate ||
      !formInstance.attendanceStatus ||
      formInstance.attendanceStatus === "Unknown"
    ) {
      const missingParts = [];
      if (!formInstance.serviceDate) {
        missingParts.push("service date");
      }
      if (!formInstance.attendanceStatus) {
        missingParts.push("attendance status");
      }
      return `Missing ${missingParts.join(" and ")}`;
    }
    return undefined;
  },
  // If you change this, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
  validateAnswerRequirements: (
    formQuestion: FormQuestion,
    answers: string[],
    isSupervisor = false
  ): string | undefined => {
    // ignore isRequired if the question is supervisor only and the user is not a supervisor
    // to allow user to complete the form before signed by supervisor
    if (formQuestion.isSupervisorOnly && !isSupervisor) {
      return undefined;
    }
    if (formQuestion.isRequired) {
      if (!answers?.[0]) {
        return "This is required to submit.";
      }
      if (
        formQuestion.type === "SingleCheckbox" &&
        answers?.[0] === SingleCheckboxOptions.unchecked
      ) {
        return "This is required to submit.";
      }
    }

    for (const answer of answers) {
      if (answer.includes("***")) {
        return "Please fill in all *** fields.";
      }
    }

    return undefined;
  },
  // If you change this, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
  validateAnswers: (formInstance: FormInstanceWithForm, isSupervisor = false): string[] | null => {
    const errors = new Set<string>();

    // Pre-condition: Skip answer validation if the patient did not attend and form is automated
    if (
      formInstance?.isAutoCreatedByScheduleItem &&
      MissingAttendanceStatuses.includes(
        (formInstance?.attendanceStatus as AttendanceStatusValues) ?? ""
      )
    ) {
      return null;
    }

    // Helper function to validate a list of questions against answers
    const validateQuestionList = (
      questions: FormInstanceWithForm["form"]["questions"],
      answers: FormInstanceWithForm["answers"]
    ): void => {
      for (const question of questions) {
        const answer = answers.find((a) => a?.questionId?.toString() === question?._id?.toString());
        const error = FormValidator.validateAnswerRequirements(
          question,
          answer?.answers ?? [],
          isSupervisor
        );
        if (error) {
          errors.add("Missing required fields.");
        }
      }
    };

    // Execute validate questions and answers
    validateQuestionList(formInstance.form.questions, formInstance.answers);

    const errorArray = Array.from(errors);
    return errorArray.length ? errorArray : null;
  },
  // If you change this, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
  validateServiceDate: (formInstance: FormInstance): string | null => {
    if (formInstance.serviceDate && new Date(formInstance.serviceDate as string) > new Date()) {
      return "Service date cannot be in the future.";
    }
    return null;
  },
  // If you change this, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
  validateFormInstance: (
    formInstance: FormInstanceWithForm,
    isSupervisor = false,
    formUser?: User
  ): string[] | null => {
    const errors: string[] = [];
    // flatAnswers and includesEngagement are used for Open Notes form validation
    const flatAnswers = formInstance.answers.map((a) => a?.answers).flat();
    const includesEngagement = flatAnswers.some((a) => OPEN_NOTE_TYPES_ENGAGEMENT.includes(a));
    if (
      formInstance.scheduleItemId ||
      // Open Notes is an engagement type form and requires service date and attendance status
      (formInstance.form.name === "Open Notes" && includesEngagement)
    ) {
      const attendanceError = FormValidator.validateServiceDateAndAttendance(formInstance);
      if (attendanceError) {
        errors.push(attendanceError);
      }
    }

    const attendanceDetailsErr = FormValidator.validateAttendanceDetails(formInstance);
    if (attendanceDetailsErr) {
      errors.push(attendanceDetailsErr);
    }

    const answersErrs = FormValidator.validateAnswers(formInstance, isSupervisor);
    if (answersErrs) {
      errors.push(...answersErrs);
    }

    // Conflict validation Only runs before form is completed since the backend only populates the
    // user on initial completion and the frontend disables populate questions after initial
    // completion
    // Skip validation for auto-created forms with missing attendance
    if (
      SHOULD_VALIDATE_CONFLICT_STATUSES.includes(formInstance.status) &&
      !(
        formInstance.isAutoCreatedByScheduleItem &&
        MissingAttendanceStatuses.includes(formInstance.attendanceStatus as AttendanceStatusValues)
      )
    ) {
      for (const question of formInstance.form.questions) {
        const answer = formInstance.answers.find(
          (a) => a?.questionId?.toString() === question?._id?.toString()
        );

        const conflictError = FormValidator.validatePopulatedFieldConflict(
          question,
          formUser,
          answer
        );
        if (conflictError) {
          errors.push(conflictError);
        }
      }
    }
    return errors.length ? errors : null;
  },
  // If you change this, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
  validatePopulatedFieldConflict: (
    question: FormQuestion,
    formUser: User | undefined,
    answer: FormInstance["answers"][number] | undefined
  ): string | undefined => {
    if (
      !question?.populateFields?.length ||
      !formUser ||
      (!answer?.populatedFieldSnapshot?.value?.[0] &&
        answer?.populatedFieldSnapshot?.value?.[0] !== "")
    ) {
      return undefined;
    }

    const fieldToPopulate = question.populateFields[0];
    const currentValue = formUser[fieldToPopulate.key as keyof typeof formUser];
    const snapshotValue = answer.populatedFieldSnapshot.value[0];

    if (currentValue === undefined) {
      return undefined;
    }

    // If the current form answer matches the user's current value, there's no conflict
    if (answer.answers?.[0] === currentValue) {
      return undefined;
    }

    if (currentValue !== snapshotValue) {
      return `${formUser.name}'s ${question.prompt} has been updated since you started editing. Please compare changes and resolve differences.`;
    }

    return undefined;
  },
  // if you add more functions to this, ALSO ADD to THE BACKEND /backend/src/utils/formValidator.ts
};
