import {FormQuestion} from "@store";
import {Box, BoxProps, Spinner, Text} from "ferns-ui";
import React, {ReactElement} from "react";

import {FreeformTextArea} from "./FreeformTextArea";

export const FollowUpResponseBox = ({
  boxProps = {},
  disabled = false,
  followUpResponse = "",
  question,
  selectedOptions,
  remoteOptions,
  onChange,
  onBlur,
  onFocus,
}: {
  boxProps?: BoxProps;
  disabled?: boolean;
  followUpResponse?: string;
  question: FormQuestion;
  selectedOptions: string[]; // currently selected options from formInstanceStore ahead of app store
  remoteOptions: string[]; // answers from app store
  onChange: (value: any, isFollowUpResponse?: boolean) => void;
  onBlur: (value: any, isFollowUpResponse?: boolean) => Promise<void>;
  onFocus: () => void;
}): ReactElement | null => {
  const shouldShowFollowUpResponse = Boolean(
    question?.followUpResponseSettings?.allowFollowUpResponse &&
      selectedOptions.some((so) =>
        question.followUpResponseSettings?.qualifyingOptions?.includes(so)
      )
  );

  const remoteOptionsShouldShowFollowUpResponse = Boolean(
    question?.followUpResponseSettings?.allowFollowUpResponse &&
      remoteOptions.some((ro) => question.followUpResponseSettings?.qualifyingOptions?.includes(ro))
  );

  if (!shouldShowFollowUpResponse) {
    return null;
  }

  if (shouldShowFollowUpResponse && !remoteOptionsShouldShowFollowUpResponse) {
    return (
      <Box padding={3} {...boxProps}>
        <Spinner />
      </Box>
    );
  }
  return (
    <Box {...boxProps}>
      <Box marginTop={2} width="100%">
        <Text>{question.followUpResponseSettings?.prompt}</Text>
      </Box>
      <Box marginTop={2}>
        <FreeformTextArea
          answerRequiredErr={undefined}
          disabled={disabled}
          value={[followUpResponse]}
          onBlur={async (result): Promise<void> => {
            await onBlur(result[0], true);
          }}
          onChange={(result): void => {
            onChange(result[0], true);
          }}
          onFocus={onFocus}
        />
      </Box>
    </Box>
  );
};
