// A read-only cell that displays a checkmark if the user has completed a task or not
import {Box, DataTableCellData, Icon} from "ferns-ui";
import React from "react";

export const CheckedCell: React.FC<{cellData: DataTableCellData}> = ({
  cellData,
}): React.ReactElement => {
  const {value: checked} = cellData ?? {};
  return (
    <Box flex="grow" justifyContent="center" width="100%">
      <Icon color={checked ? "success" : "secondaryDark"} iconName={checked ? "check" : "x"} />
    </Box>
  );
};
