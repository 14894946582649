import {FieldConfig, ModelAdminScreen, UserList} from "@components";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  CarePod,
  IntakeCapacity,
  useDeleteCarePodsByIdMutation,
  useGetCarePodsQuery,
  useGetIntakeCapacitiesQuery,
  usePatchCarePodsByIdMutation,
  usePatchIntakeCapacitiesByIdMutation,
  usePostCarePodsMutation,
  usePostIntakeCapacitiesMutation,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {
  Box,
  Button,
  Field,
  Icon,
  IconButton,
  printOnlyDate,
  Text,
  useTheme,
  useToast,
} from "ferns-ui";
import cloneDeep from "lodash/cloneDeep";
import {DateTime} from "luxon";
import React, {useState} from "react";
import {Pressable, View} from "react-native";

import {STATE_OPTIONS} from "../constants";

interface CareTeamPickerProps {
  instanceData: any;
  setInstanceData: (data: any) => void;
  staffRole: "Psychiatrist" | "Therapist" | "PatientGuide" | "FamilyGuide";
}

const CareTeamPicker = ({
  instanceData,
  setInstanceData,
  staffRole,
}: CareTeamPickerProps): React.ReactElement => {
  return (
    <Box paddingY={2}>
      <UserList
        buttonText={`Select ${staffRole}`}
        limit={1}
        staff
        title={staffRole}
        userFilter={(user) => Boolean(user.staffRoles[staffRole])}
        userIds={
          instanceData?.careTeam?.[staffRole] ? [instanceData?.careTeam?.[staffRole]?._id] : []
        }
        onChangeUsers={(users) => {
          const newInstanceData = cloneDeep(instanceData);
          if (!newInstanceData.careTeam) {
            newInstanceData.careTeam = {};
          }
          newInstanceData.careTeam[staffRole] = users[0];
          setInstanceData(newInstanceData);
        }}
      />
    </Box>
  );
};

const IntakeCapacityRow = ({
  intakeCapacityInstance,
}: {
  intakeCapacityInstance: IntakeCapacity;
}): React.ReactElement => {
  const [isEditing, setIsEditing] = useState(false);
  const [maxIntakes, setMaxIntakes] = useState(intakeCapacityInstance.maxIntakes ?? 0);

  const [updateIntakeCapacity] = usePatchIntakeCapacitiesByIdMutation();

  const {theme} = useTheme();

  const toast = useToast();

  return (
    <View
      style={{
        alignItems: "center",
        width: "100%",
        gap: theme.spacing.md,
        paddingTop: theme.spacing.md,
        paddingRight: theme.spacing.sm,
        paddingBottom: theme.spacing.md,
        paddingLeft: theme.spacing.md,
        backgroundColor: theme.surface.base,
        borderRadius: theme.primitives.radiusMd,
        borderWidth: 1,
        borderColor: theme.border.default,
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <View style={{width: 100}}>
        <Text>{printOnlyDate(intakeCapacityInstance.weekOfStartDate)}</Text>
      </View>
      <View
        style={{
          flex: 1,
          maxWidth: 100,
          alignItems: "center",
        }}
      >
        {Boolean(isEditing) ? (
          <Field
            type="number"
            value={maxIntakes as any}
            onChange={(result: any) => {
              setMaxIntakes(result);
            }}
          />
        ) : (
          <Text>
            {`${intakeCapacityInstance.maxIntakes}${intakeCapacityInstance.maxIntakes && intakeCapacityInstance.maxIntakes === 1 ? " Intake" : " Intakes"}`}
          </Text>
        )}
      </View>
      <View style={{paddingRight: theme.spacing.md}}>
        {Boolean(isEditing) ? (
          <Box alignItems="center" direction="row" gap={2}>
            <IconButton
              accessibilityLabel="delete option"
              iconName="x"
              variant="secondary"
              onClick={() => setIsEditing(false)}
            />
            <IconButton
              accessibilityLabel="save changes"
              iconName="check"
              onClick={async () => {
                if (intakeCapacityInstance.maxIntakes !== maxIntakes) {
                  try {
                    await updateIntakeCapacity({
                      body: {
                        maxIntakes,
                      },
                      id: intakeCapacityInstance._id,
                    }).unwrap();
                    setIsEditing(false);
                  } catch (error) {
                    toast.catch(error);
                  }
                }
              }}
            />
          </Box>
        ) : (
          <Pressable onPress={() => setIsEditing(true)}>
            <Icon color="link" iconName="pencil" />
          </Pressable>
        )}
      </View>
    </View>
  );
};

const IntakeCapacityEditor = ({instanceData}: {instanceData: CarePod}): React.ReactElement => {
  const {data: intakeCapacities} = useGetIntakeCapacitiesQuery(
    instanceData?._id
      ? {
          carePodId: instanceData._id,
          weekOfEndDate: {$gte: DateTime.now().startOf("day").toISO()},
        }
      : skipToken
  );

  const [createIntakeCapacity] = usePostIntakeCapacitiesMutation();
  const toast = useToast();

  if (!intakeCapacities) {
    return <Box paddingY={2} />;
  }

  return (
    <Box paddingY={2}>
      <Text bold>Intake Capacity</Text>
      {intakeCapacities?.data?.map((ic: any) => (
        <Box key={ic._id} paddingY={2}>
          <IntakeCapacityRow intakeCapacityInstance={ic} />
        </Box>
      ))}
      {/* automatically create a new intake capacity for the current week if it doesn't exist, or next week after furthest existing future week */}
      <Box marginBottom={4} paddingY={4} width={250}>
        <Button
          iconName="plus"
          text="Add Weekly Capacity"
          variant="secondary"
          onClick={async () => {
            try {
              await createIntakeCapacity({
                carePodId: instanceData._id,
              }).unwrap();
            } catch (error) {
              toast.catch(error);
            }
          }}
        />
      </Box>
    </Box>
  );
};

interface Props extends StaffStackScreenProps<"CarePodAdmin"> {}

export const CarePodAdminScreen = ({navigation}: Props): React.ReactElement => {
  return (
    <ModelAdminScreen
      confirmationText="Are you sure you want to delete? Make sure you've removed this care pod from all users before deleting, or the app will behave weirdly."
      description="Care Pods are groupings of staff that work together with a group of families"
      display={(carePod): {title: string; subtitle?: string} => ({title: carePod?.name})}
      extraFields={[
        (instanceData, setInstanceData): React.ReactElement => (
          <CareTeamPicker
            key={`${instanceData._id}-Psychiatrist`}
            instanceData={instanceData}
            setInstanceData={setInstanceData}
            staffRole="Psychiatrist"
          />
        ),
        (instanceData, setInstanceData): React.ReactElement => (
          <CareTeamPicker
            key={`${instanceData._id}-Therapist`}
            instanceData={instanceData}
            setInstanceData={setInstanceData}
            staffRole="Therapist"
          />
        ),
        (instanceData, setInstanceData): React.ReactElement => (
          <CareTeamPicker
            key={`${instanceData._id}-PatientGuide`}
            instanceData={instanceData}
            setInstanceData={setInstanceData}
            staffRole="PatientGuide"
          />
        ),
        (instanceData, setInstanceData): React.ReactElement => (
          <CareTeamPicker
            key={`${instanceData._id}-FamilyGuide`}
            instanceData={instanceData}
            setInstanceData={setInstanceData}
            staffRole="FamilyGuide"
          />
        ),
      ]}
      fields={
        [
          {fieldKey: "name", type: "text", title: "Name"},
          {fieldKey: "googleCalendarId", type: "text", title: "The calendar to sync events to."},
          {fieldKey: "url", type: "text", title: "The calendar's public URL", disabled: true},
          {
            fieldKey: "launchDate",
            type: "date",
            title: "The date the care pod was launched.",
          },
          {fieldKey: "slackAlertChannel", type: "text", title: "Slack Alert Channel"},
          {fieldKey: "slackUrgentAlertChannel", type: "text", title: "Slack Urgent Alert Channel"},
          {
            fieldKey: "states",
            type: "multiselect",
            title: "States",
            options: STATE_OPTIONS,
            variant: "rightText",
          },
        ] as FieldConfig[]
      }
      modelName="CarePod"
      navigation={navigation}
      preSave={(instance) => {
        delete instance.enrolledUsers;
        delete instance.growthUsers;
        return instance;
      }}
      relatedModelEditors={[
        (instanceData): React.ReactElement => (
          <IntakeCapacityEditor key="intakeCapacityEditor" instanceData={instanceData} />
        ),
      ]}
      useCreate={usePostCarePodsMutation}
      useList={useGetCarePodsQuery}
      useRemove={useDeleteCarePodsByIdMutation}
      useUpdate={usePatchCarePodsByIdMutation}
    />
  );
};
