import {Box, Button, DataTableColumn, Heading, Text, useToast} from "ferns-ui";
import React from "react";

import {useReadProfile} from "../hooks";
import {
  flourishApi,
  useAppDispatch,
  useGetStaffExplorerQuery,
  usePatchUsersByIdMutation,
  UserFlags,
} from "../store";
import {isSuperUser} from "../utils";
import {DataExplorer} from "./DataExplorer";

const StaffExplorerDrawer: React.FC<{
  column: DataTableColumn;
  rowData: any[];
  rowIndex: number;
  featureFlags?: any;
}> = ({rowData, featureFlags}) => {
  const dataId = rowData?.[0]?.userId;
  const dispatch = useAppDispatch();
  const profile = useReadProfile();
  const toast = useToast();
  const [updateUser] = usePatchUsersByIdMutation();

  const flags: UserFlags = Object.fromEntries(
    Object.entries((featureFlags ?? {}) as UserFlags).filter(([key]) => key !== "_id")
  );

  // Check if every flag is enabled
  const allEnabled = Object.values(flags).every((flag) => flag?.enabled);

  if (!profile) {
    return null;
  }
  return (
    <Box paddingY={4} width="100%">
      <Heading>Feature Flags</Heading>

      <Box paddingY={2}>
        <Text>
          Current flags:{" "}
          {Object.entries(flags)
            .filter(([_, value]) => value?.enabled)
            .map(([key]) => key)
            .join(", ")}
        </Text>
      </Box>
      {isSuperUser(profile) && (
        <Box maxWidth={300}>
          <Button
            fullWidth={false}
            text={allEnabled ? "Disable All" : "Enable All"}
            onClick={async () => {
              await updateUser({
                id: dataId,
                body: Object.keys(flags).reduce((acc, flag) => {
                  acc[`featureFlags.${flag}.enabled` as any] = !allEnabled;
                  return acc;
                }, {} as any),
              })
                .unwrap()
                .then(() => {
                  dispatch(flourishApi.util.invalidateTags(["staffExplorer"] as any));
                })
                .catch(toast.catch);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

interface StaffExplorerProps {}

export const StaffExplorer: React.FC<StaffExplorerProps> = () => {
  return (
    <DataExplorer
      defaultTextSize="sm"
      hook={useGetStaffExplorerQuery}
      moreContentComponent={StaffExplorerDrawer}
      page={1}
    />
  );
};
