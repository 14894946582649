import {useGetUserStatusesQuery} from "@store";
import {Badge, useTheme} from "ferns-ui";
import React from "react";

import {BadgeColors} from "../constants";

interface Props {
  statusId?: string;
}
export const UserStatusBadge = ({statusId}: Props): React.ReactElement | null => {
  const {theme} = useTheme();
  // Fetch all statuses so we hit the cache from other UserStatusBadges and UserExplorer
  const {data: userStatusData} = useGetUserStatusesQuery({});
  const status = userStatusData?.data?.find((us) => us._id === statusId);
  if (!status) {
    if (userStatusData?.data?.length) {
      console.warn(`Could not find user status ${statusId}`);
    }
    return null;
  }
  const color = (BadgeColors.find((bc) => bc.label === status.color) ?? BadgeColors[0]).value;
  const textColor = status.fontColor === "dark" ? theme.text.primary : theme.text.inverted;
  const iconColor: "primary" | "inverted" = status.fontColor === "dark" ? "inverted" : "primary";
  return (
    <Badge
      customBackgroundColor={color}
      customIconColor={iconColor}
      customTextColor={textColor}
      status="custom"
      value={status.name}
    />
  );
};
