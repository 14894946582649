import {useGetPatientEngagementStatsQuery, useGetUsersByIdQuery} from "@store";
import {Box, Table, TableRow, TableText} from "ferns-ui";
import {DateTime} from "luxon";
import React, {ReactElement} from "react";

function getTimeAgoText(date: DateTime): string {
  if (date.hasSame(DateTime.now(), "day")) {
    return "(Today)";
  }
  return `(${Math.ceil(DateTime.now().set({hour: 0}).diff(date, "days").days)} days ago)`;
}

export const PatientEngagementStats = ({userId}: {userId: string}): ReactElement | null => {
  const {data: patient} = useGetUsersByIdQuery(userId);
  const {data: patientEngagementData} = useGetPatientEngagementStatsQuery(userId);

  if (!patient) {
    return null;
  }

  return (
    <Box width="100%">
      <Table alternateRowBackground={false} columns={[220, 220]}>
        <TableRow key="lastMessaged">
          <TableText value="Last Messaged PG:" />
          <TableText
            value={
              patientEngagementData?.lastMessage
                ? `${DateTime.fromISO(patientEngagementData.lastMessage).toFormat("M/d/yyyy")} ${getTimeAgoText(DateTime.fromISO(patientEngagementData.lastMessage))}`
                : "N/A"
            }
          />
        </TableRow>
        <TableRow key="lastTherapy">
          <TableText value="Last Therapy Attended:" />
          <TableText
            value={
              patientEngagementData?.lastAttendedTherapy
                ? `${DateTime.fromISO(patientEngagementData.lastAttendedTherapy).toFormat("M/d/yyyy")} ${getTimeAgoText(DateTime.fromISO(patientEngagementData.lastAttendedTherapy))}`
                : "N/A"
            }
          />
        </TableRow>
        <TableRow key="lastPsychiatry">
          <TableText value="Last Psychiatry Attended:" />
          <TableText
            value={
              patientEngagementData?.lastAttendedPsychiatry
                ? `${DateTime.fromISO(patientEngagementData.lastAttendedPsychiatry).toFormat("M/d/yyyy")}  ${getTimeAgoText(DateTime.fromISO(patientEngagementData.lastAttendedPsychiatry))}`
                : "N/A"
            }
          />
        </TableRow>
      </Table>
    </Box>
  );
};
