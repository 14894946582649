import {Platform} from "react-native";

export const MIN_COMPOSER_HEIGHT = Platform.select({
  ios: 41,
  android: 41,
  web: 33,
}) as number;
export const MAX_COMPOSER_HEIGHT = 200;
export const DEFAULT_PLACEHOLDER = "Type a message...";
export const DATE_FORMAT = "ll";
export const TIME_FORMAT = "LT";

export const TEST_ID = {
  COMPOSER: "GC_COMPOSER",
  LOADING_WRAPPER: "GC_LOADING_CONTAINER",
  MENTION_INPUT: "GC_MENTION_INPUT",
  MESSAGE_CONTAINER: "GC_MESSAGE_CONTAINER",
  SEND_TOUCHABLE: "GC_SEND_TOUCHABLE",
  WRAPPER: "GC_WRAPPER",
};
