import {useCollapseAllRightBar, usePatchUsersByIdMutation, User} from "@store";
import {Box, Button, Icon, Text} from "ferns-ui";
import React from "react";

import {UserInfoCard} from "./UserInfoCard";

interface Props {
  user: User;
}

export const UserErrorCard = ({user}: Props): React.ReactElement | null => {
  const collapseExternal = useCollapseAllRightBar();
  const smsErrors = [...(user.smsErrors ?? [])];

  const shouldShowSmsError = smsErrors.length >= 3 && !user.smsEnabled;

  if (!shouldShowSmsError) {
    return null;
  }

  return (
    <UserInfoCard collapsable collapseExternal={collapseExternal} title="Errors">
      <Box>
        {Boolean(smsErrors.length > 0 && !user.smsEnabled) && <SmsFailureInfoLine user={user} />}
      </Box>
    </UserInfoCard>
  );
};

const SmsFailureInfoLine = ({user}: Props): React.ReactElement | null => {
  const [updateUser] = usePatchUsersByIdMutation();
  return (
    <Box direction="row" marginBottom={2}>
      <Box marginRight={4}>
        <Icon color="error" iconName="triangle-exclamation" size="lg" />
      </Box>
      <Box
        alignContent="between"
        direction="column"
        display="flex"
        flex="grow"
        justifyContent="between"
      >
        <Box direction="column" display="flex" flex="grow">
          <Text bold size="lg">
            Unable to deliver text messages.
          </Text>
          <Text size="lg">
            {`Text Messaging has been disabled for this user.\nPlease verify phone number with ${user.type === "Patient" ? "patient" : "caregiver"}.`}
          </Text>
        </Box>
        <Box direction="row">
          <Box padding={2}>
            <Button
              confirmationText={
                "This will re-enable text message delivery after the reason for failures has been " +
                "resolved (such as adding a new phone number to the user). Would you like to continue with re-enabling?"
              }
              modalTitle="Re-Enable Text Messaging"
              text="Re-Enable"
              variant="outline"
              withConfirmation
              onClick={async () => {
                await updateUser({id: user._id, body: {smsErrors: [], smsEnabled: true}});
              }}
            />
          </Box>
          <Box padding={2}>
            <Button
              confirmationText={
                "Are you sure you want to dismiss this error? " +
                "Text messaging will be continue to be disabled for this user, including messaging and notifications."
              }
              modalTitle="Dismiss SMS Error"
              text="Dismiss"
              variant="outline"
              withConfirmation
              onClick={async () => {
                await updateUser({id: user._id, body: {smsErrors: []}});
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
