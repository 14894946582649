import {useReadProfile} from "@hooks";
import {
  FollowUpResponseSettings,
  FormQuestion,
  PopulateFields,
  SingleCheckboxOptions,
} from "@store";
import {IsMobileDevice, isSoftwareEngineer} from "@utils";
import {
  BooleanField,
  Box,
  Button,
  Heading,
  Icon,
  IconButton,
  MultiselectField,
  NumberField,
  SelectField,
  Text,
  TextArea,
  TextField,
} from "ferns-ui";
import React, {ReactElement, useMemo, useState} from "react";
import {Pressable} from "react-native";

import {clinicalUpdateFieldOptions, userSelectFieldOptions} from "../../store/modelTypes";
import {StaticQuestions} from "./StaticQuestions";

type FormQuestionType =
  | "CarePlan"
  | "ClinicalStatus"
  | "Date"
  | "Email"
  | "Freeform"
  | "Heading"
  | "Multiselect"
  | "Number"
  | "PhoneNumber"
  | "SafetyPlan"
  | "Select"
  | "Shortform"
  | "SingleCheckbox";

type HelperQuestionType =
  | "1-5 Answers"
  | "CarePlan"
  | "ClinicalStatus"
  | "Date"
  | "DSM Answers"
  | "Email"
  | "Heading"
  | "Freeform"
  | "MedicationSupportRequestPsychiatrist"
  | "Multiselect"
  | "Number"
  | "PhoneNumber"
  | "SafetyPlan"
  | "Select"
  | "Shortform"
  | "SingleCheckbox"
  | "Yes/No Answers";

const StaticQuestionKeys = Object.keys(StaticQuestions);

const DISABLED_UPDATE_OPTIONS: HelperQuestionType[] = ["ClinicalStatus"];

const HAS_TEMPLATE_OPTIONS: HelperQuestionType[] = ["Freeform", "Shortform"];

const SHOW_FOLLOW_UP_RESPONSE_OPTION: HelperQuestionType[] = [
  "Multiselect",
  "Select",
  "Yes/No Answers",
  "MedicationSupportRequestPsychiatrist",
];

const SHOW_OPTIONS_TYPES: HelperQuestionType[] = [
  "1-5 Answers",
  "ClinicalStatus",
  "DSM Answers",
  "MedicationSupportRequestPsychiatrist",
  "Multiselect",
  "Select",
  "Yes/No Answers",
];

const QUESTION_COMPONENT_ERRORS = {
  carePlanUserKeyDoesNotMatchQuestionType:
    'For User Key to populate "Care Plan", ' + 'Question Type must be "Update Care Plan"',
  checkboxScoreNAN: "Score must be a number",
  clinicalStatusUserKeyDoesNotMatchQuestionType:
    'For User Key to populate "Clinical Status", ' +
    'Question Type must be "Update Clinical Status"',
  followUpResMissingOptions: "Follow-Up to response must have at least one qualifying option",
  followUpResMissingPrompt: "Follow-Up to response prompt is required",
  mustHaveOptions: "At least one option is required",
  mustHavePrompt: "Prompt is required",
  safetyPlanUserKeyDoesNotMatchQuestionType:
    'For User Key to populate "Safety Plan", ' + 'Question Type must be "Update Safety Plan"',
  duplicateUserKey: "Duplicate User Key with another question, please use unique keys.",
  duplicateInternalKey:
    "Internal Key is already in use with another question on this form. Use a different key or remove question.",
};

interface FormQuestionProps {
  formQuestion: FormQuestion;
  excludeFormRiskLanguageAlerts: boolean;
  onSave: (question: FormQuestion) => boolean;
  index: number;
  onDelete: () => void;
  formType: "Note" | "Assessment" | "Survey";
  isActive?: boolean;
  onDuplicate: () => void;
  drag?: () => void;
  questions: FormQuestion[];
}

export const FormCreationQuestionComponent = ({
  formQuestion,
  excludeFormRiskLanguageAlerts,
  onSave,
  onDelete,
  onDuplicate,
  index,
  formType,
  drag,
  questions,
}: FormQuestionProps): ReactElement => {
  const profile = useReadProfile();
  const [editing, setEditing] = useState(!formQuestion.prompt && !formQuestion.grouping);
  const [prompt, setPrompt] = useState(formQuestion.prompt);
  const [grouping, setGrouping] = useState(formQuestion.grouping);
  const [type, setType] = useState<HelperQuestionType>(
    (formQuestion.type as HelperQuestionType) ?? "Freeform"
  );
  const [userKey, setUserKey] = useState<PopulateFields[]>(formQuestion.populateFields ?? []);
  const [options, setOptions] = useState(formQuestion.options ?? []);
  const [newOption, setNewOption] = useState("");
  const [newValue, setNewValue] = useState("");
  const [description, setDescription] = useState(formQuestion.description ?? "");
  const [template, setTemplate] = useState(formQuestion.template ?? "");
  const [questionErrors, setQuestionErrors] = useState<string[]>([]);
  const [isRequired, setIsRequired] = useState(formQuestion.isRequired ?? false);
  const [isSupervisorOnly, setIsSupervisorOnly] = useState(formQuestion.isSupervisorOnly ?? false);
  const [excludeQuestionRiskLanguageAlerts, setExcludeQuestionRiskLanguageAlerts] = useState(
    formQuestion.excludeQuestionRiskLanguageAlerts ?? false
  );
  const [internalKey, setInternalKey] = useState(formQuestion.internalKey ?? "");
  const [followUpResponseSettings, setFollowUpResponseSettings] = useState(
    formQuestion?.followUpResponseSettings?.allowFollowUpResponse
      ? formQuestion.followUpResponseSettings
      : {
          allowFollowUpResponse: false,
          prompt: "",
          qualifyingOptions: [],
        }
  );

  const setStaticQuestionValues = (typeQuestion: string): void => {
    if (StaticQuestionKeys.includes(typeQuestion)) {
      setPrompt(StaticQuestions[typeQuestion].prompt);
      setDescription(StaticQuestions[typeQuestion]?.description ?? "");
      setOptions(StaticQuestions[typeQuestion]?.options ?? []);
      setGrouping(StaticQuestions[typeQuestion]?.grouping ?? "");
      setUserKey(StaticQuestions[typeQuestion]?.populateFields ?? []);
      setTemplate(StaticQuestions[typeQuestion]?.template ?? "");
      setIsRequired(StaticQuestions[typeQuestion]?.isRequired ?? false);
      setIsSupervisorOnly(StaticQuestions[typeQuestion]?.isSupervisorOnly ?? false);
      setExcludeQuestionRiskLanguageAlerts(
        StaticQuestions[typeQuestion]?.excludeQuestionRiskLanguageAlerts ?? false
      );
      setInternalKey(StaticQuestions[typeQuestion]?.internalKey ?? "");
      setFollowUpResponseSettings(
        StaticQuestions[typeQuestion]?.followUpResponseSettings?.allowFollowUpResponse
          ? StaticQuestions[typeQuestion].followUpResponseSettings
          : {
              allowFollowUpResponse: false,
              prompt: "",
              qualifyingOptions: [],
            }
      );
    }
  };

  const disabledByStaticQuestion = useMemo(() => {
    const staticQuestionInternalKeys = Object.values(StaticQuestions).map(
      (question) => question.internalKey
    );

    return (
      StaticQuestionKeys.includes(type) ||
      staticQuestionInternalKeys.includes(formQuestion?.internalKey ?? "")
    );
  }, [formQuestion?.internalKey, type]);

  // set errors disables save button and displays on form
  const questionErrorsMemo = useMemo(() => {
    const updatedErrors = new Set(questionErrors);
    const {
      carePlanUserKeyDoesNotMatchQuestionType,
      clinicalStatusUserKeyDoesNotMatchQuestionType,
      followUpResMissingPrompt,
      followUpResMissingOptions,
      mustHaveOptions,
      mustHavePrompt,
      safetyPlanUserKeyDoesNotMatchQuestionType,
      duplicateUserKey,
      duplicateInternalKey,
    } = QUESTION_COMPONENT_ERRORS;

    const updateError = (condition: boolean, error: string): void => {
      if (condition) {
        updatedErrors.add(error);
      } else {
        updatedErrors.delete(error);
      }
    };

    updateError(
      Boolean(followUpResponseSettings.allowFollowUpResponse) &&
        (!followUpResponseSettings.prompt || !followUpResponseSettings.prompt.trim()),
      followUpResMissingPrompt
    );
    updateError(
      Boolean(followUpResponseSettings.allowFollowUpResponse) &&
        !followUpResponseSettings?.qualifyingOptions?.length,
      followUpResMissingOptions
    );
    updateError(!prompt.trim(), mustHavePrompt);
    updateError(
      (type === "Select" && !options.length) || (type === "Multiselect" && !options.length),
      mustHaveOptions
    );
    updateError(
      userKey[0]?.key === "clinicalStatus" && type !== "ClinicalStatus",
      clinicalStatusUserKeyDoesNotMatchQuestionType
    );
    updateError(
      userKey[0]?.key === "carePlan" && type !== "CarePlan",
      carePlanUserKeyDoesNotMatchQuestionType
    );
    updateError(
      userKey[0]?.key === "safetyPlan" && type !== "SafetyPlan",
      safetyPlanUserKeyDoesNotMatchQuestionType
    );
    updateError(
      questions.some(
        (q) =>
          q !== formQuestion &&
          q.populateFields?.some((field) => userKey.some((key) => key.key === field.key))
      ),
      duplicateUserKey
    );

    // if internalKey exists, check for duplicates within the form
    updateError(
      internalKey.trim() !== "" &&
        questions.some((q) => q !== formQuestion && q.internalKey === internalKey),
      duplicateInternalKey
    );

    return Array.from(updatedErrors);
  }, [
    questionErrors,
    followUpResponseSettings.allowFollowUpResponse,
    followUpResponseSettings.prompt,
    followUpResponseSettings?.qualifyingOptions?.length,
    prompt,
    type,
    options.length,
    userKey,
    questions,
    internalKey,
    formQuestion,
  ]);

  const saveForm = (): void => {
    let questionType: FormQuestionType;
    if (
      [
        "DSM Answers",
        "Yes/No Answers",
        "1-5 Answers",
        "MedicationSupportRequestPsychiatrist",
      ].includes(type)
    ) {
      questionType = "Select";
    } else {
      questionType = type as FormQuestionType;
    }
    let followUpSetting: FollowUpResponseSettings;
    if (followUpResponseSettings.allowFollowUpResponse) {
      followUpSetting = followUpResponseSettings;
    } else {
      followUpSetting = undefined;
    }
    const success = onSave({
      ...formQuestion,
      type: questionType,
      description,
      grouping,
      prompt,
      options,
      populateFields: userKey,
      template,
      followUpResponseSettings: followUpSetting,
      isRequired,
      isSupervisorOnly,
      excludeQuestionRiskLanguageAlerts,
      // Conditional inclusion of internalKey
      ...(internalKey.trim() !== "" && {internalKey}),
    });
    if (success) setEditing(false);
  };

  const updateFollowUpResponseSettings = (
    key: keyof FollowUpResponseSettings,
    value: FollowUpResponseSettings[keyof FollowUpResponseSettings]
  ): void => {
    const newFollowUpResponseSettings: FollowUpResponseSettings = {...followUpResponseSettings};
    newFollowUpResponseSettings[key] = value;
    setFollowUpResponseSettings(newFollowUpResponseSettings);
  };

  const renderForm = (): ReactElement => {
    const saveDisabled = questionErrorsMemo.length > 0;
    return (
      <Box gap={4} paddingX={4} paddingY={2} width="100%">
        <Heading size="sm">{formQuestion.prompt ? "Edit Question" : "New Question"}</Heading>
        <SelectField
          options={[
            {label: "Freeform", value: "Freeform"},
            {label: "Select", value: "Select"},
            {label: "Multiselect", value: "Multiselect"},
            {label: "DSM Answers", value: "DSM Answers"}, // Shortcut to put in 5 options.
            {label: "1-5 Answers", value: "1-5 Answers"}, // Shortcut to put in 5 options.
            {label: "Yes/No Answers", value: "Yes/No Answers"}, // Shortcut to put in yes/no.
            {label: "Shortform", value: "Shortform"},
            {label: "Number", value: "Number"},
            {label: "Date", value: "Date"},
            {label: "Email", value: "Email"},
            {label: "Phone Number", value: "PhoneNumber"},
            {label: "Heading", value: "Heading"},
            {label: "Single Checkbox", value: "SingleCheckbox"},
            {label: "Update Clinical Status", value: "ClinicalStatus"},
            {label: "Update Care Plan", value: "CarePlan"},
            {label: "Update Safety Plan", value: "SafetyPlan"},
            {
              label: "Update Externalizing Behaviors Treatment Module",
              value: "ExternalizingBehaviorsTreatmentManualModule",
            },
            {
              label: "Medication Support Request Psychiatrist",
              value: "MedicationSupportRequestPsychiatrist",
            },
          ]}
          requireValue
          title="Type"
          value={type}
          onChange={(value: string): void => {
            // Reset internalKey if type is changed and internalKey has a value
            if (value !== type && internalKey.trim() !== "") {
              setInternalKey("");
            }
            setType(value as HelperQuestionType);
            if (value === "Heading") {
              setIsRequired(false);
            }
            if (value === "DSM Answers") {
              setOptions([
                {label: "Not at all", score: 0},
                {label: "Rare, less than a day or two", score: 1},
                {label: "Several days", score: 2},
                {label: "More than half the days", score: 3},
                {label: "Nearly every day", score: 4},
              ]);
            } else if (value === "1-5 Answers") {
              setOptions([
                {label: "Strongly Disagree", score: 0},
                {label: "Disagree", score: 1},
                {label: "Neutral", score: 2},
                {label: "Agree", score: 3},
                {label: "Strongly Agree", score: 4},
              ]);
            } else if (value === "Yes/No Answers") {
              setOptions([
                {label: "Yes", score: 1},
                {label: "No", score: 0},
              ]);
            } else if (value === "SingleCheckbox") {
              setOptions([
                {label: SingleCheckboxOptions.checked, score: 1},
                {label: SingleCheckboxOptions.unchecked, score: 0},
              ]);
            } else if (value === "ClinicalStatus") {
              setPrompt("Clinical Status");
              setDescription("Please update the patient's clinical status as needed.");
              setUserKey([{schema: "User", key: "clinicalStatus"}]);
              setOptions(clinicalUpdateFieldOptions.clinicalStatus);
            } else if (value === "CarePlan") {
              setPrompt("Care Plan");
              setDescription("Please update the patient's care plan as needed.");
              setUserKey([{schema: "User", key: "carePlan"}]);
            } else if (value === "SafetyPlan") {
              setPrompt("Safety Plan");
              setDescription("Please update the patient's safety plan as needed.");
              setUserKey([{schema: "User", key: "safetyPlan"}]);
            } else if (value === "ExternalizingBehaviorsTreatmentManualModule") {
              setType("Select");
              setPrompt("Externalizing Behaviors Treatment Manual Module");
              setDescription(
                "Please select the externalizing behaviors treatment manual module you worked on with the caregiver. Skip this question if the family is not using the EBTM."
              );
              setUserKey([{schema: "User", key: "externalizingBehaviorsTreatmentManualModule"}]);
              setOptions(userSelectFieldOptions.externalizingBehaviorsTreatmentManualModule);
            } else if (StaticQuestionKeys.includes(value)) {
              setStaticQuestionValues(value);
            }
          }}
        />
        <TextField
          disabled={disabledByStaticQuestion}
          title="Prompt"
          value={prompt}
          onChange={(value): void => {
            setPrompt(value);
          }}
        />
        <TextField
          disabled={disabledByStaticQuestion}
          title="Description"
          value={description}
          onChange={(value): void => {
            setDescription(value);
          }}
        />
        <BooleanField
          disabled={disabledByStaticQuestion}
          helperText="If required, this question must be answered before form can be marked Completed."
          title="Answer Required"
          value={isRequired}
          onChange={setIsRequired}
        />
        <BooleanField
          disabled={disabledByStaticQuestion}
          helperText="If marked as Supervisor Only, only staff supervisors will be able to answer this question. Note: This question will be visible for all staff. If patient visibility is allowed, it will be visible by patients and family member."
          title="Supervisor Only"
          value={isSupervisorOnly}
          onChange={setIsSupervisorOnly}
        />
        {Boolean(
          formType === "Note" &&
            HAS_TEMPLATE_OPTIONS.includes(type) &&
            !excludeFormRiskLanguageAlerts
        ) && (
          <BooleanField
            disabled={disabledByStaticQuestion}
            helperText="If marked the answer for this question will not be reviewed for risk language and risk language alerts will not be created."
            title="Exclude Risk Language Detection for This Question"
            value={excludeQuestionRiskLanguageAlerts}
            onChange={setExcludeQuestionRiskLanguageAlerts}
          />
        )}
        {Boolean(HAS_TEMPLATE_OPTIONS.includes(type) && !disabledByStaticQuestion) && (
          <TextArea
            blurOnSubmit={false}
            disabled={disabledByStaticQuestion}
            helperText={
              `This will be automatically filled in for new notes, unless an answer is copied forward. ` +
              `Add \"***\" to prevent completing notes until that portion is filled in.`
            }
            title="Template"
            value={template}
            onChange={(value): void => {
              setTemplate(value);
            }}
          />
        )}

        {Boolean(SHOW_OPTIONS_TYPES.includes(type)) && (
          <Box width="100%">
            <Text bold>Options:</Text>
            {options.map((option, i) => (
              <Box
                key={`${option.label}-${i}`}
                alignItems="center"
                direction="row"
                justifyContent="between"
                paddingY={2}
                width={300}
              >
                <Box width="100%">
                  <Text truncate>
                    {option.label}{" "}
                    {formType === "Assessment" || formType === "Survey" ? `(${option.score})` : ""}
                  </Text>
                </Box>

                {Boolean(!DISABLED_UPDATE_OPTIONS.includes(type) && !disabledByStaticQuestion) && (
                  <Box width={60}>
                    <IconButton
                      accessibilityLabel="delete option"
                      confirmationText="Are you sure you want to delete this option?"
                      iconName="trash"
                      variant="destructive"
                      withConfirmation
                      onClick={(): void => {
                        const newOptions = [...options];
                        newOptions.splice(i, 1);
                        setOptions(newOptions);
                      }}
                    />
                  </Box>
                )}
              </Box>
            ))}

            {Boolean(!DISABLED_UPDATE_OPTIONS.includes(type) && !disabledByStaticQuestion) && (
              <Box direction="row" paddingY={4}>
                <Box flex="grow" marginRight={2}>
                  <TextField
                    title="New Option"
                    value={newOption}
                    onChange={(value): void => setNewOption(value)}
                  />
                </Box>
                {Boolean(formType === "Assessment" || formType === "Survey") && (
                  <Box flex="grow" marginRight={2}>
                    <TextField
                      title="Score"
                      value={newValue}
                      onChange={(value): void => setNewValue(value)}
                    />
                  </Box>
                )}
                <Box alignItems="center" justifyContent="center" marginTop={5}>
                  <Button
                    text="Add Option"
                    variant="secondary"
                    onClick={(): void => {
                      setOptions([...options, {label: newOption, score: Number(newValue)}]);
                      setNewOption("");
                      setNewValue("");
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}

        {Boolean(type === "SingleCheckbox") && (
          <Box width="100%">
            <Text bold>Options:</Text>
            {options.map((option, i) => (
              <Box
                key={`${option.label}-${i}`}
                alignItems="center"
                direction="row"
                justifyContent="between"
                paddingY={2}
                width={300}
              >
                <Box width="70%">
                  <Text truncate>{option.label}</Text>
                </Box>
                <Box width="30%">
                  {Boolean(formType === "Assessment" || formType === "Survey") && (
                    <NumberField
                      disabled={disabledByStaticQuestion}
                      errorText={
                        questionErrorsMemo.includes(QUESTION_COMPONENT_ERRORS.checkboxScoreNAN)
                          ? QUESTION_COMPONENT_ERRORS.checkboxScoreNAN
                          : ""
                      }
                      title="Score"
                      type="number"
                      value={option?.score?.toString() ?? "0"}
                      onChange={(value): void => {
                        if (isNaN(Number(value))) {
                          setQuestionErrors([
                            ...questionErrors,
                            QUESTION_COMPONENT_ERRORS.checkboxScoreNAN,
                          ]);
                          return;
                        }
                        setQuestionErrors(
                          questionErrors.filter(
                            (error) => error !== QUESTION_COMPONENT_ERRORS.checkboxScoreNAN
                          )
                        );
                        const newOptions = [...options];
                        newOptions[i] = {...newOptions[i], score: Number(value)};
                        setOptions(newOptions);
                      }}
                    />
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        )}

        {Boolean(SHOW_FOLLOW_UP_RESPONSE_OPTION.includes(type)) && (
          <Box marginTop={1}>
            <BooleanField
              disabled={disabledByStaticQuestion}
              title="Include Follow-Up to Response Freeform"
              value={Boolean(followUpResponseSettings.allowFollowUpResponse)}
              onChange={(): void => {
                updateFollowUpResponseSettings(
                  "allowFollowUpResponse" as keyof FollowUpResponseSettings,
                  !followUpResponseSettings.allowFollowUpResponse as FollowUpResponseSettings[keyof FollowUpResponseSettings]
                );
              }}
            />

            {Boolean(followUpResponseSettings.allowFollowUpResponse) && (
              <Box paddingY={2}>
                <Text>Options that Trigger a Follow-Up Prompt and Freeform</Text>
                <Box width={200}>
                  <MultiselectField
                    disabled={disabledByStaticQuestion}
                    options={options.map((option) => ({label: option.label, value: option.label}))}
                    title="Response Prompt Qualifiers"
                    value={followUpResponseSettings.qualifyingOptions ?? []}
                    onChange={(value: string[]): void => {
                      updateFollowUpResponseSettings(
                        "qualifyingOptions" as keyof FollowUpResponseSettings,
                        value as FollowUpResponseSettings[keyof FollowUpResponseSettings]
                      );
                    }}
                  />
                </Box>
                <Box>
                  <TextField
                    disabled={disabledByStaticQuestion}
                    helperText="Prompt shows if any of the response prompt qualifiers are chosen by the user."
                    title="Follow-Up On Response Prompt"
                    value={followUpResponseSettings.prompt}
                    onChange={(value): void => {
                      updateFollowUpResponseSettings(
                        "prompt" as keyof FollowUpResponseSettings,
                        value as FollowUpResponseSettings[keyof FollowUpResponseSettings]
                      );
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}

        {Boolean(formType === "Assessment") && (
          <TextField
            disabled={disabledByStaticQuestion}
            helperText="For Assessment forms, questions with the same grouping will use the highest score (how the DSM is scored)."
            title="Grouping"
            value={grouping}
            onChange={(value): void => {
              setGrouping(value);
            }}
          />
        )}

        {Boolean(type !== "Heading") && (
          <SelectField
            disabled={
              ["ClinicalStatus", "SafetyPlan", "CarePlan"].includes(type) ||
              disabledByStaticQuestion
            }
            helperText="Populate the selected User field from this form once it is completed."
            options={[
              {label: "Name", value: "name"},
              {label: "Email", value: "email"},
              {label: "Birthday", value: "birthday"},
              {label: "Gender", value: "gender"},
              {label: "Gender Self Describe", value: "genderSelfDescribe"},
              {label: "Timezone", value: "timezone"},
              {label: "Address", value: "address"},
              {label: "Phone Number", value: "phoneNumber"},
              {label: "Pronouns", value: "pronouns"},
              {label: "Goals", value: "goals"},
              {label: "Care Plan", value: "carePlan"},
              {label: "One Liner", value: "oneLiner"},
              {label: "Emergency Contact", value: "emergencyContact"},
              {label: "Primary Care Doctor", value: "primaryCareDoctor"},
              {label: "Dentist", value: "dentist"},
              {label: "Billing Info", value: "billingInfo"},
              {label: "Clinical Status", value: "clinicalStatus"},
              {label: "Safety Plan", value: "safetyPlan"},
              {label: "Patient Treatment Manual Module", value: "patientTreatmentManualModule"},
              {
                label: "Externalizing Behaviors Treatment Manual Module",
                value: "externalizingBehaviorsTreatmentManualModule",
              },
            ]}
            requireValue={false}
            title="User Key"
            value={userKey[0]?.key}
            // for now, this only supports setting a single field to populate.
            // Could expand this should the need arise
            onChange={(value: string | undefined): void => {
              setUserKey(value ? [{schema: "User", key: value}] : []);
            }}
          />
        )}

        <TextField
          disabled={
            !isSoftwareEngineer(profile) ||
            Boolean(formQuestion?.internalKey) ||
            disabledByStaticQuestion
          }
          errorText={
            questionErrorsMemo.includes(QUESTION_COMPONENT_ERRORS.duplicateInternalKey)
              ? QUESTION_COMPONENT_ERRORS.duplicateInternalKey
              : ""
          }
          helperText="For engineering purposes only. It should be kabob-case and unique. Once saved, this cannot be changed."
          placeholder="ex: identifying-information"
          title="Internal Key"
          value={internalKey}
          onChange={(value): void => {
            setInternalKey(value.trim().toLowerCase());
          }}
        />

        {Boolean(questionErrorsMemo.length > 0) && (
          <>
            {questionErrorsMemo.map((error, i) => (
              <Box key={`questionError-${i}`} alignContent="center" direction="row" gap={2}>
                <Box alignItems="center" justifyContent="center">
                  <Icon color="error" iconName="triangle-exclamation" />
                </Box>
                <Text key={`questionError-${i}`} color="error">
                  {error}
                </Text>
              </Box>
            ))}
          </>
        )}

        <Box direction="row" width={200}>
          <Box flex="grow" marginRight={4}>
            <Button disabled={saveDisabled} text="Save" onClick={saveForm} />
          </Box>
          <Box flex="grow">
            <Button
              confirmationText="Are you sure you want to cancel? Your changes will not be saved."
              text="Cancel"
              variant="muted"
              withConfirmation
              onClick={(): void => {
                setEditing(false);
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  // TODO: allow for draggable questions in create form for mobile app
  const renderRow = (): ReactElement => {
    let formQuestionTypeDisplay = "";
    if (formQuestion.type === "Freeform") {
      formQuestionTypeDisplay = "Freeform";
    } else if (formQuestion.type === "Select" || formQuestion.type === "Multiselect") {
      formQuestionTypeDisplay = `Select ${formQuestion.type === "Select" ? "One" : "Many"}: ${(
        formQuestion.options ?? []
      )
        .map((option) => option.label)
        .join(", ")}`;
    } else {
      formQuestionTypeDisplay = formQuestion.type || "";
    }

    const populateFieldsText: string | null = formQuestion.populateFields
      ? formQuestion.populateFields.map((field) => `${field.schema} ${field.key}`).join(", ")
      : null;
    return (
      <Box
        border="default"
        direction="row"
        maxWidth="100%"
        paddingX={2}
        paddingY={4}
        rounding="md"
        width="100%"
      >
        {/* We use a Pressable here instead of IconButton because onPressIn works with dragging. onClick in
              IconButton requires two clicks. WEB ONLY */}
        {Boolean(!IsMobileDevice) && (
          <Pressable
            hitSlop={10}
            style={{
              width: 32,
              height: 32,
              marginRight: 4 * 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onPressIn={drag}
          >
            <Icon iconName="arrows-up-down-left-right" />
          </Pressable>
        )}
        <Box flex="grow" marginRight={4}>
          <Text truncate>
            {index + 1}: {formQuestion.grouping ? `(${formQuestion.grouping})` : ""}{" "}
            {formQuestion.type === "Heading" && "Heading:"}
            {formQuestion.prompt}
          </Text>
          <Box paddingY={1}>
            <Text color="secondaryLight" size="sm">
              {formQuestionTypeDisplay}
              {formQuestion.isRequired ? " - Requires an Answer to Submit" : ""}
            </Text>
          </Box>
          {Boolean(formQuestion.description) && (
            <Box paddingY={1}>
              <Text color="secondaryLight" size="sm">
                Description: {formQuestion.description}
              </Text>
            </Box>
          )}
          {Boolean(formQuestion.template) && (
            <Box paddingY={1}>
              <Text color="secondaryLight" size="sm">
                Has Template
              </Text>
            </Box>
          )}
          {Boolean(formQuestion.populateFields?.length ?? 0 > 0) && (
            <Box paddingY={1}>
              <Text color="secondaryLight" size="sm">
                Populates: {populateFieldsText}
              </Text>
            </Box>
          )}
        </Box>

        <Box alignItems="center" direction="row" height="100%" justifyContent="between" width={105}>
          <IconButton
            accessibilityLabel="duplicate question"
            iconName="copy"
            onClick={onDuplicate}
          />
          <IconButton
            accessibilityLabel="add question"
            iconName="pencil"
            onClick={(): void => setEditing(true)}
          />
          <IconButton
            accessibilityLabel="delete question"
            confirmationText="Are you sure you want to delete this question?"
            iconName="trash"
            variant="destructive"
            withConfirmation
            onClick={onDelete}
          />
        </Box>
      </Box>
    );
  };
  return (
    <Box
      key={(formQuestion?._id ?? "new") + formQuestion.prompt}
      alignItems="center"
      color="base"
      direction="row"
      justifyContent="between"
      marginBottom={2}
      paddingX={4}
      paddingY={2}
      rounding="md"
      width="100%"
    >
      {editing ? renderForm() : renderRow()}
    </Box>
  );
};
