import {AppointmentType, NotificationOptions} from "@constants";
import {skipToken} from "@reduxjs/toolkit/query";
import {hideNotificationEditButton, NotificationItem, useGetUsersQuery, userName} from "@store";
import {Badge, BooleanField, Box, Button, Icon, IconButton, SelectField, Text} from "ferns-ui";
import React, {ReactElement, useMemo, useState} from "react";

import {DeliveryStatuses} from "./DeliveryStatuses";

const getUserFriendlyNotificationTimeString = (minutesBefore: number): string => {
  const days = Math.floor(minutesBefore / 1440);
  const hours = Math.floor((minutesBefore % 1440) / 60);
  const minutes = minutesBefore % 60;

  const parts: string[] = [];

  if (days > 0) parts.push(`${days} day${days > 1 ? "s" : ""}`);
  if (hours > 0) parts.push(`${hours} hour${hours > 1 ? "s" : ""}`);
  if (minutes > 0) parts.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);

  return `${parts.join(" and ")} before`;
};

interface NotificationManagerProps {
  appointmentType: AppointmentType | "";
  notifications: NotificationItem[];
  setNotifications: (notifications: NotificationItem[]) => void;
  attendeeIds: string[];
  disabled?: boolean;
}
export const NotificationManager = ({
  appointmentType,
  notifications,
  setNotifications,
  attendeeIds,
  disabled,
}: NotificationManagerProps): ReactElement => {
  const [addNotificationActive, setAddNotificationActive] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState<string>("5");
  const [sendAsPush, setSendAsPush] = useState<boolean>(true);
  const [sendAsSms, setSendAsSms] = useState<boolean>(true);
  const {data: attendeeData} = useGetUsersQuery(
    attendeeIds.length > 0 ? {_id: {$in: attendeeIds}} : skipToken
  );
  const [hideEditNotification, hideDeleteNotification] = useMemo(() => {
    const hideEdit = hideNotificationEditButton(appointmentType) || disabled;
    const hideDelete = hideEdit || disabled;
    return [hideEdit, hideDelete];
  }, [appointmentType, disabled]);

  const userNotificationWarnings: string[] = useMemo(() => {
    const attendees = attendeeData?.data || [];
    const warnings: string[] = [];
    for (const attendee of attendees) {
      const warningMsgs: string[] = [];
      if (!attendee.pushNotifications) {
        warningMsgs.push(`Push Notifications not consented`);
      }
      if (!attendee.smsNotifications) {
        warningMsgs.push(`SMS Notifications not consented`);
      }
      if (!attendee.smsEnabled) {
        if (attendee.smsErrors && attendee.smsErrors.length > 0) {
          warningMsgs.push(
            `SMS Notifications not enabled due to ` +
              `${attendee.smsErrors.length} error(s) when attempting to send SMS messages`
          );
        } else {
          warningMsgs.push(`SMS Notifications not enabled`);
        }
      }
      if (warningMsgs.length > 0) {
        warnings.push(`${userName(attendee)}: ${warningMsgs.join(", ")}`);
      }
    }
    return warnings;
  }, [attendeeData?.data]);

  return (
    <Box paddingY={2}>
      {userNotificationWarnings.length > 0 && (
        <Box marginBottom={2}>
          <Box direction="row">
            <Box marginRight={4}>
              <Icon color="error" iconName="triangle-exclamation" />
            </Box>
            <Box direction="column">
              <Box marginBottom={2}>
                <Text bold color="error">
                  The following User(s) may not receive certain types of notifications:
                </Text>
              </Box>
              {userNotificationWarnings.map((user) => (
                <Box key={user} marginBottom={2}>
                  <Text>- {user}</Text>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      <Box>
        {notifications.map((notification) => (
          <Box
            key={notification.minutesBefore}
            alignItems="center"
            color="base"
            direction="row"
            justifyContent="between"
            marginBottom={2}
            padding={1}
            rounding="md"
            width="100%"
          >
            <Box direction={hideEditNotification ? "row" : "column"}>
              <Text>{getUserFriendlyNotificationTimeString(notification.minutesBefore)}</Text>
              <Box
                direction="row"
                justifyContent="start"
                paddingX={hideEditNotification ? 1 : 0}
                paddingY={hideEditNotification ? 0 : 1}
              >
                {notification.sendAsPush && <Badge status="info" value="Push" />}
                {notification.sendAsSms && <Badge status="success" value="SMS" />}
                {notification?.addEnrollmentPhoneNumberPrompt && (
                  <Badge secondary status="neutral" value="+ Enrollment #" />
                )}
                {notification?.askForConfirmationSMS && (
                  <Badge secondary status="success" value="With Confirmation Request" />
                )}
              </Box>
            </Box>
            <Box>
              <DeliveryStatuses notification={notification} />
            </Box>
            {!hideDeleteNotification && (
              <Box direction="row" justifyContent="end" width={70}>
                <IconButton
                  accessibilityLabel="remove notification"
                  iconName="trash"
                  variant="destructive"
                  onClick={(): void => {
                    setNotifications(
                      notifications.filter((n) => n.minutesBefore !== notification.minutesBefore)
                    );
                  }}
                />
              </Box>
            )}
          </Box>
        ))}
      </Box>
      {!hideEditNotification && (
        <Box width={200}>
          <Button
            disabled={disabled}
            text="Add Notification"
            variant="secondary"
            onClick={(): void => {
              setAddNotificationActive(true);
            }}
          />
        </Box>
      )}
      {!hideEditNotification && addNotificationActive && (
        <Box>
          <SelectField
            options={Array.from(NotificationOptions.keys()).map((key) => ({
              label: key,
              value: NotificationOptions.get(key)!.toString(),
            }))}
            requireValue
            title="Add Notification"
            value={selectedTime}
            onChange={setSelectedTime}
          />
          <Box maxWidth={300}>
            <Box paddingY={2}>
              <BooleanField
                title="Send As Push Notification"
                value={sendAsPush}
                onChange={setSendAsPush}
              />
            </Box>
            <Box paddingY={2}>
              <BooleanField title="Send as SMS" value={sendAsSms} onChange={setSendAsSms} />
            </Box>
          </Box>
          <Box direction="row" paddingY={4} width={200}>
            <Box flex="grow" marginRight={4}>
              <Button
                text="Add"
                onClick={(): void => {
                  if (selectedTime) {
                    if (notifications.find((n) => n.minutesBefore === parseInt(selectedTime))) {
                      // don't allow duplicates
                      return;
                    }
                    setNotifications([
                      ...notifications,
                      {
                        minutesBefore: parseInt(selectedTime),
                        sendAsPush,
                        sendAsSms,
                      },
                    ]);
                  }
                  setAddNotificationActive(false);
                }}
              />
            </Box>
            <Box flex="grow">
              <Button
                text="Cancel"
                variant="muted"
                onClick={(): void => {
                  setAddNotificationActive(false);
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
