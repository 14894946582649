import {useGetUserStatusesQuery, usePatchUsersByIdMutation, User} from "@store";
import {Box, Button, Icon, MultiselectField, SelectField, Text, useToast} from "ferns-ui";
import React, {useCallback, useState} from "react";
import {View} from "react-native";

import {UserStatusBadge} from "./UserStatusBadge";

interface TapToEditUserStatusProps {
  currentObj: User;
  subtitle?: string;
}

export const TapToEditUserStatus = ({
  currentObj: currentUser,
  subtitle,
}: TapToEditUserStatusProps): React.ReactElement => {
  const toast = useToast();
  const {data: userStatuses} = useGetUserStatusesQuery({});
  const [updateUser] = usePatchUsersByIdMutation();
  const [edit, setEdit] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(currentUser.statusId);
  const [selectedReasons, setSelectedReasons] = useState<string[]>(currentUser.statusReasons || []);

  const onEdit = useCallback(() => {
    setEdit(!edit);
  }, [edit]);

  const onStatusChange = useCallback((statusId?: string) => {
    setSelectedStatus(statusId);
    setSelectedReasons([]);
  }, []);

  const onReasonChange = useCallback((reasons: string[]) => {
    setSelectedReasons(reasons);
  }, []);

  const options =
    userStatuses?.data?.map((status) => ({label: status.name, value: status._id})) ?? [];
  const currentStatus = userStatuses?.data?.find((status) => status._id === selectedStatus);

  const statusReasons = currentStatus?.reasons || [];
  const userReasons =
    selectedStatus === currentUser.statusId ? currentUser.statusReasons || [] : [];
  const allReasons = [...statusReasons, ...userReasons];

  const uniqueReasons = Array.from(new Set(allReasons));
  const reasonOptions = uniqueReasons.map((reason) => ({label: reason, value: reason}));

  const isSaveDisabled = currentStatus?.captureReasons && selectedReasons.length === 0;

  const onSave = useCallback(async () => {
    if (isSaveDisabled) return;

    try {
      await updateUser({
        id: currentUser._id,
        body: {
          statusId: selectedStatus,
          statusReasons: selectedReasons,
        },
      }).unwrap();
      setEdit(false);
      toast.success("User status updated successfully");
    } catch (error) {
      toast.catch(error);
    }
  }, [currentUser._id, selectedStatus, selectedReasons, updateUser, toast, isSaveDisabled]);

  return (
    <Box borderBottom="default" direction="column" paddingY={2} width="100%">
      <Box direction="row" justifyContent="between" width="100%">
        <Box flex="grow">
          <Text bold>User Status:</Text>
        </Box>
        {Boolean(!edit) && (
          <Box direction="column">
            <Box alignItems="center" direction="row" justifyContent="end">
              <Box justifyContent="end">
                <UserStatusBadge statusId={currentUser.statusId} />
              </Box>
              <Box
                accessibilityHint="Update user's user status"
                accessibilityLabel="Edit user status"
                marginLeft={2}
                width={16}
                onClick={onEdit}
              >
                <Icon iconName="pencil" size="md" />
              </Box>
            </Box>
            {Boolean(subtitle) && (
              <Box justifyContent="end" width="100%">
                <Text>{subtitle}</Text>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {edit && (
        <Box gap={2} marginTop={2}>
          <Box marginBottom={2} width="66%">
            <Text bold>Status</Text>
            <SelectField
              options={options}
              requireValue={false}
              value={selectedStatus}
              onChange={onStatusChange}
            />
          </Box>
          {Boolean(currentStatus?.captureReasons) && (
            <Box marginBottom={2} width="66%">
              <MultiselectField
                options={reasonOptions}
                title="Reasons"
                value={selectedReasons}
                onChange={onReasonChange}
              />
            </Box>
          )}
          {Boolean(isSaveDisabled) && (
            <Box width="66%">
              <Text color="warning">
                Reason required: please select at least one reason in order to save.
              </Text>
            </Box>
          )}
          <View style={{flexDirection: "row", justifyContent: "flex-end", gap: 16}}>
            <Button text="Cancel" variant="muted" onClick={onEdit} />
            <View style={{marginLeft: 8}}>
              <Button disabled={isSaveDisabled} text="Save" onClick={onSave} />
            </View>
          </View>
        </Box>
      )}
    </Box>
  );
};
