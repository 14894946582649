import {
  DotPhrase,
  useDeleteDotPhrasesByIdMutation,
  useGetDotPhrasesQuery,
  usePatchDotPhrasesByIdMutation,
  usePostDotPhrasesMutation,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {pageOnError} from "@utils";
import {
  Box,
  Button,
  Heading,
  IconButton,
  Page,
  Text,
  TextArea,
  TextField,
  useToast,
} from "ferns-ui";
import React, {useState} from "react";

interface Props extends StaffStackScreenProps<"DotPhraseAdmin"> {}

const DotPhraseRow = ({
  dotPhrase,
  isEditing = false,
}: {
  dotPhrase?: DotPhrase;
  isEditing?: boolean;
}): React.ReactElement | null => {
  const toast = useToast();
  const [edit, setEdit] = useState(isEditing);
  const [phrase, setPhrase] = useState(dotPhrase?.phrase ?? "");
  const [replacement, setReplacement] = useState(dotPhrase?.replacement ?? "");
  const [updatePhrase] = usePatchDotPhrasesByIdMutation();
  const [createPhrase] = usePostDotPhrasesMutation();
  const [removePhrase] = useDeleteDotPhrasesByIdMutation();

  const saveDisabled = !phrase || (phrase && phrase[0] === ".") || !replacement;

  if (edit) {
    return (
      <Box
        color="base"
        gap={4}
        justifyContent="center"
        marginBottom={2}
        paddingX={4}
        paddingY={4}
        rounding="md"
      >
        {Boolean(!dotPhrase?._id) && (
          <Box paddingY={2}>
            <Heading size="sm">New Dot Phrase</Heading>
          </Box>
        )}
        <TextField
          errorText={
            phrase && phrase[0] === "." ? "Phrase must not start with a period" : undefined
          }
          title="Phrase To Be Replaced"
          value={phrase}
          onChange={setPhrase}
        />
        <TextArea
          title="Text To Replace Phrase With"
          value={replacement}
          onChange={setReplacement}
        />
        <Box direction="row" width="100%">
          <Box marginRight={4} width={200}>
            <Button
              disabled={saveDisabled}
              text="Save"
              onClick={async (): Promise<void> => {
                if (dotPhrase) {
                  await updatePhrase({
                    id: dotPhrase._id,
                    body: {
                      phrase,
                      replacement,
                    },
                  })
                    .unwrap()
                    .catch((error: any) => toast.catch("Error updating dot phrase", error));
                  setEdit(false);
                } else {
                  await createPhrase({
                    phrase,
                    replacement,
                  })
                    .unwrap()
                    .catch((error: any) => toast.catch("Error creating dot phrase", error));
                  // Reset the form
                  setPhrase("");
                  setReplacement("");
                  setEdit(true);
                }
              }}
            />
          </Box>
          {Boolean(dotPhrase) && (
            <Box width={200}>
              <Button
                text="Cancel"
                variant="muted"
                onClick={async (): Promise<void> => {
                  setEdit(false);
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  } else if (dotPhrase) {
    return (
      <Box
        color="base"
        direction="row"
        justifyContent="center"
        marginBottom={2}
        paddingX={4}
        paddingY={2}
        rounding="md"
      >
        <Box alignItems="center" direction="row" flex="grow">
          <Box marginRight={2} width="50%">
            <Text bold>{`.${dotPhrase.phrase}`}</Text>
          </Box>
          <Box width="50%">
            <Text>{dotPhrase.replacement}</Text>
          </Box>
        </Box>
        <Box direction="row" justifyContent="between" width={100}>
          <IconButton
            accessibilityLabel="Edit dot phrase"
            iconName="pencil"
            onClick={(): void => setEdit(true)}
          />
          <IconButton
            accessibilityLabel="Delete dot phrase"
            iconName="trash"
            variant="destructive"
            onClick={async (): Promise<void> => {
              await removePhrase(dotPhrase._id)
                .unwrap()
                .catch((error: any) => toast.catch("Error deleting dot phrase", error));
            }}
          />
        </Box>
      </Box>
    );
  } else {
    return null;
  }
};

export const DotPhraseAdminScreen = ({navigation}: Props): React.ReactElement => {
  const {data: dotPhrasesData} = useGetDotPhrasesQuery({});
  const dotPhrases = dotPhrasesData?.data;
  return (
    <Page navigation={navigation} scroll onError={pageOnError}>
      <Box paddingY={2}>
        <Heading>Dot Phrases</Heading>
      </Box>
      <Box paddingY={1}>
        <Text>
          Here you can configure dot phrases which will allow you replace a short phrase beginning
          with a period with a longer phrase or template. For example, you could replace
          &ldquo;.fh&rdquo; with &ldquo;Flourish Health&rdquo;.
        </Text>
      </Box>
      <Box paddingY={1}>
        <Text>
          If you want to use it as a template with required fields, add &ldquo;***&rdquo; where you
          or other staff would need to fill in info. The form cannot be completed until the
          &ldquo;***&rdquo; has been replaced.
        </Text>
      </Box>
      <Box marginTop={5}>
        {dotPhrases?.map((dotPhrase) => <DotPhraseRow key={dotPhrase._id} dotPhrase={dotPhrase} />)}
        <DotPhraseRow key="new" isEditing />
      </Box>
    </Page>
  );
};
