import {useSentryAndToast} from "@store";
import {Mutex} from "async-mutex";
import React, {
  createContext,
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

// TODO: limit logs for useFormInstancePatch to when AUTH_DEBUG is true
const log = (s: string): void => console.debug(`[FormInstanceProvider]: ${s}`);

type FormInstanceContextType = {
  formInstanceMutex: Mutex;
  pendingUpdates: MutableRefObject<Set<string>>;
};

const FormInstanceContext = createContext<FormInstanceContextType | null>(null);

export const FormInstanceProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const sentryAndToast = useSentryAndToast();
  // Create a single Mutex per form instance.
  const formInstanceMutex = useMemo(() => new Mutex(), []);
  // track pending updates- prevents duplicates
  const pendingUpdates = useRef(new Set<string>());

  // Effect to force release the mutex time out or clean up on unmount.
  useEffect((): (() => void) => {
    // Capture the current pending updates snapshot.
    const pendingUpdatesSnapshot = pendingUpdates.current;

    return (): void => {
      if (formInstanceMutex.isLocked()) {
        const releaseMsg = `[FormInstanceProvider]: Component unmounted - releasing mutex with incomplete pending updates: ${Array.from(pendingUpdatesSnapshot).join(", ")}`;
        // If there is only 1 pending update:
        //   it means the process is still running on the backend error will be handled
        //   by patch hook toast and backend
        // If there are more than 1 pending updates,
        //    it indicates that the queued updates will not complete,
        //    and this is a critical error that needs to be reported
        if (pendingUpdatesSnapshot.size > 1) {
          sentryAndToast(
            "Error Editing Note. Please Reach out in #product-questions",
            new Error(releaseMsg)
          );
        }
        log(`[FormInstanceProvider]: ${releaseMsg} `);
        formInstanceMutex.release();
      }
      pendingUpdatesSnapshot.clear();
    };
    // do not duplicate toast error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInstanceMutex]);

  return (
    <FormInstanceContext.Provider value={{formInstanceMutex, pendingUpdates}}>
      {children}
    </FormInstanceContext.Provider>
  );
};

export const useFormInstanceContext = (): FormInstanceContextType => {
  const context = useContext(FormInstanceContext);
  const sentryAndToast = useSentryAndToast();
  if (!context) {
    sentryAndToast(
      "Error Loading Form Information. Please Reach out in #product-questions",
      new Error("useFormInstanceMutex must be used within a FormInstanceProvider")
    );
    // provide a fallback value to avoid returning null
    return {
      formInstanceMutex: new Mutex(),
      pendingUpdates: {current: new Set<string>()},
    };
  }
  return context;
};
