import {useFormInstanceContext} from "@components";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {
  FormInstance,
  useGetFormInstancesByIdQuery,
  usePatchFormInstanceAnswerMutation,
  useSentryAndToast,
} from "@store";
import isEqual from "lodash/isEqual";
import {useCallback} from "react";

import {FormInstancePatchReturnType, versionErrorTitleIncluded} from "./useFormInstancePatch";

type UseRetryPatchFormInstance = {
  retryPatchAnswerOnVersionError: (
    answer: FormInstance["answers"][number],
    remoteAnswer: FormInstance["answers"][number]["answers"] | undefined,
    e: FetchBaseQueryError,
    requestKey: string
  ) => FormInstancePatchReturnType;
};

// TODO: limit logs for useRetryPatchFormInstance to when AUTH_DEBUG is true
const log = (s: string): void => console.debug(`[useFormInstancePatch]: ${s}`);

/**
 * Handles retry logic for `patchFormInstanceAnswer`
 * Currently only handles when a `VersionError` occurs for patch answers
 *
 * What It Does:
 * - Compare the server's answer with the answer the user originally modified (`remoteAnswer`)
 * - If they match, attempts a retry without incrementing the version.
 * - If they don't match, skips the retry and notifies the user.
 * - Handles logging, cleanup from `pendingUpdates`, and user feedback via sentry and toast.
 *
 * This hook is designed to be used inside `useFormInstancePatch`, keeping retry logic separate
 * for clarity and modularity.
 */

export const useRetryPatchFormInstance = (formInstanceId: string): UseRetryPatchFormInstance => {
  const sentryAndToast = useSentryAndToast();
  const {pendingUpdates} = useFormInstanceContext();
  const [patchFormInstanceAnswer] = usePatchFormInstanceAnswerMutation();
  const {data: formInstance} = useGetFormInstancesByIdQuery(formInstanceId);

  /**
   * Attempts a retry of patching an answer after a VersionError.
   * Only retries if the answer on the server matches the previously sent value.
   */
  const retryPatchAnswerOnVersionError = useCallback(
    async (
      answer: FormInstance["answers"][number],
      remoteAnswer: FormInstance["answers"][number]["answers"] | undefined,
      e: any,
      requestKey: string
    ): FormInstancePatchReturnType => {
      const removeRequestKeyAndLog = (reqStatus: "succeeded" | "failed"): void => {
        log(`Patch ${reqStatus}: Removing request from pending updates ${requestKey}`);
        pendingUpdates.current.delete(requestKey);
      };

      if (!versionErrorTitleIncluded(e, formInstanceId)) {
        removeRequestKeyAndLog("failed");
        console.error(
          `Error VersionError Title did not pass` +
            `formInstanceId: ${formInstanceId}` +
            ` ${e?.data?.title}`
        );
        // use original error if not a VersionError
        sentryAndToast("Error saving answer. Please try again.", e as Error);
        return {error: e as Error};
      }

      if (!answer || !remoteAnswer || !formInstance) {
        removeRequestKeyAndLog("failed");
        const newError = new Error(
          `Missing` +
            `${!answer ? " answer" : ""}` +
            `${!remoteAnswer ? " remoteAnswer" : ""}` +
            `${!formInstance ? " formInstance" : ""}.` +
            ` Cannot retry.`
        );
        sentryAndToast("Error saving answer. Please try again.", newError);
        return {error: newError};
      }

      const currentAnswer = formInstance?.answers.find((a) => a._id === answer._id)?.answers;

      if (isEqual(remoteAnswer, currentAnswer)) {
        log("Retrying patch...");

        try {
          const data = await patchFormInstanceAnswer({
            formInstanceId,
            answerId: answer!._id!,
            answer: {...answer},
          }).unwrap();
          removeRequestKeyAndLog("succeeded");
          return {data};
        } catch (error) {
          removeRequestKeyAndLog("failed");
          sentryAndToast("Error saving answer on retry. Please try again.", error as Error);
          // Don't throw the error here to avoid stopping queued updates.
          // Return the error so the UI can handle it.
          return {error: error as Error};
        }
      } else {
        removeRequestKeyAndLog("failed");

        const newError = new Error(
          `FormInstance Data answer has changed. Versions out of sync. Skipping retry.` +
            ` Most Recent Answer:` +
            ` ${JSON.stringify(currentAnswer)},` +
            ` Remote Answer: ${JSON.stringify(remoteAnswer)}`
        );
        sentryAndToast("Error saving answer on retry. Please try again.", newError);
        // Don't throw the error here to avoid stopping queued updates.
        // Return the error so the UI can handle it.
        return {error: newError};
      }
    },
    [formInstance, formInstanceId, patchFormInstanceAnswer, pendingUpdates, sentryAndToast]
  );

  return {retryPatchAnswerOnVersionError};
};
