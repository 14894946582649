import {ScheduleItem} from "@store";
import {Box, Field, Modal, SelectField} from "ferns-ui";
import React, {ReactElement, useState} from "react";

interface ScheduleItemDeleteReasonModalProps {
  visible: boolean;
  onConfirm: (option: ScheduleItem["attendanceStatus"], reason: string) => void;
  onDismiss: () => void;
  scheduleItemModalAction: "Reschedule" | "Cancel";
  isRecurring?: boolean;
}

export const ScheduleItemDeleteReasonModal = ({
  visible,
  onConfirm,
  onDismiss,
  scheduleItemModalAction,
  isRecurring = false,
}: ScheduleItemDeleteReasonModalProps): ReactElement | null => {
  const [deleteScheduleItemOption, setDeleteScheduleItemOption] = useState<
    ScheduleItem["attendanceStatus"] | undefined
  >(undefined);
  const [deleteScheduleItemReason, setDeleteScheduleItemReason] = useState<string>("");

  let modalSubtitle,
    modalTitle = "";

  let deleteScheduleItemOptions: {label: string; value: ScheduleItem["attendanceStatus"]}[] = [];

  const showTextField = deleteScheduleItemOption !== "Incorrect Entry";

  const canSubmit = showTextField
    ? Boolean(deleteScheduleItemOption && deleteScheduleItemReason)
    : Boolean(deleteScheduleItemOption);

  if (scheduleItemModalAction === "Cancel") {
    deleteScheduleItemOptions = [
      {label: "Cancelled", value: "Cancellation"},
      {label: "Rescheduled", value: "Reschedule"},
      {label: "Other", value: "Other"},
    ];
    if (isRecurring) {
      modalSubtitle =
        "This action will remove this single event from the recurring series, but leave the rest in place.\n\nPlease select a reason for deleting this event";
    } else {
      modalSubtitle = "Please select a reason for deleting this event";
    }
    modalTitle = "Confirm Delete Schedule Item";
  } else if (scheduleItemModalAction === "Reschedule") {
    deleteScheduleItemOptions = [
      {label: "Rescheduled", value: "Reschedule"},
      {label: "Entered wrong date/time", value: "Incorrect Entry"},
      {label: "Other", value: "Other"},
    ];
    modalSubtitle = "Please select a reason for update this event";
    modalTitle = "Confirm Update Schedule Item";
  } else {
    console.error("Invalid schedule item modal action");
    return null;
  }

  return (
    <Modal
      primaryButtonDisabled={!canSubmit}
      primaryButtonOnClick={() => onConfirm(deleteScheduleItemOption!, deleteScheduleItemReason)}
      primaryButtonText="Confirm"
      secondaryButtonOnClick={onDismiss}
      secondaryButtonText="Cancel"
      size="md"
      subtitle={modalSubtitle}
      title={modalTitle}
      visible={visible}
      onDismiss={onDismiss}
    >
      <Box alignSelf="center" flex="grow" gap={4} maxWidth="100%" width="100%">
        <Box width="40%">
          <SelectField
            options={deleteScheduleItemOptions}
            placeholder="Please select an option"
            value={deleteScheduleItemOption || ""}
            onChange={(val) => {
              setDeleteScheduleItemOption(val as ScheduleItem["attendanceStatus"]);
            }}
          />
        </Box>
        {showTextField && (
          <Box width="100%">
            <Field
              placeholder="Additional notes..."
              rows={4}
              type="textarea"
              value={deleteScheduleItemReason || ""}
              onChange={(val) => {
                setDeleteScheduleItemReason(val);
              }}
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};
