import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  Address,
  passwordHasErrors,
  splitOnUpperCase,
  StaffRoles,
  useCreateEmailUserMutation,
  useGetUsersQuery,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {hasFeatureFlag, isSuperUser, pageOnError, UserTypes} from "@utils";
import {
  AddressField,
  AddressInterface,
  BooleanField,
  Box,
  Button,
  DateTimeField,
  Icon,
  Page,
  SelectField,
  Text,
  TextField,
  useToast,
} from "ferns-ui";
import React, {ReactElement, useCallback, useMemo, useState} from "react";

interface CreateUserProps extends StaffStackScreenProps<"CreateStaff"> {}
interface StaffRolesQueryCondition {
  "staffRoles.PatientGuideSupervisor"?: boolean;
  "staffRoles.FamilyGuideSupervisor"?: boolean;
  "staffRoles.TherapistSupervisor"?: boolean;
}

const Errors: {[id: string]: string} = {
  missingName: "Name is required.",
  missingEmail: "Email is required.",
  missingPhoneNumber: "Phone number is required.",
  missingSupervisorPhoneNumber:
    "Selected supervisor is missing a phone number and cannot be used as a supervisor. Please select a different supervisor or add a phone number to the supervisor before creating the staff user.",
  invalidPhoneNumber: "Phone number must be 10 digits, or 11 digits if starting with 1.",
  missingPassword: "Password is required.",
  invalidPassword:
    "Password must be at least 12 character long and include at least three of the following: uppercase character, lower case character, digit, and special symbol",
  missingBirthday: "Birthday is required.",
  missingGender: "Gender is required.",
  missingSupervisor: "Supervisor is required for the selected role(s).",
};

export const CreateStaffScreen = ({navigation}: CreateUserProps): ReactElement => {
  const [createEmailUser, {isLoading}] = useCreateEmailUserMutation();
  const user = useReadProfile();
  const toast = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [admin, setAdmin] = useState(false);
  const [password, setPassword] = useState("");
  const [staffRoles, setStaffRoles] = useState({
    SuperUser: false,
    Therapist: false,
    Psychiatrist: false,
    PatientGuide: false,
    FamilyGuide: false,
    TherapistSupervisor: false,
    PatientGuideSupervisor: false,
    FamilyGuideSupervisor: false,
    EnrollmentCoordinator: false,
    ClinicalLeader: false,
    ClinicalDirector: false,
    RiskManager: false,
    MedicalDirector: false,
    EnrollmentSupervisor: false,
    SoftwareEngineer: false,
    ClinicalQualityAssurance: false,
    OnCallCrisisConsultant: false,
    PsychiatristSupervisor: false,
  });
  const [supervisor, setSupervisor] = useState("");

  const [address, setAddress] = useState<Partial<Address>>({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
  });
  const [testUser, setTestUser] = useState<boolean>(false);
  const [birthday, setBirthday] = useState<string>("");
  const [gender, setGender] = useState("");

  const queryConditions: StaffRolesQueryCondition[] = [];
  if (staffRoles.PatientGuide) queryConditions.push({"staffRoles.PatientGuideSupervisor": true});
  if (staffRoles.FamilyGuide) queryConditions.push({"staffRoles.FamilyGuideSupervisor": true});
  if (staffRoles.Therapist) queryConditions.push({"staffRoles.TherapistSupervisor": true});

  const supervisorQuery = queryConditions.length ? {$or: queryConditions} : skipToken;
  const {data: res} = useGetUsersQuery(supervisorQuery);
  const supervisors = res?.data;

  const supervisorOptions = supervisors?.map((s) => ({label: s.name, value: s._id})) || [];

  const isSupervisorRequired =
    staffRoles.PatientGuide || staffRoles.FamilyGuide || staffRoles.Therapist;

  const isMissingSupervisor =
    (staffRoles.PatientGuide || staffRoles.FamilyGuide || staffRoles.Therapist) &&
    supervisorOptions.length &&
    !supervisor;

  const supervisorIsMissingPhoneNumber =
    supervisor && !supervisors?.find((s) => s._id === supervisor)?.phoneNumber;

  const submissionErrors = useMemo((): string[] => {
    const errors: string[] = [];

    if (!name) {
      errors.push(Errors.missingName);
    }

    if (!email) {
      errors.push(Errors.missingEmail);
    }

    if (!phoneNumber) {
      errors.push(Errors.missingPhoneNumber);
    } else {
      // Validate phone number length: 11 digits if starts with 1, otherwise 10 digits
      const isValidLength =
        (phoneNumber.startsWith("1") && phoneNumber.length === 11) ||
        (!phoneNumber.startsWith("1") && phoneNumber.length === 10);

      if (!isValidLength) {
        errors.push(Errors.invalidPhoneNumber);
      }
    }

    if (!password) {
      errors.push(Errors.missingPassword);
    } else {
      const passwordError = passwordHasErrors(UserTypes.Staff, password);
      if (passwordError) {
        errors.push(Errors.invalidPassword);
      }
    }

    if (!birthday) {
      errors.push(Errors.missingBirthday);
    }

    if (!gender) {
      errors.push(Errors.missingGender);
    }

    if (isMissingSupervisor) {
      errors.push(Errors.missingSupervisor);
    }
    if (supervisorIsMissingPhoneNumber) {
      errors.push(Errors.missingSupervisorPhoneNumber);
    }

    return errors;
  }, [
    name,
    email,
    phoneNumber,
    password,
    birthday,
    gender,
    isMissingSupervisor,
    supervisorIsMissingPhoneNumber,
  ]);

  const canSubmit = submissionErrors.length === 0;

  const submit = async (): Promise<void> => {
    try {
      await createEmailUser({
        email,
        password,
        type: UserTypes.Staff,
        name,
        birthday,
        phoneNumber,
        staffRoles,
        admin,
        gender,
        address,
        testUser,
        ...(Boolean(supervisor) && {supervisor}),
      } as any).unwrap();
    } catch (error: any) {
      toast.catch(error, error?.data?.title ?? "Failed to create staff user");
    }
    navigation.pop();
  };

  const checkAndClearSupervisor = useCallback((updatedRoles: typeof staffRoles) => {
    // Check if roles require a supervisor is no longer selected
    if (!updatedRoles.PatientGuide && !updatedRoles.FamilyGuide && !updatedRoles.Therapist) {
      setSupervisor(""); // Clear the supervisor if none of the critical roles are selected
    }
  }, []);

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box paddingY={2}>
        <TextField
          title="Name"
          value={name}
          onChange={(value): void => {
            setName(value);
          }}
        />
      </Box>
      <Box paddingY={2}>
        <TextField
          title="Email"
          type="email"
          value={email}
          onChange={(value): void => {
            setEmail(value);
          }}
        />
      </Box>
      <Box paddingY={2}>
        <TextField
          helperText="Personal phone number for notifications, ex: 8005882300"
          title="Phone Number"
          type="phoneNumber"
          value={phoneNumber}
          onChange={(value): void => {
            // Only allow numeric characters
            const numericValue = value.replace(/\D/g, "");
            setPhoneNumber(numericValue);
          }}
        />
      </Box>
      <Box paddingY={2}>
        <TextField
          helperText={`They will change this when they first log in.
Password must be at least 12 character long and include at least three of the following: uppercase character, lower case character, digit, and special symbol`}
          title="Password"
          value={password}
          onChange={(value): void => {
            setPassword(value);
          }}
        />
      </Box>
      <Box paddingY={2}>
        <DateTimeField title="Birthday" type="date" value={birthday} onChange={setBirthday} />
      </Box>
      <Box paddingY={2}>
        <SelectField
          options={[
            {label: "Please Select", value: ""},
            {label: "Male", value: "Male"},
            {label: "Female", value: "Female"},
            {label: "Non-binary", value: "Non-binary"},
            {label: "Prefer to self-describe", value: "Prefer to self-describe"},
            {label: "Prefer not to say", value: "Prefer not to say"},
          ]}
          requireValue
          title="Gender"
          value={gender}
          onChange={setGender}
        />
      </Box>
      <Box paddingY={2}>
        <AddressField
          value={(address ?? {}) as AddressInterface}
          onChange={(value) => setAddress(value as Address)}
        />
      </Box>
      <Box maxWidth={200}>
        <BooleanField
          title="Test User"
          value={testUser}
          onChange={(value): void => setTestUser(value)}
        />
      </Box>

      {user?.admin && (
        <Box maxWidth={200} paddingY={4}>
          <BooleanField
            title="Admin"
            value={admin}
            onChange={(value: boolean): void => setAdmin(value)}
          />
        </Box>
      )}
      <Box marginTop={4} maxWidth={200}>
        <Box paddingY={2}>
          <Text bold size="lg">
            Staff Roles:
          </Text>
        </Box>
        {["Therapist", "Psychiatrist", "PatientGuide", "FamilyGuide"].map((role) => (
          <Box key={role} paddingY={2}>
            <BooleanField
              title={splitOnUpperCase(role)}
              value={staffRoles[role as StaffRoles] as boolean}
              onChange={(value: boolean): void => {
                const updatedRoles = {...staffRoles, [role]: value};
                checkAndClearSupervisor(updatedRoles);
                setStaffRoles(updatedRoles);
              }}
            />
          </Box>
        ))}
        {/* TODO: prevent creation of super user by non-superuser staff on the backend */}
        {Boolean(user && isSuperUser(user)) &&
          [
            "ClinicalQualityAssurance",
            "SoftwareEngineer",
            "EnrollmentSupervisor",
            "TherapistSupervisor",
            "PatientGuideSupervisor",
            "FamilyGuideSupervisor",
            "EnrollmentCoordinator",
            "ClinicalLeader",
            "RiskManager",
            "SuperUser",
            ...(hasFeatureFlag(user!, "enableCrisisConsultantSetUp")
              ? ["OnCallCrisisConsultant", "PsychiatristSupervisor"]
              : []),
          ].map((role) => (
            <Box key={`${role}superUser`} paddingY={2}>
              <BooleanField
                title={splitOnUpperCase(role)}
                value={staffRoles[role as StaffRoles] as boolean}
                onChange={(value: boolean): void => {
                  const updatedRoles = {...staffRoles, [role]: value};
                  checkAndClearSupervisor(updatedRoles);
                  setStaffRoles(updatedRoles);
                }}
              />
            </Box>
          ))}
      </Box>
      {isSupervisorRequired && supervisorOptions.length ? (
        <Box paddingY={2}>
          <SelectField
            options={[...[{label: "Please Select", value: ""}], ...supervisorOptions]}
            requireValue
            title="Supervisor"
            value={supervisor}
            onChange={setSupervisor}
          />
        </Box>
      ) : null}

      {submissionErrors.length > 0 && (
        <Box marginBottom={4} marginTop={4}>
          {submissionErrors.map((error, index) => (
            <Box
              key={`create-staff-error-${index}`}
              alignContent="center"
              direction="row"
              gap={2}
              marginBottom={1}
              marginTop={1}
            >
              <Box alignItems="center" justifyContent="center">
                <Icon color="error" iconName="triangle-exclamation" />
              </Box>
              <Text color="error">{error}</Text>
            </Box>
          ))}
        </Box>
      )}

      <Button
        disabled={!canSubmit || isLoading}
        loading={isLoading}
        text="Create Staff"
        onClick={submit}
      />
    </Page>
  );
};
