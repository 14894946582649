import {useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {User} from "@store";
import {StaffStackParamList} from "@types";
import {Box, Button, SelectField} from "ferns-ui";
import React, {useState} from "react";

import {APPOINTMENT_CONFIG, AppointmentType, DEFAULT_APPT_OPTIONS} from "../constants";
import {
  hasFeatureFlag,
  isEnrollmentCoordinatorOrSupervisor,
  isSoftwareEngineer,
  isStaff,
  IsWeb,
} from "../utils";

interface EventSchedulerButtonProps {
  selectedUser: User;
}

export const EventSchedulerButton: React.FC<EventSchedulerButtonProps> = ({selectedUser}) => {
  const profile = useReadProfile();
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();

  const [type, setType] = useState<AppointmentType | "Tentative Therapy Slot" | undefined>(
    undefined
  );

  // Only support the appointment scheduler on web for now.
  if (!IsWeb || !profile) {
    return null;
  }

  let options = DEFAULT_APPT_OPTIONS;
  if (
    (isEnrollmentCoordinatorOrSupervisor(profile) || isSoftwareEngineer(profile)) &&
    hasFeatureFlag(profile, "tentativeSchedule")
  ) {
    options = [
      ...DEFAULT_APPT_OPTIONS,
      {label: "Tentative Therapy Slot", value: "Tentative Therapy Slot"},
    ];
  }

  return (
    <Box direction="row" gap={4}>
      <SelectField
        options={options}
        requireValue={false}
        value={type}
        onChange={(t) => setType(t as AppointmentType)}
      />
      <Button
        disabled={!type}
        text="Schedule"
        onClick={(): void => {
          let itemType = type as AppointmentType;
          if (itemType === "Therapy Clinical Intake") {
            // Both clinical intakes are handled as one in Guide Clinical Intake
            itemType = "Guide Clinical Intake";
          } else if (type === "Tentative Therapy Slot") {
            itemType = "Therapy - Patient Session";
          }
          const config = APPOINTMENT_CONFIG[type! as AppointmentType];
          if (
            config?.intake ||
            (type &&
              [
                "Therapy - Caregiver Session",
                "Therapy - Patient Session",
                "Tentative Therapy Slot",
              ].includes(type))
          ) {
            navigation.navigate("IntakeScheduling", {
              type: itemType as AppointmentType,
              selectedUserId: !isStaff(selectedUser?.type) ? selectedUser?._id : undefined,
              tentative: type === "Tentative Therapy Slot",
            });
          } else {
            navigation.navigate("CreateScheduleItem", {
              selectedUserId: !isStaff(selectedUser?.type) ? selectedUser?._id : undefined,
              selectedStaffId: profile?._id,
              type: type as AppointmentType,
            });
          }
        }}
      />
    </Box>
  );
};
