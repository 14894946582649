import {GENDEROPTIONS, GOOGLE_MAPS_API_KEY, TIMEZONES} from "@constants";
import {populateId} from "@ferns-rtk";
import {useAnalytics, useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  ConsentFormType,
  getObjectDifferences,
  splitOnUpperCase,
  StaffRoles,
  useCollapseAllRightBar,
  useGetCarePodsQuery,
  useGetCompanyOrganizationsQuery,
  useGetExternalCliniciansQuery,
  useGetReferralSourcesQuery,
  useGetUsersByIdQuery,
  usePatchUsersByIdMutation,
  User,
} from "@store";
import {StaffStackParamList} from "@types";
import {
  getAllCarePodStates,
  hasFeatureFlag,
  hasPreferredPharmacy,
  hasPrimaryCareDoctorContact,
  isFamilyMember,
  IsNative,
  isPatient,
  isStaff,
  isSuperUser,
  UserTypes,
} from "@utils";
import * as Clipboard from "expo-clipboard";
import Constants from "expo-constants";
import {
  BorderTheme,
  Box,
  Button,
  humanDate,
  Icon,
  Link,
  printOnlyDate,
  printSince,
  TapToEdit,
  Text,
  useToast,
} from "ferns-ui";
import parsePhoneNumberFromString from "libphonenumber-js";
import {DateTime} from "luxon";
import React, {ReactElement, useCallback, useEffect, useState} from "react";

import {AutoTapToEdit} from "./AutoTapToEdit";
import {AutoTapToEditInsurance} from "./AutoTapToEditInsurance";
import {ContactsPane} from "./ContactsPane";
import {PharmaciesPane} from "./PharmaciesPane";
import {StaffPhoneNumber} from "./StaffPhoneNumber";
import {TapToEditCheckbox} from "./TapToEditCheckbox";
import {TapToEditConsent} from "./TapToEditConsent";
import {TapToEditRow} from "./TapToEditRow";
import {TapToEditSchedule} from "./TapToEditSchedule";
import {TapToEditUserStatus} from "./TapToEditUserStatus";
import {UserBioPane} from "./UserBioPane";
import {UserInfoCard} from "./UserInfoCard";
import {WorkingHoursSchedule} from "./WorkingHoursSchedule";
import {ZoomUserIdRow} from "./ZoomUserIdRow";

type SetCurrentUser = React.Dispatch<React.SetStateAction<User | undefined>>;

interface UserInfoSectionProps {
  currentUser: User;
  onSave: () => void;
  onCancel: () => void;
  setCurrentUser: SetCurrentUser;
}

const rowBorder = {borderBottom: "default" as keyof BorderTheme};

const UserInfoSupervisor = ({currentUser}: UserInfoSectionProps): ReactElement | null => {
  const logEvent = useAnalytics();
  const [updateUser] = usePatchUsersByIdMutation();
  const profile = useReadProfile();

  const {data: userData} = useGetUsersByIdQuery(currentUser._id);
  const {data: supervisor, isLoading: supervisorLoading} = useGetUsersByIdQuery(
    userData?.supervisor ?? skipToken
  );
  const navigation =
    useNavigation<NativeStackNavigationProp<StaffStackParamList, UserTypes.Staff>>();

  const toast = useToast();

  const onSelect = useCallback(
    async (selectedUser: User | null) => {
      if (!userData) {
        return;
      }
      try {
        await updateUser({
          id: userData._id,
          body: {
            supervisor: selectedUser?._id,
          },
        }).unwrap();
        await logEvent({
          name: "SetSupervisor",
          staffId: profile?._id,
          // type is reserved by mongo and cannot be a property
          userType: userData.type,
          collectionModel: "users",
          isActivityLogEvent: true,
          appliedUserId: userData._id,
          docId: userData._id,
          payload: {
            prevValue: supervisor?.name,
            newValue: selectedUser?.name,
          },
        });
      } catch (error) {
        toast.catch(error, "Error setting supervisor");
      }
    },
    [logEvent, profile?._id, supervisor?.name, toast, updateUser, userData]
  );

  const onClick = useCallback(() => {
    navigation.navigate("UserPicker", {
      staff: true,
      // Don't allow setting someone as their own supervisor.
      userFilter: (u: User | null): boolean => u?._id !== currentUser?._id,
      onSelect,
    });
  }, [currentUser?._id, navigation, onSelect]);

  return (
    <Box borderBottom="default" direction="row" justifyContent="between" paddingY={2} width="100%">
      <Box>
        <Text bold>Supervisor: </Text>
      </Box>
      <Box direction="row">
        <Box>
          <Text>{supervisorLoading ? "Loading.." : (supervisor?.name ?? "Not Set")}</Text>
        </Box>

        {isSuperUser(profile!) && (
          <Box
            accessibilityHint="Edit supervisor"
            accessibilityLabel="Edit"
            marginLeft={2}
            onClick={onClick}
          >
            <Icon iconName="pencil" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const UserInfoStaffFields = ({
  currentUser,
  setCurrentUser,
  onSave,
  onCancel,
}: UserInfoSectionProps): ReactElement | null => {
  const profile = useReadProfile();

  if (!isStaff(currentUser.type) || !currentUser) {
    return null;
  }

  const tapToEditProps = {
    border: true,
    currentObj: currentUser,
    setValue: setCurrentUser,
    model: "users",
    instanceId: currentUser._id,
  };

  return (
    <>
      <AutoTapToEdit field="outOfOffice" {...tapToEditProps} />
      <UserInfoSupervisor
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        onCancel={onCancel}
        onSave={onSave}
      />
      {[
        StaffRoles.PatientGuide,
        StaffRoles.FamilyGuide,
        StaffRoles.Therapist,
        StaffRoles.Psychiatrist,
        StaffRoles.PatientGuideSupervisor,
        StaffRoles.FamilyGuideSupervisor,
        StaffRoles.TherapistSupervisor,
        StaffRoles.EnrollmentCoordinator,
        StaffRoles.ClinicalLeader,
        StaffRoles.ClinicalDirector,
        StaffRoles.MedicalDirector,
        StaffRoles.SuperUser,
        StaffRoles.RiskManager,
        StaffRoles.SoftwareEngineer,
        StaffRoles.ClinicalQualityAssurance,
        StaffRoles.EnrollmentSupervisor,
        ...(hasFeatureFlag(profile!, "enableCrisisConsultantSetUp")
          ? [StaffRoles.OnCallCrisisConsultant, StaffRoles.PsychiatristSupervisor]
          : []),
      ].map((role) => (
        <AutoTapToEdit
          key={role}
          editable={
            // Superuser and Supervisor roles are only editable by superusers
            Boolean(profile && isSuperUser(profile)) ||
            [
              StaffRoles.FamilyGuide,
              StaffRoles.PatientGuide,
              StaffRoles.Psychiatrist,
              StaffRoles.Therapist,
            ].includes(role)
          }
          field={`staffRoles.${role}`}
          // rowBoxProps={rowBorder}
          title={`Role - ${splitOnUpperCase(role)}`}
          {...tapToEditProps}
        />
      ))}
      <ZoomUserIdRow userId={currentUser._id} />
      <AutoTapToEdit
        field="videoChatLink"
        placeholder="Example: https://flourishhealth.doxy.me/username"
        title="Video Platform Link"
        {...tapToEditProps}
      />
      <AutoTapToEdit
        field="credentials"
        placeholder="Example: LCSW"
        title="Credentials"
        {...tapToEditProps}
      />
      <AutoTapToEdit
        field="dosespotClinicianId"
        title="DoseSpot Clinician ID"
        {...tapToEditProps}
      />
    </>
  );
};

const UserInfoGrowthFields = ({
  currentUser,
  setCurrentUser,
  onSave,
  onCancel,
}: UserInfoSectionProps): React.ReactElement | null => {
  const {data: referralData} = useGetReferralSourcesQuery({});
  const {data: externalClinicianData} = useGetExternalCliniciansQuery({});
  const [isEditing, setIsEditing] = useState(false);
  const collapseAll = useCollapseAllRightBar();

  if (!currentUser || isStaff(currentUser.type)) {
    return null;
  }

  const tapToEditProps = {
    border: true,
    currentObj: currentUser,
    setValue: setCurrentUser,
    isEditing,
    model: "users",
    instanceId: currentUser._id,
  };
  return (
    <UserInfoCard
      collapsable
      collapseExternal={collapseAll}
      enableEditButton // hides the edit button if enableEdit is true since it's already in edit mode
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      title="Growth"
      onCancel={onCancel}
      onSave={onSave}
    >
      <AutoTapToEdit field="growth.hospitalDischargeDate" type="date" {...tapToEditProps} />
      <AutoTapToEdit
        field="growth.enrolledDate"
        type="date"
        {...tapToEditProps}
        transform={(value: string) => {
          return value ? `${printOnlyDate(value)} (${printSince(value)})` : "Not set";
        }}
      />
      <AutoTapToEdit field="growth.serviceStartDate" {...tapToEditProps} type="date" />
      <AutoTapToEdit field="growth.serviceEndDate" {...tapToEditProps} type="date" />
      <AutoTapToEdit
        field="growth.estimatedDischargeDate"
        title="Estimated Flourish Discharge Date"
        type="date"
        {...tapToEditProps}
      />
      <AutoTapToEdit
        field="growth.referralDate"
        title="Referral/Self Referral Date"
        type="date"
        {...tapToEditProps}
      />

      <TapToEditSchedule
        border
        currentUser={currentUser}
        scheduleItemId={currentUser.growth.eligibilityInterview?.scheduleItemId}
        title="Pre-Enrollment Consultation"
        type="Eligibility Interview"
      />
      <TapToEditSchedule
        border
        currentUser={currentUser}
        scheduleItemId={currentUser.growth.clinicalIntake?.scheduleItemId}
        type="Clinical Intake"
      />
      <TapToEditSchedule
        border
        currentUser={currentUser}
        scheduleItemId={currentUser.growth.meetAndGreet?.scheduleItemId}
        type="Meet and Greet"
      />
      <TapToEditSchedule
        border
        currentUser={currentUser}
        scheduleItemId={currentUser.growth.therapyIntake?.scheduleItemId}
        type="Therapy Intake"
      />
      <TapToEditSchedule
        border
        currentUser={currentUser}
        scheduleItemId={currentUser.growth.psychiatryIntake?.scheduleItemId}
        type="Psychiatry Intake"
      />
      <TapToEditSchedule
        border
        currentUser={currentUser}
        scheduleItemId={currentUser.growth.inHomeOnboarding?.scheduleItemId}
        title="Care Advocate Visit"
        type="In Home Onboarding Visit"
      />
      <TapToEditSchedule
        border
        currentUser={currentUser}
        scheduleItemId={currentUser.growth.meetAndGreet?.scheduleItemId}
        type="Meet and Greet"
      />
      <AutoTapToEdit
        field="growth.referralSource"
        options={[
          ...(referralData?.data ?? []).map((referral) => ({
            label: referral.name,
            value: referral._id,
          })),
        ]}
        transform={(referralId): string => {
          if (!currentUser.growth?.referralSource) {
            return "Not Set";
          }
          return populateId(referralId, referralData)?.name ?? "Unknown";
        }}
        type="select"
        value={currentUser.growth?.referralSource ?? ""}
        {...tapToEditProps}
      />
      <AutoTapToEdit
        field="growth.referringClinician"
        options={[
          ...(externalClinicianData?.data ?? []).map((clinician) => ({
            label: clinician.name,
            value: clinician._id,
          })),
        ]}
        transform={(referralId): string => {
          if (!currentUser.growth?.referringClinician) {
            return "Not Set";
          }
          return populateId(referralId, externalClinicianData)?.name ?? "Unknown";
        }}
        type="select"
        value={currentUser.growth?.referringClinician ?? ""}
        {...tapToEditProps}
      />
      <AutoTapToEdit
        field="growth.referringPsychiatrist"
        options={[
          ...(externalClinicianData?.data ?? []).map((clinician) => ({
            label: clinician.name,
            value: clinician._id,
          })),
        ]}
        transform={(referralId): string => {
          if (!currentUser.growth?.referringPsychiatrist) {
            return "Not Set";
          }
          return populateId(referralId, externalClinicianData)?.name ?? "Unknown";
        }}
        type="select"
        value={currentUser.growth?.referringPsychiatrist ?? ""}
        {...tapToEditProps}
      />
      <AutoTapToEdit
        field="growth.dischargeDate"
        title="Actual Flourish Discharge Date"
        type="date"
        {...tapToEditProps}
      />
      <AutoTapToEdit
        field="wasProvidedTechnology"
        title="Was Provided Technology"
        type="boolean"
        {...tapToEditProps}
      />
    </UserInfoCard>
  );
};

const UserInfoTaskFields = ({
  currentUser,
  setCurrentUser,
  onSave,
  onCancel,
}: UserInfoSectionProps): React.ReactElement | null => {
  const [isEditing, setIsEditing] = useState(false);
  const collapseAll = useCollapseAllRightBar();

  if (!currentUser || isStaff(currentUser.type)) {
    return null;
  }

  const tapToEditProps = {
    border: true,
    currentObj: currentUser,
    setValue: setCurrentUser,
    isEditing,
    model: "users",
    instanceId: currentUser._id,
  };

  if (!currentUser || (currentUser && isStaff(currentUser.type))) {
    return null;
  }

  return (
    <UserInfoCard
      collapsable
      collapseExternal={collapseAll}
      enableEditButton
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      title="Growth Tasks"
      onCancel={onCancel}
      onSave={onSave}
    >
      <AutoTapToEdit field="tasks.dischargeMedsReceived" {...tapToEditProps} />
      <AutoTapToEdit field="tasks.authorizationInformationConfirmed" {...tapToEditProps} />
      <AutoTapToEdit field="tasks.documentationRequestSent" {...tapToEditProps} />
      {/* Mirroring User Explorer, showing an easy yes/no on if this task is done */}
      <TapToEditCheckbox
        border
        checked={hasPreferredPharmacy(currentUser)}
        currentUser={currentUser}
        description="To complete, add a pharmacy below"
        title="Preferred Pharmacy"
      />
      {/* Mirroring User Explorer, showing an easy yes/no on if this task is done */}
      <TapToEditCheckbox
        border
        checked={hasPrimaryCareDoctorContact(currentUser)}
        currentUser={currentUser}
        description="To complete, add contact information for Primary Care Doctor"
        title="Provider Information Received"
      />
      <AutoTapToEdit field="tasks.roiSent" title="ROI Sent" {...tapToEditProps} />
      <AutoTapToEdit field="tasks.signedROIReceived" {...tapToEditProps} />
      <AutoTapToEdit field="tasks.signedROISentToProviders" {...tapToEditProps} />
      <AutoTapToEdit field="tasks.medicalRecordsRequested" {...tapToEditProps} />
      {/* Mirroring User Explorer, showing an easy yes/no on if this task is done */}
      <TapToEditCheckbox
        border
        checked={Boolean(currentUser.fitbitId)}
        currentUser={currentUser}
        description="To complete, have the user sync their fitbit."
        title="Fitbit Synced"
      />
      <AutoTapToEdit
        field="growth.notes"
        title="Additional Notes"
        type="textarea"
        {...tapToEditProps}
      />
    </UserInfoCard>
  );
};

const UserInfoBillingFields = ({
  currentUser,
  setCurrentUser,
  onSave,
  onCancel,
}: UserInfoSectionProps): React.ReactElement | null => {
  const [isEditing, setIsEditing] = useState(false);
  const collapseAll = useCollapseAllRightBar();

  if (currentUser && isStaff(currentUser.type)) {
    return null;
  }

  const tapToEditProps = {
    border: true,
    currentObj: currentUser,
    setValue: setCurrentUser,
    isEditing,
    model: "users",
    instanceId: currentUser._id,
  };

  return (
    <UserInfoCard
      collapsable
      collapseExternal={collapseAll}
      enableEditButton
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      title="Billing Info"
      onCancel={onCancel}
      onSave={onSave}
    >
      <AutoTapToEdit field="billingInfo.firstName" title="Billing First Name" {...tapToEditProps} />
      <AutoTapToEdit field="billingInfo.lastName" title="Billing Last Name" {...tapToEditProps} />
      <AutoTapToEdit field="billingInfo.gender" title="Billing Gender" {...tapToEditProps} />

      <AutoTapToEditInsurance
        currentUser={currentUser}
        title="Insurance Plan"
        value={currentUser.billingInfo?.insurancePlan}
        {...tapToEditProps}
      />
      <AutoTapToEdit field="billingInfo.renewalDate" type="date" {...tapToEditProps} />
      <AutoTapToEdit
        field="billingInfo.memberId"
        title="Health Plan Member ID"
        {...tapToEditProps}
      />
      <AutoTapToEdit field="billingInfo.groupId" title="Health Plan Group ID" {...tapToEditProps} />
      <AutoTapToEdit
        field="billingInfo.healthPlanId"
        title="Health Plan Plan ID"
        {...tapToEditProps}
      />
      <AutoTapToEdit
        field="billingInfo.policyHolder"
        title="Health Plan Policy Holder "
        {...tapToEditProps}
      />
      <AutoTapToEdit field="billingInfo.authorizationCode" {...tapToEditProps} />
      <AutoTapToEdit field="billingInfo.medicaidNumber" {...tapToEditProps} />
    </UserInfoCard>
  );
};

const UserInfoUsageFields = ({
  currentUser,
  setCurrentUser,
}: UserInfoSectionProps): React.ReactElement | null => {
  const collapseAll = useCollapseAllRightBar();
  const tapToEditProps = {
    border: true,
    currentObj: currentUser,
    setValue: setCurrentUser,
    model: "users",
    instanceId: currentUser._id,
  };

  return (
    <UserInfoCard collapsable collapseExternal={collapseAll} title="Usage Info">
      <AutoTapToEdit
        editable={false}
        field="usageData.lastAppLaunch"
        transform={(value) => (value ? humanDate(value) : "Never Launched")}
        {...tapToEditProps}
      />
      <AutoTapToEdit
        editable={false}
        field="usageData.currentAppVersion"
        transform={(value) => (value ? value : "Not available")}
        {...tapToEditProps}
      />
      <AutoTapToEdit
        editable={false}
        field="usageData.phoneModel"
        transform={(value) => (value ? value : "Not available")}
        {...tapToEditProps}
      />
      <AutoTapToEdit
        editable={false}
        field="usageData.operatingSystem"
        transform={(value) => (value ? value : "Not available")}
        {...tapToEditProps}
      />
    </UserInfoCard>
  );
};

const UserInfoPersonalFields = ({
  onSave,
  onCancel,
  currentUser,
  setCurrentUser,
}: UserInfoSectionProps): ReactElement | null => {
  const [updateUser] = usePatchUsersByIdMutation();
  const navigation =
    useNavigation<NativeStackNavigationProp<StaffStackParamList, UserTypes.Staff>>();
  const toast = useToast();
  const timezone = currentUser.timezone ?? "America/New_York";

  const {data: companyOrganizationData} = useGetCompanyOrganizationsQuery({});
  const collapseAll = useCollapseAllRightBar();
  const {data: carePods} = useGetCarePodsQuery({});

  const insuranceStates = getAllCarePodStates(carePods?.data ?? []).map((state) => ({
    label: state,
    value: state,
  }));

  const [isEditing, setIsEditing] = useState(false);
  const tapToEditProps = {
    border: true,
    currentObj: currentUser,
    setValue: setCurrentUser,
    model: "users",
    instanceId: currentUser._id,
    isEditing,
  };

  return (
    <UserInfoCard
      collapsable
      collapseExternal={collapseAll}
      enableEditButton
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      title="Personal Details"
      onCancel={onCancel}
      onSave={onSave}
    >
      <AutoTapToEdit field="name" title="Preferred Name" {...tapToEditProps} />
      <TapToEditUserStatus {...tapToEditProps} />
      <AutoTapToEdit
        field="namePronunciation"
        placeholder="How to say the user's name"
        title="Pronunciation (Visible to Patients)"
        {...tapToEditProps}
      />
      <AutoTapToEdit
        field="pronouns"
        options={[
          {label: "she/her/hers", value: "she/her/hers"},
          {label: "he/him/his", value: "he/him/his"},
          {label: "they/them/theirs", value: "they/them/theirs"},
          {label: "Prefer not to say", value: "Not set"},
        ]}
        placeholder="None Selected"
        title="Pronouns"
        type="customSelect"
        {...tapToEditProps}
      />
      <AutoTapToEdit field="initials" {...tapToEditProps} />
      <AutoTapToEdit field="email" type="email" {...tapToEditProps} />
      <AutoTapToEdit
        field="phoneNumber"
        title={
          isStaff(currentUser.type) ? "Personal Phone Number (Hidden to Patients)" : "Phone Number"
        }
        type="phoneNumber"
        {...tapToEditProps}
        transform={(phone): string => {
          if (phone) {
            const phoneNumber = parsePhoneNumberFromString(phone, "US");
            return phoneNumber ? phoneNumber.formatNational() : phone;
          } else {
            return "";
          }
        }}
      />
      {isStaff(currentUser.type) ? <StaffPhoneNumber userId={currentUser._id} /> : null}
      <AutoTapToEdit
        field="communicationPreferences"
        placeholder="SMS, app, phone, email, times of day, etc."
        {...tapToEditProps}
      />
      <AutoTapToEdit field="smsEnabled" title="SMS Enabled" {...tapToEditProps} />
      <AutoTapToEdit field="languages" {...tapToEditProps} />
      <AutoTapToEdit field="interpreterNeeded" {...tapToEditProps} />

      <Box direction="row" justifyContent="between" paddingY={2} width="100%" {...rowBorder}>
        <Text bold>Password:</Text>
        <Button
          text="Reset Password"
          onClick={(): void => {
            navigation.navigate("ResetPassword", {user: currentUser});
          }}
        />
      </Box>
      <AutoTapToEdit
        field="birthday"
        timezone={timezone}
        transform={(birthday): string => {
          return currentUser.birthday
            ? `${printOnlyDate(birthday)} (${Math.floor(
                DateTime.now().diff(DateTime.fromISO(birthday), "years").years
              )})`
            : "";
        }}
        type="date"
        {...tapToEditProps}
      />
      <TapToEditRow
        isEditing={isEditing}
        options={GENDEROPTIONS.map((gender) => ({label: gender, value: gender}))}
        placeholder="None Selected"
        setValue={(value): void => {
          if (GENDEROPTIONS.includes(value)) {
            setCurrentUser({...currentUser, genderSelfDescribe: "", gender: value});
          } else {
            setCurrentUser({
              ...currentUser,
              gender: "Prefer to self-describe",
              genderSelfDescribe: value,
            });
          }
        }}
        title="Gender"
        transform={(gender): string => {
          if (GENDEROPTIONS.includes(gender)) {
            return currentUser.gender!;
          } else {
            return currentUser.genderSelfDescribe ?? "";
          }
        }}
        type="customSelect"
        value={
          currentUser.gender
            ? currentUser.gender === "Prefer to self-describe"
              ? currentUser.genderSelfDescribe
              : currentUser.gender
            : ""
        }
        onSave={async (gender): Promise<void> => {
          if (GENDEROPTIONS.includes(gender)) {
            await updateUser({
              id: currentUser._id,
              body: {genderSelfDescribe: "", gender},
            });
          } else {
            await updateUser({
              id: currentUser._id,
              body: {genderSelfDescribe: gender, gender: "Prefer to self-describe"},
            });
          }
        }}
      />
      <AutoTapToEdit field="height" title="Height" {...tapToEditProps} />
      <AutoTapToEdit field="weight" title="Weight" {...tapToEditProps} />
      <Box borderBottom="default" paddingY={2}>
        <TapToEdit
          googleMapsApiKey={GOOGLE_MAPS_API_KEY}
          includeCounty
          isEditing={isEditing}
          setValue={(value): void => {
            setCurrentUser({...currentUser, address: value});
          }}
          title="Address"
          type="address"
          value={currentUser.address}
          onSave={async (address): Promise<void> => {
            await updateUser({
              id: currentUser._id,
              body: {address},
            });
          }}
        />
        <Link
          href="https://www.notion.so/flourishhealth/County-Codes-for-Charm-d44ef1a8a3ef418c93d5c6fcb0b0d889?pvs=4"
          text="ⓘ Find county code"
        />
      </Box>
      <AutoTapToEdit
        field="billingInfo.address.state"
        options={insuranceStates}
        title="State (Licensing)"
        {...tapToEditProps}
      />
      <AutoTapToEdit
        field="timezone"
        options={TIMEZONES.map((tz) => ({label: tz, value: tz}))}
        {...tapToEditProps}
      />
      <AutoTapToEdit
        field="companyOrganizationId"
        options={[
          ...(companyOrganizationData?.data ?? []).map((organization) => ({
            label: organization.name,
            value: organization._id,
          })),
        ]}
        title="Flourish Organization"
        transform={(companyOrganizationId): string => {
          if (!currentUser.companyOrganizationId) {
            return "Not Set";
          }
          return populateId(companyOrganizationId, companyOrganizationData)?.name ?? "Unknown";
        }}
        type="select"
        {...tapToEditProps}
      />
      <UserInfoStaffFields
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        onCancel={onCancel}
        onSave={onSave}
      />
      {Boolean(currentUser && !isStaff(currentUser.type)) ? (
        <>
          <AutoTapToEdit
            editable={false}
            field="patientId"
            title="Patient ID"
            {...tapToEditProps}
          />

          <AutoTapToEdit
            editable={false}
            field="dosespotPatientId"
            title="DoseSpot Patient ID"
            {...tapToEditProps}
          />
        </>
      ) : null}
      <AutoTapToEdit editable={false} field="_id" title="Support ID" {...tapToEditProps} />
      <Box direction="row" justifyContent="between" paddingY={2} width="100%" {...rowBorder}>
        <Text bold>User Link:</Text>
        <Button
          iconName="link"
          iconPosition="left"
          text="Copy User Link"
          variant="secondary"
          onClick={async (): Promise<void> => {
            const baseAppUrl = IsNative ? "https://app.flourish.health" : Constants.experienceUrl;
            Clipboard.setString(`${baseAppUrl}/User?userId=${currentUser._id}`);
            toast.show("User link copied to clipboard.");
          }}
        />
      </Box>
      <AutoTapToEdit field="testUser" {...tapToEditProps} />
    </UserInfoCard>
  );
};

const UserInfoConsentFields = ({
  currentUser,
  setCurrentUser,
}: UserInfoSectionProps): React.ReactElement | null => {
  const collapseAll = useCollapseAllRightBar();

  if (!currentUser || isStaff(currentUser.type)) {
    return null;
  }

  const tapToEditProps = {
    border: true,
    currentObj: currentUser,
    setValue: setCurrentUser,
    model: "users",
    instanceId: currentUser._id,
  };

  return (
    <UserInfoCard collapsable collapseExternal={collapseAll} title="Consent">
      <AutoTapToEdit field="pushNotifications" {...tapToEditProps} />
      <AutoTapToEdit field="smsNotifications" {...tapToEditProps} />
      <AutoTapToEdit field="smsMessaging" {...tapToEditProps} />

      {Boolean(isPatient(currentUser.type) || isFamilyMember(currentUser.type)) ? (
        <>
          {[
            isPatient(currentUser.type) ? "patientAgreement" : "familyMemberAgreement",
            "research",
            "transportation",
            "consent",
          ].map((consentType) => {
            return (
              <TapToEditConsent
                key={consentType}
                consentType={consentType as ConsentFormType}
                currentUser={currentUser}
              />
            );
          })}
        </>
      ) : null}
    </UserInfoCard>
  );
};

const UserInfoContactFields = ({currentUser}: UserInfoSectionProps): ReactElement | null => {
  const collapseAll = useCollapseAllRightBar();
  if (!currentUser || isStaff(currentUser.type)) {
    return null;
  }
  return (
    <UserInfoCard collapsable collapseExternal={collapseAll} title="Contacts">
      <ContactsPane userId={currentUser._id} />
    </UserInfoCard>
  );
};

const UserInfoPharmaciesFields = ({currentUser}: UserInfoSectionProps): ReactElement | null => {
  const collapseAll = useCollapseAllRightBar();
  return (
    <UserInfoCard collapsable collapseExternal={collapseAll} title="Pharmacies">
      <PharmaciesPane userId={currentUser._id} />
    </UserInfoCard>
  );
};

interface UserInfoProps {
  userId: string;
  enableEdit?: boolean;
  onSave?: () => void;
}

export const UserInfo = ({userId, onSave}: UserInfoProps): ReactElement | null => {
  const [updateUser] = usePatchUsersByIdMutation();
  const collapseAll = useCollapseAllRightBar();
  const {data: userData} = useGetUsersByIdQuery(userId);
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);

  const profile = useReadProfile();

  // Set the user data once we finish fetching
  useEffect(() => {
    if (userData) {
      setCurrentUser(userData);
    }
  }, [userData]);

  const doOnSave = useCallback(async () => {
    if (!currentUser) {
      console.warn("No user to save");
      return;
    }
    await updateUser({
      id: currentUser._id,
      body: {...getObjectDifferences(currentUser, userData)},
    });
    onSave?.();
  }, [currentUser, onSave, updateUser, userData]);

  const onCancel = useCallback(() => {
    setCurrentUser(userData);
  }, [setCurrentUser, userData]);

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <UserInfoPersonalFields
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        onCancel={onCancel}
        onSave={doOnSave}
      />

      {Boolean(profile) && Boolean(hasFeatureFlag(profile!, "enableCrisisConsultantSetUp")) && (
        <Box>
          <WorkingHoursSchedule currentUserId={currentUser._id} />
        </Box>
      )}

      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        subtitle="Displayed in Patient App"
        title="User Bio"
      >
        <UserBioPane userId={currentUser._id} />
      </UserInfoCard>

      <UserInfoBillingFields
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        onCancel={onCancel}
        onSave={doOnSave}
      />

      <UserInfoGrowthFields
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        onCancel={onCancel}
        onSave={doOnSave}
      />

      <UserInfoTaskFields
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        onCancel={onCancel}
        onSave={doOnSave}
      />

      <UserInfoContactFields
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        onCancel={onCancel}
        onSave={doOnSave}
      />

      <UserInfoPharmaciesFields
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        onCancel={onCancel}
        onSave={doOnSave}
      />

      <UserInfoConsentFields
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        onCancel={onCancel}
        onSave={doOnSave}
      />

      <UserInfoUsageFields
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        onCancel={onCancel}
        onSave={doOnSave}
      />
    </>
  );
};
