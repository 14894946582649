import {FallbackScheduleItem, ScheduleItem} from "@components";
import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react-native";
import {useGetScheduleItemsQuery} from "@store";
import {PatientTabScreenProps} from "@types";
import {pageOnError} from "@utils";
import {Box, Page, Text} from "ferns-ui";
import React from "react";

interface HomeScreenProps extends PatientTabScreenProps<"Schedule"> {}

export const ScheduleScreen: React.FC<HomeScreenProps> = ({navigation}) => {
  const user = useReadProfile();
  const {data: scheduleItems} = useGetScheduleItemsQuery(
    user ? {"users.userId": user._id} : skipToken
  );

  if (!user) {
    return null;
  }

  return (
    <Page navigation={navigation} scroll onError={pageOnError}>
      <Box
        alignItems="center"
        alignSelf="center"
        gap={4}
        height="100%"
        justifyContent="center"
        paddingX={4}
        width="100%"
      >
        {!scheduleItems?.data?.length && (
          <Text>
            Once your first sessions are scheduled, you will be able to manage and join them here.
          </Text>
        )}
        {(scheduleItems?.data ?? []).map((s) => (
          <Sentry.ErrorBoundary key={s._id} fallback={<FallbackScheduleItem />}>
            <ScheduleItem scheduleItem={s} />
          </Sentry.ErrorBoundary>
        ))}
      </Box>
    </Page>
  );
};
