import {useFormInstancePatch, useReadProfile} from "@hooks";
import {useFormInstanceIsReadOnly, useLocalFormInstance} from "@store";
import {Box, humanDateAndTime, Text} from "ferns-ui";
import React, {FC, useCallback, useEffect, useState} from "react";

import {FreeformTextArea} from "./FreeformTextArea";
import {QuestionPromptText} from "./QuestionPromptText";

export const MissingAttendanceNote: FC<{
  formInstanceId: string;
}> = ({formInstanceId}) => {
  const profile = useReadProfile();
  const disabled = useFormInstanceIsReadOnly(formInstanceId);
  const {updateFormInstance} = useFormInstancePatch(formInstanceId, disabled);
  const formInstance = useLocalFormInstance(formInstanceId);

  const [value, setValue] = useState<string>("");

  // set value locally
  useEffect(() => {
    if (formInstance?.missingAttendanceNote) {
      setValue(formInstance.missingAttendanceNote);
    }
  }, [formInstance?.missingAttendanceNote]);

  const onBlur = useCallback(async () => {
    await updateFormInstance(
      {
        missingAttendanceNote: value,
      },
      "Error saving missing attendance note"
    );
  }, [updateFormInstance, value]);

  if (!profile || !formInstance?.isAutoCreatedByScheduleItem) {
    return null;
  }

  return (
    <Box paddingY={4}>
      <Box direction="column" flex="grow">
        <QuestionPromptText
          index={0}
          prompt="Notes for Not Attending - optional"
          questionType="other"
        />
        <Box marginTop={2} width="100%">
          <FreeformTextArea
            answerRequiredErr=""
            disabled={disabled}
            value={[value]}
            onBlur={onBlur}
            onChange={(newVal): void => setValue(newVal[0])}
          />
        </Box>
      </Box>
      <Box marginBottom={4}>
        <Text>Last saved: {humanDateAndTime(formInstance.updated)}</Text>
      </Box>
    </Box>
  );
};
