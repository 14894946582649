import {Form} from "@store";

// Questions that are not editable by the users
// These questions can be used in multiple forms and expect the same backend side effects
// They are identified by their internalKey
export const StaticQuestions: Record<string, Form["questions"][number]> = {
  MedicationSupportRequestPsychiatrist: {
    prompt:
      "Once the family/patient guide has contacted the pharmacy, is there action needed from the psychiatrist?",
    description: "",
    type: "Select",
    options: [
      {
        label: "Yes",
        score: 0,
      },
      {
        label: "No",
        score: 0,
      },
    ],
    grouping: "",
    populateFields: [],
    template: "",
    followUpResponseSettings: {
      allowFollowUpResponse: true,
      prompt:
        "Please briefly summarize the issue the psychiatrist needs to address (2–3 sentences). " +
        "A To Do will be created for the patient's psychiatrist, and an alert will be sent to your supervisor, the psychiatrist, and the psychiatrist's supervisor.",
      qualifyingOptions: ["Yes"],
    },
    isSupervisorOnly: false,
    isRequired: true,
    excludeQuestionRiskLanguageAlerts: false,
    internalKey: "medication-support-request-psychiatrist",
  },
};
