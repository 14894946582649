import {Box, Text} from "ferns-ui";
import React from "react";
/**
 * Array of string used for clinical information field templates used in UserTextField.tsx.
 * Each index represents one block of text and will be separated by 2 new lines.
 * Keys correspond to the User field name in the database.
 */
export const ClinicalInfoFieldTemplates: {[key: string]: string[] | undefined} = {
  carePlan: ["Psychiatry:", "Therapy:", "Patient Guide:", "Family Guide:"],
  oneLiner: [
    "[Patient Name] is a [Age] y/o [ethnicity] [gender (pronouns: [stated pronouns])," +
      " referred to Flourish [for/following] [referral context] and began flourish treatment on [date]." +
      " [Patient Name] has [diagnoses] and is on [medications]." +
      " [Note language or ability accommodations/needs if applicable]." +
      " [Pronoun] currently [state living conditions] and [state school/work status]." +
      " [Pronoun]’s social support system includes [brief overview]." +
      " [Patient name] enjoys [hobby # 1, interest # 2, any other fun fact that can be used for rapport building]." +
      " [Pronoun]’s current goal is to [insert goal].",
  ],
  safetyPlan: [
    "My Triggers and Stressors:",
    "My Personal Warning Signs:",
    "My Coping Skills:",
    "My Distractors:",
    "My Reason for Living:",
    "My Support System:",
    "My Emergency Plan:",
    "My Means Reduction Plan:",
  ],
  weeklyUpdates: ["Week of [date]:", "Patient guide:", "Family guide:", "Therapist:"],
};

const CarePlanTextComponent = (): React.ReactElement => {
  return (
    <Box>
      <Box marginBottom={2}>
        <Text>
          The care plan is a patient-facing document serving as our weekly treatment roadmap. It is
          the central organizing document for the current treatment provided by the Flourish Health
          team. It should be succinct, direct and clear.
        </Text>
      </Box>
      <Box marginBottom={1}>
        <Text bold>Psychiatry</Text>
        <Text>
          Must have the latest information on medication regimen and date range for next psychiatry
          appointment.
        </Text>
      </Box>
      <Box marginBottom={1}>
        <Text bold>Therapy</Text>
        <Text>
          The treatment plan should be focused on the therapy plan and any additional details
          specific to the therapists intervention for the patient/family.
        </Text>
      </Box>
      <Box marginBottom={1}>
        <Text bold>Patient Guide</Text>
        <Text>
          Covers several important topics and should be reviewed with screen-shared by the whole
          team every time a patient is discussed in a carepod huddle. In addition to guiding hours,
          it is important for the patient guide to note what they are focused on -- whether it is
          engagement, rapport, or specific skills. Also, it is critical for the psychiatrist to
          explain what red flags they want the guides to look out for that would warrant an urgent
          evaluation.
        </Text>
      </Box>
      <Box marginBottom={1}>
        <Text bold>Family Guide</Text>
        <Text>
          Aside from family guiding hours, the family guide should note what they are focused on
          with the primary caregiver -- is it school advocacy for IEP evaluation, addressing
          scheduling issues, supporting parent management training, getting in touch with DSS or
          helping the caregiver navigate their stress.
        </Text>
      </Box>
    </Box>
  );
};

export const ClinicalStatusTextComponentText =
  "Clinical Status is a quick way to flag and track what the patient’s current status " +
  "and helps the care team remember what kind of engagement and outreach this patient needs. " +
  "It also helps the growth team balance the acuity of the patients on each panel.";

const ClinicalStatusTextComponent = (): React.ReactElement => {
  return (
    <Box>
      <Text>{ClinicalStatusTextComponentText}</Text>
    </Box>
  );
};

const DiagnosisTextComponent = (): React.ReactElement => {
  return (
    <Box>
      <Box marginBottom={2}>
        <Text>
          We use DSM-V-TR diagnoses. Our authorization team also has to submit the primary diagnosis
          when the health plan authorizes the patient for our care for 12 months. We otherwise do
          not need diagnosis or codes for billing as we have a bundled value based care agreement.
        </Text>
      </Box>

      <Box marginBottom={1}>
        <Text>
          ● In general, the psychiatrist holds the primary responsibility for diagnosing and
          updating patient diagnoses given their decade long formal training in how to diagnose
          patients. Discussing the patients presentation, symptoms, and diagnosis should be a
          collaborative process. For these patients, the psychiatrist may defer to the therapist for
          final diagnosis. In either case, the psychiatrist should seek input from the therapist and
          both guides to inform their diagnoses as the rest of the team spends more time with the
          patient than the psychiatrist.
        </Text>
      </Box>
      <Box marginBottom={1}>
        <Text>
          ● Therapists are no longer required to provide a diagnosis at intake. If they have enough
          diagnostic clarity at intake, the therapist can list provisional diagnoses. Otherwise they
          can note the patients symptoms in their intake note and the psychiatrist can assess
          further at the psychiatry intake and diagnose.
        </Text>
      </Box>
      <Box>
        <Text>● List in descending order of severity.</Text>
      </Box>
    </Box>
  );
};

const EngagementDetailsTextComponent = (): React.ReactElement => {
  return (
    <Box>
      <Text>
        Allows staff to capture unique data about each patient that supports personalized engagement
        when they are out on pto and a covering guide may be jumping in with little context.
      </Text>
    </Box>
  );
};

const OneLinerTextComponent = (): React.ReactElement => {
  return (
    <Box>
      <Box marginBottom={1}>
        <Text bold>What is a one liner?</Text>
        <Text>
          The one liner is a short paragraph that gives a quick snapshot that highlights the most
          pertinent information about a patient for the whole team.
        </Text>
      </Box>
      <Box marginBottom={1}>
        <Text bold>Who updates the one liner?</Text>
        <Text>
          The therapist initially writes the one liner after the therapy intake if the patient is
          greenlit for enrollment. After that, any team member can update the one liner as they
          learn more about the patient.
        </Text>
      </Box>
      <Box marginBottom={1}>
        <Text bold>How do we use the one liner?</Text>
        <Text>
          The one liner helps ground the team in unique details about the patient. It is
          particularly helpful for patient guides who are filling in for each other and working with
          patients they haven’t worked with before, and for psychiatrists who might not see the
          patients for weeks at a time.
        </Text>
      </Box>
    </Box>
  );
};

const WeeklyUpdatesTextComponent = (): React.ReactElement => {
  return (
    <Box>
      <Box marginBottom={2}>
        <Text>
          This is a way for guides and therapists to outline how the patient is doing and any needs
          they feel the team should address for the patient that week. It facilitates more efficient
          collaboration across disciplines and lay the groundwork for the huddle agendas.
        </Text>
      </Box>
      <Box marginBottom={1}>
        <Text bold>Patient guide</Text>
        <Box marginLeft={2}>
          <Text>● Emerging/pressing issues: risk, reporting related concerns</Text>
          <Text>
            ● Observing, noticing, or was told of significant changes with patient related to
            symptoms, functioning, or psychosocial circumstances/stressors that would benefit from
            team response or team monitoring
          </Text>
          <Text>
            ● Currently working on XYZ with patient - any feedback on this or ways we can
            collaborate to reinforce this more?
          </Text>
          <Text>● Could use whole team/psychiatry collaboration on XYZ</Text>
        </Box>
        <Box marginBottom={1}>
          <Text bold>Family Guide</Text>
          <Box marginLeft={2}>
            <Text>● Emerging/pressing issues: risk, reporting related concerns</Text>
            <Text>
              ● Was told of significant changes with patient related to symptoms, functioning, or
              psychosocial circumstances/stressors that would benefit from team response or team
              monitoring
            </Text>
            <Text>
              ● Currently working on XYZ with family - any feedback on this or ways we can
              collaborate to reinforce this more?
            </Text>
            <Text>● Could use whole team/psychiatry collaboration on XYZ</Text>
          </Box>
        </Box>
      </Box>
      <Box marginBottom={1}>
        <Text bold>Therapist</Text>
        <Box marginLeft={2}>
          <Text>● Emerging/pressing issues: risk, reporting related concerns</Text>
          <Text>
            ● Was told of significant changes with patient related to symptoms, functioning, or
            psychosocial circumstances/stressors that would benefit from team response or team
            monitoring
          </Text>
          <Text>
            ● Currently working on XYZ with patient - any feedback on this or ways we can
            collaborate to reinforce this more?
          </Text>
          <Text>● Could use whole team/psychiatry collaboration on XYZ</Text>
        </Box>
      </Box>
    </Box>
  );
};

const SafetyConcernsTextComponent = (): React.ReactElement => {
  return (
    <Box>
      <Box marginBottom={1}>
        <Text bold>What is the Patient Safety Concerns section?</Text>
        <Text>
          The Patient Safety Concerns section is a place to note the high risk or complex patient
          issues that are of clinical importance and what the team should do if things get worse.
        </Text>
      </Box>

      <Box marginBottom={1}>
        <Text bold>How do we use the Patient Safety Concerns section?</Text>
        <Text>
          The Patient Safety Concerns section notes the issues that are the highest priority for
          patient safety and details what should be done if things get worse. It also serves as a
          place to document issues the staff needs to be aware of that could be triggering for the
          patient to be reminded of regularly in their care plan, such as an upcoming trial.
        </Text>
      </Box>
      <Box marginBottom={1}>
        <Text bold>How often do we update the Patient Safety Concerns section?</Text>
        <Text>
          This should be formally updated by the team every time the patient is discussed in carepod
          huddles. The patient guide should screen share and the team should agree on the edits.
        </Text>
      </Box>
      <Box>
        <Text bold>What goes in the Patient Safety Concerns section?</Text>
        <Text>
          The Patient Safety Concerns section lists the concerns, their contexts, and what actions
          the team should take if things get worse. For example:
        </Text>
        <Text>
          ● CPS report filed. Team has decided not to inform the patient and caregiver. If
          investigated, be prepared to encourage distress tolerance and activate crisis protocol.
        </Text>
        <Text>
          ● Medication red flags for the guides to watch out for (guidance provided by psychiatrist)
          with clear actions to take if they see these red flags.
        </Text>
      </Box>
    </Box>
  );
};

const SafetyPlanTextComponent = (): React.ReactElement => {
  return (
    <Box>
      <Box marginBottom={2}>
        <Text>
          A Safety Plan is a tool for the patient and caregiver to use when they start to notice
          that the patient might be escalating into a crisis or when the patient starts to feel
          unsafe. It should have strategies that help get the patient through their difficult
          moments and back to a place of feeling safe. While the strongest evidence for the efficacy
          of safety planning is in the treatment and prevention of suicidality, a Safety Plan can
          also be a useful tool during other types of crisis situations. Please consider the unique
          needs of each patient you serve, and what safety means to them.
        </Text>
      </Box>
      <Text bold>It Is Not</Text>
      <Text>
        A No Suicide Decision, nor a Safety Contract, nor any type of statement made by the patient
        where they promise not to harm themself.
      </Text>
      <Text>
        The Safety Plan is not required, and lack of engagement in Safety Planning does not
        disqualify a patient from receiving treatment with Flourish Health.
      </Text>
    </Box>
  );
};

/**
 * The ClinicalInfoModalText object contains components that render the text content for different
 * sections of the information modal associated with UserInfoCare.tsx.
 * Each component represents a specific section of the modal and provides information and
 * instructions related to that section.
 * Keys correspond to the User field name in the database.
 */
export const ClinicalInfoModalText: {
  [key: string]:
    | {title: string; subtitle?: string; textComponent: () => React.ReactElement}
    | undefined;
} = {
  carePlan: {
    title: "Care Plan",
    subtitle: "Displayed in Patient App",
    textComponent: CarePlanTextComponent,
  },
  clinicalStatus: {
    title: "Clinical Status",
    subtitle: "Only Displayed in Staff App",
    textComponent: ClinicalStatusTextComponent,
  },
  diagnosis: {
    title: "Diagnosis",
    subtitle: "Only Displayed in Staff App",
    textComponent: DiagnosisTextComponent,
  },
  engagementDetails: {
    title: "Engagement Details",
    subtitle: "Only Displayed in Staff App",
    textComponent: EngagementDetailsTextComponent,
  },
  oneLiner: {
    title: "One Liner",
    subtitle: "Only Displayed in Staff App",
    textComponent: OneLinerTextComponent,
  },
  safetyConcerns: {
    title: "Safety Concerns",
    subtitle: "Only Displayed in Staff App",
    textComponent: SafetyConcernsTextComponent,
  },
  safetyPlan: {
    title: "Safety Plan",
    subtitle: "Displayed in Patient App",
    textComponent: SafetyPlanTextComponent,
  },
  weeklyUpdates: {
    title: "Weekly Updates",
    subtitle: "Only Displayed in Staff App",
    textComponent: WeeklyUpdatesTextComponent,
  },
};
