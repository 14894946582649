import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query";
import {StaffStackParamList} from "@types";
import {Box, IconButton, Text, useToast} from "ferns-ui";
import {DateTime} from "luxon";
import React, {useCallback} from "react";

import {useGetCarePodsByIdQuery, useGetScheduleItemsByIdQuery} from "../store/openApiSdk";
import {DropdownButton} from "./DropdownButton";

export const SchedulingSlotCell: React.FC<{
  cellData: {
    value: string;
    slot?: any;
    scheduleItemId?: string;
    textSize?: "sm" | "md" | "lg";
  };
}> = ({cellData}) => {
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const toast = useToast();
  const {scheduleItemId, slot, value, textSize} = cellData ?? {};
  const {data: carePod} = useGetCarePodsByIdQuery(slot?.carePodId ?? skipToken);
  const {data: scheduleItem} = useGetScheduleItemsByIdQuery(scheduleItemId ?? skipToken);
  const staff = carePod?.careTeam?.Therapist;

  const addAppointment = useCallback(
    (tentative: boolean, everyOtherWeek: boolean) => {
      navigation.navigate("CreateIntake", {
        slot: JSON.stringify({...slot, type: "Therapy - Patient Session"}),
        staffIds: [staff?._id ?? ""],
        userIds: [],
        cadence: everyOtherWeek ? "Every Other Week" : "Weekly",
        tentative,
        scheduleItemId,
        navToScreenOnSave: "UserExplorer",
      });
    },
    [navigation, slot, staff, scheduleItemId]
  );

  if (!slot) {
    return null;
  }

  // Check if slot.startDatetime is after now
  const isInFuture = slot?.startDatetime && DateTime.fromISO(slot.startDatetime) > DateTime.now();

  return (
    <Box
      alignItems="start"
      direction={scheduleItemId ? "row" : "column"}
      gap={4}
      justifyContent="between"
      width="100%"
    >
      <Text size={textSize}>{value}</Text>
      {Boolean(scheduleItemId && isInFuture) && (
        <IconButton
          accessibilityLabel="Edit Appointment"
          iconName="pencil"
          tooltipText="Edit Appointment"
          variant={scheduleItem?.tentative ? "muted" : "secondary"}
          onClick={() => {
            if (!scheduleItem) {
              toast.warn("No schedule item id found, cannot edit appointment");
              return;
            }

            // Find the first user with a care pod.
            const carePodId = scheduleItem.users.find((u) => u.userId.carePod)?.userId.carePod;

            if (!carePodId) {
              toast.warn("No care pod id found, cannot edit appointment");
              return;
            }

            navigation.navigate("CreateScheduleItem", {
              scheduleItemId,
            });
          }}
        />
      )}

      {Boolean(!scheduleItemId && isInFuture) && (
        <DropdownButton
          options={[
            // If next week is every other week, don't show weekly option.
            ...(slot.hasEveryOtherWeekNextWeek
              ? []
              : [
                  {label: "Weekly", value: "weekly"},
                  {label: "Tentative Weekly", value: "tentativeWeekly"},
                ]),

            {label: "Every Other Week", value: "everyOtherWeek"},
            {label: "Tentative Every Other Week", value: "tentativeEveryOtherWeek"},
          ]}
          selectedValue=""
          size="sm"
          text="Schedule"
          onSelect={(val) => {
            const tentative = ["tentativeWeekly", "tentativeEveryOtherWeek"].includes(val);
            const everyOtherWeek = ["everyOtherWeek", "tentativeEveryOtherWeek"].includes(val);
            addAppointment(tentative, everyOtherWeek);
          }}
        />
      )}
    </Box>
  );
};
