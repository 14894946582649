import {
  useCurrentWorkflowMapping,
  useGetConversationForWorkflowMapping,
  useReadProfile,
} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  isOutOfOffice,
  useGetAlertInstancesQuery,
  useGetUsersByIdQuery,
  useGetUserSessionsQuery,
  useSelectWorkflowStaffId,
  useUpdateLastReadMutation,
} from "@store";
import {IsIos, IsMobileDevice, KEYBOARD_VERTICAL_OFFSET} from "@utils";
import {Box, useToast} from "ferns-ui";
import React, {ReactElement} from "react";
import {KeyboardAvoidingView} from "react-native";

import {AlertBanner} from "../alerts";
import {CenterWorkflowViewHeader} from "../CenterWorkflowViewHeader";
import {StaffOfflineBanner} from "../StaffOfflineBanner";
import {ChatBanner} from "./ChatBanner";
import {ChatBox} from "./ChatBox";
import {NoConversation} from "./ChatNoConversation";
import {CriticalEventHelpModal} from "./CriticalEventHelpModal";

interface ChatViewProps {
  disableComposer?: boolean;
  setShowHelpModal: (show: boolean) => void;
  showHelpModal: boolean;
}

export const WorkflowChatView = ({
  disableComposer,
  setShowHelpModal,
  showHelpModal,
}: ChatViewProps): ReactElement | null => {
  const currentWorkflowMapping = useCurrentWorkflowMapping();
  // Member, family member, or staff user who the workflow mapping is about
  const userId = currentWorkflowMapping?.userId?._id;
  const {data: userSessionsData} = useGetUserSessionsQuery(userId ? {ownerId: userId} : skipToken);
  const staffId = useSelectWorkflowStaffId();
  const {conversation, isLoading: isLoadingConversation} = useGetConversationForWorkflowMapping(
    staffId ? currentWorkflowMapping : undefined
  );
  const toast = useToast();

  const profile = useReadProfile();
  const {data: displayedUser, isLoading: isLoadingUser} = useGetUsersByIdQuery(userId ?? skipToken);
  const {data: unresolvedAlerts} = useGetAlertInstancesQuery(
    displayedUser?._id
      ? {
          associatedUserId: displayedUser?._id,
          page: 1,
        }
      : skipToken
  );
  const isLoading =
    isLoadingConversation ||
    !currentWorkflowMapping ||
    !userId ||
    !displayedUser ||
    !userSessionsData ||
    isLoadingUser;

  const [updateLastRead] = useUpdateLastReadMutation();

  if (!profile || !displayedUser) {
    return null;
  }

  const isMyWorkflow = Boolean(staffId && staffId === profile._id);

  const markRead = async (): Promise<void> => {
    if (!conversation) {
      console.error("No conversation to mark read");
      return Promise.resolve();
    }
    await updateLastRead({
      conversationId: conversation._id,
      lastReadDateTime: new Date(),
    })
      .unwrap()
      .catch(toast.catch);
  };

  return (
    <KeyboardAvoidingView
      behavior={IsIos ? "padding" : "height"}
      keyboardVerticalOffset={KEYBOARD_VERTICAL_OFFSET}
      style={{flex: 1}}
    >
      {Boolean(userId) && Boolean(IsMobileDevice) && (
        <CriticalEventHelpModal
          associatedUserId={userId!}
          setHelpModal={setShowHelpModal}
          showHelpModal={showHelpModal}
        />
      )}
      <Box direction="row" flex="grow" height="100%" justifyContent="center" width="100%">
        <Box direction="row" height="100%" justifyContent="center" width="100%">
          <Box color="base" justifyContent="start" rounding="md" width="100%">
            <CenterWorkflowViewHeader />
            <ChatBanner />
            <StaffOfflineBanner />
            <AlertBanner
              alertInstance={unresolvedAlerts?.data ? unresolvedAlerts?.data[0] : undefined}
            />
            <NoConversation />
            {conversation && !isLoading && (
              <ChatBox
                key={conversation?._id}
                allowDelete
                // hide send button if you are not in your own workflow
                alwaysShowSend={isMyWorkflow}
                conversationId={conversation._id}
                // disable composer if you are not in your own workflow
                disableComposer={!isMyWorkflow || isOutOfOffice(profile) || disableComposer}
                includeSMSIntroText={Boolean(conversation?.needsSMSIntroText)}
                showSendAsSms={conversation.smsCapable}
                onSend={markRead}
              />
            )}
          </Box>
        </Box>
      </Box>
    </KeyboardAvoidingView>
  );
};
