import {Badge, Box, Button, Modal, Text, TextArea} from "ferns-ui";
import React, {useEffect, useState} from "react";

type FormConflictModalProps = {
  outsideChanges: string;
  currentValue: string;
  fieldTitle: string;
  readOnly: boolean;
  disabled: boolean;
  conflictError?: string;
  conflictResolved?: boolean;
  onBlur: (v: string) => void;
  onFocus: () => void;
  primaryButtonOnClick: (resolvedValue: string) => void;
};

export const FormConflictModal: React.FC<FormConflictModalProps> = ({
  fieldTitle,
  outsideChanges,
  currentValue,
  readOnly,
  disabled,
  conflictError,
  conflictResolved,
  onBlur,
  onFocus,
  primaryButtonOnClick,
}: FormConflictModalProps) => {
  const [resolvedValue, setResolvedValue] = useState(currentValue);
  const [showModal, setShowModal] = useState(false);

  // for form presence, trigger onFocus when the modal is visible,
  // and onBlur when it changes from visible to not visible
  useEffect(() => {
    if (showModal) {
      onFocus();
      return (): void => {
        void onBlur(resolvedValue);
      };
    }
    // Only run when modal visibility changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  if (readOnly || disabled) {
    return null;
  }

  return (
    <>
      {Boolean(conflictError) && (
        <Box>
          <Box paddingY={3}>
            <Text color="error">{conflictError}</Text>
          </Box>
          <Box maxWidth={200}>
            <Button
              disabled={disabled}
              text="Compare Changes"
              variant="secondary"
              onClick={() => setShowModal(true)}
            />
          </Box>
        </Box>
      )}

      {Boolean(conflictResolved) && Boolean(!conflictError) && (
        <Box
          accessibilityHint="Click to surface the conflict resolution modal"
          accessibilityLabel="Edit conflict resolution"
          marginTop={2}
          onClick={() => setShowModal(true)}
        >
          <Badge
            iconName="check"
            secondary
            status="success"
            value={`You've resolved the differences, thanks! (Click to edit resolution)`}
          />
          <Text />
        </Box>
      )}

      <Modal
        primaryButtonOnClick={async () => {
          await primaryButtonOnClick(resolvedValue);
          setShowModal(false);
        }}
        primaryButtonText="Resolve Changes"
        secondaryButtonOnClick={() => {
          setShowModal(false);
        }}
        secondaryButtonText="Cancel"
        size="lg"
        title="Please update your changes to include outside updates since you started editing, if necessary."
        visible={showModal}
        onDismiss={() => {
          setShowModal(false);
        }}
      >
        <Box maxHeight={400} overflow="auto" width="100%">
          <Box direction="row" justifyContent="between" padding={2}>
            <Box color="neutralLight" padding={4} rounding="md" width="48%">
              <Text bold size="lg">
                Outside updates since you started editing
              </Text>
              <Text bold>{fieldTitle}</Text>
              <Box color="base" padding={2} rounding="md">
                <Text>{outsideChanges}</Text>
              </Box>
            </Box>

            <Box color="secondaryLight" padding={4} rounding="md" width="48%">
              <Text bold size="lg">
                Your changes (will update the patient when note is completed)
              </Text>
              <Text bold>{fieldTitle}</Text>
              <Box rounding="md">
                <TextArea
                  blurOnSubmit={false}
                  grow
                  rows={5}
                  value={resolvedValue}
                  onChange={setResolvedValue}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
