import {FormQuestion} from "@store";
import {Box, Text} from "ferns-ui";
import React, {ReactElement, useMemo} from "react";

import {FormQuestionPresenceText} from "./FormQuestionPresenceText";

interface QuestionSupportProps {
  formInstanceId: string;
  question: FormQuestion;
  isStaff: boolean;
  isSupervisor: boolean;
}
/**
 * Displays support information for a question in a form instance.
 *  This includes :
 *  question's description,
 *  any fields that will be update user info upon completion,
 *  visibility information based on the user's role (staff/supervisor)
 *  and the question's presence status - when another user is editing it
 
 */
export const QuestionSupport = ({
  question,
  isStaff,
  isSupervisor,
}: QuestionSupportProps): ReactElement | null => {
  const populateFieldsText = useMemo(() => {
    return question.populateFields?.map((field) => `${field.schema} ${field.key}`).join(", ");
  }, [question.populateFields]);

  return (
    <Box width="100%">
      <FormQuestionPresenceText questionId={question._id} />
      {Boolean(question.description) && (
        <Box marginTop={2}>
          <Text>{question.description}</Text>
        </Box>
      )}

      {Boolean(isStaff && populateFieldsText) && (
        <Box marginTop={2}>
          <Text size="sm">Updates {populateFieldsText} upon completion</Text>
        </Box>
      )}
      {Boolean(isStaff && ["CarePlan", "SafetyPlan"].includes(question.type)) && (
        <Box marginTop={2}>
          <Text size="sm">**Visible in Patient App**</Text>
        </Box>
      )}
      {Boolean(!isSupervisor && question.isSupervisorOnly) && (
        <Box marginTop={2}>
          <Text size="sm">This question is to be completed by your supervisor.</Text>
        </Box>
      )}
    </Box>
  );
};
