import {Box, DataTableCellData, Text} from "ferns-ui";
import React from "react";

export const TextCell: React.FC<{cellData: DataTableCellData}> = ({
  cellData,
}): React.ReactElement => {
  const {value, highlight} = cellData ?? {};
  return (
    <Box color={highlight} flex="grow" justifyContent="center">
      <Text size={cellData.textSize}>{value}</Text>
    </Box>
  );
};
