import {ToolButton} from "@components";
import {useReadProfile} from "@hooks";
import {StaffTabScreenProps} from "@types";
import {IsMobileDevice, IsWeb, pageOnError} from "@utils";
import {Box, Heading, Page, Spinner} from "ferns-ui";
import React, {ReactElement} from "react";

import {SuperUserTools} from "../components/superUserTools/SuperUserTools";
import {isSuperUserOrSupervisor} from "../store";

interface StaffToolsProps extends StaffTabScreenProps<"Tools"> {}

export const StaffToolsPage = ({navigation}: StaffToolsProps): ReactElement => {
  const profile = useReadProfile();

  if (!profile) {
    return <Spinner />;
  }
  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box paddingY={2}>
        <Heading>Users</Heading>
      </Box>
      <Box direction="row" gap={2} paddingY={2} width="100%" wrap>
        <ToolButton iconName="plus" screen="CreateUser" text="Create New User" />
        <ToolButton iconName="plus" screen="CreateStaff" text="Create Staff" />
        <ToolButton iconName="pencil" screen="ResetPassword" text="Reset Password" />
      </Box>
      <Box paddingY={2}>
        <Heading>Other Tools</Heading>
      </Box>
      <Box direction="row" gap={2} width="100%" wrap>
        {Boolean(!IsMobileDevice) && (
          <ToolButton iconName="user" screen="UserExplorer" text="User Explorer" />
        )}
        <ToolButton iconName="user" screen="ToDoListExplorer" text="To Do List" />
        <ToolButton iconName="comments" screen="MessageExplorer" text="Message Explorer" />
        {!IsMobileDevice && (
          <ToolButton
            iconName="triangle-exclamation"
            screen="AlertsExplorer"
            text="Alerts Explorer"
          />
        )}
        <ToolButton iconName="list" screen="PreClinicUpdates" text="Pre-Clinic Updates" />
        {Boolean(isSuperUserOrSupervisor(profile)) && Boolean(IsWeb) && (
          <ToolButton iconName="people-carry-box" screen="Team" text="My Team" />
        )}
        <ToolButton iconName="phone" screen="DialerScreen" text="Phone Call" />

        {Boolean(IsWeb) && <ToolButton iconName="chart-bar" screen="Stats" text="Stats" />}
        <ToolButton iconName="microchip" screen="GPT" text="GPT" />
        <ToolButton
          featureFlag="carePodQA"
          iconName="id-badge"
          screen="CarePodQA"
          text="Care Pod QA"
          variant="secondary"
        />
      </Box>

      {Boolean(profile.staffRoles.SuperUser) && <SuperUserTools />}
    </Page>
  );
};
