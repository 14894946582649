// A component for displaying a list of users, adding them with the UserPicker. This should simplify
// some instances where we need to handle user pagination better.

import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query";
import {StaffRoles, useGetUsersQuery, User, userName} from "@store";
import {StaffStackParamList} from "@types";
import {Box, Button, Text} from "ferns-ui";
import React from "react";

import {FilterItem} from "./FilterItems";

export interface UserRowProps {
  user: User;
  users: User[];
  onChangeUsers: (users: User[]) => void;
  showTypes?: boolean;
  extraProps?: any;
}

// A default UserRow component that can be used in UserList.
// This can be overridden by passing in UserRowComponent to UserList.
const UserRow = ({user, users, onChangeUsers, showTypes}: UserRowProps): React.ReactElement => {
  const text = `${userName(user)} ${showTypes ? `(${user.type})` : ""} ${user?.testUser ? "- Test User" : ""}`;
  return (
    <FilterItem
      text={text}
      onDismiss={() => onChangeUsers(users.filter((u) => u._id !== user._id))}
    />
  );
};

interface Props {
  userIds: string[];
  onChangeUsers: (users: User[]) => void;
  showTypes?: boolean;
  userPickerTitle?: string;
  staff?: boolean;
  patient?: boolean;
  familyMember?: boolean;
  buttonText?: string;
  UserRowComponent?: React.ComponentType<UserRowProps>;
  extraProps?: any;
  limit?: number;
  title?: string;
  description?: string;
  staffRole?: keyof StaffRoles;
  userFilter?: (user: User) => boolean;
  // Limit the users that can be picked to only those in the given care pod
  pickerCarePodId?: string;
}

export const UserList = ({
  userIds,
  onChangeUsers,
  showTypes = false,
  staffRole,
  userPickerTitle,
  staff = false,
  patient = false,
  familyMember = false,
  buttonText,
  UserRowComponent = UserRow,
  title,
  description,
  limit,
  userFilter,
  extraProps,
  pickerCarePodId,
}: Props): React.ReactElement => {
  if (!staff && !patient && !familyMember) {
    throw new Error("UserList must be passed at least one of staff, patient, or familyMember");
  }
  const {data: userData} = useGetUsersQuery(userIds?.length ? {_id: {$in: userIds}} : skipToken);
  const users = userIds?.length > 0 ? (userData?.data ?? []) : [];
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const atLimit = limit !== undefined && users?.length >= limit;
  const btnText = buttonText ?? (limit === 1 ? "Select User" : "Add User");

  return (
    <Box>
      {Boolean(title) && (
        <Box paddingY={1}>
          <Text bold>{title}</Text>
        </Box>
      )}
      {Boolean(description) && (
        <Box paddingY={1}>
          <Text>{description}</Text>
        </Box>
      )}
      <Box gap={4}>
        {users.map((user) => (
          <UserRowComponent
            key={user?._id}
            extraProps={extraProps}
            showTypes={showTypes}
            user={user}
            users={users}
            onChangeUsers={onChangeUsers}
          />
        ))}
      </Box>
      {!Boolean(atLimit) && (
        <Box marginTop={4} maxWidth={225}>
          <Button
            iconName="plus"
            text={btnText}
            onClick={(): void => {
              navigation.navigate("UserPicker", {
                title: userPickerTitle ?? (limit === 1 ? "Select User" : "Add User"),
                patient,
                familyMember,
                staff,
                carePodId: pickerCarePodId,
                userFilter: (u: User): boolean => {
                  if (userFilter) {
                    return userFilter(u);
                  }
                  if (staffRole && !(u.staffRoles as any)[staffRole]) {
                    return false;
                  }
                  return !users.some((user) => user._id === u._id);
                },
                onSelect: (user: User | null): void => {
                  if (!user) {
                    return;
                  }
                  onChangeUsers([...users, user]);
                },
              });
            }}
          />
        </Box>
      )}
    </Box>
  );
};
