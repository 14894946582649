import {
  POPULATE_FIELD_SHOULD_PREFILL_FORM_TYPES,
  SHOULD_VALIDATE_CONFLICT_STATUSES,
} from "@constants";
import {useFormInstancePatch, useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  Form,
  getFormInternalKeysAssocWithApptMap,
  useFormInstanceIsReadOnly,
  useGetFormInstancesQuery,
  useGetUsersByIdQuery,
  useLocalFormInstance,
} from "@store";
import {Box, Button, humanDateAndTime, Text} from "ferns-ui";
import React, {FC, useEffect, useMemo} from "react";

import {FormQuestionComponent} from "../formInstanceQuestions";

interface FormQuestionProps {
  formInstanceId: string;
}

export const FormQuestions: FC<FormQuestionProps> = ({formInstanceId}) => {
  const profile = useReadProfile();
  const formInstance = useLocalFormInstance(formInstanceId);
  const readOnly = useFormInstanceIsReadOnly(formInstanceId);
  const {updateFormInstance} = useFormInstancePatch(formInstanceId, readOnly);
  const formInternalKeysMap = getFormInternalKeysAssocWithApptMap();
  const {data: finishedInstances} = useGetFormInstancesQuery(
    formInstance
      ? {
          userId: formInstance.userId?._id, // Exact match for userId
          formId: formInstance.formId, // Exact match for formId
          attendanceStatus: "Attended",
          $and: [
            {
              $or: [{status: "Signed"}, {status: "Completed"}],
            },
          ],
        }
      : skipToken
  );

  const form = formInstance?.form as Form | undefined;
  const answers = formInstance?.answers;
  const {data: formUser} = useGetUsersByIdQuery(formInstance?.userId?._id ?? skipToken);

  // Set initial snapshots for all questions that need them upon mount
  useEffect(() => {
    const setInitialSnapshots = async (): Promise<void> => {
      if (
        !formInstance ||
        !form?.questions ||
        !formUser ||
        // Don't set snapshots if we won't be validating conflicts
        !SHOULD_VALIDATE_CONFLICT_STATUSES.includes(formInstance.status)
      ) {
        return;
      }

      const updatedAnswers = [...(formInstance.answers || [])];

      let hasChanges = false;

      form.questions.forEach((question, index) => {
        if (!POPULATE_FIELD_SHOULD_PREFILL_FORM_TYPES.includes(question.type)) {
          return;
        }

        const existingAnswer = updatedAnswers.find((a) => a.questionId === question._id);

        if (!existingAnswer || existingAnswer.populatedFieldSnapshot) {
          return;
        }

        const fieldToPopulate = question.populateFields?.[0];
        if (!fieldToPopulate) {
          return;
        }

        const currentValue = formUser[fieldToPopulate.key as keyof typeof formUser] ?? "";

        hasChanges = true;
        updatedAnswers[index] = {
          ...existingAnswer,
          // if the answer is empty, use the current value of the user
          answers: [existingAnswer.answers?.[0] ?? currentValue],
          populatedFieldSnapshot: {
            value: [currentValue],
            conflictResolved: false,
          },
        };
      });

      if (hasChanges) {
        await updateFormInstance(
          {
            answers: updatedAnswers,
          },
          `Failed to set initial snapshots for form instance ${formInstance?._id}.`
        );
      }
    };

    void setInitialSnapshots();
    // only run this once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formQuestionsWithoutHeadings = form?.questions?.filter((q) => q.type !== "Heading");

  // check in order to copy answers from the most recent form instance of the same type
  const userHasCompletedFiOfSameType = useMemo(() => {
    if (
      finishedInstances?.data?.length === 1 &&
      finishedInstances?.data[0]?._id === formInstanceId
    ) {
      return false;
    }

    if (
      finishedInstances?.data?.length &&
      finishedInstances.data.some((fi) => {
        const fiForm = fi?.form as Form | undefined;
        return fiForm?.internalKey === form?.internalKey;
      })
    ) {
      return true;
    }

    return false;
  }, [finishedInstances?.data, formInstanceId, form?.internalKey]);

  const formIsAssociatedWithAppt = useMemo(() => {
    if (!formInternalKeysMap || !form?.internalKey) {
      return false;
    }
    return formInternalKeysMap.has(form.internalKey);
  }, [formInternalKeysMap, form?.internalKey]);

  if (!formInstance || !form || !answers || !profile) {
    return null;
  }

  return (
    <Box paddingY={2}>
      {formIsAssociatedWithAppt && userHasCompletedFiOfSameType && !readOnly && (
        <Box marginTop={2} maxWidth={400}>
          <Button
            confirmationText={`Copying answers from the most recent "${form.name}". \n\nIt will OVERWRITE any existing answers in this ${form.type} and CANNOT be reversed. \n\nAre you sure you want to proceed?`}
            iconName="copy"
            text={`Autofill Answers from Last Completed ${form.type}`}
            variant="secondary"
            withConfirmation
            onClick={async (): Promise<void> => {
              await updateFormInstance(
                {
                  copyMostRecentAnswers: true,
                } as any,
                `Error copying answers from last signed ${form.name}.`
              );
            }}
          />
        </Box>
      )}
      {form.questions?.map((q) => {
        if (!q._id) {
          console.error("Form question has no ID");
          return null;
        }
        return (
          <FormQuestionComponent
            key={q._id}
            formInstanceId={formInstance._id}
            index={formQuestionsWithoutHeadings?.findIndex((f) => f._id === q._id) ?? 0}
            questionId={q._id}
          />
        );
      })}
      <Box marginBottom={4}>
        <Text>Last saved: {humanDateAndTime(formInstance.updated)}</Text>
      </Box>
    </Box>
  );
};
