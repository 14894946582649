import {IsWeb} from "@utils";
import {useTheme} from "ferns-ui";
import React, {useEffect, useImperativeHandle, useRef} from "react";
import {
  NativeSyntheticEvent,
  Platform,
  TextInput,
  TextInputContentSizeChangeEventData,
} from "react-native";
import {useCallbackOne} from "use-memo-one";

import {MentionInput} from "../controlledMentions";
import Color from "./Color";
import {DEFAULT_PLACEHOLDER, MAX_COMPOSER_HEIGHT, MIN_COMPOSER_HEIGHT, TEST_ID} from "./Constant";
import {ComposerProps, IMessage} from "./Models";

export interface ComposerHandles {
  focusInput: () => void;
}

export const Composer = React.forwardRef<ComposerHandles, ComposerProps<any>>(
  <TMessage extends IMessage>(
    props: ComposerProps<TMessage>,
    ref: React.Ref<ComposerHandles>
  ): React.ReactElement | null => {
    const {
      allowMentions,
      isRoomTag,
      taggableUsers = [],
      taggedUsers = [],
      disableComposer = false,
      keyboardAppearance = "default",
      multiline = true,
      onInputSizeChanged = (): void => {},
      onSend,
      onTextChanged = (): void => {},
      onSelectionChange = (): void => {},
      placeholder = DEFAULT_PLACEHOLDER,
      placeholderColor: placeholderTextColor = Color.defaultColor,
      selection,
      onFocusInput,
      text = "",
      textInputAutoFocus = false,
      textInputProps = {},
      textInputStyle,
    } = props;
    const textInputRef = useRef<TextInput>(null);
    const dimensionsRef = useRef<{width: number; height: number}>();
    const prevTextRef = useRef<string>(text);
    const {theme} = useTheme();

    // Reset height only when text changes from non-empty to empty
    useEffect(() => {
      const wasNonEmpty = prevTextRef.current.length > 0;
      const isNowEmpty = text.length === 0;

      if (wasNonEmpty && isNowEmpty) {
        const width = (dimensionsRef.current?.width ?? MIN_COMPOSER_HEIGHT) as number;
        dimensionsRef.current = {
          width,
          height: MIN_COMPOSER_HEIGHT,
        };
        onInputSizeChanged({
          width,
          height: MIN_COMPOSER_HEIGHT,
        });
      }

      prevTextRef.current = text;
    }, [text, onInputSizeChanged]);

    const determineInputSizeChange = useCallbackOne(
      (dimensions: {width: number; height: number}) => {
        // Support earlier versions of React Native on Android.
        if (!dimensions) {
          return;
        }

        // Ensure height doesn't exceed max
        const height = Math.min(dimensions.height, MAX_COMPOSER_HEIGHT);

        if (
          !dimensionsRef?.current ||
          (dimensionsRef.current &&
            (dimensionsRef.current.width !== dimensions.width ||
              dimensionsRef.current.height !== height))
        ) {
          dimensionsRef.current = {width: dimensions.width, height};
          onInputSizeChanged({width: dimensions.width, height});
        }
      },
      [onInputSizeChanged]
    );

    const handleContentSizeChange = ({
      nativeEvent: {contentSize},
    }: NativeSyntheticEvent<TextInputContentSizeChangeEventData>): void =>
      determineInputSizeChange(contentSize);

    const handleKeyPress = (event: any): void => {
      if (textInputProps.onKeyPress) {
        return textInputProps.onKeyPress(event);
      }
      if (IsWeb && event?.code === "Enter") {
        // Send message on enter, unless shift is pressed for a new line
        if (!event?.shiftKey && text && onSend) {
          void onSend([{text: text.trim()} as TMessage], {});
        }
      }
    };

    const doFocusInput = (): void => {
      if (textInputRef.current) {
        textInputRef.current.focus();
      }
    };

    useImperativeHandle(ref, () => ({
      focusInput: doFocusInput,
    }));

    return allowMentions ? (
      <MentionInput
        containerStyle={{
          flex: 1,
          marginTop: 6,
          marginRight: 4,
          marginBottom: 10,
          marginLeft: 0,
          maxHeight: MAX_COMPOSER_HEIGHT,
          ...Platform.select({
            web: {
              outlineWidth: 0,
              outlineColor: "transparent",
              outlineOffset: 0,
            },
          }),
        }}
        editable={!disableComposer}
        enablesReturnKeyAutomatically
        isRoomTag={isRoomTag}
        keyboardAppearance={keyboardAppearance}
        multiline={multiline}
        partTypes={[
          {
            trigger: "@", // Should be a single character like '@' or '#'
            textStyle: {fontWeight: "bold", color: theme.surface.primary}, // The mention style in the input
            isInsertSpaceAfterMention: true,
          },
        ]}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        style={[
          {
            flex: 1,
            marginLeft: 12,
            fontSize: 16,
            lineHeight: 16,
            minHeight: MIN_COMPOSER_HEIGHT,
            maxHeight: MAX_COMPOSER_HEIGHT,
            ...Platform.select({
              web: {
                paddingTop: 6,
                paddingLeft: 4,
              },
            }),
          },
          textInputStyle,
          {
            margin: 0,
            height: "100%",
            ...Platform.select({
              web: {
                outlineWidth: 0,
                outlineColor: "transparent",
                outlineOffset: 0,
              },
            }),
          },
        ]}
        taggableUsers={taggableUsers}
        taggedUsers={taggedUsers}
        testID={TEST_ID.MENTION_INPUT}
        underlineColorAndroid="transparent"
        value={text}
        onChange={onTextChanged}
        onContentSizeChange={handleContentSizeChange}
        onKeyPress={handleKeyPress}
      />
    ) : (
      <TextInput
        accessibilityLabel={placeholder}
        accessible
        autoFocus={textInputAutoFocus}
        editable={!disableComposer}
        enablesReturnKeyAutomatically
        keyboardAppearance={keyboardAppearance}
        multiline={multiline}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        selection={selection}
        style={[
          textInputStyle,
          {
            flex: 1,
            marginLeft: 12,
            fontSize: 16,
            lineHeight: 16,
            minHeight: MIN_COMPOSER_HEIGHT,
            maxHeight: MAX_COMPOSER_HEIGHT,
            height: dimensionsRef.current?.height ?? MIN_COMPOSER_HEIGHT,
            ...Platform.select({
              web: {
                paddingTop: 14,
                paddingBottom: 20,
                paddingLeft: 4,
              },
              ios: {
                paddingTop: 14,
              },
            }),
            ...Platform.select({
              web: {
                outlineWidth: 0,
                outlineColor: "transparent",
                outlineOffset: 0,
              },
            }),
          },
        ]}
        testID={TEST_ID.COMPOSER}
        underlineColorAndroid="transparent"
        value={text}
        onChangeText={onTextChanged}
        onContentSizeChange={handleContentSizeChange}
        onSelectionChange={onSelectionChange}
        {...textInputProps}
        ref={textInputRef}
        onFocus={onFocusInput}
      />
    );
  }
);

Composer.displayName = "Composer";
