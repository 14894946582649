import {Box, Heading, IconButton, Text, useToast} from "ferns-ui";
import React, {useCallback, useEffect, useState} from "react";

import {useReadProfile} from "../hooks";
import {
  formatTimeRange,
  isSupervisor,
  useGetUsersByIdQuery,
  usePatchUsersByIdMutation,
} from "../store";
import {isSuperUser} from "../utils";
import {type Day} from "./DayDropdown";
import {DayTimeRange} from "./DayTimeRange";

const DAYS_OF_WEEK: Day[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

const DEFAULT_HOURS = {
  startHour: 9,
  startMin: 0,
  endHour: 17,
  endMin: 0,
};

interface WorkingHoursDayProps {
  day: Day;
  daySchedule?: DayTimeRange;
  canEdit: boolean;
  handleTimeChange: (day: Day, newRange: DayTimeRange) => void;
  handleDelete: (day: Day) => void;
}

const WorkingHoursDay = React.memo(
  ({
    day,
    daySchedule,
    canEdit,
    handleTimeChange,
    handleDelete,
  }: WorkingHoursDayProps): React.ReactElement => {
    if (!daySchedule) {
      return (
        <Box key={day} marginBottom={4}>
          <Box alignItems="center" direction="row" height={70}>
            <Box marginRight={2} width={120}>
              <Text bold>{day}</Text>
            </Box>
            {canEdit && (
              <Box>
                <IconButton
                  accessibilityLabel={`add ${day} schedule`}
                  iconName="plus"
                  onClick={(): void => {
                    void handleTimeChange(day, {
                      day,
                      ...DEFAULT_HOURS,
                    });
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      );
    }

    return (
      <Box key={day} marginBottom={4}>
        {canEdit ? (
          <DayTimeRange
            dayTimeRange={daySchedule}
            disablePickDay
            showDelete
            onChange={(newRange): void => {
              void handleTimeChange(day, newRange);
            }}
            onDelete={(): void => {
              void handleDelete(day);
            }}
          />
        ) : (
          <Box alignItems="center" direction="row" height={70}>
            <Box marginRight={2} width={120}>
              <Text bold>{daySchedule.day}</Text>
            </Box>
            <Box flex="grow">
              <Text>
                {formatTimeRange(
                  daySchedule.startHour,
                  daySchedule.startMin,
                  daySchedule.endHour,
                  daySchedule.endMin
                )}{" "}
                ET
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
);

WorkingHoursDay.displayName = "WorkingHoursDay";

interface WorkingHoursScheduleProps {
  currentUserId: string;
}

export const WorkingHoursSchedule = ({
  currentUserId,
}: WorkingHoursScheduleProps): React.ReactElement | null => {
  const toast = useToast();
  const {data: user, isLoading} = useGetUsersByIdQuery(currentUserId);
  const [updateUser] = usePatchUsersByIdMutation();
  const [schedule, setSchedule] = useState(user?.workingHoursSchedule ?? []);

  const profile = useReadProfile();

  // Update schedule when user's working hours schedule changes
  useEffect(() => {
    setSchedule(user?.workingHoursSchedule ?? []);
  }, [user?.workingHoursSchedule]);

  const handleTimeChange = useCallback(
    async (day: Day, newRange: DayTimeRange): Promise<void> => {
      // Replace or add the day's schedule
      const currentSchedule = [...schedule.filter((s) => s.day !== day), {...newRange}];

      setSchedule(currentSchedule);

      try {
        await updateUser({
          id: currentUserId,
          body: {
            workingHoursSchedule: currentSchedule,
          },
        }).unwrap();
        toast.success("Working hours updated successfully");
      } catch (error) {
        toast.catch(error, "Error updating working hours");
      }
    },
    [schedule, updateUser, currentUserId, toast]
  );

  const handleDelete = useCallback(
    async (dayToDelete: Day): Promise<void> => {
      const currentSchedule = schedule.filter((s) => s.day !== dayToDelete);
      setSchedule(currentSchedule);

      try {
        await updateUser({
          id: currentUserId,
          body: {
            workingHoursSchedule: currentSchedule,
          },
        }).unwrap();
        toast.success("Schedule removed successfully");
      } catch (error) {
        toast.catch(error, "Error removing schedule");
      }
    },
    [schedule, updateUser, currentUserId, toast]
  );

  // only show working hours schedule for supervisors
  if (isLoading || !user || !isSupervisor(user)) {
    return null;
  }

  // only superusers and users themselves can edit working hours schedule
  const canEdit = isSuperUser(profile) || currentUserId === profile?._id;

  return (
    <Box width="100%">
      <Box paddingY={4}>
        <Heading size="sm">Working Hours</Heading>
        <Text>Times are in eastern time</Text>
      </Box>
      <Box>
        {DAYS_OF_WEEK.map((day) => {
          const daySchedule = schedule.find((s) => s.day === day);
          return (
            <WorkingHoursDay
              key={day}
              canEdit={canEdit}
              day={day}
              daySchedule={daySchedule}
              handleDelete={handleDelete}
              handleTimeChange={handleTimeChange}
            />
          );
        })}
      </Box>
    </Box>
  );
};
