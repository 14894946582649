import {Banner, Box} from "ferns-ui";
import React, {FC} from "react";

import {useChatContext} from "../giftedChat/GiftedChatContext";

interface IntroBannerProps {
  includeSMSIntroText: boolean;
}

export const IntroTextBanner: FC<IntroBannerProps> = ({includeSMSIntroText}) => {
  const {sendAsSms} = useChatContext();
  if (!includeSMSIntroText || !sendAsSms) {
    return null;
  }

  return (
    <Box direction="column" display="flex" marginTop={1} width="100%">
      <Banner
        dismissible={false}
        id="smsIntroWarning"
        status="info"
        text={
          `This is your first SMS to this person.` +
          `\nIntroduce yourself as -` +
          `\n"{Your_Name} from Flourish Health"` +
          `\nso they know who you are.`
        }
      />
    </Box>
  );
};
