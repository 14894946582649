import "expo-dev-client";
import "setimmediate";
import "react-native-get-random-values";

import * as Sentry from "@sentry/react-native";
import {decode} from "base-64";
global.atob = decode;

import {BIOMETRICS_MAX_RETRIES, BIOMETRICS_TIMEOUT_SECONDS, TIMEZONES} from "@constants";
import {baseUrl, getAuthToken, useSelectCurrentUserId} from "@ferns-rtk";
import {SerializedError} from "@reduxjs/toolkit";
import {skipToken} from "@reduxjs/toolkit/query";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query/react";
import {
  captureException,
  IsEmulator,
  isPatientOrFamilyMember,
  isStaff,
  IsWeb,
  sentryInit,
  sentrySetUser,
  setupUnhandledRejectionHandler,
} from "@utils";
import * as Device from "expo-device";
import * as LocalAuthentication from "expo-local-authentication";
import {getCalendars} from "expo-localization";
import * as Notifications from "expo-notifications";
import * as SplashScreen from "expo-splash-screen";
import {Box, Button, FernsProvider} from "ferns-ui";
import isEqual from "lodash/isEqual";
import React, {memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ActivityIndicator, AppState, StatusBar} from "react-native";
import {enableExperimentalWebImplementation} from "react-native-gesture-handler";
import {SafeAreaProvider} from "react-native-safe-area-context";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";

// Needed to make draggables work correctly on the web.
enableExperimentalWebImplementation(true);
import {SocketProvider} from "@components";
import {useLogoutUser} from "@hooks";
import {AuthNavigation, ConsentNavigation, PatientNavigation, StaffNavigation} from "@navigation";
import store, {
  persistor,
  setLastBiometricsSuccess,
  setShowConsent,
  shouldShowAnyConsent,
  useAppDispatch,
  useGetUsersByIdQuery,
  usePatchUsersByIdMutation,
  User,
  useSelectLastBiometricsSuccess,
  useSelectShowConsent,
  useSetupNotifications,
  useSetupWebNotifications,
  versionInfo,
} from "@store";
import axios from "axios";
import {DateTime} from "luxon";

import {setupHeap} from "./utils/heapSnippet";

const consoleWarn = console.warn;
const SUPPRESSED_WARNINGS_ERRORS = [
  "Animated: `useNativeDriver` is not supported",
  "props.pointerEvents is deprecated.",
  "selectable prop is deprecated. Use styles.userSelect.",
  "TouchableWithoutFeedback is deprecated. Please use Pressable.",
  "TouchableOpacity is deprecated. Please use Pressable.",
  "style props are deprecated.", // shadow*
  "style is deprecated. Use", // verticalAlign, textAlignVertical
  // TODO: Remove once the types for react-native are updated for these properties.
  "keyboardType is deprecated. Use inputMode.",
  "TextInput numberOfLines is deprecated. Use rows.",
  "returnKeyType is deprecated. Use enterKeyHint.",
  "editable is deprecated. Use readOnly.",
  "focusable is deprecated.",
  "accessibilityDisabled is deprecated.",
  "accessibilityLabel is deprecated. Use aria-label.",
  // TODO: Remove once we switch to expo-router
  "accessibilityRole is deprecated. Use role.",
  "BackHandler is not supported on web and should not be used.",
];

console.warn = function filterWarnings(msg, ...args): void {
  const now = DateTime.now().toLocaleString(DateTime.TIME_WITH_SECONDS);
  const message = `${now} ${msg}`;

  if (typeof msg !== "string" || !msg?.includes) {
    consoleWarn(message, ...args);
    return;
  }
  if (!SUPPRESSED_WARNINGS_ERRORS.some((entry) => msg?.includes(entry))) {
    consoleWarn(message, ...args);
  }
};

const consoleError = console.error;

console.error = function filterWarnings(msg, ...args): void {
  const now = DateTime.now().toLocaleString(DateTime.TIME_WITH_SECONDS);
  const message = `${now} ${msg}`;

  if (typeof msg !== "string" || !msg?.includes) {
    consoleError(message, ...args);
    return;
  }
  if (!SUPPRESSED_WARNINGS_ERRORS.some((entry) => msg?.includes(entry))) {
    consoleError(message, ...args);
  }
};

console.debug("App version", versionInfo().version);
console.debug("App environment", versionInfo().environment);
sentryInit(versionInfo().environment ?? "unknown");
setupUnhandledRejectionHandler();

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: false, // don't show notification when app is already in foreground
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

interface AppProps {
  launchTime: Date;
  user: User | undefined;
  error: FetchBaseQueryError | {status: string; error: string} | SerializedError | undefined;
  currentUserId: string | undefined;
  logoutUser(): Promise<void>;
}

const App = ({
  launchTime,
  user,
  error: launchError,
  currentUserId,
  logoutUser,
}: AppProps): ReactElement => {
  const [updateUser] = usePatchUsersByIdMutation();
  const GlobalAppState = useRef(AppState.currentState);
  const showConsentScreen = useSelectShowConsent();
  const dispatch = useAppDispatch();
  const lastBiometricsCheck = useSelectLastBiometricsSuccess();
  const updateTimeZone = useCallback(async (): Promise<void> => {
    const currentTimeZone = getCalendars()[0]?.timeZone ?? "America/New_York";
    if (!TIMEZONES.includes(currentTimeZone)) {
      return;
    }
    if (user && currentTimeZone && user.timezone !== currentTimeZone) {
      await updateUser({
        id: user._id,
        body: {timezone: currentTimeZone as any},
      });
    }
  }, [updateUser, user]);

  const doBiometricsCheck = useCallback(
    (retries = 0): void => {
      if (!user || !isStaff(user?.type)) {
        return;
      }
      if (IsWeb || IsEmulator) {
        console.debug("doBiometricsCheck: skipping because web or Android emulator");
        return;
      }
      if (__DEV__) {
        console.debug("doBiometricsCheck: skipping because in dev mode");
        return;
      }

      const timeSinceLastBiometricsCheck = lastBiometricsCheck
        ? DateTime.now().diff(DateTime.fromISO(lastBiometricsCheck)).seconds
        : 0;

      if (lastBiometricsCheck && timeSinceLastBiometricsCheck < BIOMETRICS_TIMEOUT_SECONDS) {
        console.debug(
          `doBiometricsCheck: skipping because lastBiometricsCheck was too recent, ` +
            `${timeSinceLastBiometricsCheck} seconds ago`
        );
        return;
      }

      console.debug("doBiometricsCheck: checking biometrics");
      LocalAuthentication.authenticateAsync({})
        .then(async (result) => {
          console.debug(`doBiometricsCheck result`, result);
          if (result.success) {
            console.debug("doBiometricsCheck: success");
            dispatch(setLastBiometricsSuccess(DateTime.now().toISO()));
          } else {
            if (retries < BIOMETRICS_MAX_RETRIES) {
              console.debug(`doBiometricsCheck: retry ${retries + 1}`);
              doBiometricsCheck(retries + 1);
              return;
            } else {
              console.warn(`doBiometricsCheck: failed:`, result);
              await logoutUser();
            }
          }
        })
        .catch((error: any) => {
          console.error(`Error authenticating with biometrics: ${error}`);
        });
    },
    [dispatch, lastBiometricsCheck, logoutUser, user]
  );

  // Update timezone and if required, show FaceID/fingerprint prompt for staff
  useEffect(() => {
    let initialLoad = true;

    if (initialLoad) {
      void updateTimeZone();
      doBiometricsCheck();
      initialLoad = false;
    }

    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (
        (GlobalAppState.current.match(/inactive|background/) && nextAppState === "active") ||
        initialLoad
      ) {
        void updateTimeZone();
        doBiometricsCheck();
      }
      GlobalAppState.current = nextAppState;
    });

    return (): void => {
      subscription.remove();
    };
  }, [doBiometricsCheck, updateTimeZone]);

  // Update usage data and show consent form if needed
  useEffect(() => {
    if (!currentUserId || !user?.type) {
      sentrySetUser(null);
      return;
    }

    const usageUpdate = async function (): Promise<void> {
      if (currentUserId && user?.type) {
        const token = await getAuthToken();
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        const sentryUser: any = {id: user._id};
        // We don't want to send PII for our patients to Sentry if we can avoid it.
        // But nothing having to look up the _id for staff is nice.
        if (isStaff(user.type)) {
          sentryUser.email = user.email;
          sentryUser.username = user.name;
        }
        sentrySetUser(sentryUser);

        const currentUsageData = {
          ...user.usageData,
          lastAppLaunch: user.usageData?.lastAppLaunch
            ? new Date(user.usageData?.lastAppLaunch).toISOString()
            : undefined,
        };
        const usageData = {
          lastAppLaunch: launchTime.toISOString(),
          currentAppVersion: versionInfo().version,
          phoneModel: Device?.modelName || "Unknown",
          operatingSystem:
            Device?.osName && Device?.osVersion
              ? `${Device?.osName} ${Device?.osVersion}`
              : "Unknown",
        };
        if (!isEqual(currentUsageData, usageData)) {
          await updateUser({
            id: user._id,
            body: {usageData},
          });
        }

        // don't worry about consent forms for staff
        if (!isPatientOrFamilyMember(user.type)) return;

        if (shouldShowAnyConsent(user)) {
          dispatch(setShowConsent(true));
        }
      }
    };
    usageUpdate().catch((error) => {
      console.warn("Error updating usage data", error);
      captureException(error);
    });
  }, [currentUserId, launchTime, showConsentScreen, updateUser, user, dispatch]);

  const Loading = (): ReactElement => {
    return (
      <Box
        alignItems="center"
        direction="column"
        height="100%"
        justifyContent="center"
        width="100%"
      >
        <Box alignItems="center" flex="grow" justifyContent="center" width="100%">
          <ActivityIndicator size="large" />
        </Box>
        <Box alignItems="center" paddingY={5} width="100%">
          <Button
            text="Log Out"
            variant="destructive"
            onClick={async (): Promise<void> => {
              await logoutUser();
            }}
          />
        </Box>
      </Box>
    );
  };

  if (launchError) {
    console.debug("Error loading user", launchError);
  }

  const AppBody = (): ReactElement => {
    if (currentUserId && !user) {
      console.debug("Loading...", currentUserId);
      return <Loading />;
    } else if (!currentUserId) {
      console.debug("Going to auth stack.");

      return (
        <>
          <AuthNavigation colorScheme="light" />
          <StatusBar />
        </>
      );
    } else if (!user) {
      console.debug("Waiting for user...");
      return <Loading />;
    } else if (showConsentScreen) {
      console.debug("Going to consent stack.");
      return (
        <>
          <ConsentNavigation colorScheme="light" />
          <StatusBar />
        </>
      );
    } else if (isStaff(user?.type)) {
      console.debug("Going to staff stack.");
      return <StaffNavigation colorScheme="light" />;
    } else if (isPatientOrFamilyMember(user?.type)) {
      console.debug("Going to user stack.");
      return <PatientNavigation colorScheme="light" />;
    } else {
      console.error("Couldn't find something to render.", user);
      return <Loading />;
    }
  };
  return (
    <SocketProvider>
      <AppBody />
    </SocketProvider>
  );
};

function areAppPropsEqual(prevProps: AppProps, nextProps: AppProps): boolean {
  const userTypesEqual = prevProps.user?.type === nextProps.user?.type;
  const consentFormAgreementsEqual =
    prevProps.user?.consentFormAgreements === nextProps.user?.consentFormAgreements;
  const currentUserIdEqual = prevProps.currentUserId === nextProps.currentUserId;
  const errorsEqual = prevProps.error === nextProps.error;

  return userTypesEqual && consentFormAgreementsEqual && currentUserIdEqual && errorsEqual;
}

const AppMemo = memo(App, areAppPropsEqual);

function heapIdentifyUser(user: User | undefined): void {
  if (user) {
    window.heap.identify(user._id);
    window.heap.addUserProperties({name: user.name});
  }
}

const AppParent = (): ReactElement | null => {
  // Hide splash screen once the app is ready
  useEffect(() => {
    SplashScreen.hideAsync().catch(console.warn);
  }, []);

  const [time, setTime] = useState<Date>();
  const currentUserId = useSelectCurrentUserId();
  const {data: user, error} = useGetUsersByIdQuery(currentUserId ?? skipToken);
  // Memoization of user data is used to manage the scenario where, upon logout,
  // currentUserId is null but user data persists in RTK Query's cache.
  // Although we use the `skipToken` to conditionally skip the query execution based on the presence
  // of currentUserId, the previously fetched user data can remain accessible in the cache until
  // it's explicitly invalidated or purged. This memoization ensures that components do not respond
  // to this stale user data, preventing unnecessary re-renders and irrelevant conditional checks
  // (e.g., biometrics, updateUser) when the user is not authenticated.
  // It complements the `skipToken` approach by providing a safeguard against the use of outdated
  // data. This is a temporary solution until logout() and resetAppState() synchronously purge RTK
  const memoizedUser = useMemo(() => {
    if (user?._id === currentUserId) {
      return user;
    } else {
      return undefined;
    }
  }, [user, currentUserId]);
  const logoutUser = useLogoutUser();
  if (IsWeb) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSetupWebNotifications();
    setupHeap();
    heapIdentifyUser(user);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSetupNotifications();
  }

  // Set time on first render only.
  useEffect(() => {
    setTime(new Date());
  }, []);

  if (!time) {
    return null;
  }

  return (
    <AppMemo
      currentUserId={currentUserId}
      error={error as any}
      launchTime={time}
      logoutUser={logoutUser}
      user={memoizedUser}
    />
  );
};

const AppRoot = (): ReactElement => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SafeAreaProvider>
          <FernsProvider openAPISpecUrl={`${baseUrl}/openapi.json`}>
            <AppParent />
          </FernsProvider>
        </SafeAreaProvider>
      </PersistGate>
    </Provider>
  );
};

// eslint-disable-next-line import/no-default-export
export default Sentry.wrap(AppRoot);
