import {baseUrl} from "@ferns-rtk";
import {useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query";
import {ScheduleItem, useGetScheduleItemsByIdQuery} from "@store";
import {isPatient, isStaff} from "@utils";
import {ZoomMtg} from "@zoom/meetingsdk";
import axios from "axios";
import {Box, Button, Field, Heading, humanDate, Page, Text} from "ferns-ui";
import React, {ReactElement, useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import {Linking} from "react-native";

import {AllRouteParamList, BaseStackScreenProps} from "../types";

interface VideoCallScreenProps extends BaseStackScreenProps<"VideoCall"> {}

export const VideoCallScreen = ({navigation, route}: VideoCallScreenProps): ReactElement => {
  const scheduleItemId = route.params.sid;
  const profile = useReadProfile();
  const [displayName, setDisplayName] = useState<string>(route.params?.displayName ?? "");
  const {data: scheduleItem} = useGetScheduleItemsByIdQuery(profile ? scheduleItemId : skipToken);
  const isEnded = route.params.isEnded;

  // hide zoom element until initialized
  useEffect(() => {
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    document.getElementById("zmmtg-root")!.style.display = "none";
  }, []);

  // if the user is logged in, set their details like name automatically
  useEffect(() => {
    if (profile) {
      setDisplayName(profile.name);
    }
  }, [profile]);

  if (isEnded) {
    return <MeetingEndedView />;
  }

  const authEndpoint = `${baseUrl}/zoom/signature`;

  const getSignature = async (): Promise<void> => {
    if (!route.params?.sid) {
      console.error("No appointmentId provided");
      return;
    }
    try {
      const req = await axios.get(authEndpoint, {
        params: {
          scheduleItemId: route.params.sid,
        },
      });
      const signature = req.data.signature as string;
      const zoomMeetingNumber = req.data.zoomMeetingNumber as number;
      const passcode = req.data.zoomMeetingPasscode as string | undefined;
      const sdkKey = req.data.sdkKey as string;
      await startMeeting(signature, zoomMeetingNumber, passcode, sdkKey);
    } catch (error) {
      console.error(`Error getting signature: ${error}`);
    }
  };

  async function startMeeting(
    signature: string,
    meetingNumber: number,
    passcode: string | undefined,
    sdkKey: string
  ): Promise<void> {
    const zoomRoot = document.getElementById("zmmtg-root");
    if (zoomRoot) {
      zoomRoot.style.display = "block";
    }

    ZoomMtg.init({
      leaveUrl: `${window.location.origin}/VideoCall?sid=${scheduleItemId}&isEnded=true`,
      patchJsMedia: true,
      leaveOnPageUnload: true,
      success: (success: unknown) => {
        console.debug(`Successfully initialized zoom meeting: ${success}`);
        ZoomMtg.join({
          signature,
          sdkKey,
          meetingNumber,
          passWord: passcode,
          userName: displayName,
          userEmail: profile?.email ? profile.email : "",
          success: (val: any) => {
            console.debug(`Successfully joined zoom meeting: ${val}`);
            ZoomMtg.mirrorVideo({mirrored: true});
          },
          error: (err: any) => {
            console.debug(`Error joining zoom meeting: ${err}`);
          },
        });
      },
      error: (error: unknown) => {
        console.debug(`Error initializing zoom meeting: ${error}`);
      },
    });
  }

  return (
    <Page navigation={navigation}>
      <Box direction="column" gap={2}>
        <Box direction="column" gap={2} padding={2}>
          {!Boolean(profile) && (
            <Field title="Your Name" value={displayName} onChange={setDisplayName} />
          )}
          {scheduleItem && <MeetingDetails scheduleItem={scheduleItem} />}
        </Box>
        <Box direction="column" gap={2} padding={2} width="auto">
          <Button text="Join Appointment" variant="primary" onClick={getSignature} />
        </Box>
      </Box>
    </Page>
  );
};

const MeetingDetails = ({scheduleItem}: {scheduleItem: ScheduleItem}): ReactElement => {
  return (
    <Box>
      <Heading>{scheduleItem.title}</Heading>
      <Text>Start Time: {`${humanDate(scheduleItem.startDatetime)}`}</Text>
      <Text>End Time: {`${humanDate(scheduleItem.endDatetime)}`}</Text>
    </Box>
  );
};

const MeetingEndedView = (): ReactElement | null => {
  const navigation = useNavigation<NativeStackScreenProps<AllRouteParamList>["navigation"]>();
  const profile = useReadProfile();

  const isStaffUser = isStaff(profile?.type);
  const isPatientUser = isPatient(profile?.type);

  if (isStaffUser) {
    if (isMobile) {
      // should open the app since we use universal links
      void Linking.openURL("https://app.flourish.health");
      return null;
    } else {
      navigation.navigate("Staff");
    }
    return null;
  }

  const handleGoBack = async (): Promise<void> => {
    const state = navigation.getState();
    const previousRoute = state?.routes[state.routes.length - 2];

    if (isMobile) {
      await Linking.openURL("https://app.flourish.health");
    } else {
      // On desktop, just use web navigation
      if (previousRoute) {
        navigation.goBack();
      } else {
        if (isPatientUser) {
          navigation.navigate("Patient");
        } else {
          navigation.navigate("LogIn");
        }
      }
    }
  };

  return (
    <Box
      alignItems="center"
      alignSelf="center"
      height="100%"
      justifyContent="center"
      marginTop={8}
      maxWidth={400}
      paddingX={4}
      width="100%"
    >
      <Heading>Your Appointment Has Ended</Heading>
      <Text>
        The appointment has ended. Please contact your provider if you have any questions.
      </Text>
      <Button text="Go Back" onClick={handleGoBack} />
    </Box>
  );
};
