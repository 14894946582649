import {useFormInstancePatch} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  AttendanceStatusMapping,
  FormInstanceWithForm,
  setAttendanceData,
  setAttended,
  useFormInstanceIsReadOnly,
  useGetScheduleItemsByIdQuery,
  useGetUsersQuery,
  useLocalFormInstance,
} from "@store";
import {Box, MultiselectField, SelectField} from "ferns-ui";
import React, {FC, useCallback, useMemo} from "react";
import {useDispatch} from "react-redux";

interface AttendanceStatusDropdownProps {
  formInstanceId: string;
}

export const AttendanceStatusDropdown: FC<AttendanceStatusDropdownProps> = ({formInstanceId}) => {
  const dispatch = useDispatch();
  const formInstance = useLocalFormInstance(formInstanceId);
  const readOnly = useFormInstanceIsReadOnly(formInstanceId);
  const {updateFormInstance} = useFormInstancePatch(formInstanceId, readOnly);
  const {data: scheduleItem} = useGetScheduleItemsByIdQuery(
    formInstance?.scheduleItemId ?? skipToken
  );
  const {data: usersData} = useGetUsersQuery(
    scheduleItem?.users?.length
      ? {_id: {$in: scheduleItem.users.map((u) => u.userId._id)}}
      : skipToken
  );

  const scheduleItemUsers = useMemo(() => {
    if (scheduleItem && usersData?.data) {
      return usersData.data.map((u) => ({userId: {_id: u._id, name: u.name}}));
    }
    return [];
  }, [scheduleItem, usersData?.data]);
  const handleAttendanceStatusChange = useCallback(
    async (status?: string): Promise<void> => {
      if (!formInstance) return;

      const newAttendanceStatus = status ?? undefined;
      const newAttended = [];

      if (status === "Attended") {
        // auto add all users in the schedule item to the
        // attended list
        if (scheduleItemUsers.length) {
          newAttended.push(...scheduleItemUsers.map((u) => ({userId: u.userId._id})));
        } else {
          newAttended.push({userId: formInstance.userId});
        }
      }

      // optimistically update Redux store to prevent UI glitching
      dispatch(
        setAttendanceData({
          attendanceStatus: newAttendanceStatus,
          attended: newAttended,
        })
      );

      // make the API call and rollback on failure
      const res = await updateFormInstance(
        {
          attendanceStatus: newAttendanceStatus as FormInstanceWithForm["attendanceStatus"],
          attended: newAttended,
        },
        "Error updating attendance status. Please try again."
      );
      if (res?.error) {
        // rollback Redux state on failure
        dispatch(
          setAttendanceData({
            attendanceStatus: formInstance.attendanceStatus,
            attended: formInstance.attended,
          })
        );
      }
    },
    [dispatch, formInstance, scheduleItemUsers, updateFormInstance]
  );

  const handleAttendeesChange = useCallback(
    async (selected: string[]): Promise<void> => {
      const attendees = selected.map((userId) => ({userId}));

      // optimistically update Redux store to prevent UI glitching
      dispatch(setAttended(attendees));

      const res = await updateFormInstance(
        {
          attended: attendees,
        },

        "Error updating attendance. Please try again."
      );
      if (res?.error) {
        // rollback Redux state on failure
        dispatch(setAttended(formInstance?.attended || []));
      }
    },
    [dispatch, formInstance?.attended, updateFormInstance]
  );

  if (
    !formInstance ||
    Boolean(formInstance.form?.hideAttendanceStatus) ||
    Boolean(!formInstance?.serviceDate)
  ) {
    return null;
  }

  // Determine if we should show custom options based on form.internalKey
  const isGuideProgressForm =
    formInstance?.form?.internalKey === "patient-guide-progress" ||
    formInstance?.form?.internalKey === "family-guide-progress";

  // Define custom options for guide progress forms
  const guideProgressOptions: Array<{
    label: string;
    value: FormInstanceWithForm["attendanceStatus"];
  }> = [
    {label: "Engaged", value: "Attended"},
    {label: "No Engagement", value: "No-Show"},
  ];

  // Define the options to use based on form type
  const options = isGuideProgressForm
    ? guideProgressOptions
    : [
        ...Object.values(AttendanceStatusMapping).map((status) => {
          return {label: status, value: status};
        }),
      ];

  return (
    <Box direction="row" paddingY={1} width="100%">
      <Box flex="grow" marginRight={2} maxWidth="50%">
        <SelectField
          disabled={readOnly}
          options={options as any}
          placeholder="None Selected"
          requireValue={false}
          title="Attendance Status"
          value={formInstance?.attendanceStatus}
          onChange={handleAttendanceStatusChange}
        />
        {/* Only show attendee selection if schedule item has multiple users, and status is "Attended" */}
        {formInstance?.attendanceStatus === "Attended" &&
          scheduleItemUsers?.length > 1 &&
          !readOnly && (
            <Box paddingY={3}>
              <MultiselectField
                options={scheduleItemUsers.map((user) => ({
                  value: user.userId._id,
                  label: user.userId.name,
                }))}
                title="Please select all in attendance"
                value={(formInstance.attended ?? []).reduce<string[]>(
                  (matchedUsers, attendedUser) => {
                    const matchedUser = scheduleItemUsers.find(
                      (scheduleUser) => scheduleUser.userId._id === attendedUser.userId
                    );
                    if (matchedUser) {
                      matchedUsers.push(matchedUser.userId._id);
                    }
                    return matchedUsers;
                  },
                  []
                )}
                onChange={handleAttendeesChange}
              />
            </Box>
          )}
      </Box>
    </Box>
  );
};
