import {
  ActivityLogView,
  ClinicalView,
  FitbitView,
  FormsColumn,
  ScheduleColumn,
  StaffColumn,
  StaffColumnTab,
  ToDoList,
  UserInfoView,
  WorkflowChatView,
  WorkflowItemData,
  WorkflowListHeader,
  WorkflowMappingItem,
} from "@components";
import {useCurrentWorkflowMapping, useReadProfile} from "@hooks";
import {useBottomTabBarHeight} from "@react-navigation/bottom-tabs";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  setWorkflowMappingId,
  useAppDispatch,
  useGetUsersByIdQuery,
  useGetWorkflowMappingsQuery,
  useOutboundPhoneCallMutation,
  useSelectWorkflowStaffId,
  WorkflowMapping,
} from "@store";
import {StaffTabScreenProps} from "@types";
import {IsMobileDevice, isPsychiatrist, isSuperUser, isTherapist} from "@utils";
import {Box, Button, IconButton, ScrollView, SplitPage, Text, useTheme, useToast} from "ferns-ui";
import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {ListRenderItemInfo, StyleProp, ViewStyle} from "react-native";

interface WorkflowScreenProps extends StaffTabScreenProps<"Workflows"> {}

export const defaultRightTabs: StaffColumnTab[] = [
  "Clinical",
  "User",
  "To Do's",
  "Forms",
  "Schedule",
  "Fitbit",
  "Activity",
];

export interface SendWorkflowItemsProps {
  userId: string;
  role: string;
}

export const StaffScreen = ({navigation}: WorkflowScreenProps): ReactElement | null => {
  const dispatch = useAppDispatch();

  const staffId = useSelectWorkflowStaffId();
  const [outboundPhoneCall] = useOutboundPhoneCallMutation();
  const toast = useToast();
  const profile = useReadProfile();

  const currentWorkflowMapping = useCurrentWorkflowMapping();
  const workflowMappings = useGetWorkflowMappingsQuery(staffId ? {staffId} : skipToken);
  const [showMobileItemList, setShowMobileItemList] = useState<boolean>(true);
  const [listFilter, setListFilter] = useState<string>("");
  const [showHelpModal, setShowHelpModal] = useState(false);

  // Patient, family member, or staff user who the workflow mapping is about
  const userId = currentWorkflowMapping?.userId?._id;
  const {data: selectedUser} = useGetUsersByIdQuery(userId ?? skipToken);

  const bottomTabBarHeight = useBottomTabBarHeight();
  const {theme} = useTheme();

  const listViewData: WorkflowItemData[] = useMemo(() => {
    if (!profile || !workflowMappings?.data?.data) {
      return [];
    }

    if (!listFilter) {
      return workflowMappings?.data?.data;
    }

    // Search is the only thing filtered on the frontend.
    return workflowMappings?.data?.data.filter((wm) =>
      (wm as any).userId?.name.toLowerCase().includes(listFilter.toLowerCase())
    ) as any[];
  }, [workflowMappings?.data?.data, profile, listFilter]);

  const renderOutboundPhoneCallButton = useCallback((): ReactElement | null => {
    if (!profile || !selectedUser) {
      return null;
    }
    return (
      <IconButton
        accessibilityLabel="Call User"
        iconName="phone"
        onClick={async (): Promise<void> => {
          toast.show(`Calling phone number: "${selectedUser.name}"`);
          try {
            await outboundPhoneCall({
              callRecipient: {
                id: selectedUser._id,
                name: selectedUser.name,
                phoneNumber: selectedUser.phoneNumber,
              },
            }).unwrap();
          } catch (error: any) {
            toast.error(`${error?.data?.title ?? "Unknown error calling user"}`);
          }
        }}
      />
    );
  }, [outboundPhoneCall, profile, selectedUser, toast]);

  const renderCrisisEventHelpButton = useCallback((): ReactElement | null => {
    if (!profile || !selectedUser) {
      return null;
    }
    return (
      <Box alignItems="center" marginRight={1}>
        <Button text="Help" variant="outline" onClick={() => setShowHelpModal(true)} />
      </Box>
    );
  }, [profile, selectedUser, setShowHelpModal]);

  const renderHeaderRightButtons = useCallback((): ReactElement | null => {
    if (!profile || !selectedUser) {
      return null;
    }
    return (
      <Box alignItems="center" direction="row" gap={2}>
        {renderOutboundPhoneCallButton()}
        {renderCrisisEventHelpButton()}
      </Box>
    );
  }, [renderOutboundPhoneCallButton, renderCrisisEventHelpButton, profile, selectedUser]);

  // For mobile, set the header to show the selected user's name and pronouns.
  useEffect(() => {
    if (IsMobileDevice && selectedUser) {
      navigation.setOptions({
        headerLeft: () => {
          return (
            <Box paddingX={2}>
              <IconButton
                accessibilityLabel="deselect mobile item"
                iconName="arrow-left"
                variant="muted"
                onClick={(): void => {
                  setShowMobileItemList(true);
                  dispatch(setWorkflowMappingId(undefined));

                  // reset navigation to default options
                  navigation.reset({stale: true, routes: [{name: "Workflows"}]});
                }}
              />
            </Box>
          );
        },
        headerTitle: () => {
          return (
            <Box>
              <Text align="center">{selectedUser?.name}</Text>
              {Boolean(selectedUser?.pronouns) && (
                <Text align="center" color="secondaryLight" size="sm">
                  {selectedUser?.pronouns}
                </Text>
              )}
            </Box>
          );
        },
        headerRight: () => renderHeaderRightButtons(),
      });
    }
  }, [selectedUser, dispatch, navigation, renderHeaderRightButtons]);

  const contentContainerStyle: StyleProp<ViewStyle> = {backgroundColor: "#ffffff"};

  if (!profile) {
    return null;
  }

  // Default tabs (same as patient guide)
  let leftTabs: StaffColumnTab[] = ["Chat"];
  let rightTabs: StaffColumnTab[] = defaultRightTabs;

  if (isTherapist(profile) || isSuperUser(profile)) {
    leftTabs = ["Forms", "Chat"];
    // TODO: Support Forms in the staff right tabs as well.
    rightTabs = ["Clinical", "User", "To Do's", "Schedule", "Fitbit", "Activity"];
  } else if (isPsychiatrist(profile)) {
    leftTabs = ["Forms", "Chat"];
    rightTabs = ["Clinical", "User", "To Do's", "Schedule", "Fitbit", "Activity"];
  }

  return (
    <SplitPage
      bottomNavBarHeight={(bottomTabBarHeight || 0) + 35}
      listViewData={listViewData}
      renderListViewHeader={(): ReactElement => (
        <WorkflowListHeader onListFilterChange={({name}) => setListFilter(name || "")} />
      )}
      renderListViewItem={(itemInfo): ReactElement => <WorkflowMappingItem itemInfo={itemInfo} />}
      selectLimit={2}
      showItemList={showMobileItemList}
      tabs={["Chat", "Clinical", "User", "Fitbit"]}
      onSelectionChange={async (itemInfo: ListRenderItemInfo<WorkflowMapping>): Promise<void> => {
        if (itemInfo?.item?._id) {
          if (IsMobileDevice) {
            setShowMobileItemList(false);
          }
          dispatch(setWorkflowMappingId(itemInfo?.item?._id));
        }
      }}
    >
      {Boolean(IsMobileDevice) ? (
        <WorkflowChatView
          key={userId}
          setShowHelpModal={setShowHelpModal}
          showHelpModal={showHelpModal}
        />
      ) : (
        <StaffColumn
          isCenterColumn
          maxTabs={2}
          setShowHelpModal={setShowHelpModal}
          showHelpModal={showHelpModal}
          tabs={leftTabs}
          userId={userId}
          onRemoveUser={(): void => {
            dispatch(setWorkflowMappingId(undefined));
          }}
        />
      )}
      {Boolean(IsMobileDevice) ? (
        <>
          <ScrollView contentContainerStyle={contentContainerStyle}>
            <ClinicalView userId={userId} />
          </ScrollView>
          <ScrollView contentContainerStyle={contentContainerStyle}>
            <UserInfoView
              userId={userId}
              onRemoveUser={(): void => {
                dispatch(setWorkflowMappingId(undefined));
              }}
            />
          </ScrollView>
          <ScrollView contentContainerStyle={{...contentContainerStyle, padding: theme.spacing.md}}>
            <ToDoList userId={userId || ""} />
          </ScrollView>
          <ScrollView contentContainerStyle={{...contentContainerStyle, padding: theme.spacing.md}}>
            <FormsColumn userId={userId || ""} />
          </ScrollView>
          {selectedUser && (
            <ScrollView
              contentContainerStyle={{...contentContainerStyle, padding: theme.spacing.md}}
            >
              <ScheduleColumn userId={userId || ""} />
            </ScrollView>
          )}
          <ScrollView contentContainerStyle={contentContainerStyle}>
            <FitbitView userId={userId} />
          </ScrollView>
          <ScrollView contentContainerStyle={contentContainerStyle}>
            <ActivityLogView userId={userId || ""} />
          </ScrollView>
        </>
      ) : (
        <StaffColumn
          maxTabs={4}
          setShowHelpModal={setShowHelpModal}
          showHelpModal={showHelpModal}
          tabs={rightTabs}
          userId={userId}
          onRemoveUser={(): void => {
            dispatch(setWorkflowMappingId(undefined));
          }}
        />
      )}
    </SplitPage>
  );
};
