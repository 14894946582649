/**
 * This component provides a simple and generic way to manage simple models.
 * Given the mongooseSlice methods for a model and a config for the fields,
 * it will show a list of the existing instances of the model and a form for creating
 * new instances, as well as edit/delete for existing instances.
 *
 * See ExternalCliniciansScreen.tsx for an example of how to use this component.
 */
import {pageOnError} from "@utils";
import {Box, Button, Field, FieldProps, Heading, IconButton, Page, Text, useToast} from "ferns-ui";
import React, {useEffect, useState} from "react";

// TODO: Fix up FieldConfig type, it seems to have issues with differentiation based on `type`.
export interface FieldConfig extends Omit<FieldProps, "name"> {
  fieldKey: string;
}

type ExtraField = (instanceData: any, onChange: (val: any) => void) => React.ReactElement;

type RelatedModelEditor = (parentModelInstance: any) => React.ReactElement;

interface ModelInstanceAdminProps {
  confirmationText?: string;
  display: ModelAdminDisplay;
  fields: FieldConfig[];
  instance?: any;
  isEditing?: boolean;
  isNew?: boolean;
  useCreate: any;
  useRemove: any;
  useUpdate: any;
  extraFields?: ExtraField[];
  relatedModelEditors?: RelatedModelEditor[];
  modelName?: string;
  preSave?: (instance: any) => any;
}

export const ModelInstanceAdmin = ({
  confirmationText,
  display,
  fields,
  instance,
  isEditing,
  isNew,
  useCreate,
  useRemove,
  useUpdate,
  extraFields,
  relatedModelEditors,
  modelName,
  preSave,
}: ModelInstanceAdminProps): React.ReactElement | null => {
  const toast = useToast();
  const [edit, setEdit] = useState(isEditing);
  const [doUpdate] = useUpdate();
  const [doCreate] = useCreate();
  const [doRemove] = useRemove();
  const [instanceData, setInstanceData] = useState(instance ?? {});

  // Update the instance data when the instance prop changes to prevent versioning errors for
  // multiple saves
  useEffect(() => {
    setInstanceData(instance ?? {});
  }, [instance]);

  if (isNew && !edit) {
    // just return a button to "Create New"
    return (
      <Button
        text="Create New"
        onClick={(): void => {
          setEdit(true);
        }}
      />
    );
  }

  if (edit) {
    return (
      <Box
        border="default"
        color="base"
        gap={4}
        justifyContent="center"
        marginBottom={2}
        paddingX={4}
        paddingY={4}
        rounding="md"
      >
        {Boolean(!instance?._id) && <Heading size="lg">New</Heading>}
        {fields.map((field: FieldConfig) => (
          <Field
            key={field.fieldKey}
            type={field.type as any}
            {...field}
            value={instanceData[field.fieldKey] ?? ""}
            onChange={(result: any): void => {
              setInstanceData({
                ...instanceData,
                [field.fieldKey]: result,
              });
            }}
          />
        ))}

        {Boolean(extraFields) &&
          extraFields?.map((extraField: ExtraField) => extraField(instanceData, setInstanceData))}

        {Boolean(relatedModelEditors) &&
          relatedModelEditors?.map((relatedModelEditor: RelatedModelEditor) =>
            relatedModelEditor(instance)
          )}

        <Box direction="row" width="100%">
          <Box width={100}>
            <Button
              text="Cancel"
              variant="muted"
              onClick={async (): Promise<void> => {
                setEdit(false);
              }}
            />
          </Box>
          <Box marginLeft={4} width={200}>
            <Button
              text={
                instance?._id
                  ? `Save${modelName ? ` ${modelName}` : ""}`
                  : `Create${modelName ? ` ${modelName}` : ""}`
              }
              variant="primary"
              onClick={async (): Promise<void> => {
                let data = {...instanceData};
                if (preSave) {
                  data = preSave(data);
                }
                if (instance?._id) {
                  await doUpdate({
                    id: instance._id,
                    body: {...data},
                  })
                    .unwrap()
                    .catch((error: any) => toast.catch(error, "Error updating"));
                  setEdit(false);
                } else {
                  await doCreate(data)
                    .unwrap()
                    .catch((error: any) => toast.catch(error, "Error creating"));
                  toast.success(`Created successfully`);
                  // Reset the form
                  setInstanceData({});
                  setEdit(false);
                }
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  } else if (instance) {
    return (
      <Box
        border="default"
        color="base"
        direction="row"
        justifyContent="center"
        marginBottom={2}
        paddingX={4}
        paddingY={2}
        rounding="md"
      >
        <Box alignItems="center" direction="row" flex="grow">
          <Box alignItems="center" direction="column" marginRight={2} width="100%">
            <Box width="100%">
              <Text bold>{display(instance).title}</Text>
            </Box>
            {Boolean(display(instance).subtitle) && (
              <Box width="100%">
                <Text bold>{display(instance).subtitle}</Text>
              </Box>
            )}
          </Box>
        </Box>
        <Box direction="row" justifyContent="between" width={100}>
          <IconButton
            accessibilityLabel="edit"
            iconName="pencil"
            onClick={(): void => setEdit(true)}
          />
          <IconButton
            accessibilityLabel="delete"
            confirmationText={confirmationText ?? "Are you sure you want to delete this?"}
            iconName="trash"
            variant="destructive"
            withConfirmation
            onClick={async (): Promise<void> => {
              await doRemove(instance._id)
                .unwrap()
                .catch((error: any) => toast.catch("Error deleting", error));
            }}
          />
        </Box>
      </Box>
    );
  } else {
    return null;
  }
};

export type ModelAdminDisplay = (instance: any) => {title: string; subtitle?: string};

interface ModelAdminScreenProps {
  confirmationText?: string;
  description: string;
  display: ModelAdminDisplay;
  fields: FieldConfig[];
  navigation: any;
  useCreate: any;
  useList: any;
  useRemove: any;
  useUpdate: any;
  extraFields?: ExtraField[];
  relatedModelEditors?: RelatedModelEditor[];
  modelName?: string;
  preSave?: (instance: any) => any;
}

export const ModelAdminScreen = ({
  confirmationText,
  description,
  fields,
  navigation,
  useCreate,
  useList,
  useRemove,
  useUpdate,
  display,
  extraFields,
  relatedModelEditors,
  modelName,
  preSave,
}: ModelAdminScreenProps): any => {
  const {data} = useList({});
  return (
    <Page navigation={navigation} scroll onError={pageOnError}>
      <Box paddingY={1}>
        <Text>{description}</Text>
      </Box>

      <Box marginTop={5}>
        {data?.data?.map((instance: any) => (
          <ModelInstanceAdmin
            key={instance?._id}
            confirmationText={confirmationText}
            display={display}
            extraFields={extraFields}
            fields={fields}
            instance={instance}
            modelName={modelName}
            preSave={preSave}
            relatedModelEditors={relatedModelEditors}
            useCreate={useCreate}
            useRemove={useRemove}
            useUpdate={useUpdate}
          />
        ))}
        <ModelInstanceAdmin
          key="new"
          display={display}
          extraFields={extraFields}
          fields={fields}
          isNew
          preSave={preSave}
          relatedModelEditors={relatedModelEditors}
          useCreate={useCreate}
          useRemove={useRemove}
          useUpdate={useUpdate}
        />
      </Box>
    </Page>
  );
};
