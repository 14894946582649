import {DayTimeRange, UserList} from "@components";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {
  GuidingHour,
  useDeleteGuidingHoursByIdMutation,
  useGetGuidingHoursQuery,
  usePatchGuidingHoursByIdMutation,
  usePostGuidingHoursMutation,
  User,
} from "@store";
import {StaffStackParamList, StaffStackScreenProps} from "@types";
import {Box, Button, IconButton, Page, SelectField, Text, useToast} from "ferns-ui";
import React, {useCallback, useState} from "react";

function printHourMinute(hr: number, min: number): string {
  const hour = hr % 12;
  if (hour === 0) {
    return "12";
  }
  const amPm = hr < 12 ? "AM" : "PM";
  return `${hour}:${min.toString().padStart(2, "0")} ${amPm}`;
}

const GuidingHoursForm = ({
  guidingHour,
  editing,
  onComplete,
}: {
  guidingHour: GuidingHour;
  editing: boolean;
  onComplete?: () => void;
}): React.ReactElement => {
  const toast = useToast();
  const [createGuidingHour] = usePostGuidingHoursMutation();
  const [updateGuidingHour] = usePatchGuidingHoursByIdMutation();
  const [deleteGuidingHour] = useDeleteGuidingHoursByIdMutation();

  const [time, setTime] = useState(guidingHour.time);
  const [type, setType] = useState(guidingHour.type);
  const [supervisor, setSupervisor] = useState<User | undefined>(guidingHour.supervisor as User);
  const [clinicalLeader, setClinicalLeader] = useState<User | undefined>(
    guidingHour.clinicalLeader as User
  );
  const [edit, setEdit] = useState(editing);

  const onSave = useCallback(async () => {
    const body: any = {
      time,
      type,
      supervisor: supervisor?._id,
      clinicalLeader: clinicalLeader?._id,
    };
    if (guidingHour._id) {
      await updateGuidingHour({id: guidingHour._id, body}).unwrap().catch(toast.catch);
      toast.show("Guiding Hour updated successfully.");
    } else {
      await createGuidingHour(body).unwrap().catch(toast.catch);
      toast.show("Guiding Hour created successfully.");
      setClinicalLeader(undefined);
      setSupervisor(undefined);
      setType("PatientGuide");
      setTime({day: "Monday", startHour: 17, startMin: 0, endHour: 19, endMin: 0});
      onComplete?.();
    }
    setEdit(false);
  }, [
    onComplete,
    clinicalLeader?._id,
    createGuidingHour,
    guidingHour._id,
    supervisor?._id,
    time,
    toast,
    type,
    updateGuidingHour,
  ]);

  const onDelete = useCallback(async () => {
    await deleteGuidingHour(guidingHour._id).unwrap().catch(toast.catch);
  }, [toast, deleteGuidingHour, guidingHour._id]);

  const onCancel = useCallback(async () => {
    onComplete?.();
  }, [onComplete]);

  if (edit) {
    return (
      <Box border="default" color="base" padding={4} rounding="md">
        <Box width={200}>
          <SelectField
            options={[
              {label: "Patient Guide", value: "PatientGuide"},
              {label: "Family Guide", value: "FamilyGuide"},
            ]}
            requireValue
            title="Guiding Hours Type"
            value={type}
            onChange={(value) => setType(value as "PatientGuide" | "FamilyGuide")}
          />
        </Box>
        <Box marginBottom={1}>
          <Text bold>Guiding Hours</Text>
        </Box>
        <Box marginBottom={2}>
          <Text>All times in Eastern.</Text>
        </Box>
        <DayTimeRange dayTimeRange={time as any} onChange={setTime} />
        <Box marginBottom={2} width={300}>
          <UserList
            description="The supervisor of the guiding hour. They are the first point of contact for escalations."
            limit={1}
            staff
            title="Supervisor"
            userFilter={(user) => {
              return Boolean(
                user.staffRoles.FamilyGuideSupervisor ||
                  user.staffRoles.PatientGuideSupervisor ||
                  user.staffRoles.ClinicalLeader
              );
            }}
            userIds={supervisor ? [supervisor._id] : []}
            onChangeUsers={(users) => {
              setSupervisor(users[0]);
            }}
          />
        </Box>
        <Box marginBottom={2} width={300}>
          <UserList
            description="A staff member with the Clinical Leader role who is the backup for the supervisor."
            limit={1}
            staff
            title="Clinical Leader"
            userFilter={(user) => Boolean(user.staffRoles.ClinicalLeader)}
            userIds={clinicalLeader ? [clinicalLeader._id] : []}
            onChangeUsers={(users) => {
              setClinicalLeader(users[0]);
            }}
          />
        </Box>
        <Box direction="row" marginTop={2}>
          <Box marginRight={2}>
            <Button
              disabled={!supervisor || !clinicalLeader}
              // TODO: Does save work?
              iconName={"save" as any}
              text="Save"
              onClick={onSave}
            />
          </Box>
          {Boolean(guidingHour._id) ? (
            <Button iconName="trash" text="Delete" variant="destructive" onClick={onDelete} />
          ) : (
            <Button text="Cancel" variant="muted" onClick={onCancel} />
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box border="default" color="base" direction="row" paddingX={4} paddingY={2} rounding="md">
        <Box direction="column" flex="grow">
          <Box marginBottom={2}>
            <Text bold>
              {guidingHour.type === "PatientGuide"
                ? "Patient Guiding Hours"
                : "Family Guiding Hours"}
            </Text>
            <Text bold>
              {guidingHour.time.day}{" "}
              {printHourMinute(guidingHour.time.startHour!, guidingHour.time.startMin!)}
              {" - "}
              {printHourMinute(guidingHour.time.endHour!, guidingHour.time.endMin!)}
              {" Eastern"}
            </Text>
          </Box>
          <Box marginBottom={2}>
            <Text>
              Supervisor: {supervisor?.name}, Clinical Leader: {clinicalLeader?.name}
            </Text>
          </Box>
        </Box>

        <Box marginRight={2}>
          <IconButton
            accessibilityLabel="edit"
            iconName="pencil"
            variant="muted"
            onClick={() => setEdit(true)}
          />
        </Box>

        <IconButton
          accessibilityLabel="delete"
          iconName="trash"
          variant="destructive"
          onClick={onDelete}
        />
      </Box>
    );
  }
};

interface GuidingHoursProps extends StaffStackScreenProps<"GuidingHours"> {}

export const GuidingHoursScreen = ({}: GuidingHoursProps): React.ReactElement | null => {
  const navigation =
    useNavigation<NativeStackNavigationProp<StaffStackParamList, "GuidingHours">>();
  const {data: guidingHoursData} = useGetGuidingHoursQuery({});

  const [showNewGuidingHour, setShowNewGuidingHour] = useState(false);
  return (
    <Page navigation={navigation}>
      {guidingHoursData?.data?.map((guidingHour) => (
        <Box key={guidingHour._id} marginBottom={2}>
          <GuidingHoursForm editing={false} guidingHour={guidingHour} />
        </Box>
      ))}
      {Boolean(showNewGuidingHour) ? (
        <GuidingHoursForm
          editing
          guidingHour={
            {
              time: {day: "Monday", startHour: 17, startMin: 0, endHour: 19, endMin: 0},
              type: "PatientGuide",
            } as any
          }
          onComplete={() => setShowNewGuidingHour(false)}
        />
      ) : (
        <Box marginTop={4} width={300}>
          <Button
            iconName="plus"
            text="Add New Guiding Hour"
            onClick={() => setShowNewGuidingHour(true)}
          />
        </Box>
      )}
    </Page>
  );
};
