/* eslint-disable react/prop-types */
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetUsersByIdQuery, usePatchUsersByIdMutation} from "@store";
import {Box, TextArea, useToast} from "ferns-ui";
import get from "lodash/get";
import React, {useCallback, useEffect, useState} from "react";

interface DataExplorerTextAreaCellProps {
  cellData: {value: string; field?: string; instanceId?: string};
}

export const DataExplorerTextAreaCell: React.FC<DataExplorerTextAreaCellProps> = React.memo(
  ({
    cellData,
    // Only supports User model for now.
  }) => {
    const {field, instanceId} = cellData ?? {};

    const toast = useToast();
    const [text, setText] = useState("");
    const {data: userData} = useGetUsersByIdQuery(instanceId ?? skipToken);
    const [updateUser] = usePatchUsersByIdMutation();
    const [focused, setFocused] = useState(false);

    // Update value when userData changes and we're not focused.
    // This runs the risk of overwriting each other if two people are editing the same field at the
    // same time.
    useEffect(() => {
      if (!field) {
        return;
      }

      if (!focused && userData) {
        setText(get(userData, field));
      }
    }, [userData, field, focused]);

    const onBlur = useCallback(
      async (v: string) => {
        if (!field || !instanceId) {
          return;
        }

        setFocused(false);
        const val = v.trim();
        setText(val);

        // Don't save if the value is empty and the user didn't have a value set.
        if (val === "" && !get(userData, field)) {
          return;
        }

        // Only save if the value has changed
        if (val !== get(userData, field)) {
          // Update using dot notation to be able to handle nested values correctly.
          const body = {id: instanceId, body: {[field]: val}};
          await updateUser(body)
            .unwrap()
            .then(() => {
              // TODO this can probably go away once we're secure with our updates.
              toast.success(`Saved ${userData?.name}`);
            })
            // TODO: Do we want to revert here? Leave it so it can be sent again? Leaving seems
            // safer, but we may need a "save again" button (focus/blur isn't good UX).
            .catch(toast.catch);
        }
      },
      [instanceId, field, userData, updateUser, toast]
    );

    const handleChange = useCallback((v: string) => setText(v), []);
    const handleFocus = useCallback(() => setFocused(true), []);

    if (!field || !instanceId) {
      return null;
    }

    return (
      <Box height={120} justifyContent="center" maxHeight={120} width="100%">
        <TextArea
          grow={false}
          rows={2}
          value={text}
          onBlur={onBlur}
          onChange={handleChange}
          onFocus={handleFocus}
        />
      </Box>
    );
  }
);

DataExplorerTextAreaCell.displayName = "DataExplorerTextAreaCell";
