import {User} from "@store";
import {Box, CheckBox} from "ferns-ui";
import get from "lodash/get";
import React, {useState} from "react";

import {CheckedCell} from "./CheckedCell";

export const CheckboxCell = ({
  user,
  field,
  editing,
  onChange,
}: {
  user: User;
  field: string; // Use dot notation to access nested objects.
  editing: boolean;
  onChange: (checked: boolean) => Promise<void>;
}): React.ReactElement => {
  // Locally cache this value so that we can update it immediately when the user taps it,
  // otherwise we wind up with a delay while the server responds (very noticeable when checking a
  // couple)
  const [checked, setChecked] = useState(get(user, field));
  if (editing) {
    return (
      <Box
        accessibilityHint="Check cell"
        accessibilityLabel="Check"
        flex="grow"
        justifyContent="center"
        width="100%"
        onClick={(): Promise<void> => {
          setChecked(!checked);
          return onChange(!checked);
        }}
      >
        <CheckBox selected={checked} />
      </Box>
    );
  } else {
    return <CheckedCell cellData={{value: get(user, field)}} />;
  }
};
