import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {Box, Page, SelectField, Spinner, Text, useToast} from "ferns-ui";
import React, {useCallback} from "react";

import {
  formatTimeRange,
  useGetCrisisConsultantSchedulesQuery,
  useGetUsersQuery,
  usePatchCrisisConsultantSchedulesByIdMutation,
  usePostCrisisConsultantSchedulesMutation,
} from "../store";
import {StaffStackParamList} from "../types";

type WeekDay = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday";

type DailyHours = {
  startHour: number;
  startMin: number;
  endHour: number;
  endMin: number;
};

const DAYS_OF_WEEK: WeekDay[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

// Hardcoded On Call Crisis Consultant hours per day, not editable in V1
const DEFAULT_HOURS: Record<WeekDay, DailyHours> = {
  Monday: {startHour: 8, startMin: 0, endHour: 19, endMin: 30},
  Tuesday: {startHour: 9, startMin: 0, endHour: 21, endMin: 0},
  Wednesday: {startHour: 9, startMin: 0, endHour: 19, endMin: 30},
  Thursday: {startHour: 9, startMin: 0, endHour: 21, endMin: 0},
  Friday: {startHour: 8, startMin: 0, endHour: 21, endMin: 0},
};

interface OnCallScheduleDayProps {
  day: WeekDay;
  selectedOnCallCrisisConsultant?: string;
  options: {label: string; value: string}[];
  onOnCallCrisisConsultantChange: (day: WeekDay, userId: string) => void;
}

const OnCallScheduleDay = React.memo(
  ({
    day,
    selectedOnCallCrisisConsultant,
    options,
    onOnCallCrisisConsultantChange,
  }: OnCallScheduleDayProps): React.ReactElement => {
    const defaultHours = DEFAULT_HOURS[day];

    const formattedTime = formatTimeRange(
      defaultHours.startHour,
      defaultHours.startMin,
      defaultHours.endHour,
      defaultHours.endMin
    );

    const handleChange = useCallback(
      (value: string) => onOnCallCrisisConsultantChange(day, value),
      [day, onOnCallCrisisConsultantChange]
    );

    return (
      <Box alignItems="center" direction="row" height={70} width="100%">
        <Box marginRight={2} width={120}>
          <Text bold>{day}</Text>
        </Box>
        <Box flex="grow" marginRight={2} maxWidth={300}>
          <SelectField
            options={options}
            placeholder="Please select an on call crisis consultant"
            requireValue={Boolean(selectedOnCallCrisisConsultant)}
            value={selectedOnCallCrisisConsultant ?? ""}
            onChange={handleChange}
          />
        </Box>
        <Box flex="grow">
          <Text>{formattedTime} ET</Text>
        </Box>
      </Box>
    );
  }
);

OnCallScheduleDay.displayName = "OnCallScheduleDay";

export const OnCallScheduleScreen = (): React.ReactElement => {
  const navigation = useNavigation<NativeStackNavigationProp<StaffStackParamList>>();
  const toast = useToast();
  const {data: users, isLoading: isLoadingUsers} = useGetUsersQuery({
    "staffRoles.OnCallCrisisConsultant": true,
  });
  const {data: onCallSchedules, isLoading: isLoadingOnCallSchedules} =
    useGetCrisisConsultantSchedulesQuery({});

  const [createOnCallSchedule] = usePostCrisisConsultantSchedulesMutation();
  const [updateOnCallSchedule] = usePatchCrisisConsultantSchedulesByIdMutation();

  const handleOnCallCrisisConsultantChange = useCallback(
    async (day: WeekDay, userId: string): Promise<void> => {
      const existingSchedule = onCallSchedules?.data?.find((s) => s.time?.day === day);
      const defaultHours = DEFAULT_HOURS[day];

      try {
        if (existingSchedule) {
          await updateOnCallSchedule({
            id: existingSchedule._id,
            body: {
              onCallCrisisConsultant: userId,
            },
          }).unwrap();
        } else {
          await createOnCallSchedule({
            time: {
              day,
              startHour: defaultHours.startHour,
              startMin: defaultHours.startMin,
              endHour: defaultHours.endHour,
              endMin: defaultHours.endMin,
            },
            onCallCrisisConsultant: userId,
          }).unwrap();
        }
        toast.show(
          `On call crisis consultant ${existingSchedule ? "updated" : "assigned"} successfully`
        );
      } catch (error) {
        toast.catch(
          error,
          `Error ${existingSchedule ? "updating" : "assigning"} on call crisis consultant`
        );
      }
    },
    [createOnCallSchedule, updateOnCallSchedule, onCallSchedules?.data, toast]
  );

  return (
    <Page navigation={navigation}>
      <Box color="base" maxWidth={800} padding={4} rounding="lg">
        <Text>Select the on call crisis consultant for each day of the week.</Text>
        {isLoadingUsers || isLoadingOnCallSchedules ? (
          <Box width="100%">
            <Spinner />
          </Box>
        ) : (
          <Box gap={2}>
            {DAYS_OF_WEEK.map((day) => (
              <OnCallScheduleDay
                key={day}
                day={day}
                options={(users?.data ?? []).map((user) => ({
                  label: user.name,
                  value: user._id,
                }))}
                selectedOnCallCrisisConsultant={
                  onCallSchedules?.data?.find((s) => s.time?.day === day)?.onCallCrisisConsultant
                }
                onOnCallCrisisConsultantChange={handleOnCallCrisisConsultantChange}
              />
            ))}
          </Box>
        )}
      </Box>
    </Page>
  );
};
