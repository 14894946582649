import {Box, BoxProps, Heading, Text} from "ferns-ui";
import React, {ReactElement} from "react";

export const QuestionPromptText = ({
  index,
  prompt,
  questionType,
  boxProps = {},
}: {
  index?: number;
  prompt: string;
  questionType: string;
  boxProps?: BoxProps;
}): ReactElement | null => {
  const finalBoxProps: BoxProps =
    Object.keys(boxProps).length === 0 ? {marginBottom: 4, marginTop: 8} : boxProps;

  // Single Checkbox styling for prompt is handled within the checkbox component
  if (questionType === "SingleCheckbox") {
    return null;
  }

  if (questionType === "Heading") {
    return (
      <Box {...finalBoxProps}>
        <Heading size="sm">{prompt}</Heading>
      </Box>
    );
  }

  return (
    <Text bold>
      {typeof index === "number" ? `${index + 1}: ` : null}
      {prompt}
    </Text>
  );
};
