import FontAwesome6 from "@expo/vector-icons/FontAwesome6";
import {ButtonProps, Text, useTheme} from "ferns-ui";
import React, {FC, useCallback, useRef, useState} from "react";
import {Dimensions, Modal, Pressable, Text as NativeText, View} from "react-native";

interface Option {
  label: string;
  value: string;
}

interface DropdownButtonProps extends Omit<ButtonProps, "iconName" | "onClick"> {
  options: Option[];
  size?: "sm" | "md";
  onSelect: (value: string) => void;
  selectedValue?: string;
}

interface MenuPosition {
  top?: number;
  bottom?: number;
  left: number;
  width: number;
  showAbove: boolean;
}

export const DropdownButton: FC<DropdownButtonProps> = ({
  options,
  size = "md",
  onSelect,
  selectedValue,
  ...buttonProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState<MenuPosition>({
    top: 0,
    left: 0,
    width: 0,
    showAbove: false,
  });
  const buttonRef = useRef<View>(null);
  const {theme} = useTheme();

  // This useEffect will check if we're near the bottom of the screen and position the dropdown
  // accordingly
  const handlePress = useCallback((): void => {
    if (buttonRef.current) {
      // @ts-ignore - getBoundingClientRect exists on web
      const rect = buttonRef.current.getBoundingClientRect();
      const windowHeight = Dimensions.get("window").height;

      // Check if there's enough space below the button
      const spaceBelow = windowHeight - rect.bottom;
      const menuHeight = options.length * (size === "sm" ? 40 : 48); // Approximate height of menu
      const showAbove = spaceBelow < menuHeight && rect.top > menuHeight;

      if (showAbove) {
        // Position menu above button
        setMenuPosition({
          bottom: windowHeight - rect.top,
          left: rect.left,
          width: rect.width,
          showAbove: true,
        });
      } else {
        // Position menu below button
        setMenuPosition({
          top: rect.bottom,
          left: rect.left,
          width: rect.width,
          showAbove: false,
        });
      }
      setIsOpen(true);
    }
  }, [options.length, size]);

  const handleSelect = useCallback(
    (option: Option): void => {
      onSelect(option.value);
      setIsOpen(false);
    },
    [onSelect]
  );

  const handleClose = useCallback((): void => {
    setIsOpen(false);
  }, []);

  if (!theme) return null;

  const selectedOption = options.find((opt) => opt.value === selectedValue);
  const buttonText = selectedOption ? selectedOption.label : buttonProps.text;

  const buttonHeight = size === "sm" ? 32 : 40;
  const fontSize = size === "sm" ? 14 : 16;
  const paddingHorizontal = size === "sm" ? 12 : 20;

  return (
    <View>
      <Pressable
        ref={buttonRef}
        style={{
          alignItems: "center",
          backgroundColor: theme.surface.primary,
          borderRadius: theme.radius.rounded,
          flexDirection: "row",
          height: buttonHeight,
          justifyContent: "space-between",
          paddingHorizontal,
          width: buttonProps.fullWidth ? "100%" : "auto",
        }}
        onPress={handlePress}
      >
        <NativeText
          style={{
            color: theme.text.inverted,
            fontSize,
            fontWeight: "700",
          }}
        >
          {buttonText}
        </NativeText>
        <View style={{marginLeft: 8}}>
          <FontAwesome6
            color={theme.text.inverted}
            name={menuPosition.showAbove ? "chevron-up" : "chevron-down"}
            size={size === "sm" ? 12 : 14}
            solid
          />
        </View>
      </Pressable>

      <Modal transparent visible={isOpen} onRequestClose={handleClose}>
        <Pressable
          style={{
            flex: 1,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: "flex-start",
          }}
          onPress={handleClose}
        >
          <View
            style={{
              backgroundColor: theme.surface.base,
              borderRadius: theme.radius.minimal,
              position: "absolute",
              minWidth: menuPosition.width,
              maxWidth: 300,
              left: Math.min(
                menuPosition.left,
                (typeof window !== "undefined" ? window.innerWidth : 0) - 300
              ),
              ...(menuPosition.showAbove
                ? {
                    bottom: menuPosition.bottom,
                    marginBottom: theme.spacing.sm,
                  }
                : {
                    top: menuPosition.top,
                    marginTop: theme.spacing.sm,
                  }),
              shadowColor: "#000",
              shadowOffset: {width: 0, height: 2},
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
              elevation: 5,
              zIndex: 1000,
              padding: theme.spacing.xs,
            }}
          >
            {options.map((option) => (
              <Pressable
                key={option.value}
                // TODO: The hovered state is not working on mobile.
                style={({hovered}: any) => ({
                  padding: size === "sm" ? 8 : 12,
                  backgroundColor: hovered ? theme.surface.neutralLight : "transparent",
                  borderRadius: theme.radius.minimal,
                })}
                onPress={() => handleSelect(option)}
              >
                <Text
                  bold
                  color={selectedValue === option.value ? "primary" : undefined}
                  size={size}
                >
                  {option.label}
                </Text>
              </Pressable>
            ))}
          </View>
        </Pressable>
      </Modal>
    </View>
  );
};
