import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  AttendanceStatusValues,
  clearLocalFormInstanceStore,
  Form,
  MissingAttendanceStatuses,
  setRouteReadOnly,
  useGetFormInstancesByIdQuery,
  userName,
} from "@store";
import {StaffStackParamList} from "@types";
import {pageOnError} from "@utils";
import {Box, Heading, Page, Spinner, Text} from "ferns-ui";
import React, {ReactElement, useEffect, useState} from "react";
import {Dimensions} from "react-native";
import {useDispatch} from "react-redux";

import {AssessmentScore} from "./AssessmentScore";
import {FormPrescenceWatcher, MissingAttendanceNote} from "./formInstanceQuestions";
import {
  AllFormInstanceSignatures,
  AttendanceStatusDropdown,
  FormInstanceBanners,
  FormInstanceProvider,
  FormQuestions,
  SendFormToUserButton,
  ServiceDateTimeField,
  SubmitAndCreatePDFButtons,
  ToastDuplicateAnswersError,
  ToastNullAnswersError,
  UserSignatures,
} from "./formInstanceView";
import {FormAISummarizer} from "./formInstanceView/FormAISummarizer";
import {FormInstanceAddendum} from "./formInstanceView/FormInstanceAddendum";
import {ISPAssessmentHistory} from "./ISPAssessmentHistory";
import {ManageFormsRightBar} from "./ManageFormsRightBar";

const GUIDE_PROGRESS_NOTES = ["Patient Guide Progress Note", "Family Guide Progress Note"];

interface FormViewProps {
  formId?: string;
  readOnly?: boolean;
  fullScreen?: boolean;
  navigation?: NativeStackNavigationProp<StaffStackParamList, "ManageFormScreen", undefined>;
}

export const ManageFormView = ({
  formId,
  readOnly,
  fullScreen,
  navigation,
}: FormViewProps): ReactElement => {
  const dispatch = useDispatch();
  const {data: formInstance} = useGetFormInstancesByIdQuery(formId ?? skipToken);

  const form = formInstance?.form as Form | undefined;
  const formUser = formInstance?.userId;
  const createdByUser = formInstance?.createdBy;
  const windowDimensions = Dimensions.get("window");

  const [dimensions, setDimensions] = useState({
    height: windowDimensions.height,
    width: windowDimensions.width,
  });

  // Set the read only state for the form instance
  useEffect(() => {
    dispatch(setRouteReadOnly(Boolean(readOnly)));
  }, [dispatch, readOnly]);

  // If the window dimensions change, update our state
  useEffect(() => {
    const subscription = Dimensions.addEventListener("change", ({window}) => {
      setDimensions({height: window.height, width: window.width});
    });
    return (): void => subscription?.remove();
  }, []);

  // Set the header title to the form name and the user's name
  useEffect(() => {
    const loadForm = async (): Promise<void> => {
      if (formInstance && navigation) {
        navigation.setOptions({
          headerTitle: `${(formInstance.form as Form).name} - ${userName(formUser)}`,
        });
      }
    };
    void loadForm();
  }, [formInstance, formUser, navigation]);

  // Dispatch clearFormInstanceData when the component is unmounted
  useEffect(() => {
    return (): void => {
      if (formInstance?._id) {
        dispatch(clearLocalFormInstanceStore());
      }
    };
  }, [dispatch, formInstance?._id]);

  if (!formInstance) {
    return (
      <Box alignItems="center" justifyContent="center" paddingY={4} width="100%">
        <Spinner />
      </Box>
    );
  }

  const formContent = (
    <Box color="neutralLight" marginRight={4} rounding="md">
      <Box color="neutralLight" rounding="md" scroll>
        <Box alignItems="center" direction="row" justifyContent="between" width="100%">
          <Heading>{form?.name}</Heading>
        </Box>
        {Boolean(form?.description) && (
          <Box paddingY={2}>
            <Text>{form?.description}</Text>
          </Box>
        )}
        <Box paddingY={2}>
          <Text>Created By: {createdByUser?.name}</Text>
        </Box>
        <SendFormToUserButton formInstanceId={formInstance?._id} />
        <AllFormInstanceSignatures formInstanceId={formInstance?._id} />
        <FormInstanceBanners formInstanceId={formInstance?._id} />

        <ServiceDateTimeField formInstanceId={formInstance._id} />
        <AttendanceStatusDropdown formInstanceId={formInstance._id} />

        {/* Auto Created FormInstances have specific fields for missing attendance */}
        {formInstance?.isAutoCreatedByScheduleItem &&
        MissingAttendanceStatuses.includes(
          (formInstance?.attendanceStatus as AttendanceStatusValues) ?? ""
        ) ? (
          <MissingAttendanceNote formInstanceId={formInstance._id} />
        ) : (
          <FormQuestions formInstanceId={formInstance._id} />
        )}
        {GUIDE_PROGRESS_NOTES.includes(form?.name as string) &&
          Boolean(formInstance.serviceDate) && (
            <FormAISummarizer formInstanceId={formInstance._id} />
          )}

        <UserSignatures formInstanceId={formInstance?._id} />
        {formUser?._id && form?.internalKey === "isp" && (
          <ISPAssessmentHistory userId={formUser?._id} />
        )}
        <Box color="base" marginBottom={2} marginTop={2} rounding="md">
          <AssessmentScore formInstanceId={formInstance?._id} />
        </Box>
        <FormInstanceAddendum formInstanceId={formInstance?._id} />
        <SubmitAndCreatePDFButtons formInstanceId={formInstance._id} />
      </Box>
    </Box>
  );

  const fullScreenFormContent = (
    <Box direction="row" flex="grow" width="100%">
      {/* Width should only be 70% if the screen is wide enough to display the staff side bar */}
      <Box width={dimensions?.width > 1100 ? "70%" : "100%"}>
        {/* Take 140 pixels out due to padding, header, and bottom tab bar so we don't end up with a third scroll bar on the page */}
        <Box maxHeight={dimensions?.height - 140} scroll width="100%">
          {formContent}
        </Box>
      </Box>
      <Box paddingX={3} width="30%">
        {Boolean(dimensions?.width && dimensions.width > 1100) && (
          /**
           * Take 140 pixels out due to padding, header,
           * and bottom tab bar so we don't end up with a third scroll bar on the page
           */
          <Box maxHeight={dimensions?.height - 140} scroll width="100%">
            <ManageFormsRightBar userId={formUser?._id} />
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <FormInstanceProvider>
      <Page color="neutralLight" maxWidth="100%" navigation={navigation} onError={pageOnError}>
        {Boolean(formInstance?._id) && <FormPrescenceWatcher formInstanceId={formInstance?._id} />}
        <ToastNullAnswersError formInstanceId={formInstance?._id} />
        <ToastDuplicateAnswersError formInstanceId={formInstance?._id} />
        {fullScreen ? fullScreenFormContent : formContent}
      </Page>
    </FormInstanceProvider>
  );
};
