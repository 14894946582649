import {ActionSheetProvider, ActionSheetProviderRef} from "@expo/react-native-action-sheet";
import {usePrevious} from "@hooks";
import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Keyboard, View} from "react-native";
import {v4} from "uuid";

import {IntroTextBanner} from "../chat/IncludeIntroTextBanner";
import {DEFAULT_PLACEHOLDER, TIME_FORMAT} from "./Constant";
import {GiftedChatProvider} from "./GiftedChatContext";
import {DeleteModal} from "./GiftedDeleteModal";
import {InputToolbar} from "./InputToolbar";
import {MessageContainer, MessageContainerHandles} from "./MessageContainer";
import {GiftedChatProps, IMessage, SendOptions} from "./Models";

interface GiftedChatHandles {
  scrollToBottom(animated?: boolean): void;
  focusTextInput(): void;
}

const useKeyboardHandling = (
  messageContainerRef: React.RefObject<MessageContainerHandles>,
  setIsTypingDisabled: (disabled: boolean) => void
): void => {
  // Comment: Handle keyboard show/hide events and update typing state
  useEffect(() => {
    const didShowListener = Keyboard.addListener("keyboardDidShow", (): void => {
      messageContainerRef?.current?.scrollToBottom(true);
      setIsTypingDisabled(false);
    });

    const didHideListener = Keyboard.addListener("keyboardDidHide", (): void => {
      messageContainerRef?.current?.scrollToBottom(true);
      setIsTypingDisabled(false);
    });

    return (): void => {
      didShowListener.remove();
      didHideListener.remove();
    };
  }, [messageContainerRef, setIsTypingDisabled]);
};

export const GiftedChat = React.forwardRef<GiftedChatHandles, GiftedChatProps>(
  <TMessage extends IMessage = IMessage>(
    {
      allowMentions,
      dateFormat,
      disableComposer = false,
      infiniteScroll,
      initialText,
      inverted = true,
      isLoadingEarlier = false,
      isTyping = false,
      keyboardAppearance,
      lightboxProps = {},
      loadEarlier = false,
      locale = "en",
      maxInputLength,
      messageIdGenerator = (): string => v4(),
      messages = [],
      messagesContainerStyle,
      multiline,
      onInputTextChanged,
      onLoadEarlier = (): void => {},
      onLongPressAvatar,
      onMentionSelect,
      onPressActionButton,
      onPressAvatar,
      onSelectMessageIds,
      onSend: propsSend = (): void => {},
      onTaggedUserChange,
      placeholder = DEFAULT_PLACEHOLDER,
      placeholderColor,
      renderAccessory,
      renderActions,
      renderAvatar,
      renderAvatarOnTop = false,
      renderBubble,
      renderChatEmpty,
      renderChatFooter,
      renderComposer,
      renderDay,
      renderFooter,
      renderLoadEarlier,
      renderMessage,
      renderMessageAudio,
      renderMessageImage,
      renderMessageText,
      renderMessageVideo,
      renderSend,
      renderSystemMessage,
      renderTime,
      selectedMessageIds,
      sendAsSms,
      setSendAsSmsForConversation,
      showSendAsSms,
      shouldShowDeliveryIcons,
      shouldUpdateMessage,
      showEmojiPicker,
      showMessageSelector,
      showUserAvatar = false,
      taggableUsers,
      textInputAutoFocus,
      textInputProps = {},
      textInputStyle,
      timeFormat = TIME_FORMAT,
      user,
      removeMessage,
      removeMessageLoading,
      removeMessageError,
      enableRemoveMessage,
      enableMarkUnread,
      onMarkUnread,
      enableMessageDebugging,
      conversationId,
      includeSMSIntroText,
    }: GiftedChatProps<TMessage>,
    ref: React.ForwardedRef<any>
  ): React.ReactElement => {
    const messageContainerRef = useRef<MessageContainerHandles>(null);
    const actionSheetRef = useRef<ActionSheetProviderRef>(null);
    const textInput = useRef<any>(null);
    const [isTypingDisabled, setIsTypingDisabled] = useState(false);
    const prevMessages = usePrevious(messages);

    const scrollToBottom = useCallback(
      (animated = true): void => {
        if (messageContainerRef?.current) {
          if (inverted) {
            messageContainerRef.current.scrollToBottom(animated);
          } else {
            messageContainerRef.current.scrollTo({animated, offset: 0});
          }
        }
      },
      [inverted]
    );

    useKeyboardHandling(messageContainerRef, setIsTypingDisabled);

    // Comment: When receiving a new message, scroll to the bottom
    useEffect(() => {
      if (messages?.slice(-1)?.[0]?._id !== prevMessages?.slice(-1)?.[0]?._id) {
        setTimeout(() => scrollToBottom(false), 200);
      }
    }, [messages, prevMessages, scrollToBottom]);

    useImperativeHandle(ref, () => ({
      scrollToBottom,
      focusTextInput: (): void => textInput.current?.focus(),
    }));

    const onSend = async (msgs: TMessage[] = [], options: SendOptions): Promise<void> => {
      if (!Array.isArray(msgs)) {
        msgs = [msgs];
      }
      const newMessages: TMessage[] = msgs.map((msg) => {
        return {
          ...msg,
          user: user!,
          createdAt: new Date(),
          _id: messageIdGenerator?.(),
        };
      });

      if (propsSend) {
        await propsSend(newMessages, options);
      }
      resetInputToolbar();
    };

    const resetInputToolbar = (): void => {
      if (textInput.current) {
        textInput.current.clear();
      }
      onInputTextChanged?.("");
    };

    const doOnInputTextChanged = (t: string): void => {
      if (isTypingDisabled) {
        return;
      }
      onInputTextChanged?.(t);
    };

    const inputToolbarProps = {
      allowMentions,
      disableComposer,
      keyboardAppearance,
      multiline,
      onMentionSelect,
      onPressActionButton,
      onSend,
      onTaggedUserChange,
      onTextChanged: doOnInputTextChanged,
      onInputSizeChanged: ({height: _height}: {height: number}): void => {
        messageContainerRef.current?.scrollToBottom(false);
      },
      placeholder,
      placeholderColor,
      renderAccessory,
      renderActions,
      renderComposer,
      renderSend,
      showEmojiPicker,
      taggableUsers,
      textInputAutoFocus,
      textInputProps: {
        ...textInputProps,
        ref: textInput,
        maxLength: isTypingDisabled ? 0 : maxInputLength,
      },
      textInputStyle,
      includeSMSIntroText,
    };

    return (
      <GiftedChatProvider
        value={{
          actionSheet: () => actionSheetRef.current?.getContext()! as any,
          getLocale: () => locale,
          sendAsSms,
          setSendAsSmsForConversation,
          showSendAsSms,
          shouldShowDeliveryIcons,
          removeMessage,
          removeMessageLoading,
          removeMessageError,
          enableRemoveMessage,
          enableMarkUnread,
          onMarkUnread,
          enableMessageDebugging,
          initialText,
          conversationId,
        }}
      >
        <ActionSheetProvider ref={actionSheetRef}>
          <View style={{flex: 1, flexDirection: "column"}}>
            <IntroTextBanner includeSMSIntroText={Boolean(includeSMSIntroText)} />
            <DeleteModal />
            <View style={{flex: 1, minHeight: 0}}>
              <MessageContainer
                ref={messageContainerRef}
                containerStyle={[{flex: 1}, messagesContainerStyle]}
                dateFormat={dateFormat}
                infiniteScroll={infiniteScroll}
                inverted={inverted}
                isLoadingEarlier={isLoadingEarlier}
                isTyping={isTyping}
                lightboxProps={lightboxProps}
                loadEarlier={loadEarlier}
                messages={messages}
                renderAvatar={renderAvatar}
                renderAvatarOnTop={renderAvatarOnTop}
                renderBubble={renderBubble}
                renderChatEmpty={renderChatEmpty}
                renderDay={renderDay}
                renderFooter={renderFooter}
                renderLoadEarlier={renderLoadEarlier}
                renderMessage={renderMessage}
                renderMessageAudio={renderMessageAudio}
                renderMessageImage={renderMessageImage}
                renderMessageText={renderMessageText}
                renderMessageVideo={renderMessageVideo}
                renderSystemMessage={renderSystemMessage}
                renderTime={renderTime}
                selectedMessageIds={selectedMessageIds}
                shouldUpdateMessage={shouldUpdateMessage}
                showMessageSelector={showMessageSelector}
                showScrollToBottom
                showUserAvatar={showUserAvatar}
                timeFormat={timeFormat}
                user={user}
                onLoadEarlier={onLoadEarlier}
                onLongPressAvatar={onLongPressAvatar}
                onPressAvatar={onPressAvatar}
                onSelectMessageIds={onSelectMessageIds}
              />
              {renderChatFooter?.()}
            </View>
            <View style={{flexShrink: 0, flexGrow: 0}}>
              <InputToolbar {...inputToolbarProps} />
            </View>
          </View>
        </ActionSheetProvider>
      </GiftedChatProvider>
    );
  }
);

GiftedChat.displayName = "GiftedChat";
