import {skipToken} from "@reduxjs/toolkit/query/react";
import {useGetCarePodsByIdQuery, useGetCarePodSchedulingQuery} from "@store";
import {Box, Text} from "ferns-ui";
import React from "react";

import {DataExplorer} from "./DataExplorer";
import {useExplorerContext} from "./UserExplorerContext";

export const CarePodSchedulingTable = (): React.ReactElement => {
  const {carePodId, dateFilter} = useExplorerContext();
  const {data: carePod} = useGetCarePodsByIdQuery(carePodId ?? skipToken);

  if (!carePodId) {
    return (
      <Box alignItems="center" flex="grow" paddingY={12}>
        <Text bold>Select a care pod to see scheduling data</Text>
      </Box>
    );
  }

  // Add padding to the bottom so the dropdown button doesn't get cut off.
  // Once we put this in ferns-ui, we can make the button smarter about where it puts the dropdown.
  return (
    <>
      {carePod?.states && (
        <Box paddingY={2}>
          <Text bold>{`${carePod.states.join(", ")}`}</Text>
        </Box>
      )}
      <DataExplorer
        alternateRowBackground={false}
        defaultTextSize="sm"
        filters={{
          carePodId,
          dateFilter,
        }}
        hook={useGetCarePodSchedulingQuery}
        noDataMessage="No therapist availability slots. Set availability in My Team."
        page={1}
        pinnedColumns={2}
        rowHeight={130}
      />
    </>
  );
};
