import {useGetUserStatusesQuery, UserStatus} from "@store";
import {SelectField, SelectFieldPropsWithoutRequire} from "ferns-ui";
import sortBy from "lodash/sortBy";
import React from "react";

interface UserStatusDropdownProps extends Omit<SelectFieldPropsWithoutRequire, "options"> {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  // Whether to add an option for all enrolled users.
  allEnrolled?: boolean;
}

export const UserStatusDropdown = ({
  value,
  onChange,
  allEnrolled = false,
  ...fieldProps
}: UserStatusDropdownProps): React.ReactElement => {
  const {data: userStatuses} = useGetUserStatusesQuery({});
  const options = [
    ...(sortBy(userStatuses?.data, "index") as UserStatus[]).map((us) => ({
      label: us?.name ?? "",
      value: us?._id ?? "",
    })),
  ];

  if (allEnrolled) {
    options.unshift({
      label: "All Enrolled",
      value: "allEnrolled",
    });
  }
  return (
    <SelectField
      requireValue={false}
      title="User Status"
      {...fieldProps}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
