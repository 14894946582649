import {NotificationManager, UserList} from "@components";
import {APPOINTMENT_CONFIG, NotificationsState} from "@constants";
import {useReadProfile} from "@hooks";
import {StaffStackScreenProps} from "@types";
import {
  Accordion,
  Box,
  Button,
  DateTimeField,
  Field,
  Heading,
  isMobileDevice,
  printDateAndTime,
  TapToEdit,
  Text,
  useToast,
} from "ferns-ui";
import {DateTime} from "luxon";
import React, {FC, useCallback, useMemo} from "react";

import {
  generateScheduleItemTitle,
  getStaffRole,
  ScheduleItemType,
  usePostScheduleItemsMutation,
  User,
} from "../store";
import {hasFeatureFlag, isFamilyGuide, isPatientGuide, isTherapist} from "../utils";

interface CreateScheduleItemScreenProps extends StaffStackScreenProps<"CreateScheduleItem"> {}

type UserInfo = {
  data: User[];
  ids: string[];
  idsMapped: {
    userId: string;
  }[];
};

type StaffInfo = UserInfo & {
  apptTitle: string;
};

const ERROR = {
  missingGuides: "*Please select at least one guide.",
  missingTherapists: "*Please select a therapist.",
  missingUsers: "*Please select at least one user.",
  pastStart: "*Start time cannot be in the past.",
  missingLocation:
    "*Missing location. Please ensure you have a patient guide with their video link in their profile in the appointment or manually add the location you want to use for both appointments.",
};

interface ClinicalIntakeManualCreationFormProps {
  location?: string;
  isZoomMeeting: boolean;
  setIsZoomMeeting: (isZoomMeeting: boolean) => void;
  setLocation: (location: string) => void;
  setStaffIds: (ids: string[]) => void;
  setStaffNotes: (notes: string) => void;
  setStartDatetime: (r: string) => void;
  setUserIds: (ids: string[]) => void;
  setUserNotes: (notes: string) => void;
  staffData: User[];
  staffNotes: string;
  startDatetime: string;
  userNotes: string;
  usersData: User[];
  nav: CreateScheduleItemScreenProps["navigation"];
}

export const ClinicalIntakeManualCreationForm: FC<ClinicalIntakeManualCreationFormProps> = ({
  location,
  setLocation,
  isZoomMeeting,
  setIsZoomMeeting,
  setStaffIds,
  setStaffNotes,
  setStartDatetime,
  setUserIds,
  setUserNotes,
  staffData,
  staffNotes,
  startDatetime,
  userNotes,
  usersData,
  nav,
}) => {
  const toast = useToast();
  const authUser = useReadProfile();
  const [createScheduleItem] = usePostScheduleItemsMutation();
  const [GuideIntake, TherapyIntake]: ScheduleItemType[] = [
    "Guide Clinical Intake",
    "Therapy Clinical Intake",
  ];
  const defaultStartDatetime = DateTime.now().plus({days: 1}).set({hour: 12, minute: 0, second: 0});
  const endTime = DateTime.fromISO(startDatetime ?? defaultStartDatetime.toISO())
    .plus({hours: 2})
    .toISO() as string;
  const notifications: NotificationsState = APPOINTMENT_CONFIG[GuideIntake]!.reminders;

  const nonStaffUsers: UserInfo = useMemo(() => {
    const nonStaffUsersInfo: UserInfo = {
      data: [],
      ids: [],
      idsMapped: [],
    };
    usersData?.forEach((user: any) => {
      nonStaffUsersInfo.data.push(user);
      nonStaffUsersInfo.ids.push(user._id);
      nonStaffUsersInfo.idsMapped.push({userId: user._id});
    });
    return nonStaffUsersInfo;
  }, [usersData]);

  const {
    guides,
    therapists,
  }: {
    guides: StaffInfo;
    therapists: StaffInfo;
  } = useMemo(() => {
    const guidesInfo: StaffInfo = {
      data: [],
      ids: [],
      idsMapped: [],
      apptTitle: "",
    };
    const therapistsInfo: StaffInfo = {
      data: [],
      ids: [],
      idsMapped: [],
      apptTitle: "",
    };
    staffData?.forEach((staff: any) => {
      // sort data quickly to avoid many unnecessary re-renders and loops
      const roles = getStaffRole(staff);
      if (roles?.includes("PatientGuide") || roles?.includes("FamilyGuide")) {
        guidesInfo.data.push(staff);
        guidesInfo.ids.push(staff._id);
        guidesInfo.idsMapped.push({userId: staff._id});
      } else if (roles?.includes("Therapist")) {
        therapistsInfo.data.push(staff);
        therapistsInfo.ids.push(staff._id);
        therapistsInfo.idsMapped.push({userId: staff._id});
      }
    });
    // generate titles
    if (guidesInfo.data.length && nonStaffUsers.data.length) {
      const patientGuide = guidesInfo.data.filter((staff) =>
        getStaffRole(staff)?.includes("PatientGuide")
      );
      guidesInfo.apptTitle = generateScheduleItemTitle(
        GuideIntake,
        nonStaffUsers.data,
        patientGuide.length ? patientGuide : guidesInfo.data, // prioritize Patient Guide if available
        "NoRepeat"
      );
    }
    if (therapistsInfo.data.length && nonStaffUsers.data.length) {
      therapistsInfo.apptTitle = generateScheduleItemTitle(
        TherapyIntake,
        nonStaffUsers.data,
        therapistsInfo.data,
        "NoRepeat"
      );
    }

    return {guides: guidesInfo, therapists: therapistsInfo};
  }, [GuideIntake, TherapyIntake, nonStaffUsers.data, staffData]);

  const handleTherapistChange = useCallback(
    (newTherapistIds: User[]): void => {
      const updatedIds: string[] = newTherapistIds.map((user) => user._id);
      updatedIds.push(...guides.ids);
      setStaffIds(updatedIds);
    },
    [guides.ids, setStaffIds]
  );

  const handleGuideChange = useCallback(
    (newGuideIds: User[]): void => {
      const updatedIds: string[] = newGuideIds.map((user) => user._id);
      updatedIds.push(...therapists.ids);
      setStaffIds(updatedIds);
    },
    [therapists.ids, setStaffIds]
  );

  const saveError = useMemo(() => {
    const e: string[] = [];
    if (!guides.ids.length) e.push(ERROR.missingGuides);
    if (!therapists.ids.length) e.push(ERROR.missingTherapists);
    if (!nonStaffUsers.ids.length) e.push(ERROR.missingUsers);
    if (Boolean(!location)) e.push(ERROR.missingLocation);
    if (DateTime.fromISO(startDatetime).diffNow("minutes").minutes < 0) e.push(ERROR.pastStart);
    return e;
  }, [guides.ids, therapists.ids, nonStaffUsers.ids, location, startDatetime]);

  const onSave = useCallback(async () => {
    const errors: any[] = [];
    const commonBody = {
      location: location?.trim(),
      isZoomMeeting,
      staffNotes,
      userNotes,
      users: nonStaffUsers.idsMapped as any,
    };

    const guideEndTime = DateTime.fromISO(startDatetime)
      .plus({minutes: APPOINTMENT_CONFIG[GuideIntake]!.duration})
      .toISO() as string;

    try {
      await createScheduleItem({
        ...commonBody,
        type: GuideIntake,
        title: guides.apptTitle,
        startDatetime,
        endDatetime: guideEndTime,
        staff: guides.idsMapped as any,
        notifications,
      }).unwrap();
    } catch (error: any) {
      errors.push(error);
    }

    // Therapy intake starts 50 minutes after the guide intake
    const therapyStart = DateTime.fromISO(startDatetime).plus({minutes: 50}).toISO() as string;
    const therapyEndTime = DateTime.fromISO(therapyStart)
      .plus({minutes: APPOINTMENT_CONFIG[TherapyIntake]!.duration})
      .toISO() as string;

    try {
      await createScheduleItem({
        ...commonBody,
        type: TherapyIntake,
        title: therapists.apptTitle,
        startDatetime: therapyStart,
        endDatetime: therapyEndTime,
        staff: therapists.idsMapped as any,
      }).unwrap();
    } catch (error: any) {
      errors.push(error);
    }

    // attempt to create both schedule items
    // google calendars may error but we want schedule items to be created
    if (errors.length) {
      toast.error(
        `Error scheduling intake: ${errors.map((error) => error.data?.title ?? "Unknown").join(", ")}`
      );
      return;
    } else {
      toast.show(`Intake scheduled successfully`);
    }
    // The component can be rendered from IntakeSchedulingScreen or User Clinical Tab
    // If rendered from User Clinical Tab, we need to pop 2 screens
    const maxPops = 2;
    let count = 0;
    while (nav.canGoBack() && count < maxPops) {
      nav.pop();
      count++;
    }
  }, [
    location,
    isZoomMeeting,
    staffNotes,
    userNotes,
    nonStaffUsers.idsMapped,
    startDatetime,
    GuideIntake,
    TherapyIntake,
    nav,
    createScheduleItem,
    guides.apptTitle,
    guides.idsMapped,
    notifications,
    therapists.apptTitle,
    therapists.idsMapped,
    toast,
  ]);

  return (
    <Box>
      <Box marginTop={3}>
        <Heading>
          *Two appointments will be created: one for Guides and one for the Therapist.
        </Heading>
      </Box>

      <Box direction="column" gap={2} maxWidth={450} paddingY={2} width="100%">
        <DateTimeField
          title="Start Time"
          type="datetime"
          value={startDatetime}
          onChange={(r?: string): void => {
            if (!r) {
              return;
            }
            setStartDatetime(r);
          }}
        />

        <Heading>End Time</Heading>
        <Box>
          <Text size="md">{printDateAndTime(endTime, {showTimezone: false})}</Text>
        </Box>
      </Box>

      <Box gap={4} maxWidth={isMobileDevice() ? "100%" : "50%"}>
        <UserList
          familyMember
          patient
          title="Users"
          userIds={nonStaffUsers.ids}
          onChangeUsers={(users) => setUserIds(users.map((user) => user._id))}
        />
        <UserList
          staff
          title="Guides"
          userFilter={(user) => isPatientGuide(user) || isFamilyGuide(user)}
          userIds={guides.ids}
          onChangeUsers={handleGuideChange}
        />
        <UserList
          staff
          title="Therapist"
          userFilter={(user) => isTherapist(user)}
          userIds={therapists.ids}
          onChangeUsers={handleTherapistChange}
        />
      </Box>
      <Box marginTop={2} maxWidth={isMobileDevice() ? "100%" : "50%"} padding={1}>
        {authUser && hasFeatureFlag(authUser, "zoom") && (
          <Box paddingY={2}>
            <Field
              title="Zoom Meeting"
              type="boolean"
              value={isZoomMeeting}
              onChange={setIsZoomMeeting}
            />
          </Box>
        )}
        {!isZoomMeeting && (
          <Box paddingY={4}>
            <TapToEdit
              helperText="Video Link for the meeting."
              setValue={setLocation}
              title="Location"
              type="text"
              value={location}
              onSave={setLocation}
            />
          </Box>
        )}
        <TapToEdit
          helperText="These will show up in the user's app"
          setValue={setUserNotes}
          title="User-Facing Notes"
          type="textarea"
          value={userNotes}
          onSave={setUserNotes}
        />
        <TapToEdit
          helperText="Internal notes about the meeting. These will not show up in the user's app."
          setValue={setStaffNotes}
          title="Staff-Facing Notes"
          type="textarea"
          value={staffNotes}
          onSave={setStaffNotes}
        />
        <Box>
          <Accordion isCollapsed title="Notifications">
            <Text italic>*You cannot edit for Clinical Intakes</Text>
            <Box paddingY={2}>
              <NotificationManager
                appointmentType="Guide Clinical Intake"
                attendeeIds={nonStaffUsers.ids}
                // cannot update notifications for clinical intakes
                disabled
                notifications={notifications}
                setNotifications={() => {}}
              />
            </Box>
          </Accordion>
        </Box>
        {Boolean(saveError.length) && (
          <Box marginTop={2}>
            {saveError.map((error: string, index: number) => (
              <Text key={`create-intake-error-${index}`} color="error">
                {error}
              </Text>
            ))}
          </Box>
        )}
        <Box paddingY={4}>
          <Button
            disabled={Boolean(saveError.length)}
            text="Schedule Clinical Intake"
            onClick={onSave}
          />
        </Box>
      </Box>
    </Box>
  );
};
