import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {StaffStackParamList} from "@types";
import {Box, Icon, IconColor, IconName, Text, TextSize, Tooltip} from "ferns-ui";
import React from "react";

interface NameCellProps {
  userId: string;
  text: string;
  iconName?: IconName;
  iconColor?: IconColor;
  tooltip?: string;
  textSize?: TextSize;
}

export const NameCell = ({
  text,
  iconName,
  iconColor,
  tooltip,
  userId,
  textSize = "md",
}: NameCellProps): React.ReactElement => {
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  return (
    <Box
      accessibilityHint="View user profile"
      accessibilityLabel="View"
      flex="grow"
      justifyContent="center"
      onClick={(): void => {
        navigation.navigate("User", {userId});
      }}
    >
      <Tooltip idealPosition="top" text={tooltip}>
        <Box alignItems="center" direction="row" flex="grow">
          {Boolean(iconName) && (
            <Box marginRight={2}>
              <Icon color={iconColor} iconName={iconName!} size="sm" />
            </Box>
          )}
          <Text size={textSize} underline={Boolean(userId)}>
            {text}
          </Text>
        </Box>
      </Tooltip>
    </Box>
  );
};
