import {StaffStackScreenProps} from "@types";
import React, {ReactElement} from "react";

import {ManageFormView} from "../components/ManageFormView";

interface FormScreenProps extends StaffStackScreenProps<"ManageFormScreen"> {}

export const ManageFormScreen = ({route, navigation}: FormScreenProps): ReactElement => {
  const isReadOnly =
    typeof route.params?.readOnly === "boolean"
      ? Boolean(route.params?.readOnly)
      : route.params?.readOnly === "true";
  return (
    <ManageFormView
      formId={route.params.formId}
      fullScreen
      navigation={navigation}
      readOnly={isReadOnly}
    />
  );
};
