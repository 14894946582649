import {useSelectCurrentUserId} from "@ferns-rtk";
import {GetTodoByIdRes, LimitedUser, useGetTodoQuery, useGetUsersQuery} from "@store";
import {pageOnError, UserTypes} from "@utils";
import {Box, Button, Heading, Page, SelectField, Text} from "ferns-ui";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import React, {ReactElement, useEffect, useState} from "react";

import {sortToDoList, ToDoEditor, ToDoItem} from "../components/ToDoList";

const generateToDoQuery = (assignedUser?: string, referencedUser?: string): object => {
  const query: {assignedUser?: string; referencedUser?: string} = {};
  if (assignedUser && assignedUser !== "All") {
    query.assignedUser = assignedUser;
  }
  // we only want referencedUser in the query if there is no assignedUser selected
  // otherwise, we won't know what options to present the user in the 'Referenced User' filter
  if (referencedUser && !assignedUser && referencedUser !== "All") {
    query.referencedUser = referencedUser;
  }
  return query;
};

const ToDoGroup = ({
  userGroup,
}: {
  userGroup: {user: LimitedUser; items: GetTodoByIdRes[]};
}): ReactElement => {
  const [showEditor, setShowEditor] = useState(false);
  const [toDoItemId, setToDoItemId] = useState<string>();
  return (
    <Box marginBottom={2}>
      <Box marginBottom={4}>
        <Text bold size="lg">
          {userGroup.user.name}
        </Text>
      </Box>
      <Box marginLeft={4}>
        {userGroup.items.map((tdItem) => (
          <ToDoItem
            key={tdItem._id}
            item={tdItem}
            onEdit={(): void => {
              setToDoItemId(tdItem._id);
              setShowEditor(true);
            }}
          />
        ))}
      </Box>
      {showEditor ? (
        <Box marginTop={4}>
          <ToDoEditor
            setShowEditor={setShowEditor}
            toDoItemId={toDoItemId}
            userId={userGroup.user._id}
          />
        </Box>
      ) : (
        <Box direction="row" paddingY={3}>
          <Button
            iconName="plus"
            text="Add"
            variant="secondary"
            onClick={(): void => {
              setToDoItemId(undefined);
              setShowEditor(true);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
export const ToDoListExplorerScreen = ({navigation}: any): ReactElement => {
  const currentUserId = useSelectCurrentUserId();
  const [selectedAssignedUser, setSelectedAssignedUser] = useState<string | undefined>(
    currentUserId
  );
  const [selectedReferencedUser, setSelectedReferencedUser] = useState<string | undefined>("All");

  const {data: toDoListData} = useGetTodoQuery(
    generateToDoQuery(selectedAssignedUser, selectedReferencedUser)
  );

  // get options for Staff
  const {data: staffUserData} = useGetUsersQuery({type: UserTypes.Staff, disabled: false, page: 1});
  const staffUserListOptions = React.useMemo(() => {
    return [
      {label: "All", value: "All"},
      ...Array.from(
        uniqBy(staffUserData?.data ?? [], "_id").map((u) => {
          return {label: u.name, value: u._id};
        }) ?? []
      ),
    ];
  }, [staffUserData]);

  // set the assigned user to the current user if it is not set already
  useEffect(() => {
    if (currentUserId && !selectedAssignedUser) {
      setSelectedAssignedUser(currentUserId);
    }
  }, [selectedAssignedUser, currentUserId]);

  interface GroupedToDos {
    user: LimitedUser;
    items: GetTodoByIdRes[];
  }

  // get options for referenced users (mostly patients,
  // but could be staff) that have To Dos assigned to them

  const {groupedToDos, referencedUserOptions} = React.useMemo(() => {
    const referencedUsers = toDoListData?.data?.map((toDoItem) => toDoItem.referencedUser) ?? [];
    const uniqueReferencedUsers = uniqBy(referencedUsers, "_id");

    const referenced = !isEmpty(uniqueReferencedUsers)
      ? [
          {label: "All", value: "All"},
          ...uniqueReferencedUsers.map((user) => ({
            label: user?.name ?? "Loading...",
            value: user?._id,
          })),
        ]
      : [];
    const toDoListDataItems: GetTodoByIdRes[] = toDoListData?.data ?? [];
    const grouped: GroupedToDos[] = toDoListDataItems
      ? uniqBy(
          toDoListDataItems.map((toDoItem) => ({
            user: toDoItem.referencedUser,
            items: sortToDoList(
              toDoListDataItems.filter(
                (item) => item.referencedUser?._id === toDoItem.referencedUser?._id
              )
            ),
          })),
          "user._id"
        ).filter(
          (group) => group.user._id === selectedReferencedUser || selectedReferencedUser === "All"
        )
      : [];
    return {groupedToDos: grouped, referencedUserOptions: referenced};
  }, [toDoListData, selectedReferencedUser]);

  return (
    <Page navigation={navigation} scroll onError={pageOnError}>
      <Box paddingY={2}>
        <Heading>To Do List Explorer</Heading>
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <Heading size="sm">Filters</Heading>
        <Box direction="row" justifyContent="between" width="100%">
          <Box padding={4} width="50%">
            <SelectField
              options={staffUserListOptions}
              requireValue={false}
              title="Assigned User"
              value={selectedAssignedUser}
              onChange={setSelectedAssignedUser}
            />
          </Box>
          <Box padding={4} width="50%">
            <SelectField
              options={referencedUserOptions}
              requireValue={false}
              title="Referenced User"
              value={selectedReferencedUser}
              onChange={setSelectedReferencedUser}
            />
          </Box>
        </Box>
      </Box>
      <Box paddingY={1}>
        <Text>
          Here you can see all To Do lists across all patients. You can filter by assigned staff
          member, or view all staff member To Dos combined.
        </Text>
      </Box>
      <Box marginTop={5}>
        {groupedToDos.map((group) => (
          <ToDoGroup key={group.user._id} userGroup={group} />
        ))}
      </Box>
    </Page>
  );
};
