type AlertDocumentationContent = {
  title: string;
  text: string;
};

type AlertDocumentationContents = {
  alertRecipients: AlertDocumentationContent;
  triggeringEvents: AlertDocumentationContent;
  centerVsBanner: AlertDocumentationContent;
  textMergeFields: AlertDocumentationContent;
  notifications: AlertDocumentationContent;
  alertDrivers: AlertDocumentationContent;
};

export const AlertDocumentationContents: AlertDocumentationContents = {
  alertRecipients: {
    title: "Alert Recipients",
    text: [
      "Alerts are specifically designed for staff members. When an alert is triggered, a single alertInstance is created. This alertInstance can then be viewed by multiple staff members based on their roles and responsibilities within the system. The system identifies the relevant staff members who need to see the alertInstance, depending on their assigned roles and the specific requirements of the alert. The alertInstance can be viewed by staff members based on their roles, their role in a patient’s care team, or if they are directly assigned to the alertInstance.",
      "",
      "**Software Engineers** - Views all alertInstances.",
      "",
      "**Self** -This role applies to alerts that are assigned specifically to individual users, rather than based on their general role within the organization. When an alert is tagged with Self, it means that the alertInstance is personalized and directly associated with the actions or responsibilities of a particular user.",
      "For example, a user might be assigned an alertInstance to complete a task, review a form, or follow up on a patient. The alertInstance will only appear in that specific user's alert center and notification system. This allows for task-specific or event-specific alerts to be handled by the person directly responsible for the task.",
      "The Self designation ensures that alertInstances reach the exact person accountable for the alert-triggering event, bypassing general role-based notifications and making it clear who needs to take action.",
      "",
      "**Patient Guide, Family Guide, Therapist, & Psychiatrist** - Views  alertInstances if they hold the relevant role and are part of the patient’s care team in that role. \nRoles that are not part of the patient’s care team, they will see alerts that are either directly assigned to them or assigned to their non-care team role type.",
    ].join("\n"),
  },
  triggeringEvents: {
    title: "Triggering Events",
    text: [
      "A **Triggering Event** is a specific condition, action, or piece of missing information within the system that signals the need for an Alert Instance creation. Triggering Events define the exact circumstances under which an Alert should be created. They act as the system's criteria for identifying when staff need to be informed of critical updates, potential issues, or necessary actions. This relationship easily allows multiple different circumstances to be customized, ensuring that notifications are generated only for relevant conditions. Staff receive timely, targeted alerts tailored to specific actions, updates, or needs, helping them stay informed and respond effectively within the system.",
      "",
      "#### Supported Triggering Events",
      "**Assign Todo:** ",
      "Triggered when a todo is created and marked 'Assign as User Alert'.",
      "**Attendance Status:** ",
      "Triggered when a user's attendance to an event is updated, such as missed appointments.",
      "**Created Form Instance:** ",
      "Triggered when a specific form instance is created.",
      "**Missing Family Unit:** ",
      "Triggered when a patient is not associated with a family unit.",
      "**Missing Patient Information:** ",
      "Triggered when essential patient information is missing. Supported fields include: Address, Phone Number, Email, Billing Information, Care Pod, Care Team, and Preferred Pharmacy.",
      "**Note Has Risk Language:** ",
      "Triggered when a note contains risk-related language.",
      "**Reminders:** ",
      "Triggered when a reminder is set relative to a specific date like enrolledDate.",
      "**Unsigned Critical Event Form Instance:** ",
      "Triggered when a critical event form instance remains unsigned.",
      "**Unsigned Form Instance Following An Appointment:**",
      "Triggered when a form instance remains unsigned after a scheduled item appointment. The alert can also include a due date, determining when the form must be signed.",
    ].join("\n"),
  },
  centerVsBanner: {
    title: "Alert Center vs Alert Banner",
    text: [
      "The Alert Center is a dedicated area in the system where staff members can view and manage alerts. It is designed to provide a comprehensive view of based on their roles and the specific requirements of the alert. and allows staff members to take action on alerts, such as resolving or archiving them.",
      "",
      "An Alert Banner will appear above the conversation when the selected patient has an open unresolved and not archived alert. is to ensure that staff are aware the patient has an alert when they are communicating with them or bring attention to appropriate party members. The Alert Banner will disappear when the alert is resolved or archived.",
    ].join("\n"),
  },
  textMergeFields: {
    title: "Text Merge Fields And Definitions",
    text: [
      "Text Merge Fields are used to dynamically insert specific information into the alert text. These fields act as placeholders that are replaced with actual data when an alert is triggered, allowing for customized and informative messages based on the specific situation.",
      "",
      "#### Supported Text Merge Fields",
      "**${missingPatientInfoOptions}** ",
      "Replaced with a list of missing patient information options when the triggering event is Missing Patient Information.",
      "Supported fields are: Address, Phone Number, Email, Billing Information, Care Pod, Care Team, and Preferred Pharmacy.",
      "",
      "**${todoListItemTitle}** ",
      "Replaced with the title of the to-do item when the alert is triggered by an assigned to-do.",
      "",
      "**${todoListItemCreator}** ",
      "Replaced with the name of the user who created the to-do item.",
      "",
      "**${missingSignatures}** ",
      "Replaced with the list of individuals whose signatures are missing for a critical event form.",
      "",
      "**${flaggedRiskLanguage}** ",
      "Replaced with flagged risk language identified in a form instance that triggered the alert instance creation.",
      "",
      "**${formInstanceLink}** ",
      "Replaced with a link to the form instance that triggered the alert instance creation.",
      "",
      "",
      "**${patientLink}** ",
      "Replaced with a link to the patient's profile in the system.",
    ].join("\n"),
  },
  notifications: {
    title: "Notifications - SMS vs Push",
    text: [
      "**SMS Notifications:** ",
      "SMS notifications are sent when sendNotificationsToRecipients is set to true and a notificationMessage is provided. The message sent to recipients is the content of the notificationMessage field. This allows important alerts to reach users via text message when explicitly configured.",
      "SMS notifications are never sent for test users.",
      "",
      "**Push Notifications:** ",
      "Push notifications are automatically generated for all new alert instances with the message 'New User Alert'. These notifications are controlled by the user's personal notification settings in the system and do not require any additional configuration on the alert. Push notifications are used to ensure staff are notified of new alerts in real-time through the app, without requiring specific setup for each alert.",
      "",
    ].join("\n"),
  },
  alertDrivers: {
    title: "Alert Drivers: Event-Driven vs. Cron-Driven",
    text: [
      "Alert drivers determine when and how alerts are triggered in the system. There are two main types of alert drivers: **event-driven** and **cron-driven**.",
      "",
      "### Event-Driven Alerts",
      "Event-driven alerts are triggered immediately after a specific action or event occurs in the system. This type of alert allows the system to notify staff in real-time when certain actions take place. For example:",
      "- **Assign Todo**: An alert is triggered as soon as a to-do item is created and marked 'Assign as User Alert.' This ensures that the assigned user is notified about the new task right away.",
      "- **Attendance Status**: An alert is triggered when a user’s attendance status changes, such as when a patient misses an appointment. Staff are immediately alerted to follow up on attendance-related issues.",
      "- **Created Form Instance**: An alert is triggered when a specific form is created, allowing staff to take timely action based on form submissions.",
      "",
      "",
      "### Cron-Driven Alerts",
      "Cron-driven alerts are checked at scheduled intervals (e.g., once a day) to see if any conditions are met to trigger an alert. These types of alerts are useful for ongoing checks or reminders about tasks and information. For example:",
      "- **Missing Patient Information**: The system checks for patients with missing essential information, such as contact details or care team assignments. If information is missing, an alert is generated for staff to review and address.",
      "- **Reminders**: Alerts are set to remind staff or users of an action or due date relative to a specific date, like a patient’s enrollment date.",
      "- **Unsigned Critical Event Form**: The system checks if there are any critical event forms that remain unsigned. If so, an alert is triggered to prompt staff to review and complete the form.",
      "- **Unsigned Form Instance Following An Appointment**: The system checks for any form instances that remain unsigned after a scheduled appointment. If any are found, an alert is generated to remind staff to follow up.",
      "",
      "Understanding the difference between these alert drivers helps clarify when staff will be notified: either immediately after an action occurs or periodically based on scheduled checks. Event-driven alerts provide instant feedback, while cron-driven alerts are well-suited for ongoing reminders and daily checks.",
    ].join("\n"),
  },
};
