import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  StaffHomeScreenViewProps,
  useCollapseAllRightBar,
  useGetUsersByIdQuery,
  usePatchUsersByIdMutation,
  userIsLocked,
} from "@store";
import {hasFeatureFlag, isPatient, isStaff} from "@utils";
import {Box, Button, Text} from "ferns-ui";
import React from "react";

import {AssessmentHistoryLineChart} from "./AssessmentHistoryLineChart";
import {ClinicalInfo} from "./ClinicalInfo";
import {ClinicalInfoSelectField} from "./ClinicalInfoSelectField";
import {CurrentPatientWeeklyUpdates} from "./CurrentPatientWeeklyUpdates";
import {FamilyUnitPane} from "./FamilyUnitPane";
import {HealthEvents} from "./HealthEvents";
import {OpenNotesPane} from "./OpenNotesPane";
import {PatientDiagnosis} from "./PatientDiagnosis";
import {PatientEngagementStats} from "./PatientEngagementStats";
import {PatientSafetyConcerns} from "./PatientSafetyConcerns";
import {Prescriptions} from "./Prescriptions";
import {PreviousPatientWeeklyUpdates} from "./PreviousPatientWeeklyUpdates";
import {UserErrorCard} from "./UserErrorCard";
import {UserInfoCard} from "./UserInfoCard";
import {UserSelectField} from "./UserSelectField";
import {UserTextField} from "./UserTextField";

export const ClinicalView = ({userId}: StaffHomeScreenViewProps): React.ReactElement | null => {
  const authUser = useReadProfile();
  const {data: user} = useGetUsersByIdQuery(userId ?? skipToken);
  const [updateUser, {isLoading}] = usePatchUsersByIdMutation();
  const collapseAll = useCollapseAllRightBar();
  if (!user) {
    return null;
  }

  function renderLockout(): React.ReactElement | null {
    if (!user || !userIsLocked(user)) {
      return null;
    } else {
      return (
        <UserInfoCard title="User is locked">
          <Box paddingY={4}>
            <Text>The user tried an incorrect password {user.attempts} times.</Text>
          </Box>
          <Box paddingY={4}>
            <Button
              disabled={isLoading}
              loading={isLoading}
              text="Unlock User"
              onClick={async (): Promise<void> => {
                await updateUser({id: user._id, body: {attempts: 0}});
              }}
            />
          </Box>
        </UserInfoCard>
      );
    }
  }

  return (
    <>
      {renderLockout()}
      <UserErrorCard user={user} />
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="oneLiner"
        title="One Liner"
      >
        <UserTextField field="oneLiner" userId={user._id} />
      </UserInfoCard>
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="clinicalStatus"
        title="Clinical Status"
      >
        <ClinicalInfoSelectField field="clinicalStatus" showUpdateDate userId={user._id} />
      </UserInfoCard>

      <UserInfoCard collapsable collapseExternal={collapseAll} title="Assessment Score History">
        <AssessmentHistoryLineChart assessmentType="PHQ-9" userId={user._id} />
        <AssessmentHistoryLineChart assessmentType="GAD-7" userId={user._id} />
        <AssessmentHistoryLineChart assessmentType="CSSRS Screen" userId={user._id} />
      </UserInfoCard>

      <UserInfoCard collapsable collapseExternal={collapseAll} title="Therapy Cadence">
        <ClinicalInfoSelectField field="therapyCadence" showUpdateDate userId={user._id} />
      </UserInfoCard>

      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="diagnosis"
        title="Current Diagnosis"
      >
        <PatientDiagnosis userId={user._id} />
      </UserInfoCard>
      {!isStaff(user.type) && authUser && authUser.staffRoles.Psychiatrist && (
        <UserInfoCard collapsable collapseExternal={collapseAll} title="Prescriptions">
          <Prescriptions userId={user._id} />
        </UserInfoCard>
      )}
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="safetyConcerns"
        title="Safety Concerns"
      >
        <PatientSafetyConcerns userId={user._id} />
      </UserInfoCard>
      {Boolean(authUser) &&
        hasFeatureFlag(authUser!, "enablePatientTreatmentManualModuleIndicator") &&
        isPatient(user.type) && (
          <UserInfoCard
            collapsable
            collapseExternal={collapseAll}
            title="Patient Treatment Manual Module"
          >
            <UserSelectField field="patientTreatmentManualModule" userId={user._id} />
          </UserInfoCard>
        )}
      {Boolean(authUser) &&
        hasFeatureFlag(authUser!, "enableExternalizingBehaviorsTreatmentManualModuleIndicator") &&
        isPatient(user.type) && (
          <UserInfoCard
            collapsable
            collapseExternal={collapseAll}
            title="Externalizing Behaviors Treatment Manual Module"
          >
            <UserSelectField
              field="externalizingBehaviorsTreatmentManualModule"
              userId={user._id}
            />
          </UserInfoCard>
        )}
      {isPatient(user.type) && (
        <UserInfoCard collapsable collapseExternal={collapseAll} title="Patient Engagement Stats">
          <PatientEngagementStats userId={user._id} />
        </UserInfoCard>
      )}
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="weeklyUpdates"
        title="Current Weekly Updates"
      >
        <CurrentPatientWeeklyUpdates userId={user._id} />
      </UserInfoCard>
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="weeklyUpdates"
        initialCollapsed
        title="Previous Weekly Updates"
      >
        <PreviousPatientWeeklyUpdates userId={user._id} />
      </UserInfoCard>
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="engagementDetails"
        title="Engagement Details"
      >
        <UserTextField field="engagementDetails" userId={user._id} />
      </UserInfoCard>
      <UserInfoCard collapsable collapseExternal={collapseAll} title="Health Events">
        <HealthEvents userId={user._id} />
      </UserInfoCard>
      <UserInfoCard collapsable collapseExternal={collapseAll} initialCollapsed title="Open Notes">
        <OpenNotesPane userId={user._id} />
      </UserInfoCard>
      <ClinicalInfo collapseAll={collapseAll} userId={user._id} />
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        subtitle="Displayed in Patient App"
        title="Family Unit"
      >
        <FamilyUnitPane userId={user._id} />
      </UserInfoCard>
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="carePlan"
        subtitle="Displayed in Patient App"
        title="Care Plan"
      >
        <UserTextField field="carePlan" userId={user._id} />
      </UserInfoCard>
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="safetyPlan"
        subtitle="Displayed in Patient App"
        title="Safety Plan"
      >
        <UserTextField field="safetyPlan" userId={user._id} />
      </UserInfoCard>
      {Boolean(authUser) && !isStaff(user.type) && !authUser!.staffRoles.Psychiatrist && (
        <UserInfoCard collapsable collapseExternal={collapseAll} title="Prescriptions">
          <Prescriptions userId={user._id} />
        </UserInfoCard>
      )}
    </>
  );
};
