import {EngagementData} from "@store";
import {printDate} from "ferns-ui";
import React from "react";

import {TextCell} from "./TextCell";
import {useExplorerContext, UserExplorerColumn} from "./UserExplorerContext";

const DATE_WIDTH = 140;

const EngagementDataCell = ({
  userId,
  field,
  isDate,
}: {
  userId: string;
  field: keyof EngagementData;
  isDate?: boolean;
}): React.ReactElement => {
  const {engagement} = useExplorerContext();
  const data = engagement?.data?.find((error) => error.userId === userId)?.[field];
  if (isDate) {
    return <TextCell cellData={{value: data ? printDate(data as string) : "-"}} />;
  } else {
    return <TextCell cellData={{value: String(data ?? "-")}} />;
  }
};

const CaregiverCell = ({userId}: {userId: string}): React.ReactElement => {
  const {engagement} = useExplorerContext();
  const name = engagement?.data?.find((error) => error.userId === userId)?.primaryCaregiverName;
  return <TextCell cellData={{value: name ?? "No Caregiver"}} />;
};

export const patientAttendedColumn: UserExplorerColumn = {
  title: "Patient Appointments Attended",
  width: DATE_WIDTH,
  Component: (user) => <EngagementDataCell field="patientAttended" userId={user._id} />,
};

export const familyAttendedColumn: UserExplorerColumn = {
  title: "Family Appointments Attended",
  width: DATE_WIDTH,
  Component: (user) => <EngagementDataCell field="familyAttended" userId={user._id} />,
};

export const patientAttendedNonClinicalColumn: UserExplorerColumn = {
  title: "Patient Attended (Guide)",
  width: DATE_WIDTH,
  Component: (user) => <EngagementDataCell field="patientAttendedNonClinical" userId={user._id} />,
};

export const familyAttendedNonClinicalColumn: UserExplorerColumn = {
  title: "Family Attended (Guide)",
  width: DATE_WIDTH,
  Component: (user) => <EngagementDataCell field="familyAttendedNonClinical" userId={user._id} />,
};

export const patientOpenNotesEngagement: UserExplorerColumn = {
  title: "Patient Open Notes Engagement",
  width: DATE_WIDTH,
  Component: (user) => <EngagementDataCell field="patientAttendedNonClinical" userId={user._id} />,
};

export const familyOpenNotesEngagement: UserExplorerColumn = {
  title: "Family Open Notes Engagement",
  width: DATE_WIDTH,
  Component: (user) => <EngagementDataCell field="familyAttendedNonClinical" userId={user._id} />,
};

export const engagementDaysColumn: UserExplorerColumn = {
  title: "Patient Engagement Days",
  width: DATE_WIDTH,
  Component: (user) => <EngagementDataCell field="engagementDays" userId={user._id} />,
};

export const patientMessagesColumn: UserExplorerColumn = {
  title: "Patient Messages Sent",
  width: DATE_WIDTH,
  Component: (user) => <EngagementDataCell field="patientMessages" userId={user._id} />,
};

export const familyMessagesColumn: UserExplorerColumn = {
  title: "Family Messages Sent",
  width: DATE_WIDTH,
  Component: (user) => <EngagementDataCell field="familyMessages" userId={user._id} />,
};

export const engagementMonthStartColumn: UserExplorerColumn = {
  title: "Engagement Month Start",
  width: DATE_WIDTH,
  Component: (user) => (
    <EngagementDataCell field="selectedEngagementMonthStart" isDate userId={user._id} />
  ),
};

export const engagementMonthEndColumn: UserExplorerColumn = {
  title: "Engagement Month End",
  width: DATE_WIDTH,
  Component: (user) => (
    <EngagementDataCell field="selectedEngagementMonthEnd" isDate userId={user._id} />
  ),
};

export const engagementPrimaryCaregiverColumn: UserExplorerColumn = {
  title: "Primary Caregiver",
  width: DATE_WIDTH,
  Component: (user) => <CaregiverCell userId={user._id} />,
};
