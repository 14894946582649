import {UserList} from "@components";
import {useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  LimitedUser,
  roundToHour,
  ScheduleItem,
  ScheduleItemAttendee,
  useGetCarePodsQuery,
  useGetScheduleItemsQuery,
} from "@store";
import {StaffStackParamList, StaffTabScreenProps} from "@types";
import {IsMobileDevice, IsWeb, UserTypes} from "@utils";
import {
  BooleanField,
  Box,
  Button,
  FernsTheme,
  FieldOption,
  Heading,
  Icon,
  IconButton,
  MultiselectField,
  printTime,
  ScrollView,
  SegmentedControl,
  Text,
  useTheme,
} from "ferns-ui";
import indexOf from "lodash/indexOf";
import {DateTime} from "luxon";
import React, {ReactElement, useEffect, useMemo, useState} from "react";
import {Dimensions, Pressable, RecursiveArray, ViewStyle} from "react-native";
import {
  Calendar,
  EventRenderer,
  formatStartEnd,
  ICalendarEventBase,
  Mode,
} from "react-native-big-calendar";

import {APPOINTMENT_CONFIG, AppointmentConfig, SchedulingColors} from "../constants";

type EventTypes = AppointmentConfig["group"] | "Vacation" | "CompanyHolidays";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const EVENT_TYPE_OPTIONS: {label: string; value: EventTypes}[] = [
  {label: "Therapy", value: "Therapy"},
  {label: "Psychiatry", value: "Psychiatry"},
  {label: "Guide", value: "Guide"},
  {label: "Enrollment", value: "Enrollment"},
  {label: "Vacations", value: "Vacation"},
  {label: "Holidays", value: "CompanyHolidays"},
];

export const CONTROL_HEIGHT = 100;

interface ControlProps {
  onNext: () => void;
  onPrev: () => void;
  onToday: () => void;
  mode: Mode;
  setMode: (mode: Mode) => void;
}

// TODO: Update this screen for mobile. Month view does not work on calendar
const calendarModeOpts = IsMobileDevice ? ["Week", "Day"] : ["Month", "Week", "Day"];

const Control = ({onNext, onPrev, onToday, mode, setMode}: ControlProps): ReactElement => {
  return (
    <Box direction={IsMobileDevice ? "column" : "row"} justifyContent="between">
      <Box maxWidth={IsMobileDevice ? "100%" : 300} paddingY={2} width="100%">
        <SegmentedControl
          items={calendarModeOpts}
          selectedIndex={indexOf(
            calendarModeOpts.map((v) => v.toLowerCase()),
            mode
          )}
          size={IsMobileDevice ? "md" : "lg"}
          onChange={(activeIndex): void => {
            setMode(calendarModeOpts[activeIndex].toLowerCase() as Mode);
          }}
        />
      </Box>
      <Box
        alignContent={IsMobileDevice ? "center" : "end"}
        alignItems="center"
        direction="row"
        gap={4}
        justifyContent={IsMobileDevice ? "center" : "end"}
        paddingY={2}
      >
        <IconButton
          accessibilityLabel="Previous month"
          iconName="angle-left"
          variant="muted"
          onClick={onPrev}
        />
        <Pressable onPress={onToday}>
          <Heading color="link" size="sm">
            Today
          </Heading>
        </Pressable>
        <IconButton
          accessibilityLabel="Next month"
          iconName="angle-right"
          variant="muted"
          onClick={onNext}
        />
      </Box>
    </Box>
  );
};

interface StaffSchedulingScreenProps extends StaffTabScreenProps<"Scheduling"> {}

export interface ScheduleItemEvent extends ICalendarEventBase {
  itemType: "Appointment" | "Vacation" | "CompanyHoliday";
  color?: string;
  patients: ScheduleItemAttendee[];
  staff: ScheduleItemAttendee[];
  isRecurring: boolean;
  _id: string;
}

export const customEventRenderer: EventRenderer<ScheduleItemEvent> = (event, pressableProps) => {
  let title: string;
  if (event.itemType === "Appointment") {
    title = `${event.patients
      .map((m) => m.userId?.name.split(" ")[0])
      .join(", ")} <> ${event.staff.map((s) => s.userId?.name.split(" ")[0])}`;
  } else if (event.itemType === "Vacation") {
    title = `${event.staff[0]?.userId?.name} PTO - ${event.title}`;
  } else {
    title = event.title;
  }
  const {key, ...rest} = pressableProps;
  return (
    <Pressable
      key={key}
      {...rest}
      style={[
        ...(pressableProps.style as RecursiveArray<ViewStyle>),
        {
          backgroundColor: "white",
          borderWidth: 1,
          borderColor: "lightgrey",
          borderLeftColor: event.color ? event.color : "yellow",
          borderLeftWidth: 10,
          borderStyle: "solid",
          borderRadius: 6,
        },
      ]}
    >
      {DateTime.fromJSDate(event.end).diff(DateTime.fromJSDate(event.start), "minute").minutes <
      32 ? (
        <Box direction="row" justifyContent="between" maxWidth="100%">
          <Text>
            {event.title}, {printTime(event.start.toISOString())}
          </Text>
          {event.isRecurring && <Icon iconName="arrows-spin" size="sm" />}
        </Box>
      ) : (
        <Box>
          <Box direction="row" justifyContent="between" maxWidth="100%">
            <Box maxWidth="90%">
              <Text>{title}</Text>
            </Box>
            {event.isRecurring && <Icon iconName="arrows-spin" size="sm" />}
          </Box>
          <Text size="sm">{formatStartEnd(event.start, event.end, "h:mm a")}</Text>
          {event.children && event.children}
        </Box>
      )}
    </Pressable>
  );
};

const getColor = (scheduleItem: ScheduleItem, theme: FernsTheme): string => {
  if (scheduleItem.itemType === "Vacation") {
    return SchedulingColors.gray;
  } else if (scheduleItem.itemType === "CompanyHoliday") {
    return theme.text.primary;
  }

  if (!scheduleItem.type || !APPOINTMENT_CONFIG[scheduleItem.type]) {
    return theme.surface.error;
  }

  const config = APPOINTMENT_CONFIG[scheduleItem.type];
  if (config?.group === "Therapy") {
    return theme.surface.success;
  } else if (config?.group === "Psychiatry") {
    return SchedulingColors.blue;
  } else if (config?.group === "Guide") {
    return SchedulingColors.purple;
  } else if (config?.group === "Enrollment") {
    return SchedulingColors.orange;
  } else {
    return theme.surface.error;
  }
};

// If mode is month, should pass goingToNextMonth so we can accurately calculate the day to skip
// to next/prev month.
const modeToNum = (mode: Mode, current?: Date, goingToNextMonth = false): number => {
  if (mode === "month") {
    if (!current) {
      throw new Error("You must specify current date if mode is month");
    }
    const d = current ? DateTime.fromJSDate(current) : DateTime.now();

    if (goingToNextMonth) {
      return d.plus({month: 1}).day ?? 1;
    } else {
      return d.minus({month: 1}).day ?? 1;
    }
  }
  switch (mode) {
    case "day":
      return 1;
    case "3days":
      return 3;
    case "week":
    case "custom":
      return 7;
    default:
      throw new Error("undefined mode");
  }
};

interface CalendarEvent {
  _id: string;
  title: string;
  start: Date;
  end: Date;
  patients: ScheduleItemAttendee[];
  staff: ScheduleItemAttendee[];
  isRecurring: boolean;
  color: string;
  itemType: "Appointment" | "Vacation" | "CompanyHoliday";
}

interface CalendarViewProps {
  events: CalendarEvent[];
  mode: "day" | "week" | "month";
  setMode: (mode: "day" | "week" | "month") => void;
  date: Date;
  setDate: (date: Date) => void;
}

const CalendarView = ({events, mode, setMode, date, setDate}: CalendarViewProps): ReactElement => {
  const {theme} = useTheme();
  const scheduleTheme = {
    palette: {
      primary: {
        main: theme.surface.secondaryLight,
        contrastText: theme.surface.base,
      },
      // this is for the lines on the calender grid;
      // theme.gray was too light and theme.darkGray was too dark so we went with #666 from the
      // library doc example
      gray: {
        "200": "#666",
      },
    },
  };
  const navigation =
    useNavigation<NativeStackNavigationProp<StaffStackParamList, UserTypes.Staff>>();

  // 48 for the height of the bottom bar, 64 for the height of the top bar
  const height = Dimensions.get("window").height - CONTROL_HEIGHT - 48 - 64;
  return (
    <>
      <Control
        mode={mode}
        setMode={(m): void => {
          if (m === "custom" || m === "3days") {
            console.error("Unsupported mode", m);
            return;
          }
          setMode(m as "day" | "week" | "month");
        }}
        onNext={(): void => {
          setDate(
            DateTime.fromJSDate(date)
              .plus({day: modeToNum(mode, date, true)})
              .toJSDate()
          );
        }}
        onPrev={(): void => {
          setDate(
            DateTime.fromJSDate(date)
              .minus({day: modeToNum(mode, date)})
              .toJSDate()
          );
        }}
        onToday={(): void => setDate(new Date())}
      />
      <Box alignContent="center" paddingY={3}>
        <Heading align="center">{monthNames[date.getMonth()]}</Heading>
      </Box>
      <Calendar
        ampm
        date={date}
        dayHeaderHighlightColor={theme.surface.base}
        events={events}
        height={height}
        mode={mode}
        overlapOffset={70}
        renderEvent={customEventRenderer}
        scrollOffsetMinutes={420}
        showTime
        swipeEnabled
        theme={scheduleTheme}
        onPressCell={(event): void => {
          if (event) {
            navigation.navigate("CreateScheduleItem", {
              startDatetime: roundToHour(event).toISOString(),
            });
          }
        }}
        onPressEvent={(event): void => {
          if (event._id) {
            navigation.navigate("CreateScheduleItem", {scheduleItemId: event._id});
          }
        }}
      />
    </>
  );
};

const getEventTypes = (types: EventTypes[]): string[] => {
  let eventTypesRes: string[] = [];

  // Take each of the types, and add the appointment types for that group from the
  // APPOINTMENT_CONFIG
  for (const type of types) {
    if (["Therapy", "Psychiatry", "Guide", "Enrollment"].includes(type)) {
      // Find any appointment type where group matches type
      const appointmentType = Object.keys(APPOINTMENT_CONFIG).filter(
        (key): key is keyof typeof APPOINTMENT_CONFIG =>
          APPOINTMENT_CONFIG[key as keyof typeof APPOINTMENT_CONFIG]?.group === type
      );
      eventTypesRes = [...eventTypesRes, ...appointmentType];
    }
  }
  return eventTypesRes;
};

export const StaffSchedulingScreen = ({navigation}: StaffSchedulingScreenProps): ReactElement => {
  const profile = useReadProfile();
  const {data: carePods} = useGetCarePodsQuery({});
  const {theme} = useTheme();
  const [showTestUsers, setShowTestUsers] = useState(false);
  const [date, setDate] = useState<Date>(new Date());
  const [filteredStaff, setFilteredStaff] = useState<LimitedUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<LimitedUser[]>([]);
  const [filterEventTypes, setFilterEventTypes] = useState<EventTypes[]>([
    "Psychiatry",
    "Therapy",
    "Guide",
    "Enrollment",
    "Vacation",
    "CompanyHolidays",
  ]);
  const [filteredCarePods, setFilteredCarePods] = useState<string[]>([]);
  const [mode, setMode] = useState<"day" | "week" | "month">(IsMobileDevice ? "day" : "week");

  const startDate = DateTime.fromJSDate(date)
    .setZone("local")
    .startOf(mode)
    .set({weekday: mode === "week" ? 7 : undefined}) // Set to Sunday if week mode
    .minus({days: mode === "week" ? 7 : 0}) // Subtract 7 days to start on Sunday
    .toISO();
  const endDate = DateTime.fromJSDate(date)
    .setZone("local")
    .endOf(mode)
    .set({weekday: mode === "week" ? 6 : undefined}) // Set to Saturday if week mode
    .toISO();

  const eventTypes = getEventTypes(filterEventTypes);

  const {data: scheduleItemsData} = useGetScheduleItemsQuery(
    eventTypes.length
      ? {
          startDatetime: {$gte: startDate as string, $lte: endDate as string},
          type: {$in: eventTypes} as any,
          "staff.userId": filteredStaff.length ? {$in: filteredStaff.map((u) => u._id)} : undefined,
          "users.userId": filteredUsers.length ? {$in: filteredUsers.map((u) => u._id)} : undefined,
          carePodIds: filteredCarePods.length ? filteredCarePods : undefined,
        }
      : skipToken
  );

  const {data: vacationData} = useGetScheduleItemsQuery(
    filterEventTypes.includes("Vacation")
      ? {
          startDatetime: {$gte: startDate as string, $lte: endDate as string},
          itemType: "Vacation",
          "staff.userId": filteredStaff.length ? {$in: filteredStaff.map((u) => u._id)} : undefined,
        }
      : skipToken
  );

  const {data: companyHolidayData} = useGetScheduleItemsQuery(
    filterEventTypes.includes("CompanyHolidays")
      ? {
          startDatetime: {$gte: startDate as string, $lte: endDate as string},
          itemType: "CompanyHoliday",
        }
      : skipToken
  );

  // Filter out test users (there's not a good way to do this in the schedule item query),
  // then transform the data to the format the calendar is expecting.
  const events = useMemo<CalendarEvent[]>((): CalendarEvent[] => {
    const eventsRes = [];
    let scheduleItems = eventTypes?.length ? (scheduleItemsData?.data ?? []) : [];
    if (!showTestUsers) {
      scheduleItems = scheduleItems.filter((s) => {
        if (!s.users) {
          return true;
        }
        return !s.users.some((u) => {
          if (u.userId) {
            return u.userId.testUser;
          } else {
            return true;
          }
        });
      });
    }
    const allEvents = [
      ...scheduleItems,
      ...(filterEventTypes.includes("Vacation") ? (vacationData?.data ?? []) : []),
      ...(filterEventTypes.includes("CompanyHolidays") ? (companyHolidayData?.data ?? []) : []),
    ];
    for (const scheduleItem of allEvents) {
      eventsRes.push({
        _id: scheduleItem._id,
        title: scheduleItem.title ?? "Unknown",
        start: new Date(scheduleItem.startDatetime),
        end: new Date(scheduleItem.endDatetime),
        patients: scheduleItem.users,
        staff: scheduleItem.staff,
        isRecurring: Boolean(scheduleItem.recurringId),
        itemType: scheduleItem.itemType,
        color: getColor(scheduleItem, theme),
      });
    }
    return eventsRes;
  }, [
    companyHolidayData?.data,
    eventTypes,
    filterEventTypes,
    scheduleItemsData?.data,
    showTestUsers,
    theme,
    vacationData?.data,
  ]);

  const carePodOptions: FieldOption[] = useMemo(() => {
    return carePods?.data?.map((c) => ({label: c.name, value: c._id})) ?? [];
  }, [carePods?.data]);

  const windowDimensions = Dimensions.get("window");
  const [dimensions, setDimensions] = useState({
    height: windowDimensions.height,
    width: windowDimensions.width,
  });

  // Update the dimensions when the screen is rotated
  useEffect(() => {
    const subscription = Dimensions.addEventListener("change", ({window}) => {
      setDimensions({height: window.height, width: window.width});
    });
    return (): void => subscription?.remove();
  });

  return (
    <Box direction="row" flex="grow" height="100%" width="100%">
      {Boolean(dimensions?.width && dimensions.width > 1100) && (
        <ScrollView
          contentContainerStyle={{
            width: 300,
            padding: theme.spacing.lg,
            backgroundColor: theme.surface.neutralLight,
          }}
          style={{flexGrow: 0, height: "100%"}}
        >
          {Boolean(profile && IsWeb) && (
            <Box paddingY={2}>
              <Button
                iconName="plus"
                text="Schedule Intake"
                onClick={() => {
                  navigation.navigate("IntakeScheduling", {});
                }}
              />
            </Box>
          )}
          <Box>
            <Box borderBottom="default" marginBottom={2}>
              <Box paddingX={4} paddingY={4}>
                <MultiselectField
                  options={EVENT_TYPE_OPTIONS}
                  title="Event Type"
                  value={filterEventTypes}
                  onChange={(types) => setFilterEventTypes(types as EventTypes[])}
                />
              </Box>
            </Box>
            {Boolean(carePodOptions && carePodOptions.length > 0) && (
              <Box borderBottom="default" marginBottom={2}>
                <Box paddingX={4} paddingY={4}>
                  <MultiselectField
                    options={carePodOptions}
                    title="Care Pod"
                    value={filteredCarePods}
                    onChange={setFilteredCarePods}
                  />
                </Box>
              </Box>
            )}
            <Box paddingX={4} paddingY={4}>
              <Box paddingY={4}>
                <UserList
                  buttonText="Add Staff"
                  staff
                  title="Staff"
                  userIds={filteredStaff?.map((u) => u._id)}
                  userPickerTitle="Filter By Staff"
                  onChangeUsers={setFilteredStaff}
                />
              </Box>
            </Box>
            <Box paddingX={4} paddingY={4}>
              <Box paddingY={4}>
                <UserList
                  familyMember
                  patient
                  showTypes
                  title="User"
                  userIds={filteredUsers?.map((u) => u._id)}
                  userPickerTitle="Filter By User"
                  onChangeUsers={setFilteredUsers}
                />
              </Box>
            </Box>
            <Box paddingY={4}>
              <BooleanField
                title="Show Test Users"
                value={showTestUsers}
                onChange={setShowTestUsers}
              />
            </Box>
          </Box>
        </ScrollView>
      )}
      <Box color="base" flex="grow" height="100%" paddingX={3} scroll>
        {/* Width should only be 70% if the screen is wide enough to display the staff side bar */}
        {/* Take 140 pixels out due to padding, header, and bottom tab bar so we don't end up with a third scroll bar on the page */}
        <CalendarView date={date} events={events} mode={mode} setDate={setDate} setMode={setMode} />
      </Box>
    </Box>
  );
};
