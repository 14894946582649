import {SectionDivider} from "@components";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  PtoCoverageConfig,
  ScheduleItem,
  useGetPtoCoverageConfigsQuery,
  useGetScheduleItemsByIdQuery,
  useGetUsersByIdQuery,
  useGetUsersQuery,
  useGetUserStatusesQuery,
  usePatchPtoCoverageConfigsByIdMutation,
  usePatchScheduleItemsByIdMutation,
  usePostPtoCoverageConfigsMutation,
  usePostScheduleItemsMutation,
  User,
} from "@store";
import {StaffStackParamList, StaffStackScreenProps} from "@types";
import {
  BooleanField,
  Box,
  Button,
  DateTimeField,
  Heading,
  Page,
  SelectField,
  Text,
  TextArea,
  TextField,
  useToast,
} from "ferns-ui";
import {DateTime} from "luxon";
import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";

interface VacationFormScreenProps extends StaffStackScreenProps<"Vacation"> {}

interface CaseLoadCoveragesProps {
  caseloadUsers: User[];
  staffOnPTO: User;
  onChange: ({coveredUserId, coveringStaffId}: any) => void;
  ptoCoverageConfig?: PtoCoverageConfig;
}

const CaseLoadCoverages = ({
  caseloadUsers,
  staffOnPTO,
  onChange,
  ptoCoverageConfig,
}: CaseLoadCoveragesProps): ReactElement => {
  // Build query to find compatible covering staff
  const staffSubQuery = [];
  if (staffOnPTO.staffRoles.PatientGuide) {
    staffSubQuery.push({"staffRoles.PatientGuide": true});
  }
  if (staffOnPTO.staffRoles.FamilyGuide) {
    staffSubQuery.push({"staffRoles.FamilyGuide": true});
  }
  if (staffOnPTO.staffRoles.Therapist) {
    staffSubQuery.push({"staffRoles.Therapist": true});
  }
  if (staffOnPTO.staffRoles.Psychiatrist) {
    staffSubQuery.push({"staffRoles.Psychiatrist": true});
  }

  const staffQuery = staffSubQuery.length
    ? {testUser: false, disabled: false, $or: staffSubQuery}
    : skipToken;

  // Query users that can cover the staff on PTO based on roles
  const {data: possibleCoveringStaffRes} = useGetUsersQuery(staffQuery);
  const possibleCoveringStaffOptions = [
    ...(possibleCoveringStaffRes?.data ?? [])
      .filter((s) => s._id !== staffOnPTO._id)
      .map((s) => ({
        value: s._id,
        label: s.name,
      })),
  ];

  const caseloadCoverages = ptoCoverageConfig?.caseloadCoverages ?? [];

  // Coverages that have a staff assigned
  const assignedCoverages = caseloadCoverages.filter((c: any) =>
    caseloadUsers.find((cu) => cu._id === (c.coveredUserId._id ?? c.coveredUserId))
  );

  const unassignedUsers = caseloadUsers.filter(
    (cu) => !caseloadCoverages.find((c: any) => (c.coveredUserId._id ?? c.coveredUserId) === cu._id)
  );

  return (
    <Box>
      {Boolean(unassignedUsers.length) && (
        <>
          <Heading color="warning" size="md">
            Unassigned Users
          </Heading>
          {unassignedUsers.map((user) => (
            <Box
              key={`${user._id}-unassigned`}
              direction="row"
              justifyContent="between"
              paddingY={2}
            >
              <Box width="48%">
                <TextField disabled title="Caseload User" value={user.name} onChange={() => {}} />
              </Box>
              <Box width="48%">
                <SelectField
                  options={possibleCoveringStaffOptions}
                  title="Staff to cover User"
                  value=""
                  onChange={(value) => {
                    onChange({coveredUserId: user._id, coveringStaffId: value});
                  }}
                />
              </Box>
            </Box>
          ))}
        </>
      )}
      {Boolean(assignedCoverages.length) && (
        <>
          <Heading size="md">Assigned Users</Heading>
          {assignedCoverages.map((coverage: any) => (
            <Box
              key={`${coverage.coveredUserId._id ?? coverage.coveredUserId}-assigned`}
              direction="row"
              justifyContent="between"
              paddingY={2}
            >
              <Box width="48%">
                <TextField
                  disabled
                  title="Caseload User"
                  value={`${
                    caseloadUsers.find(
                      (u) => u._id === (coverage.coveredUserId._id ?? coverage.coveredUserId)
                    )?.name
                  }`}
                  onChange={() => {}}
                />
              </Box>
              <Box width="48%">
                <SelectField
                  options={possibleCoveringStaffOptions}
                  title="Staff to cover User"
                  value={coverage.coveringStaffId._id ?? coverage.coveringStaffId ?? ""}
                  onChange={(value) => {
                    onChange({
                      coveredUserId: coverage.coveredUserId._id ?? coverage.coveredUserId,
                      coveringStaffId: value,
                    });
                  }}
                />
              </Box>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export const VacationFormScreen = ({route}: VacationFormScreenProps): React.ReactElement | null => {
  const {scheduleItemId, staffId} = route.params;
  const toast = useToast();
  const {data: scheduleItem} = useGetScheduleItemsByIdQuery(
    scheduleItemId ? scheduleItemId : skipToken
  );
  const [vacationScheduleItem, setVacationScheduleItem] = useState<
    Partial<ScheduleItem> | undefined
  >(scheduleItem ?? undefined);
  const navigation = useNavigation<NativeStackNavigationProp<StaffStackParamList, "Vacation">>();
  const {data: staff} = useGetUsersByIdQuery(staffId);
  const timezone = staff?.timezone ?? "America/New_York";

  const {data: ptoCoverageConfigRes} = useGetPtoCoverageConfigsQuery(
    scheduleItemId
      ? {
          ptoScheduleItemId: scheduleItemId,
        }
      : skipToken
  );

  const [ptoCoverageConfig, setPtoCoverageConfig] = useState<any>(
    ptoCoverageConfigRes?.data?.[0] ?? {
      userIdOnPto: staffId,
      ptoScheduleItemId: scheduleItemId,
      caseloadCoverages: [] as any[],
    }
  );

  const {data: userStatuses} = useGetUserStatusesQuery({});

  const relevantUserStatuses = userStatuses?.data
    ?.filter((us) => ["Enrolled", "Eligible to Enroll"].includes(us.name))
    .map((status) => status._id);

  const caseloadQuery: any = {
    $or: [
      {"careTeam.PatientGuide": staffId},
      {"careTeam.FamilyGuide": staffId},
      {"careTeam.Therapist": staffId},
      {"careTeam.Psychiatrist": staffId},
    ],
    limit: 1000,
  };

  if (staff?.staffRoles.PatientGuide) {
    caseloadQuery.type = "Patient";
    caseloadQuery.statusId = {$in: relevantUserStatuses};
  } else if (staff?.staffRoles.FamilyGuide) {
    caseloadQuery.type = "FamilyMember";
  }

  // Query for users that are on the staff on PTO's caseload
  const {data: caseloadUsersRes} = useGetUsersQuery(caseloadQuery);

  const caseloadUsers = caseloadUsersRes?.data;

  const [createPTOCoverageConfig] = usePostPtoCoverageConfigsMutation();
  const [updatePTOCoverageConfig] = usePatchPtoCoverageConfigsByIdMutation();

  const [createScheduleItem] = usePostScheduleItemsMutation();
  const [updateScheduleItem] = usePatchScheduleItemsByIdMutation();

  const [startDatetime, setStartDatetime] = useState<ScheduleItem["startDatetime"]>(
    scheduleItem?.startDatetime
      ? DateTime.fromISO(scheduleItem.startDatetime).toISO()!
      : DateTime.now().plus({days: 1}).set({hour: 12, minute: 0, second: 0}).toISO()!
  );
  const [endDatetime, setEndDatetime] = useState<ScheduleItem["endDatetime"]>(
    startDatetime
      ? DateTime.fromISO(startDatetime).plus({minutes: 50}).toISO()!
      : DateTime.now().plus({days: 1}).set({hour: 13, minute: 0, second: 0}).toISO()!
  );

  const upsertScheduleItem = useCallback(
    async (startDate: string, endDate: string): Promise<ScheduleItem | void> => {
      if (!staffId) {
        return;
      }

      const body = {
        ...vacationScheduleItem,
        startDatetime: startDate,
        endDatetime: endDate,
        staff: [{userId: staffId}] as any as ScheduleItem["staff"],
        timezone,
        itemType: "Vacation" as ScheduleItem["itemType"],
      };

      let scheduleItemRes;

      try {
        if (vacationScheduleItem?._id) {
          scheduleItemRes = await updateScheduleItem({id: vacationScheduleItem._id, body}).unwrap();
        } else {
          scheduleItemRes = await createScheduleItem(body).unwrap();
        }
      } catch (error) {
        toast.catch(error);
        return;
      }
      setVacationScheduleItem(scheduleItemRes);
      return scheduleItemRes;
    },
    [createScheduleItem, toast, updateScheduleItem, staffId, vacationScheduleItem, timezone]
  );

  const upsertPTOCoverageConfig = useCallback(
    async (startDate: string, endDate: string, vacationId: string): Promise<void> => {
      if (!staffId || !vacationId) {
        return;
      }

      const body = {
        ...ptoCoverageConfig,
        endDateTime: endDate,
        startDateTime: startDate,
        ptoScheduleItemId: vacationId,
      };
      try {
        if (ptoCoverageConfig._id) {
          await updatePTOCoverageConfig({id: ptoCoverageConfig._id, body}).unwrap();
        } else {
          await createPTOCoverageConfig(body).unwrap();
        }
      } catch (error) {
        toast.catch(error);
        return;
      }
    },
    [createPTOCoverageConfig, toast, updatePTOCoverageConfig, staffId, ptoCoverageConfig]
  );

  const updateCaseloadCoverage = useCallback(
    (caseloadCoverage: any) => {
      let updatedCaseloadCoverages: any[] = ptoCoverageConfig.caseloadCoverages.filter(
        (cc: any) => (cc.coveredUserId._id ?? cc.coveredUserId) !== caseloadCoverage.coveredUserId
      );

      if (Boolean(caseloadCoverage.coveringStaffId)) {
        updatedCaseloadCoverages = [...updatedCaseloadCoverages, caseloadCoverage];
      }

      setPtoCoverageConfig({
        ...ptoCoverageConfig,
        caseloadCoverages: [...updatedCaseloadCoverages],
      });
    },
    [ptoCoverageConfig]
  );

  const doOnSave = useCallback(async (): Promise<void> => {
    const start = vacationScheduleItem?.allDay
      ? DateTime.fromISO(startDatetime, {zone: timezone}).startOf("day").toISO()!
      : startDatetime;
    const end = vacationScheduleItem?.allDay
      ? DateTime.fromISO(endDatetime, {zone: timezone}).endOf("day").toISO()!
      : endDatetime;

    try {
      const scheduleItemRes = await upsertScheduleItem(start, end);
      await upsertPTOCoverageConfig(start, end, scheduleItemRes?._id ?? "");
    } catch (error) {
      toast.catch(error);
    }
    navigation.goBack();
  }, [
    endDatetime,
    toast,
    upsertPTOCoverageConfig,
    upsertScheduleItem,
    startDatetime,
    timezone,
    navigation,
    vacationScheduleItem?.allDay,
  ]);

  // Update the ptoCoverageConfig when the query data changes
  useEffect(() => {
    setPtoCoverageConfig(
      ptoCoverageConfigRes?.data?.[0] ??
        ({
          userIdOnPto: staffId,
          ptoScheduleItemId: scheduleItemId,
          caseloadCoverages: [] as any[],
        } as PtoCoverageConfig)
    );
  }, [ptoCoverageConfigRes, staffId, scheduleItemId]);

  // Update the header title with staff name
  useEffect(() => {
    navigation.setOptions({title: `Vacation For ${staff?.name ?? "Unknown"}`});
  }, [staff?.name, navigation]);

  // Update schedule item with data from the query
  useEffect(() => {
    setVacationScheduleItem(scheduleItem);
    setStartDatetime(
      scheduleItem?.startDatetime ??
        DateTime.now().plus({days: 1}).set({hour: 12, minute: 0, second: 0}).toISO()!
    );
    setEndDatetime(
      scheduleItem?.endDatetime ??
        DateTime.now().plus({days: 1}).set({hour: 13, minute: 0, second: 0}).toISO()!
    );
  }, [scheduleItem]);

  const disableSave = useMemo((): boolean => {
    if (caseloadUsers?.length === 0) {
      return false;
    } else {
      return !Boolean(
        caseloadUsers?.length === ptoCoverageConfig.caseloadCoverages.length &&
          ptoCoverageConfig.caseloadCoverages.every((cc: any) => Boolean(cc.coveringStaffId))
      );
    }
  }, [caseloadUsers, ptoCoverageConfig]);

  if (!staff) {
    return null;
  }

  return (
    <Page navigation={navigation}>
      <Box color="base" flex="grow" gap={4} padding={4} rounding="md" width="100%">
        <TextField
          title="Title"
          value={vacationScheduleItem?.title ?? ""}
          onChange={(r: string): void => {
            setVacationScheduleItem({...vacationScheduleItem, title: r});
          }}
        />
        <BooleanField
          title="All Day"
          value={Boolean(vacationScheduleItem?.allDay)}
          onChange={(r: boolean): void => {
            setVacationScheduleItem({...vacationScheduleItem, allDay: r});
            if (r) {
              setStartDatetime(
                DateTime.fromISO(startDatetime).setZone(timezone).startOf("day").toISO()!
              );
              setEndDatetime(DateTime.fromISO(endDatetime).setZone(timezone).endOf("day").toISO()!);
            } else {
              setStartDatetime(
                DateTime.fromISO(startDatetime)
                  .setZone(timezone)
                  .set({hour: 12, minute: 0, second: 0})
                  .toISO()!
              );
              setEndDatetime(
                DateTime.fromISO(endDatetime)
                  .setZone(timezone)
                  .set({hour: 13, minute: 0, second: 0})
                  .toISO()!
              );
            }
          }}
        />
        <Box direction="row" width="100%">
          <Box flex="grow" marginRight={2} maxWidth="50%">
            <DateTimeField
              timezone={timezone}
              title="Start"
              type={vacationScheduleItem?.allDay ? "date" : "datetime"}
              value={startDatetime}
              onChange={(r: string): void => {
                if (!r) {
                  return;
                }
                if (vacationScheduleItem?.allDay) {
                  setStartDatetime(DateTime.fromISO(r).setZone(timezone).startOf("day").toISO()!);
                  setEndDatetime(
                    DateTime.fromISO(endDatetime).setZone(timezone).endOf("day").toISO()!
                  );
                } else {
                  const diff = DateTime.fromISO(endDatetime)
                    .setZone(timezone)
                    .diff(DateTime.fromISO(startDatetime).setZone(timezone), "minutes").minutes;
                  setEndDatetime(
                    DateTime.fromISO(r).setZone(timezone).plus({minutes: diff}).toISO()!
                  );
                  setStartDatetime(r);
                }
              }}
            />
          </Box>
          <Box flex="grow" maxWidth="50%">
            <DateTimeField
              timezone={timezone}
              title="End"
              type={vacationScheduleItem?.allDay ? "date" : "datetime"}
              value={endDatetime}
              onChange={(r: string): void => {
                if (!r) {
                  return;
                }
                if (vacationScheduleItem?.allDay) {
                  setEndDatetime(DateTime.fromISO(r).setZone(timezone).endOf("day").toISO()!);
                } else {
                  setEndDatetime(r);
                }
              }}
            />
          </Box>
        </Box>
        <TextArea
          helperText="Internal notes about vacation. These will not show up in patient/family members' app."
          title="Staff-Facing Notes"
          value={vacationScheduleItem?.staffNotes}
          onChange={(value: string): void => {
            setVacationScheduleItem({...vacationScheduleItem, staffNotes: value});
          }}
        />
        <SectionDivider />
        <Heading size="lg">Caseload Coverage</Heading>
        <Box flex="grow" marginBottom={4} marginRight={4} overflow="auto">
          {!Boolean(caseloadUsers?.length) ? (
            <Text>No caseload users found</Text>
          ) : (
            <CaseLoadCoverages
              caseloadUsers={caseloadUsers ?? []}
              ptoCoverageConfig={ptoCoverageConfig}
              staffOnPTO={staff}
              onChange={(value) => {
                updateCaseloadCoverage(value);
              }}
            />
          )}
        </Box>
        <Box width="30%">
          <Button disabled={disableSave} text="Save" onClick={doOnSave} />
        </Box>
      </Box>
    </Page>
  );
};
