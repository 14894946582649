import {useSelectCurrentUserId} from "@ferns-rtk";
import {useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  GetTodoByIdRes,
  LimitedUser,
  useDeleteTodoByIdMutation,
  useGetTodoByIdQuery,
  useGetTodoQuery,
  useGetUsersByIdQuery,
  usePatchTodoByIdMutation,
  usePostTodoMutation,
  User,
} from "@store";
import {StaffStackParamList} from "@types";
import {UserTypes} from "@utils";
import {
  BooleanField,
  Box,
  Button,
  CheckBox,
  DateTimeField,
  humanDate,
  Icon,
  IconButton,
  printDate,
  SelectField,
  Text,
  TextField,
} from "ferns-ui";
import uniqBy from "lodash/uniqBy";
import React, {Dispatch, ReactElement, SetStateAction, useEffect, useState} from "react";

const UserSelector = ({
  label,
  selectedUserId,
  setSelectedUserId,
}: {
  label: string;
  selectedUserId: string;
  setSelectedUserId: Dispatch<SetStateAction<string>>;
}): ReactElement => {
  const authUser = useReadProfile();
  const {data: userData} = useGetUsersByIdQuery(
    Boolean(selectedUserId) ? selectedUserId : skipToken
  );
  const navigation =
    useNavigation<NativeStackNavigationProp<StaffStackParamList, UserTypes.Staff>>();

  return (
    <Box direction="row" justifyContent="between" marginBottom={4} padding={4} width="100%">
      <Box direction="row">
        <Box direction="column">
          <Box marginBottom={2}>
            <Text bold>{label}</Text>
          </Box>
          <Box direction="row">
            <Text bold>
              {userData ? (userData._id === authUser?._id ? "Me" : userData.name) : "Not Set"}
            </Text>
            <Box
              accessibilityHint="Select a user to assign this task to"
              accessibilityLabel="Select"
              marginLeft={2}
              onClick={(): void => {
                navigation.navigate("UserPicker", {
                  staff: true,
                  onSelect: (selectedUser: User | null) => {
                    setSelectedUserId(selectedUser?._id ?? "");
                  },
                });
              }}
            >
              <Icon iconName="pencil" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const ToDoEditor = ({
  toDoItemId,
  setShowEditor,
  userId,
}: {
  toDoItemId?: string;
  setShowEditor: (showEditor: boolean) => void;
  userId: string;
}): ReactElement => {
  const currentUserId = useSelectCurrentUserId();
  const [createToDoItem] = usePostTodoMutation();
  const [updateToDoItem] = usePatchTodoByIdMutation();
  const [removeToDoItem, {isLoading: removeLoading}] = useDeleteTodoByIdMutation();
  const {data: toDoItemData} = useGetTodoByIdQuery(toDoItemId ?? skipToken);
  const [title, setTitle] = useState("");
  const [dueDate, setDueDate] = useState<string | undefined>();
  const [isUserAlert, setIsUserAlert] = useState<boolean>(false);
  const [assignedUser, setAssignedUser] = useState<string>(currentUserId ?? "");
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [completionDate, setCompletionDate] = useState<string>();

  // if we already have an existing to do item, set the values within the state here
  // using those values. otherwise, use the default values (assuming it is a new To Do Item)
  useEffect(() => {
    if (toDoItemId && toDoItemData?._id) {
      setTitle(toDoItemData.title);
      setDueDate(toDoItemData.dueDate);
      setAssignedUser(toDoItemData.assignedUser?._id ?? "");
      setIsCompleted(toDoItemData.isCompleted);
      setCompletionDate(toDoItemData.completionDate);
      setIsUserAlert(toDoItemData.createUserAlert);
    }
  }, [toDoItemData, toDoItemId]);

  const create = async (): Promise<void> => {
    await createToDoItem({
      title,
      dueDate,
      assignedUser: assignedUser as any,
      referencedUser: userId as any,
      isCompleted,
      completionDate,
      createUserAlert: isUserAlert,
    });
    setShowEditor(false);
  };

  const update = async (): Promise<void> => {
    await updateToDoItem({
      id: toDoItemId!,
      body: {
        title,
        dueDate,
        assignedUser: assignedUser as any,
        isCompleted,
        completionDate,
        createUserAlert: isUserAlert,
      },
    });
    setShowEditor(false);
  };

  const remove = async (): Promise<void> => {
    if (!toDoItemId) {
      throw new Error("toDoItemId is undefined so cannot remove");
    }
    await removeToDoItem(toDoItemId);
    setShowEditor(false);
  };

  return (
    <Box gap={4}>
      <Text bold size="lg">
        To Do Item {Boolean(toDoItemId) ? "Editor" : "Creator"}
      </Text>
      <UserSelector
        label="Assign Task To: "
        selectedUserId={assignedUser}
        setSelectedUserId={setAssignedUser}
      />
      <TextField
        helperText="Include tasks needed to complete this To Do"
        title="Description"
        type="text"
        value={title}
        onChange={(value: string): void => {
          setTitle(value);
        }}
      />
      <DateTimeField
        title="Due Date"
        type="date"
        value={dueDate}
        onChange={(value?: string): void => setDueDate(value)}
      />
      {/* TODO: Figure out disabled boolean for Assign as User Alert in ToDo */}
      <BooleanField
        title="Assign as User Alert"
        value={isUserAlert}
        onChange={(value: boolean): void => setIsUserAlert(value)}
      />
      <Box direction="row" gap={2} width="100%">
        <Button
          text={Boolean(toDoItemId) ? "Update Todo" : "Create Todo"}
          variant="secondary"
          onClick={async (): Promise<void> => {
            Boolean(toDoItemId) ? await update() : await create();
          }}
        />
        {Boolean(toDoItemId) && (
          <Button
            confirmationText="Are you sure you want to delete this To Do Item? It will not be recoverable."
            disabled={removeLoading}
            loading={removeLoading}
            text="Delete"
            variant="destructive"
            withConfirmation
            onClick={remove}
          />
        )}
        <Button
          confirmationText="Are you sure you want to close? Your changes will not be saved."
          text="Close"
          variant="muted"
          withConfirmation
          onClick={(): void => {
            setShowEditor(false);
          }}
        />
      </Box>
    </Box>
  );
};

export const ToDoItem = ({
  item,
  onEdit,
}: {
  item: GetTodoByIdRes;
  onEdit: () => void;
}): ReactElement => {
  const [updateToDoItem] = usePatchTodoByIdMutation();
  const {data: userData} = useGetUsersByIdQuery(item.assignedUser?._id ?? skipToken);
  const authUser = useReadProfile();
  if (!userData) {
    return <Box />;
  }
  return (
    <Box
      border={item.isCompleted ? "dark" : "default"}
      color="base"
      direction="row"
      marginBottom={2}
      padding={2}
      rounding="md"
    >
      <Box
        accessibilityHint="Check survey question"
        accessibilityLabel="Checkbox"
        justifyContent="center"
        paddingX={2}
        onClick={async (): Promise<void> => {
          await updateToDoItem({
            id: item._id,
            body: {
              isCompleted: !item.isCompleted,
            },
          });
        }}
      >
        <CheckBox selected={Boolean(item.isCompleted)} />
      </Box>
      <Box direction="column" flex="grow" gap={1} justifyContent="center">
        <Text color={item.isCompleted ? "secondaryLight" : "primary"}>{item.title}</Text>
        {Boolean(item.completionDate) && (
          <Text color={item.isCompleted ? "secondaryLight" : "primary"} size="sm">{`Completed ${
            item.completionDate ? humanDate(item.completionDate) : "Unknown Date"
          }`}</Text>
        )}
        {Boolean(item.assignedUser && item.assignedUser?._id !== authUser?._id) && (
          <Box direction="row">
            <Text color="secondaryLight">{`Task assigned to `}</Text>
            <Text bold color="secondaryLight">
              {userData.name === authUser?.name ? "me" : userData.name}
            </Text>
          </Box>
        )}
      </Box>
      <Box alignItems="end" justifyContent="center">
        <IconButton accessibilityLabel="edit" iconName="pencil" variant="muted" onClick={onEdit} />
        {Boolean(item.dueDate) && (
          <Box>
            <Text color={item.isCompleted ? "secondaryLight" : "primary"} size="sm">{`Due ${
              item.dueDate ? printDate(item.dueDate) : "Unknown Date"
            }`}</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const sortToDoList = (toDoListData: GetTodoByIdRes[]): GetTodoByIdRes[] => {
  return [...toDoListData].sort((a, b) => {
    if (a.isCompleted && !b.isCompleted) {
      return 1;
    }
    if (!a.isCompleted && b.isCompleted) {
      return -1;
    }
    if (!a.dueDate && !b.dueDate) {
      return 0;
    }
    if (!a.dueDate) {
      return -1;
    }
    if (!b.dueDate) {
      return 1;
    }
    return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
  });
};

export const ToDoList = ({userId}: {userId: string; collapseExternal?: boolean}): ReactElement => {
  const authUser = useReadProfile();
  const {data: toDoListData} = useGetTodoQuery(userId ? {referencedUser: userId} : skipToken);
  const [showEditor, setShowEditor] = useState(false);
  const [toDoItemId, setToDoItemId] = useState<string>();
  const [assignedUser, setAssignedUser] = useState<string>(authUser?._id ?? "");

  // set the assigned user to the current user if it is not set already
  useEffect(() => {
    if (authUser && !assignedUser) {
      setAssignedUser(authUser._id);
    }
  }, [assignedUser, authUser]);

  // take a list of todo items and return a list of users with todo items
  const assignedUsers: LimitedUser[] = uniqBy(
    (toDoListData?.data || []).map((toDoItem) => toDoItem.assignedUser),
    "_id"
  );

  const userOptions = [
    ...assignedUsers.map((user) => ({
      label: (user._id === authUser?._id ? "Me" : user?.name) ?? "Not Set",
      key: user._id,
      value: user._id,
    })),
    {label: "All", value: "All"},
  ];

  // filter by the selected user or All if selected
  const filteredToDoListData = [...(toDoListData?.data || [])].filter((toDoItem) => {
    if (assignedUser === "All") {
      return true;
    }
    return toDoItem.assignedUser._id === assignedUser;
  });

  const sortedToDoListData = sortToDoList(filteredToDoListData);

  return (
    <Box direction="column">
      {showEditor ? (
        <Box paddingY={4}>
          <ToDoEditor setShowEditor={setShowEditor} toDoItemId={toDoItemId} userId={userId} />
        </Box>
      ) : (
        <>
          <Box borderBottom="default" direction="row" marginBottom={4} paddingY={4}>
            <Button
              iconName="plus"
              text="Create To Do"
              variant="secondary"
              onClick={(): void => {
                setToDoItemId(undefined);
                setShowEditor(true);
              }}
            />
          </Box>
          <SelectField
            options={userOptions}
            requireValue
            title="View to do's assigned to:"
            value={assignedUser === authUser?._id ? "me" : assignedUser}
            onChange={(v: string): void => {
              setAssignedUser(v);
            }}
          />
          <Box gap={4} marginTop={4}>
            {sortedToDoListData.map((tdItem) => (
              <ToDoItem
                key={tdItem._id}
                item={tdItem}
                onEdit={(): void => {
                  setToDoItemId(tdItem._id);
                  setShowEditor(true);
                }}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};
