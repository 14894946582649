import {useFormInstancePatch} from "@hooks";
import {useFormInstanceIsReadOnly, useLocalFormInstance} from "@store";
import {Box, DateTimeField} from "ferns-ui";
import React, {FC, useCallback} from "react";

interface AttendanceStatusDropdownProps {
  formInstanceId: string;
}

export const ServiceDateTimeField: FC<AttendanceStatusDropdownProps> = ({formInstanceId}) => {
  const formInstance = useLocalFormInstance(formInstanceId);
  const readOnly = useFormInstanceIsReadOnly(formInstanceId);
  const {updateFormInstance} = useFormInstancePatch(formInstanceId, readOnly);
  const isDisabled = readOnly || Boolean(formInstance?.scheduleItemId);

  const handleServiceDateChange = useCallback(
    async (dateString: string): Promise<void> => {
      if (!formInstanceId || isDisabled) return;
      await updateFormInstance(
        {
          serviceDate: dateString,
        },
        "Error saving update to Date of Service"
      );
    },
    [formInstanceId, updateFormInstance, isDisabled]
  );

  if (!formInstance) {
    return null;
  }

  return (
    <Box gap={2} width="100%">
      <Box flex="grow" marginRight={2} maxWidth="50%">
        <DateTimeField
          disabled={isDisabled}
          helperText={`${
            Boolean(formInstance?.scheduleItemId)
              ? "Date of service is automatically set based on the appointment date."
              : ""
          }`}
          title="Date Of Service"
          type="datetime"
          value={formInstance?.serviceDate}
          onChange={handleServiceDateChange}
        />
      </Box>
    </Box>
  );
};
