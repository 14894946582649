import {PatientProfile, StaffProfile} from "@components";
import {useReadProfile} from "@hooks";
import {StaffTabScreenProps} from "@types";
import {isStaff} from "@utils";
import {useToast} from "ferns-ui";
import React, {ReactElement, useEffect} from "react";

interface ProfileProps extends StaffTabScreenProps<"Profile"> {}

const StatusMessages: Record<string, string> = {
  "zoom-email-mismatch":
    "The email associated with your account does not match the email provided by Zoom. Please check that your Flourish app email matches your Zoom account.",
  "zoom-error": "An unknown error occurred while connecting to Zoom. Please try again.",
  "zoom-token-save-error": "Failed to save your Zoom connection information. Please try again.",
  "zoom-success": "Zoom successfully connected!",
};

export const ProfilePage = ({route, navigation}: ProfileProps): ReactElement | null => {
  const user = useReadProfile();
  const toast = useToast();

  // Display any status messages provided via route params
  useEffect(() => {
    if (route.params?.statusMessage) {
      toast.info(StatusMessages[route.params.statusMessage] ?? route.params.statusMessage);
      // Clear the status message
      navigation.setParams({statusMessage: undefined});
    }
  }, [navigation, route.params?.statusMessage, toast]);

  if (!user) {
    return null;
  }

  return isStaff(user.type) ? <StaffProfile /> : <PatientProfile />;
};
