// A generic component for displaying a table of data.
// The intent is to open source this as part of ferns-api and ferns-ui to help build out an admin
// interface for data.
import {useIsFocused} from "@react-navigation/native";
import {Box, DataTable, DataTableCellData, DataTableColumn, Spinner, Text} from "ferns-ui";
import React, {useEffect} from "react";

import {CheckedCell} from "./CheckedCell";
import {DataExplorerTextAreaCell} from "./explorer/DataExplorerTextAreaCell";
import {NameCell} from "./NameCell";
import {SchedulingSlotCell} from "./SchedulingSlotCell";
import {TextCell} from "./TextCell";
import {UserStatusBadge} from "./UserStatusBadge";

interface DataExplorerProps {
  // When the row is expanded, the drawerContents are shown. If drawerContents is not provided,
  // no expandable arrow is shown.
  moreContentComponent?: React.FC<{column: DataTableColumn; rowData: any[]; rowIndex: number}>;

  // A hook that returns the data for the table. We take a hook instead of the data so the table
  // can apply sorting and filtering.
  hook: any;

  // Optional prop for pagination
  page?: number;

  // How many columns to pin, defaults to 1. Pass 0 to disable.
  pinnedColumns?: number;

  // Extra props for the hook
  filters?: Record<string, string | number | boolean | undefined>;

  // Optional prop for row height, defaults to 54
  rowHeight?: number;

  // Optional prop for default text size, defaults to "md"
  defaultTextSize?: "sm" | "md" | "lg";

  // Optional prop for alternate row background, defaults to true
  alternateRowBackground?: boolean;

  // Optional prop for no data message, defaults to "No data found"
  noDataMessage?: string;
}

const UserStatusCell: React.FC<{cellData: DataTableCellData}> = ({cellData}) => {
  const {value} = cellData ?? {};
  if (!value) {
    return <Box flex="grow" justifyContent="center" />;
  }
  return (
    <Box flex="grow" justifyContent="center">
      <UserStatusBadge statusId={value} />
    </Box>
  );
};

// This is a wrapper around the NameCell component that allows us to pass in a value object with
// text and userId.
const DataExplorerNameCell: React.FC<{
  cellData: {value: string; userId?: string; textSize?: "sm" | "md" | "lg"};
}> = ({cellData}) => {
  const {value, userId, textSize} = cellData ?? {};
  if (!userId) {
    return null;
  }
  return <NameCell text={value} textSize={textSize} userId={userId} />;
};

const COLUMN_MAP: Record<string, React.FC<{cellData: DataTableCellData}>> = {
  userStatus: UserStatusCell,
  name: DataExplorerNameCell,
  textarea: DataExplorerTextAreaCell,
  date: TextCell,
  text: TextCell,
  number: TextCell,
  email: TextCell,
  boolean: CheckedCell,
  schedulingSlot: SchedulingSlotCell,
};

export const DataExplorer: React.FC<DataExplorerProps> = ({
  // Rename because React gets confused by lowercase components.
  moreContentComponent: ModalContent,
  hook,
  filters,
  rowHeight,
  page = 1,
  pinnedColumns = 1,
  defaultTextSize = "md",
  alternateRowBackground = true,
  noDataMessage = "No data found",
}) => {
  const {
    data: explorerDataResult,
    isFetching,
    refetch,
  } = hook(
    {
      page,
      ...filters,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );
  const {columns, rowData: explorerData} = explorerDataResult?.data ?? {};

  const isFocused = useIsFocused();
  // When screen comes into focus, refetch the data
  useEffect(() => {
    if (isFocused) {
      refetch();
    }
  }, [isFocused, refetch]);

  if (columns === undefined || explorerData === undefined || isFetching) {
    return <Spinner />;
  }

  if (explorerData.length === 0) {
    return (
      <Box alignItems="center" paddingY={8}>
        <Text>{noDataMessage ?? "No data found"}</Text>
      </Box>
    );
  }

  const data = explorerData.map((row: any) => row.slice(1, -1));
  // Get the last column, which is the expandable row
  const moreData = explorerData.map((row: any) => row[row.length - 1]);

  return (
    <DataTable
      alternateRowBackground={alternateRowBackground}
      columns={columns}
      customColumnComponentMap={COLUMN_MAP}
      data={data}
      defaultTextSize={defaultTextSize}
      moreContentComponent={ModalContent}
      moreContentExtraData={moreData}
      page={page}
      pinnedColumns={pinnedColumns}
      rowHeight={rowHeight}
    />
  );
};
