import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  flourishApi,
  StaffHomeScreenViewProps,
  useAppDispatch,
  useCollapseAllRightBar,
  useDeleteUsersByIdMutation,
  useGetUsersByIdQuery,
  usePatchUsersByIdMutation,
} from "@store";
import {isSuperUser} from "@utils";
import {Box, Button, Text} from "ferns-ui";
import React from "react";

import {UserInfo} from "./UserInfo";
import {UserInfoCard} from "./UserInfoCard";

interface UserInfoViewProps extends StaffHomeScreenViewProps {
  onRemoveUser: () => Promise<void> | void;
}

export const UserInfoView = ({
  userId,
  onRemoveUser,
}: UserInfoViewProps): React.ReactElement | null => {
  const dispatch = useAppDispatch();
  const [removeUser, {isLoading}] = useDeleteUsersByIdMutation();
  const [updateUser] = usePatchUsersByIdMutation();
  const collapseExternal = useCollapseAllRightBar();

  const {data: user} = useGetUsersByIdQuery(userId ?? skipToken);
  const profile = useReadProfile();

  if (!user) {
    return null;
  }

  return (
    <>
      <UserInfo key={user._id} userId={user._id} />
      {Boolean(profile && isSuperUser(profile)) && (
        <UserInfoCard collapsable collapseExternal={collapseExternal} title="Archive/Delete User">
          <Box paddingY={2}>
            <Text>
              Archiving a user will hide them from the UI. This is useful for users or staff who
              have been in the system but are no longer active. Deleting a user will permanently
              remove them from the system, such as users that were accidentally created.
            </Text>
          </Box>
          <Box paddingY={2}>
            <Text>
              Make sure to cancel any scheduled items for the patient (so they are not notified).
            </Text>
          </Box>
          <Box paddingY={2}>
            <Text>
              <Text bold color="error">
                Warning&#58; {/* eslint-disable-next-line react-native/no-raw-text */}
              </Text>
              To undo this, you will need to message in the #product-questions channel to have an
              engineer undo it.
            </Text>
          </Box>
          <Box direction="row" gap={2} justifyContent="center" paddingY={2} wrap>
            <Button
              confirmationText={
                `Disabling a user will immediately log them out and prevent them from logging in. ` +
                `You'll need to contact an engineer to undo this.`
              }
              disabled={isLoading}
              loading={isLoading}
              modalTitle={`Are you sure you want to disable ${user.name}?`}
              text="Disable User"
              variant="secondary"
              withConfirmation
              onClick={async (): Promise<void> => {
                await updateUser({id: user._id, body: {disabled: true}});
                dispatch(flourishApi.util.invalidateTags(["workflowmappings"]));
                await onRemoveUser();
              }}
            />

            <Button
              confirmationText={
                `To un-archive the user, you will need to message in the #product-questions channel to have` +
                ` an engineer un-archive the user.`
              }
              disabled={isLoading}
              loading={isLoading}
              modalTitle={`Are you sure you want to archive ${user.name}?`}
              text="Archive User"
              variant="secondary"
              withConfirmation
              onClick={async (): Promise<void> => {
                await updateUser({id: user._id, body: {archived: true}});
                dispatch(flourishApi.util.invalidateTags(["workflowmappings"]));
                await onRemoveUser();
              }}
            />
            <Button
              confirmationText={
                `To undelete the user, you will need to message in the #product channel to have` +
                ` an engineer undelete the user.`
              }
              disabled={isLoading}
              loading={isLoading}
              modalTitle={`Are you sure you want to delete ${user.name}?`}
              text="Delete User"
              variant="destructive"
              withConfirmation
              onClick={async (): Promise<void> => {
                await removeUser(user._id);
                // forces a refresh of the workflow mappings
                dispatch(flourishApi.util.invalidateTags(["workflowmappings"]));
                await onRemoveUser();
              }}
            />
          </Box>
        </UserInfoCard>
      )}
    </>
  );
};
