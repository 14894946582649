/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import {
  AlertsView,
  createInactivityBottomTabNavigator,
  HeaderRightButtons,
  PatientBrowserChecker,
  SpamDetectionToast,
  StaffBrowserChecker,
  StillThereModal,
  UserUpdatesView,
  VoicemailsView,
} from "@components";
import {FontAwesome6} from "@expo/vector-icons";
import {useAnalytics, useReadProfile} from "@hooks";
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import {
  DefaultTheme,
  NavigationContainer,
  useNavigationContainerRef,
} from "@react-navigation/native";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  AlertsAdminScreen,
  AlertsExplorerScreen,
  BillingInfoScreen,
  CarePlanScreen,
  CarePodAdminScreen,
  CarePodQAScreen,
  ChatScreen,
  ColorThemesScreen,
  CompanyOrganizationAdminScreen,
  ConsentFormScreen,
  ContactScreen,
  ConversationScreen,
  CreateFamilyUnitScreen,
  CreateFormScreen,
  CreateIntakeScreen,
  CreateScheduleItemScreen,
  CreateStaffScreen,
  CreateUserScreen,
  DialerScreen,
  DoseSpotScreen,
  DotPhraseAdminScreen,
  ExternalClinicianAdminScreen,
  FormAdminPage,
  HealthItemAdminScreen,
  HipaaPolicyScreen,
  InsurancePlanAdminScreen,
  InternalChatScreen,
  LogInScreen,
  ManageConversationsScreen,
  ManageFormScreen,
  MessageExplorerScreen,
  MyTeamScreen,
  NotificationsSettingsScreen,
  OnCallScheduleScreen,
  PatientResetPasswordScreen,
  PharmacyScreen,
  PreClinicUpdateScreen,
  PrivacyPolicyScreen,
  ProfilePage,
  ReferralMethodAdminScreen,
  ReferralSourceAdminScreen,
  ScheduleScreen,
  StaffResetPasswordScreen,
  StaffSchedulingScreen,
  StaffScreen,
  StaffToolsPage,
  StatsScreenWeb,
  ToDoListExplorerScreen,
  UserBioScreen,
  UserExplorerScreen,
  UserPicker,
  UserScreen,
  UserStatusAdminScreen,
  VacationFormScreen,
  VideoCallScreen,
  ViewFormScreen,
} from "@screens";
import {
  getBrowserTabTitle,
  setUserSettings,
  useAppDispatch,
  useGetAvatarsQuery,
  useGetConversationsQuery,
  useGetPreClinicUpdatesQuery,
  useGetUserSettingsByIdQuery,
  useSelectShowSideDrawer,
  useSelectSideDrawerComponentName,
  useSetShowSideDrawer,
} from "@store";
import {
  AuthStackParamList,
  ConsentStackParamList,
  PatientStackParamList,
  PatientTabParamList,
  StaffStackParamList,
  StaffTabParamList,
} from "@types";
import {getMissingProfileFields, IsMobileDevice, IsWeb, reactNavigationIntegration} from "@utils";
import {
  Badge,
  BadgeProps,
  Box,
  IconName,
  isMobileDevice,
  mediaQuerySmallerThan,
  SideDrawer,
  Text,
  useTheme,
} from "ferns-ui";
import * as React from "react";
import {ReactElement, useEffect, useRef} from "react";
import Favicon from "react-favicon";
import {ColorSchemeName, Platform, StatusBar} from "react-native";

import {GPTScreen} from "../screens/GPTScreen";
import {GuidingHoursScreen} from "../screens/GuidingHoursScreen";
import {IntakeSchedulingScreen} from "../screens/IntakeSchedulingScreen";
import {LinkingConfiguration} from "./LinkingConfiguration";

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    // neutral200
    background: IsMobileDevice ? "#FFFFFF" : "#D9D9D9",
  },
};

interface SideDrawerComponentMap {
  UserUpdatesView: typeof UserUpdatesView;
  AlertsView: typeof AlertsView;
  VoicemailsView: typeof VoicemailsView;
}

const SideDrawerComponents: SideDrawerComponentMap = {
  UserUpdatesView,
  AlertsView,
  VoicemailsView,
};

const isComponentMapKey = (key: string): key is keyof SideDrawerComponentMap => {
  return key in SideDrawerComponents;
};

const favicon = require("../assets/images/favicon.png");

// The NavContainer wraps any of the navigators we use. It provides support for tracking, error
// tracking, deep linking, and theming. It also ensures the StatusBar is present.
const NavContainer = ({
  children,
  // colorScheme,
}: {
  children: ReactElement;
  colorScheme: ColorSchemeName;
}): ReactElement => {
  const navigationRef = useNavigationContainerRef();
  const routeNameRef: any = useRef();
  const logEvent = useAnalytics();

  // const {theme, setTheme} = useContext(ThemeContext);
  // const user = useReadProfile();
  // const userTheme = user?.settings?.colorTheme;
  // const [loaded, setLoaded] = useState<boolean>(false);

  // Only set theme once, when the userTheme is loaded and has not already been set
  // React.useEffect(() => {
  //   if (userTheme && !loaded) {
  //     ({primary, secondary, accent} = ThemeColors[userTheme as keyof typeof ThemeColors]);
  //     setLoaded(true);
  //     setTheme({...theme, primary, secondary, accent});
  //   }
  //   // disable exhaustive deps to prevent infinite loop every time setTheme is called
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userTheme]);

  return (
    <>
      <NavigationContainer
        ref={navigationRef}
        linking={LinkingConfiguration}
        theme={MyTheme}
        onReady={(): void => {
          // Register the navigationRef container with the instrumentation
          reactNavigationIntegration?.registerNavigationContainer(navigationRef);
          routeNameRef.current = navigationRef.getCurrentRoute()?.name;
        }}
        onStateChange={async (): Promise<void> => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName = navigationRef.getCurrentRoute()?.name;
          if (previousRouteName !== currentRouteName) {
            await logEvent({name: "screen_view", currentRouteName});
          }

          // Save the current route name for later comparison
          routeNameRef.current = currentRouteName;
        }}
      >
        {children}
      </NavigationContainer>
      <StatusBar />
    </>
  );
};

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const AuthStack = createNativeStackNavigator<AuthStackParamList>();

export const AuthNavigation = ({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}): React.ReactElement => {
  return (
    <NavContainer colorScheme={colorScheme}>
      <AuthStack.Navigator>
        <AuthStack.Group screenOptions={{presentation: "modal"}}>
          <AuthStack.Screen
            component={LogInScreen}
            name="LogIn"
            options={{title: "Flourish Health", headerShown: false}}
          />
          <AuthStack.Screen
            component={VideoCallScreen}
            name="VideoCall"
            options={{headerTitle: "Video Call", headerShown: false}}
          />
        </AuthStack.Group>
      </AuthStack.Navigator>
    </NavContainer>
  );
};

const ConsentStack = createNativeStackNavigator<ConsentStackParamList>();

export const ConsentNavigation = ({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}): React.ReactElement => {
  return (
    <>
      <NavContainer colorScheme={colorScheme}>
        <ConsentStack.Navigator>
          <ConsentStack.Group screenOptions={{presentation: "modal"}}>
            <ConsentStack.Screen
              component={ConsentFormScreen}
              name="ConsentFormScreen"
              options={{title: "Flourish Health", headerShown: true}}
            />
            <ConsentStack.Screen
              component={PrivacyPolicyScreen}
              name="Privacy"
              options={{title: "Privacy Policy", headerShown: true}}
            />
            <ConsentStack.Screen
              component={HipaaPolicyScreen}
              name="HIPAA"
              options={{title: "HIPAA Compliance Statement", headerShown: true}}
            />
          </ConsentStack.Group>
        </ConsentStack.Navigator>
      </NavContainer>
      <StatusBar />
    </>
  );
};

/**
 * Patient Navigation and stack
 */
export const PatientNavigation = ({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}): React.ReactElement => {
  const {theme} = useTheme();

  /**
   * Shared between Patient and FamilyMember stacks
   */
  const userStackNavigatorScreenOptions = {
    headerTintColor: theme.surface.primary,
    headerBackTitle: "Back",
  };
  return (
    <>
      <PatientBrowserChecker />
      <NavContainer colorScheme={colorScheme}>
        <PatientStack.Navigator screenOptions={userStackNavigatorScreenOptions}>
          <PatientStack.Screen
            component={PatientBottomTabNavigator}
            name="Patient"
            options={{headerShown: false}}
          />
          {/* <PatientStack.Screen name="NotFound" component={NotFoundScreen} options={{title: "Oops!"}} /> */}
          <PatientStack.Screen component={ChatScreen} name="Chat" options={{title: "Chat"}} />
          <PatientStack.Screen
            component={UserBioScreen}
            name="UserBio"
            options={{title: "Staff Profile"}}
          />
          <PatientStack.Screen
            component={ViewFormScreen}
            name="ViewForm"
            options={{title: "View Form"}}
          />
          <PatientStack.Screen
            component={PatientResetPasswordScreen}
            name="ResetPassword"
            options={{title: "Reset Password"}}
          />
          <PatientStack.Screen
            component={NotificationsSettingsScreen}
            name="NotificationsSettings"
            options={{title: "Notification Settings"}}
          />
          <PatientStack.Screen
            component={ColorThemesScreen}
            name="ColorThemes"
            options={{headerTitle: "Color Theme"}}
          />
          <PatientStack.Screen
            component={VideoCallScreen}
            name="VideoCall"
            options={{title: "Video Call", headerTitle: "Video Call"}}
          />
        </PatientStack.Navigator>
      </NavContainer>
    </>
  );
};

const PatientStack = createNativeStackNavigator<PatientStackParamList>();

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const PatientBottomTab = createBottomTabNavigator<PatientTabParamList>();

const PatientBottomTabNavigator = (): React.ReactElement => {
  const {theme} = useTheme();
  /**
   * Shared between Patient and FamilyMember stacks
   */
  return (
    <PatientBottomTab.Navigator
      initialRouteName="Conversations"
      screenOptions={({route}) => ({
        tabBarActiveTintColor: theme.text.accent,
        tabBarShowLabel: false,
        tabBarInactiveTintColor: theme.text.secondaryLight,
        tabBarIcon: ({focused}: {focused: boolean}): React.ReactElement => {
          let iconName: IconName = "comment";
          let label = "Chat";
          const badgeCount = 0;
          let badgeStatus: BadgeProps["status"] | undefined;

          if (route.name === "Conversations") {
            iconName = "comment";
            label = "Chat";
          } else if (route.name === "Schedule") {
            iconName = "calendar";
            label = "Schedule";
          } else if (route.name === "CarePlan") {
            iconName = "notes-medical";
            label = "Care Plan";
          } else if (route.name === "Profile") {
            iconName = "user";
            label = "Profile";
          }
          return (
            <CustomTabBarItem
              badgeCount={badgeCount}
              badgeStatus={badgeStatus}
              focused={focused}
              iconName={iconName}
              label={label}
            />
          );
        },
      })}
    >
      <PatientBottomTab.Screen
        component={ConversationScreen}
        name="Conversations"
        options={{
          title: "Chat",
        }}
      />
      <PatientBottomTab.Screen
        component={ScheduleScreen}
        name="Schedule"
        options={(): any => ({
          title: "Schedule",
        })}
      />
      <PatientBottomTab.Screen
        component={CarePlanScreen}
        name="CarePlan"
        options={(): any => ({
          title: "Care Plan",

          // Leaving this because we'll likely want this button back pretty quickly.
          //   headerRight: (): React.ReactElement => (
          //     <Pressable
          //       style={({pressed}): any => ({
          //         opacity: pressed ? 0.5 : 1,
          //       })}
          //       onPress={(): void => navigation.navigate("Modal")}
          //     >
          //       <FontAwesome
          //         color={Colors[colorScheme].text}
          //         name="info-circle"
          //         size={25}
          //         style={{marginRight: 15}}
          //       />
          //     </Pressable>
          //   ),
        })}
      />
      <PatientBottomTab.Screen
        component={ProfilePage}
        name="Profile"
        options={{
          title: "Profile",
        }}
      />
    </PatientBottomTab.Navigator>
  );
};

/**
 *
 * Staff Navigation and stack
 */
const StaffStack = createNativeStackNavigator<StaffStackParamList>();
const StaffBottomTab = createInactivityBottomTabNavigator<StaffTabParamList>();

export const StaffNavigation = ({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}): React.ReactElement => {
  const profile = useReadProfile();
  const {data: conversationData} = useGetConversationsQuery(
    profile
      ? {
          type: {$in: ["Staff", "AllStaff", "Multi"]},
          "users.userId": profile._id,
        }
      : skipToken
  );

  const unreadCount =
    conversationData?.data?.reduce((acc, c) => acc + (c.unreadCount ?? 0), 0) ?? 0;

  const tagCount = conversationData?.data?.reduce((acc, c) => acc + (c.tagCount ?? 0), 0) ?? 0;

  const showSideDrawer = useSelectShowSideDrawer();
  const setShowSideDrawer = useSetShowSideDrawer();
  const sideDrawerComponentName = useSelectSideDrawerComponentName();

  const DrawerComponent =
    sideDrawerComponentName && isComponentMapKey(sideDrawerComponentName)
      ? SideDrawerComponents[sideDrawerComponentName]
      : SideDrawerComponents.UserUpdatesView;

  return (
    <>
      {IsWeb && <Favicon alertCount={tagCount} alertFillColor="#e3780c" url={favicon} />}

      <StaffBrowserChecker />
      <SpamDetectionToast />

      <NavContainer colorScheme={colorScheme}>
        <SideDrawer
          isOpen={showSideDrawer}
          position="right"
          renderContent={(): ReactElement => <DrawerComponent />}
          onClose={(): void => setShowSideDrawer(false)}
        >
          <StaffStack.Navigator screenOptions={{title: getBrowserTabTitle(unreadCount, tagCount)}}>
            <StaffStack.Screen
              component={StaffBottomTabNavigator}
              name="Staff"
              options={{headerShown: false}}
            />
            <StaffStack.Group
              screenOptions={{
                presentation: "transparentModal",
              }}
            >
              <StaffStack.Screen
                component={StillThereModal}
                name="StillThere"
                options={{title: "Still there?"}}
              />
            </StaffStack.Group>
            {/* <StaffStack.Screen name="NotFound" component={NotFoundScreen} options={{title: "Oops!"}} /> */}
            <StaffStack.Group screenOptions={{presentation: "modal"}}>
              <StaffStack.Screen
                component={BillingInfoScreen}
                name="BillingInfo"
                options={{headerTitle: "User Billing Info"}}
              />
              <StaffStack.Screen
                component={CarePodAdminScreen}
                name="CarePodAdmin"
                options={{headerTitle: "Care Pods Admin"}}
              />
              <StaffStack.Screen
                component={CarePodQAScreen}
                name="CarePodQA"
                options={{headerTitle: "CarePod QA"}}
              />
              <StaffStack.Screen
                component={CreateIntakeScreen}
                name="CreateIntake"
                options={{headerTitle: "Create Appointment"}}
              />
              <StaffStack.Screen
                component={CreateFamilyUnitScreen}
                name="CreateFamilyUnit"
                options={{headerTitle: "Create Family"}}
              />
              <StaffStack.Screen
                component={CreateFormScreen}
                name="CreateForm"
                options={{headerTitle: "Create Forms"}}
              />
              <StaffStack.Screen
                component={CreateScheduleItemScreen}
                name="CreateScheduleItem"
                options={{headerTitle: "Create Schedule Items"}}
              />
              <StaffStack.Screen
                component={CreateStaffScreen}
                name="CreateStaff"
                options={{headerTitle: "Create Staff"}}
              />
              <StaffStack.Screen
                component={CreateUserScreen}
                name="CreateUser"
                options={{headerTitle: "Create Users"}}
              />
              <StaffStack.Screen
                component={ContactScreen}
                name="Contacts"
                options={{headerTitle: "User Contact"}}
              />
              <StaffStack.Screen
                component={DoseSpotScreen}
                name="DoseSpotUI"
                options={{headerTitle: "DoseSpotUI"}}
              />
              <StaffStack.Screen
                component={DotPhraseAdminScreen}
                name="DotPhraseAdmin"
                options={{headerTitle: "Dot Phrase Admin"}}
              />
              <StaffStack.Screen
                component={InsurancePlanAdminScreen}
                name="InsurancePlanAdmin"
                options={{headerTitle: "Insurance Plan Admin"}}
              />
              <StaffStack.Screen
                component={ExternalClinicianAdminScreen}
                name="ExternalClinicianAdmin"
                options={{headerTitle: "External Clinician Admin"}}
              />
              <StaffStack.Screen component={GPTScreen} name="GPT" options={{headerTitle: "GPT"}} />
              <StaffStack.Screen
                component={GuidingHoursScreen}
                name="GuidingHours"
                options={{headerTitle: "Guiding Hours"}}
              />
              <StaffStack.Screen
                component={UserStatusAdminScreen}
                name="UserStatusAdmin"
                options={{headerTitle: "User Status Admin"}}
              />
              <StaffStack.Screen
                component={FormAdminPage}
                name="FormAdmin"
                options={{headerTitle: "Form Admin"}}
              />
              <StaffStack.Screen
                component={ManageConversationsScreen}
                name="ManageConversationsScreen"
                options={{headerTitle: "Create and Edit Conversations"}}
              />
              <StaffStack.Screen
                component={ManageFormScreen}
                name="ManageFormScreen"
                options={{headerTitle: "Update, Complete, and View Forms"}}
              />
              <StaffStack.Screen
                component={HealthItemAdminScreen}
                name="HealthItemAdminScreen"
                options={{headerTitle: "Health Event Admin"}}
              />
              <StaffStack.Screen
                component={MessageExplorerScreen}
                name="MessageExplorer"
                options={{headerTitle: "Message Explorer"}}
              />
              <StaffStack.Screen
                component={ReferralMethodAdminScreen}
                name="ReferralMethodAdmin"
                options={{headerTitle: "Referral Method Admin"}}
              />
              <StaffStack.Screen
                component={ReferralSourceAdminScreen}
                name="ReferralSourceAdmin"
                options={{headerTitle: "Referral Source Admin"}}
              />
              <StaffStack.Screen
                component={StaffResetPasswordScreen}
                name="ResetPassword"
                options={{headerTitle: "Reset Passwords"}}
              />
              <StaffStack.Screen
                component={NotificationsSettingsScreen}
                name="NotificationsSettings"
                options={{title: "Notification Settings"}}
              />
              <StaffStack.Screen
                component={OnCallScheduleScreen}
                name="OnCallScheduleScreen"
                options={{headerTitle: "On Call Crisis Consultant Schedule"}}
              />
              <StaffStack.Screen
                component={UserBioScreen}
                name="UserBio"
                options={{headerTitle: "Staff Profile"}}
              />
              <StaffStack.Screen
                component={UserExplorerScreen}
                name="UserExplorer"
                options={{headerTitle: "User Explorer"}}
              />
              <StaffStack.Screen
                component={AlertsExplorerScreen}
                name="AlertsExplorer"
                options={{headerTitle: "Alerts Explorer"}}
              />
              <StaffStack.Screen
                component={ToDoListExplorerScreen}
                name="ToDoListExplorer"
                options={{headerTitle: "To Do Lists"}}
              />
              <StaffStack.Screen
                component={UserPicker}
                name="UserPicker"
                options={{headerTitle: "Select User"}}
              />
              <StaffStack.Screen
                component={ViewFormScreen}
                name="ViewForm"
                options={{headerTitle: "View Form"}}
              />
              <StaffStack.Screen
                component={PreClinicUpdateScreen}
                name="PreClinicUpdates"
                options={{headerTitle: "Pre-Clinic Updates"}}
              />
              <StaffStack.Screen
                component={PharmacyScreen}
                name="PharmacyScreen"
                options={{headerTitle: "Manage Patient Pharmacies"}}
              />
              <StaffStack.Screen
                component={IntakeSchedulingScreen}
                name="IntakeScheduling"
                options={{headerTitle: "Appointment Scheduling"}}
              />
              <StaffStack.Screen
                component={ColorThemesScreen}
                name="ColorThemes"
                options={{headerTitle: "Color Theme"}}
              />
              <StaffStack.Screen
                component={DialerScreen}
                name="DialerScreen"
                options={{headerTitle: "Phone Call"}}
              />
              <StaffStack.Screen
                component={StatsScreenWeb}
                name="Stats"
                options={{headerTitle: "Stats"}}
              />
              <StaffStack.Screen
                component={AlertsAdminScreen}
                name="AlertsAdmin"
                options={{headerTitle: "Alerts Admin"}}
              />
              <StaffStack.Screen
                component={CompanyOrganizationAdminScreen}
                name="CompanyOrganizationAdmin"
                options={{headerTitle: "Flourish Company Organizations"}}
              />
              <StaffStack.Screen
                component={UserScreen}
                name="User"
                options={{
                  headerTitle: "User",
                  headerRight: (): React.ReactElement | null => {
                    return <HeaderRightButtons />;
                  },
                }}
              />
              <StaffStack.Screen
                component={MyTeamScreen}
                name="Team"
                options={{headerTitle: "My Team"}}
              />
              <StaffStack.Screen
                component={VacationFormScreen}
                name="Vacation"
                options={{headerTitle: "Vacation Form"}}
              />
              <StaffStack.Screen
                component={VideoCallScreen}
                name="VideoCall"
                options={{headerTitle: "Video Call"}}
              />
            </StaffStack.Group>
          </StaffStack.Navigator>
        </SideDrawer>
      </NavContainer>
    </>
  );
};

export const backButtonNavOptions = {
  // tabBarIcon: ({color, focused}: {color: string; focused: boolean}): React.ReactElement => (
  //   <TabBarIcon color={color} focused={focused} name="house" />
  // ),
};

const StaffBottomTabNavigator = (): ReactElement => {
  const profile = useReadProfile();
  const dispatch = useAppDispatch();

  const {data: userAvatarList} = useGetAvatarsQuery(
    profile?._id ? {ownerId: profile?._id} : skipToken
  );

  const {data: userSettingsRes} = useGetUserSettingsByIdQuery(profile?._id.toString() || skipToken);
  const userAvatar = userAvatarList?.data?.[0];
  const {theme} = useTheme();
  const {data: conversationData} = useGetConversationsQuery(
    profile?._id
      ? ({
          type: {$in: ["Staff", "AllStaff", "Multi"]},
          "users.userId": profile?._id,
        } as any)
      : skipToken
  );
  const unreadCount =
    conversationData?.data?.reduce((acc, c) => acc + (c.unreadCount ?? 0), 0) ?? 0;

  const internalChatTagCount =
    conversationData?.data
      ?.filter((c) => ["Staff", "AllStaff", "Multi"].includes(c.type))
      .reduce((acc, c) => acc + (c.tagCount ?? 0), 0) ?? 0;

  let profileBadgeCount = 0;
  if (!userAvatar) {
    profileBadgeCount++;
  }
  if (profile) {
    profileBadgeCount += getMissingProfileFields(profile).length;
  }

  const {data: preClinicUpdateData} = useGetPreClinicUpdatesQuery(
    profile?.staffRoles.Psychiatrist ? {ownerId: profile._id, page: 1} : skipToken
  );

  const unreadPreClinicUpdates = preClinicUpdateData?.data?.filter((p) => !p.readByOwner).length;

  const defaultWorkflowNavOptions = {
    title: "Panels",
    headerRight: (): React.ReactElement | null => {
      return <HeaderRightButtons />;
    },
  };

  const defaultInternalChatNavOptions = {
    headerTitle: "Internal Chat",
    tabBarLabel: "Internal Chat",
    // Same as the TODO for defaultWorkflowNavOptions headerRight
    headerRight: (): React.ReactElement | null => <HeaderRightButtons />,
  };

  // Update user settings app state for the rest of the app
  useEffect(() => {
    if (!userSettingsRes) {
      return;
    }
    const userSettings = userSettingsRes;
    dispatch(setUserSettings(userSettings));
  }, [dispatch, userSettingsRes]);

  return (
    <StaffBottomTab.Navigator
      initialRouteName="Workflows"
      screenOptions={({route}) => ({
        tabBarActiveTintColor: theme.text.accent,
        tabBarShowLabel: false,
        tabBarInactiveTintColor: theme.text.secondaryLight,
        tabBarIcon: ({focused}: {focused: boolean}): React.ReactElement => {
          let iconName: IconName = "house";
          let label = getBrowserTabTitle(unreadCount, internalChatTagCount);
          let badgeCount = 0;
          let badgeStatus: BadgeProps["status"] | undefined;

          if (route.name === "Workflows") {
            iconName = "house";
            label = "Workflows";
          } else if (route.name === "InternalChatScreen") {
            iconName = "comment";
            label = "Internal Chat";
            // if tags, show count for tags
            // if no tags but unread messages, show solid badge
            // if no tags or unread do not show a badge
            badgeCount = internalChatTagCount ? internalChatTagCount : unreadCount;
            badgeStatus = internalChatTagCount ? "warning" : "info";
          } else if (route.name === "Scheduling") {
            iconName = "calendar";
            label = "Scheduling";
          } else if (route.name === "Tools") {
            iconName = "code";
            label = "Tools";
          } else if (route.name === "Profile") {
            badgeCount = profileBadgeCount;
            badgeStatus = profileBadgeCount ? "error" : undefined;
            iconName = "user-large";
            label = "Profile";
          } else if (route.name === "PreClinicUpdates") {
            badgeCount = unreadPreClinicUpdates || 0;
            badgeStatus = unreadPreClinicUpdates ? "warning" : undefined;
            iconName = "list";
            // Shrink so it fits the mobile bar better
            label = isMobileDevice() ? "Pre-Clinic" : "Pre-Clinic Updates";
            badgeCount = unreadPreClinicUpdates ?? 0;
            badgeStatus = unreadPreClinicUpdates ? "warning" : undefined;
          }

          return (
            <CustomTabBarItem
              badgeCount={badgeCount}
              badgeStatus={badgeStatus}
              focused={focused}
              iconName={iconName}
              label={label}
            />
          );
        },
      })}
    >
      <StaffBottomTab.Screen
        component={StaffScreen}
        name="Workflows"
        options={(): any => defaultWorkflowNavOptions}
      />
      <StaffBottomTab.Screen
        component={StaffSchedulingScreen}
        name="Scheduling"
        options={(): any => ({
          headerTitle: "Scheduling",
          headerRight: (): React.ReactElement => <HeaderRightButtons />,
          tabBarLabel: "Scheduling",
        })}
      />
      {Boolean(profile?.staffRoles.Psychiatrist) ? (
        <StaffBottomTab.Screen
          component={PreClinicUpdateScreen}
          name="PreClinicUpdates"
          options={{
            headerTitle: "Pre-Clinic Updates",
            tabBarLabel: "Pre-Clinic Updates",
            headerRight: (): React.ReactElement | null => <HeaderRightButtons defaultIcons />,
          }}
        />
      ) : (
        <StaffBottomTab.Screen
          component={InternalChatScreen}
          name="InternalChatScreen"
          options={(): any => defaultInternalChatNavOptions}
        />
      )}
      <StaffBottomTab.Screen
        component={StaffToolsPage}
        name="Tools"
        options={{
          headerTitle: "Tools",
          tabBarLabel: "Tools",
          headerRight: (): React.ReactElement | null => <HeaderRightButtons defaultIcons />,
        }}
      />
      <StaffBottomTab.Screen
        component={ProfilePage}
        name="Profile"
        options={{
          headerTitle: "Profile",
          tabBarLabel: "Profile",
        }}
      />
    </StaffBottomTab.Navigator>
  );
};

interface CustomTabBarItemProps {
  iconName: IconName;
  label: string;
  focused: boolean;
  badgeStatus?: BadgeProps["status"];
  badgeCount?: number | string;
}

const CustomTabBarItem = ({
  focused,
  iconName,
  badgeCount,
  badgeStatus,
  label,
}: CustomTabBarItemProps): React.ReactElement => {
  const {theme} = useTheme();

  // No extra padding for web, Android has less space on the tab bar, iOS has more.
  // Add extra 2px of padding on mobile to account for the border of the focused tab.
  let paddingTop = 0;
  if (Platform.OS === "android") {
    paddingTop = focused ? 4 : 6;
  } else if (Platform.OS === "ios") {
    paddingTop = focused ? 16 : 18;
  }
  return (
    <Box
      alignItems="center"
      dangerouslySetInlineStyle={{
        __style: {
          borderTopWidth: focused ? 2 : 0,
          borderTopColor: focused ? theme.text.accent : undefined,
          paddingTop,
        },
      }}
      direction="column"
      flex="grow"
      height="100%"
      justifyContent="center"
      mdDirection="row"
      minWidth={80}
    >
      <Box marginBottom={IsMobileDevice ? 2 : 0} marginRight={IsMobileDevice ? 0 : 2}>
        <TabBarIcon focused={focused} name={iconName} />
      </Box>
      <Box direction="row" justifyContent="center">
        <Text
          color={focused ? "accent" : "secondaryLight"}
          size={mediaQuerySmallerThan("sm") ? "sm" : "lg"}
        >
          {label}
        </Text>
        <Box display={badgeCount && !IsMobileDevice ? "flex" : "none"} marginLeft={2}>
          <Badge maxValue={9} status={badgeStatus} value={badgeCount} variant="numberOnly" />
        </Box>
      </Box>
      <Box
        display={badgeCount && IsMobileDevice ? "flex" : "none"}
        marginRight={1}
        marginTop={1}
        position="absolute"
        right
        top
      >
        <Badge maxValue={9} status={badgeStatus} value={badgeCount} variant="numberOnly" />
      </Box>
    </Box>
  );
};

const TabBarIcon = ({name, focused}: {name: IconName; focused: boolean}): React.ReactElement => {
  const {theme} = useTheme();
  return (
    <FontAwesome6
      color={focused ? theme.text.accent : theme.text.secondaryLight}
      name={name}
      regular
      size={20}
    />
  );
};
