import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  FormInstance,
  FormInstanceWithForm,
  FormValidator,
  GetFormInstancesArgs,
  OPEN_NOTE_TYPES_ENGAGEMENT,
  useDeleteFormInstancesByIdMutation,
  useGetFormInstancesQuery,
  useGetFormsQuery,
  usePatchFormInstancesByIdMutation,
  usePostFormInstancesMutation,
  useSentryAndToast,
} from "@store";
import {
  Box,
  Button,
  DateTimeField,
  IconButton,
  Pagination,
  printDateAndTime,
  SelectField,
  Text,
  useToast,
} from "ferns-ui";
import {DateTime} from "luxon";
import React, {ReactElement, useCallback, useMemo, useState} from "react";

import {FreeformTextArea} from "./formInstanceQuestions/FreeformTextArea";
import {SingleSelect} from "./formInstanceQuestions/SingleSelect";

const PAGE_SIZE = 25;

type AuthorType = "PatientGuide" | "FamilyGuide" | "Therapist" | "Psychiatrist";

interface OpenNotesProps {
  userId: string;
}

export const OpenNotesPane = ({userId}: OpenNotesProps): ReactElement | null => {
  const profile = useReadProfile();
  const sentryAndToast = useSentryAndToast();
  const toast = useToast();
  const [removeNote, {isLoading: removeLoading}] = useDeleteFormInstancesByIdMutation();
  const [updateNote, {isLoading: updateLoading}] = usePatchFormInstancesByIdMutation();
  const [createNote, {isLoading: createLoading}] = usePostFormInstancesMutation();
  // create/update note states
  const [showCreate, setShowCreate] = useState(false);
  const [_id, setId] = useState<string | undefined>(undefined);
  const [type, setType] = useState<string>("Guide App/Text Interaction");
  const [text, setText] = useState("");
  const [serviceDate, setServiceDate] = useState<string | undefined>();
  const [attendanceStatus, setAttendanceStatus] = useState<
    FormInstance["attendanceStatus"] | undefined
  >(undefined);
  const [selectedAttendeeIds, setSelectedAttendeeIds] = useState<{userId: string; _id?: string}[]>(
    []
  );
  // filter control states
  const [page, setPage] = useState(1);
  const [authorTypeFilter, setAuthorTypeFilter] = useState<AuthorType | undefined>(undefined);
  const [noteTypeFilter, setNoteTypeFilter] = useState<string | undefined>(undefined);

  const {data: formData} = useGetFormsQuery({name: "Open Notes"});
  const openForm = formData?.data?.[0];
  let query: GetFormInstancesArgs = {
    userId,
    formId: openForm?._id,
    type: "Note",
    limit: PAGE_SIZE,
    page,
  };
  if (noteTypeFilter) {
    query = {...query, "answers.1.answers.0": noteTypeFilter};
  }
  if (authorTypeFilter) {
    query.authorType = authorTypeFilter;
  }
  const {data: notesData} = useGetFormInstancesQuery(formData?.data?.length ? query : skipToken);
  const notes = notesData?.data ?? [];

  if ((formData?.data?.length ?? 0) > 1) {
    sentryAndToast("Found multiple Open Notes forms.");
  }

  const resetAttendanceState = useCallback(() => {
    setAttendanceStatus(undefined);
    setSelectedAttendeeIds([]);
  }, []);

  const resetCreateNoteStates = useCallback(() => {
    setShowCreate(false);
    resetAttendanceState();
    setText("");
    setId(undefined);
    setType("Guide App/Text Interaction");
    setServiceDate(undefined);
  }, [resetAttendanceState]);

  const payload = useMemo(() => {
    if (!openForm || !profile) {
      return undefined;
    }
    return {
      userId,
      createdBy: profile._id,
      formId: openForm._id,
      type: "Note" as FormInstance["type"],
      answers: [
        {
          prompt: openForm.questions[0].prompt,
          questionId: openForm.questions[0]._id!,
          answers: [text],
        },
        {
          prompt: openForm.questions[1].prompt,
          questionId: openForm.questions[1]._id!,
          answers: [type],
        },
      ],
      attendanceStatus: attendanceStatus ?? (null as unknown as string | undefined),
      attended: selectedAttendeeIds,
      serviceDate,
    };
  }, [profile, userId, openForm, text, type, attendanceStatus, selectedAttendeeIds, serviceDate]);

  const formErrors = useMemo(() => {
    if (!openForm || !payload) {
      return ["Form not found"];
    }
    const testPayload = payload as any as FormInstanceWithForm;
    testPayload.form = openForm;
    return FormValidator.validateFormInstance(testPayload);
  }, [payload, openForm]);

  const disableSave = useMemo(() => {
    if (updateLoading) {
      return true;
    }
    if (formErrors) {
      return true;
    }
    return false;
  }, [updateLoading, formErrors]);

  const handleSaveNote = useCallback(async () => {
    if (!openForm || !payload) {
      return;
    }
    try {
      if (!_id) {
        await createNote({
          ...payload,
          status: "Completed",
          completedDate: DateTime.now().toISO(),
        } as any).unwrap();
      } else {
        await updateNote({
          id: _id,
          body: payload as any,
        });
      }
      resetCreateNoteStates();
    } catch {
      toast.error("Error saving note");
    }
  }, [payload, openForm, _id, resetCreateNoteStates, createNote, updateNote, toast]);

  if (!openForm || !profile) {
    return null;
  }

  if (!openForm.questions[1]?.options.length) {
    console.warn("No note type options found.");
  }

  return (
    <Box gap={4}>
      {/* Filter Controls */}
      <Box direction="row" width="100%">
        <Box marginRight={5} width="50%">
          <SelectField
            options={[
              {label: "Guide", value: "Guide"},
              {label: "Therapist", value: "Therapist"},
              {label: "Psychiatrist", value: "Psychiatrist"},
            ]}
            placeholder="All"
            requireValue={false}
            title="Author Type"
            value={authorTypeFilter ?? undefined}
            onChange={(value?: string): void => {
              setAuthorTypeFilter(value as AuthorType | undefined);
            }}
          />
        </Box>
        <Box flex="grow">
          <SelectField
            options={[
              ...openForm.questions[1].options?.map((o) => ({label: o.label, value: o.label})),
            ]}
            placeholder="All"
            requireValue={false}
            title="Note Type"
            value={noteTypeFilter ?? ""}
            onChange={setNoteTypeFilter}
          />
        </Box>
      </Box>
      <Box gap={2} maxHeight={300} scroll>
        {/* All Open Notes View */}
        {notes.map((note) => {
          return (
            <Box
              key={note._id}
              border="default"
              direction="row"
              justifyContent="center"
              padding={2}
              rounding="md"
            >
              <Box direction="column" flex="grow" justifyContent="center">
                <Text>{note.answers[0]?.answers}</Text>
                <Text color="secondaryLight">{`Date of Encounter: ${note?.serviceDate ? printDateAndTime(note.serviceDate) : printDateAndTime(note.created)}`}</Text>
                <Text color="secondaryLight">
                  {note.answers[1]?.answers[0] ? `${note.answers[1]?.answers[0]}, ` : ""}
                  {note.createdBy?.name}
                </Text>
              </Box>
              <IconButton
                accessibilityLabel="edit"
                iconName="pencil"
                variant="muted"
                onClick={(): void => {
                  setShowCreate(true);
                  setId(note._id);
                  setType(note.answers[1]?.answers[0]);
                  setText(note.answers[0]?.answers[0]);
                  setAttendanceStatus(
                    note?.attendanceStatus !== "Unknown" ? note.attendanceStatus : undefined
                  );
                  setSelectedAttendeeIds(note?.attended ?? []);
                  setServiceDate(note?.serviceDate ?? note.created);
                }}
              />
            </Box>
          );
        })}
      </Box>
      <Pagination
        page={page}
        setPage={setPage}
        totalPages={Math.ceil((notesData?.total ?? 0) / (notesData?.limit ?? 1))}
      />
      {Boolean(showCreate) && (
        <Box paddingY={3}>
          <Box paddingY={1}>
            <SingleSelect
              answerRequiredErr={FormValidator.validateAnswerRequirements(openForm.questions[1], [
                type,
              ])}
              disabled={false}
              label="Note Type"
              question={openForm.questions[1]}
              value={[type]}
              onChange={(value): void => {
                if (!OPEN_NOTE_TYPES_ENGAGEMENT.includes(value[0]) && attendanceStatus) {
                  resetAttendanceState();
                }
                setType(value[0]);
              }}
            />
          </Box>
          <Box paddingY={1}>
            <DateTimeField
              helperText="Note: You are reporting in your local timezone."
              title="Date Of Encounter"
              type="datetime"
              value={serviceDate}
              /*  default to schedule item start time if linking a schedule item */
              onChange={(r?: string): void => {
                setServiceDate(r);
              }}
            />
          </Box>
          {/* Only show attendance status if service date is selected and note type counts as engagement */}
          {Boolean(serviceDate) && OPEN_NOTE_TYPES_ENGAGEMENT.includes(type) && (
            <Box direction="row" width="100%">
              <Box flex="grow" marginRight={2} maxWidth="50%">
                <SelectField
                  options={[
                    ...["Attended", "No-Show", "Cancellation", "Reschedule"].map((status) => {
                      return {label: status, value: status};
                    }),
                  ]}
                  placeholder="None selected"
                  requireValue={false}
                  title="Attendance Status (Required):"
                  value={attendanceStatus}
                  onChange={(status: string | undefined): void => {
                    if (!status) {
                      resetAttendanceState();
                    } else {
                      setAttendanceStatus(status as FormInstance["attendanceStatus"]);
                      if (status === "Attended") {
                        setSelectedAttendeeIds([{userId}]);
                      } else {
                        setSelectedAttendeeIds([]);
                      }
                    }
                  }}
                />
              </Box>
            </Box>
          )}
          <Box paddingY={1}>
            <FreeformTextArea
              answerRequiredErr={FormValidator.validateAnswerRequirements(openForm.questions[0], [
                text,
              ])}
              disabled={false}
              placeholder="Add your note text here..."
              value={[text]}
              onChange={(value): void => setText(value[0])}
            />
          </Box>
          <Box direction="row" gap={4} width="100%">
            <Button
              disabled={disableSave}
              loading={updateLoading || createLoading}
              text={_id ? "Update Note" : "Save"}
              variant="secondary"
              onClick={handleSaveNote}
            />
            {_id && (
              <Button
                confirmationText="Are you sure you want to delete this note? Your document will not be recoverable."
                disabled={removeLoading}
                loading={removeLoading}
                text="Delete"
                variant="destructive"
                withConfirmation
                onClick={async (): Promise<void> => {
                  await removeNote(_id as string);
                  resetCreateNoteStates();
                }}
              />
            )}
            <Button
              confirmationText="Are you sure you want to close? Your changes will not be saved."
              text="Close"
              variant="muted"
              withConfirmation
              onClick={(): void => {
                resetCreateNoteStates();
              }}
            />
          </Box>
          {formErrors &&
            formErrors.map((error, i) => (
              <Box key={`${error}-${i}`} paddingY={1}>
                <Text color="error">{error}</Text>
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};
